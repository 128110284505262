import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { ImageOutlined } from "@mui/icons-material";
import {
  ContentCategoryRequest,
  ContentRequest,
  ContentResponse,
  DeleteFileRequest,
  ResponseStatus,
  Status,
  Tag,
} from "api/models/gamification-service/gamification_pb";
import { firebaseAuth } from "config/firebase";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import ImageCrop from "components/image-crop/image-crop";
import DialogBox from "components/dialogBox/dialog";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { useFileUploadService } from "services/FileUplaodService";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RootState } from "redux/store/store";
import { Crop } from "react-image-crop";
import { getSelfDevelopmentContentByCategory } from "redux/actions/selfDevelopmentActions";
import { getAllTags } from "redux/actions/tagActions";

interface ArticlesProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20;
  page: number;
  rowsPerPage: number;
  type: 1 | 2 | 3;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const PREFIX = "ArticlesForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export const SelfDevelopmentArticlesForm = React.forwardRef<
  HTMLDivElement,
  ArticlesProps
>((props, ref): ReactElement => {
  const cropDef: Crop = {
    x: 25,
    y: 25,
    height: 20,
    width: 20,
    unit: "%",
  };
  const typeList = [
    {
      displayName: "Mind",
      code: 1,
    },
    {
      displayName: "Body",
      code: 2,
    },
    {
      displayName: "Spirit",
      code: 3,
    },
  ];
  const articleCategoryList = [
    {
      displayName: "Featured article",
      code: 1,
    },
    {
      displayName: "All articles",
      code: 2,
    },
  ];

  const dispatch = useDispatch();

  const tagsList = useSelector((state: RootState) => state.tags.tagList);

  const languages = useSelector((state: RootState) => state.app.languages);
  const { appendFilesToUploadManager } = useFileUploadService();
  const [language, setLanguage] = useState<any>(1);
  const [states, setStates] = useState<State>(initialState);
  const [id, setId] = useState<string>("");
  const [type, setType] = useState<any>(1);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [author, setAuthor] = useState<string>("");
  const [readingTime, setReadingTime] = useState<number>(0);
  const [content, setContent] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articleCategory, setArticleCategory] = useState<any>(1);

  const [imgUrl, setImgUrl] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [imageCrop, setImageCrop] = useState<Crop>(cropDef);
  const [imageRef, setImageRef] = useState<any>();
  const [imageFile, setImageFile] = useState<any>(null);

  const [thumbnailUrl, setThumbnailUrl] = useState<any>(null);
  const [thumbnailSrc, setThumbnailSrc] = useState<any>(null);
  const [thumbnailCrop, setThumbnailCrop] = useState<Crop>(cropDef);
  const [thumbnailRef, setThumbnailRef] = useState<any>();
  const [thumbnailFile, setThumbnailFile] = useState<any>(null);

  const [featuredimageUrl, setFeaturedimageUrl] = useState<any>(null);
  const [featuredimageSrc, setFeaturedimageSrc] = useState<any>(null);
  const [featuredimageCrop, setFeaturedimageCrop] = useState<Crop>(cropDef);
  const [featuredimageRef, setFeaturedimageRef] = useState<any>();
  const [featuredimageFile, setFeaturedimageFile] = useState<any>(null);
  const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);

  const [isAdding, setIsAdding] = useState(false);

  const hiddenFileInput1: any = React.useRef(null);
  const hiddenFileInputThumbnail: any = React.useRef(null);
  const hiddenFileInputFeaturedimage: any = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState({
    image: false,
    thumbnail: false,
    featuredimage: false,
    selectedImage: false,
  });

  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback(
    (item: any) => {
      setStates({ editing: true });
      setLanguage(props.lang);
      setId(item.id);
      setTitle(item.title);
      setDescription(item.subtitle);
      setAuthor(item.author);
      setReadingTime(item.readingtime);
      setContent(item.content);
      setImgUrl(item.image);
      setThumbnailUrl(item.thumbnail);
      setFeaturedimageUrl(item.featuredimage);
      setArticleCategory(item.featuredcontent);
      setType(item.discover);
      setSelectedTagsId(item.tagidsList);
      setSelectedTags(item.tagsList.map((item) => item.name));
    },
    [props.lang]
  );

  useEffect(() => {
    console.log(props.editItem);
    if (props.isEditing) {
      onClickEdit(props.editItem);
    }
  }, [props.isEditing, props.editItem, onClickEdit]);

  useEffect(() => {
    dispatch(getAllTags(10, page));
  }, [page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const _deleteFileFromGcp = async (data: any) => {
    try {
      console.log("image url to delete from gcp", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };

  const handleUploadClick1 = () => {
    hiddenFileInput1.current.click();
  };

  const handleUploadClickThumbnail = () => {
    hiddenFileInputThumbnail.current.click();
  };

  const handleUploadClickFeaturedimage = () => {
    hiddenFileInputFeaturedimage.current.click();
  };

  const onOpenImage = () => {
    setOpen(true);
  };
  const onCloseImage = () => {
    setOpen(false);
    setImageSrc("");
    setImageCrop(cropDef);
    setIsUploading({ ...isUploading, image: false, selectedImage: false });
  };
  const onCloseThumbnail = () => {
    setOpen(false);
    setThumbnailSrc("");
    setThumbnailCrop(cropDef);
    setIsUploading({ ...isUploading, image: false, selectedImage: false });
  };
  const onCloseFeaturedImage = () => {
    setOpen(false);
    setFeaturedimageSrc("");
    setFeaturedimageCrop(cropDef);
    setIsUploading({ ...isUploading, image: false, selectedImage: false });
  };

  const onSelectImage1 = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, image: true });
      setImageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setOpen(true);
      };
    }
  };

  const onSelectThumbnail = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, thumbnail: true });
      setThumbnailFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setThumbnailSrc(reader.result);
        setOpen(true);
      };
    }
  };

  const handleMultipleTagChange = (event: any) => {
    const {
      target: { value },
    } = event;
    // console.log(value);

    const arrOfNum: number[] = (
      typeof value === "string" ? value.split(",") : value
    ).map((str) => {
      return Number(str);
    });
    const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
    const tagIndex = selectedTagsId.indexOf(val[0]);

    setSelectedTagsId(arrOfNum);

    if (tagIndex > -1) {
      setSelectedTags(() => {
        const updatedTags = [...selectedTags];
        updatedTags.splice(tagIndex, 1);
        // console.log("updated Tags: ", updatedTags);
        return updatedTags;
      });
    } else if (tagIndex === -1) {
      setSelectedTags(() => {
        const temp = tagsList.tags.tagsList.filter((item) =>
          value.includes(item.id)
        );

        const arrOfString = temp.map((item: any) => item.name);
        return [...new Set([...selectedTags, ...arrOfString])];
      });
    }
  };

  const tagsArray = () => {
    let arr: Tag[] = [];
    for (const tagIndex in selectedTags) {
      const temp = new Tag();
      temp.setId(selectedTagsId[tagIndex]);
      temp.setName(selectedTags[tagIndex]);
      arr.push(temp);
    }

    console.log("tagsArray: ", arr);
    return arr;
  };

  const onSelectFeaturedimage = (event: any) => {
    if (event.target.files[0]) {
      setIsUploading({ ...isUploading, featuredimage: true });
      setFeaturedimageFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        setFeaturedimageSrc(reader.result);
        setOpen(true);
      };
    }
  };

  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropComplete = async () => {};

  const onCropDone = async () => {
    const cr: any = imageCrop;
    if (imageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(imageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      setImgUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? "resources/article-images"
            : `resources/article-images/${languages[language - 1].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setImgUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, image: false });
          } else {
            setIsUploading({ ...isUploading, image: false });
          }
        }
      );
    }
    setImageSrc(null);
  };

  const onCropChange = (crop: any) => {
    setImageCrop(crop);
  };

  const onImageClose = () => {
    setImageSrc(null);
    _deleteFileFromGcp(imgUrl);
    setImgUrl("");
    setImageFile(null);
    setImageCrop(cropDef);
    setIsUploading({ ...isUploading, image: false });
  };

  const onThumbnailLoaded = (image: any) => {
    setThumbnailRef(image);
  };

  const onThumbnailCropDone = async () => {
    const cr: any = thumbnailCrop;
    if (thumbnailRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(thumbnailRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${thumbnailFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      setThumbnailUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? "resources/article-images/thumbnails"
            : `resources/article-images/thumbnails/${languages[
                language - 1
              ].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setThumbnailUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, thumbnail: false });
          } else {
            setIsUploading({ ...isUploading, thumbnail: false });
          }
        }
      );
    }
    setThumbnailSrc(null);
  };

  const onThumbnailCropChange = (crop: any) => {
    setThumbnailCrop(crop);
  };

  const onThumbnailClose = () => {
    setThumbnailSrc(null);
    _deleteFileFromGcp(thumbnailUrl);
    setThumbnailUrl("");
    setThumbnailFile(null);
    setThumbnailCrop(cropDef);
    setIsUploading({ ...isUploading, thumbnail: false });
  };

  const onFeaturedimageLoaded = (image: any) => {
    setFeaturedimageRef(image);
  };

  const onFeaturedimageCropDone = async () => {
    const cr: any = featuredimageCrop;
    if (featuredimageRef && cr.width && cr.height) {
      const croppedUrl = await getCroppedImg(featuredimageRef, cr);
      const croppedFile: File = dataURLtoFile(
        croppedUrl,
        `${new Date().getTime().toString()}_${featuredimageFile.name.replaceAll(
          " ",
          ""
        )}`
      );
      setFeaturedimageUrl(croppedUrl);
      const uploadFile = toUploadFile(
        croppedFile,
        `${
          language === 1
            ? "resources/article-images/featured-images"
            : `resources/article-images/featured-images/${languages[
                language - 1
              ].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setFeaturedimageUrl(res.detail.response);
            console.log("image url res", res.detail.response);
            setIsUploading({ ...isUploading, featuredimage: false });
          } else {
            setIsUploading({ ...isUploading, featuredimage: false });
          }
        }
      );
    }
    setFeaturedimageSrc(null);
  };

  const onFeaturedimageCropChange = (crop: any) => {
    setFeaturedimageCrop(crop);
  };

  const onFeaturedimageClose = () => {
    setFeaturedimageSrc(null);
    _deleteFileFromGcp(featuredimageUrl);
    setFeaturedimageUrl("");
    setFeaturedimageFile(null);
    setFeaturedimageCrop(cropDef);
    setIsUploading({ ...isUploading, featuredimage: false });
  };

  const onClickDelete = (articleId: any) => {
    setStates({ showDeleteModal: true, deleteItemId: articleId });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };

  const deleteArticle = async () => {
    deleteArticleById(states.deleteItemId);
  };

  const deleteArticleById = async (articleId: String | undefined) => {
    setIsLoading(true);
    console.log(`Delete call for article with id ${articleId}`);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ContentCategoryRequest = new ContentCategoryRequest();
      reqBody.setContentid(Number(articleId));
      reqBody.setContentcategory(1);
      grpc.unary(GamificationService.DeleteContentByIDAndCategory, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(`Delete article response ${message.toObject()}`);
              setIsLoading(false);
              _deleteFileFromGcp(imgUrl);
              setStates({ showDeleteModal: false });
              setTimeout(() => props.onClickBack(), 2000);
              dispatch(
                getSelfDevelopmentContentByCategory(
                  props.lang,
                  props.type,
                  props.page,
                  props.rowsPerPage
                )
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted article successfully",
              });
            } else {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "Error",
                message: "Error in  deleting article",
              });
            }
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      console.log(`Error: ${err}`);
    }
  };

  const validateForm = () => {
    if (title.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article title can not be empty.",
      });
    } else if (author.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article author can not be empty.",
      });
    } else if (readingTime <= 0) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article reading time can not be less than or equal to zero.",
      });
    } else if (readingTime > 10000000) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article reading time can not be more than 10000000",
      });
    } else if (Number(readingTime) - Math.floor(Number(readingTime)) !== 0) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article reading time can not be in decimal.",
      });
    } else if (content.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article content can not be empty.",
      });
    } else if (!imgUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article image can not be empty.",
      });
    } else if (!thumbnailUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article thumbnail can not be empty.",
      });
    } else if (!featuredimageUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article feature image can not be empty.",
      });
    } else if (description.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Article descrption can not be empty.",
      });
    } else if (selectedTagsId.length === 0) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Minimum 1 tag needs to be selected",
      });
    } else if (selectedTagsId.length > 3) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Maximum 3 tags can be selected",
      });
    } else {
      return true;
    }
  };

  const _addArticle = () => {
    var articleData: any = {};
    if (validateForm()) {
      switch (props.lang) {
        case 1:
          articleData = {
            title: {
              defaultContent: title,
              en: title,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
            },
            description: {
              defaultContent: description,
              en: description,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
            },
            author: {
              defaultContent: author,
              en: author,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: content,
              en: content,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 2:
          articleData = {
            title: {
              defaultContent: title,
              en: title,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: description,
              en: description,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: author,
              en: author,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: content,
              en: content,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 3:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: title,
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: description,
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: author,
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: content,
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 4:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: title,
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: description,
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: author,
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: content,
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 5:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: title,
              ta: "",
              ka: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: description,
              ta: "",
              ka: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: author,
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: content,
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 6:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: title,
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: description,
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: author,
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: content,
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 7:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: title,
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: description,
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: author,
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: content,
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 8:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: title,
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: description,
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: author,
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: content,
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 9:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: title,
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: description,
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: author,
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: content,
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 10:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: title,
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: description,
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: author,
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: content,
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 11:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: title,
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: description,
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: author,
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: content,
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 12:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: title,
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: description,
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: author,
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: content,
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 13:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: title,
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: description,
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: author,
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: content,
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 14:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: title,
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: description,
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: author,
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: content,
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 15:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: title,
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: description,
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: author,
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: content,
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 16:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: title,
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: description,
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: author,
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: content,
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 17:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: title,
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: description,
              od: "",
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: author,
              od: "",
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: content,
              od: "",
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 18:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: title,
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: description,
              enUs: "",
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: author,
              enUs: "",
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: content,
              enUs: "",
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 19:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: title,
              enAu: "",
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: description,
              enAu: "",
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: author,
              enAu: "",
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: content,
              enAu: "",
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
        case 20:
          articleData = {
            title: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: title,
            },
            description: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: description,
            },
            author: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: author,
            },
            content: {
              defaultContent: "",
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: content,
            },
            image: imgUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            articleCategory: articleCategory,
            readingTime: readingTime,
            discover: type,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 1,
            video: "",
          };
          break;
      }
      if (props.isEditing) {
        setIsAdding(true);
        updateArticle(articleData);
      } else {
        setIsAdding(true);
        const articleData = {
          title: {
            defaultContent: title,
            en: title,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
          },
          description: {
            defaultContent: description,
            en: description,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
          },
          author: {
            defaultContent: author,
            en: author,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
          },
          content: {
            defaultContent: content,
            en: content,
            hi: "",
            te: "",
            gu: "",
            ta: "",
            ka: "",
            mr: "",
            it: "",
            ru: "",
            es: "",
            fr: "",
            pt: "",
            de: "",
            bn: "",
            ml: "",
            uk: "",
            od: "",
            enUs: "",
            enAu: "",
          },
          image: imgUrl,
          thumbnail: thumbnailUrl,
          featuredimage: featuredimageUrl,
          articleCategory: articleCategory,
          readingTime: readingTime,
          discover: type,
          isWebView: false,
          webViewUrl: "",
          contentCategory: 1,
          video: "",
        };
        addArticle(articleData);
      }
    }
  };

  const updateArticle = async (data: any) => {
    setIsLoading(true);
    try {
      console.log(`Request to updating article ${data.title.hi}`);

      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultContent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultContent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);

      const authorLocale: Locale = new Locale();
      authorLocale.setDefaultcontent(data.author.defaultContent);
      authorLocale.setEn(data.author.en);
      authorLocale.setHi(data.author.hi);
      authorLocale.setTe(data.author.te);
      authorLocale.setGu(data.author.gu);
      authorLocale.setTa(data.author.ta);
      authorLocale.setKa(data.author.ka);
      authorLocale.setMr(data.author.mr);
      authorLocale.setIt(data.author.it);
      authorLocale.setRu(data.author.ru);
      authorLocale.setEs(data.author.es);
      authorLocale.setFr(data.author.fr);
      authorLocale.setPt(data.author.pt);
      authorLocale.setDe(data.author.de);
      authorLocale.setBn(data.author.bn);
      authorLocale.setMl(data.author.ml);
      authorLocale.setUk(data.author.uk);
      authorLocale.setOd(data.author.od);
      authorLocale.setEnUs(data.author.enUs);
      authorLocale.setEnAu(data.author.enAu);

      const contentLocale: Locale = new Locale();
      contentLocale.setDefaultcontent(data.content.defaultContent);
      contentLocale.setEn(data.content.en);
      contentLocale.setHi(data.content.hi);
      contentLocale.setTe(data.content.te);
      contentLocale.setGu(data.content.gu);
      contentLocale.setTa(data.content.ta);
      contentLocale.setKa(data.content.ka);
      contentLocale.setMr(data.content.mr);
      contentLocale.setIt(data.content.it);
      contentLocale.setRu(data.content.ru);
      contentLocale.setEs(data.content.es);
      contentLocale.setFr(data.content.fr);
      contentLocale.setPt(data.content.pt);
      contentLocale.setDe(data.content.de);
      contentLocale.setBn(data.content.bn);
      contentLocale.setMl(data.content.ml);
      contentLocale.setUk(data.content.uk);
      contentLocale.setOd(data.content.od);
      contentLocale.setEnUs(data.content.enUs);
      contentLocale.setEnAu(data.content.enAu);

      const reqBody = new ContentResponse();
      reqBody.setId(props.editItem.id);
      reqBody.setTitle(titleLocale);
      reqBody.setSubtitle(descriptionLocale);
      reqBody.setAuthor(authorLocale);
      reqBody.setContent(contentLocale);
      reqBody.setImage(data.image);
      reqBody.setThumbnail(data.thumbnail);
      reqBody.setFeatureimage(data.featuredimage);
      reqBody.setReadingtime(data.readingTime);
      reqBody.setWebviewurl(data.webViewUrl);
      reqBody.setContentcategory(1);
      reqBody.setFeaturedcontent(data.articleCategory);
      reqBody.setFilteridsList([]);
      reqBody.setDiscover(data.discover);
      reqBody.setTagsList(tagsArray());

      console.log("Request for updating article", reqBody.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.UpdateContent, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ContentResponse>) => {
          console.log("we are here");
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("update article response", message.toObject());
            setIsLoading(false);
            dispatch(
              getSelfDevelopmentContentByCategory(
                props.lang,
                props.type,
                props.page,
                props.rowsPerPage
              )
            );
            setSnackBar({
              open: true,
              severity: "success",
              message: "Article updated successfully",
            });
            setTimeout(() => props.onClickBack(), 2000);
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: `Error in updating article : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.log(`Error in update article ${error}`);
      setSnackBar({
        open: true,
        severity: "error",
        message: `catch error: ${error}`,
      });
    }
  };

  const addArticle = async (data: any) => {
    setIsLoading(true);
    try {
      console.log(`Request to adding new article ${data}`);

      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultContent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultContent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);

      const authorLocale: Locale = new Locale();
      authorLocale.setDefaultcontent(data.author.defaultContent);
      authorLocale.setEn(data.author.en);
      authorLocale.setHi(data.author.hi);
      authorLocale.setTe(data.author.te);
      authorLocale.setGu(data.author.gu);
      authorLocale.setTa(data.author.ta);
      authorLocale.setKa(data.author.ka);
      authorLocale.setMr(data.author.mr);
      authorLocale.setIt(data.author.it);
      authorLocale.setRu(data.author.ru);
      authorLocale.setEs(data.author.es);
      authorLocale.setFr(data.author.fr);
      authorLocale.setPt(data.author.pt);
      authorLocale.setDe(data.author.de);
      authorLocale.setBn(data.author.bn);
      authorLocale.setMl(data.author.ml);
      authorLocale.setUk(data.author.uk);
      authorLocale.setOd(data.author.od);
      authorLocale.setEnUs(data.author.enUs);
      authorLocale.setEnAu(data.author.enAu);

      const contentLocale: Locale = new Locale();
      contentLocale.setDefaultcontent(data.content.defaultContent);
      contentLocale.setEn(data.content.en);
      contentLocale.setHi(data.content.hi);
      contentLocale.setTe(data.content.te);
      contentLocale.setGu(data.content.gu);
      contentLocale.setTa(data.content.ta);
      contentLocale.setKa(data.content.ka);
      contentLocale.setMr(data.content.mr);
      contentLocale.setIt(data.content.it);
      contentLocale.setRu(data.content.ru);
      contentLocale.setEs(data.content.es);
      contentLocale.setFr(data.content.fr);
      contentLocale.setPt(data.content.pt);
      contentLocale.setDe(data.content.de);
      contentLocale.setBn(data.content.bn);
      contentLocale.setMl(data.content.ml);
      contentLocale.setUk(data.content.uk);
      contentLocale.setOd(data.content.od);
      contentLocale.setEnUs(data.content.enUs);
      contentLocale.setEnAu(data.content.enAu);

      const reqBody = new ContentRequest();
      reqBody.setTitle(titleLocale);
      reqBody.setSubtitle(descriptionLocale);
      reqBody.setAuthor(authorLocale);
      reqBody.setContent(contentLocale);
      reqBody.setImage(data.image);
      reqBody.setThumbnail(data.thumbnail);
      reqBody.setFeatureimage(data.featuredimage);
      reqBody.setReadingtime(data.readingTime);
      reqBody.setIswebview(data.isWebView);
      reqBody.setWebviewurl(data.webViewUrl);
      reqBody.setContentcategory(1);
      reqBody.setFeaturedcontent(data.articleCategory);
      reqBody.setFilteridsList([]);
      reqBody.setDiscover(data.discover);
      reqBody.setTagsList(tagsArray());

      console.log(`Request for adding article ${reqBody}`);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.AddContent, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ContentResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("add article response", res.message.toObject());
              setIsLoading(false);
              dispatch(
                getSelfDevelopmentContentByCategory(
                  props.lang,
                  props.type,
                  props.page,
                  props.rowsPerPage
                )
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: "Article added successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
            }
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding article : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.log(`Error in add article ${error}`);
      setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

  return (
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {props.isEditing ? "Edit Article" : "Add New Article"}
              </Typography>
              {props.isEditing ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={props.onClickBack}
                  >
                    Articles
                  </Typography>
                  <Tooltip
                    title={props.editItem.title}
                    disableHoverListener={props.editItem.title?.length < 20}
                    placement="bottom"
                  >
                    <Box>
                      <Typography color="inherit" style={{ cursor: "pointer" }}>
                        {props.editItem.title?.slice(0, 20)}
                        {props.editItem.title?.length > 20 && "..."}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Typography color="textPrimary">Edit</Typography>
                </Breadcrumbs>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.isEditing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(id)}
              >
                Delete
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="title"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="author"
              label="Author"
              variant="outlined"
              value={author}
              onChange={(e) => {
                setAuthor(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="number"
              name="readingTime"
              label="Reading Time (min)"
              variant="outlined"
              value={readingTime}
              onChange={(e) => {
                setReadingTime(Number(e.target.value));
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Type</InputLabel>
                <Select
                  defaultValue=""
                  labelId="video-category-select"
                  value={type}
                  label="Type"
                  onChange={(e) => {
                    setType(e.target.value as number);
                  }}
                >
                  {typeList.map((type: any, i: any) => (
                    <MenuItem key={i} value={type.code}>
                      {type.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
              <Select
                labelId="tags-multiple-checkbox-label"
                id="tags-multiple-checkbox"
                multiple
                value={selectedTagsId}
                onChange={handleMultipleTagChange}
                input={<OutlinedInput label="Tags" />}
                renderValue={() => selectedTags.join(",")}
                MenuProps={{
                  sx: {
                    maxHeight: "500px",
                  },
                }}
              >
                {tagsList.loading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  tagsList.tags.tagsList.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      <Checkbox checked={selectedTagsId.indexOf(x.id) > -1} />
                      <ListItemText primary={String(x.name)} />
                    </MenuItem>
                  ))
                )}
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={tagsList.tags.pageable?.totalcount!}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={10}
                  style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                />
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="language-select">Category</InputLabel>
              <Select
                defaultValue=""
                labelId="video-category-select"
                value={articleCategory}
                label="Language"
                onChange={(e) => {
                  setArticleCategory(e.target.value as number);
                }}
              >
                {articleCategoryList.map((catagory: any, i: any) => (
                  <MenuItem key={i} value={catagory.code}>
                    {catagory.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {imgUrl ? (
                  <PreviewProfileImage
                    onImageClose={onImageClose}
                    src={imgUrl}
                    rectangular
                  />
                ) : (
                  <>
                    {isUploading.image ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.root}
                        onClick={() => {
                          handleUploadClick1();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={onSelectImage1}
                          ref={hiddenFileInput1}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Image
              </Typography>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {thumbnailUrl ? (
                  <PreviewProfileImage
                    onImageClose={onThumbnailClose}
                    src={thumbnailUrl}
                    rectangular
                  />
                ) : (
                  <>
                    {isUploading.thumbnail ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.root}
                        onClick={() => {
                          handleUploadClickThumbnail();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={onSelectThumbnail}
                          ref={hiddenFileInputThumbnail}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Thumbnail
              </Typography>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Card variant="outlined" className={classes.root}>
                {featuredimageUrl ? (
                  <PreviewProfileImage
                    onImageClose={onFeaturedimageClose}
                    src={featuredimageUrl}
                    rectangular
                  />
                ) : (
                  <>
                    {isUploading.featuredimage ? (
                      <Card className={classes.thumbNail}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-5"
                        >
                          <Typography className="pt-4">Uploading...</Typography>
                        </Grid>
                      </Card>
                    ) : (
                      <CardActionArea
                        className={classes.root}
                        onClick={() => {
                          handleUploadClickFeaturedimage();
                        }}
                      >
                        {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                        <Avatar className={classes.actionIcon} variant="square">
                          <ImageOutlined />
                        </Avatar>
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={onSelectFeaturedimage}
                          ref={hiddenFileInputFeaturedimage}
                        />
                      </CardActionArea>
                    )}
                  </>
                )}
              </Card>
              <Typography variant="overline" align="center" gutterBottom>
                Feature image
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                marginBottom: "10px",
                marginLeft: "10px",
              }}
            >
              Article content
            </Typography>
            <ReactQuill
              value={content}
              theme="snow"
              placeholder="Write something awesome..."
              onChange={setContent}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                marginBottom: "10px",
                marginLeft: "10px",
              }}
            >
              Article description
            </Typography>
            <ReactQuill
              value={description}
              theme="snow"
              placeholder="Write something awesome..."
              onChange={setDescription}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.formBtn}
          onClick={props.onClickBack}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          className={classes.formBtn}
          onClick={() => _addArticle()}
          color="primary"
          variant="contained"
          disabled={isAdding}
        >
          {props.isEditing ? "Update article" : "Add article"}
        </Button>
      </Box>
      {imageSrc && (
        <ImageCrop
          src={imageSrc.toString()}
          crop={imageCrop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
          onPressDone={onCropDone}
          onImageClose={onImageClose}
          open={open}
          onOpen={onOpenImage}
          onClose={onCloseImage}
        />
      )}
      {thumbnailSrc && (
        <ImageCrop
          src={thumbnailSrc.toString()}
          crop={thumbnailCrop}
          onImageLoaded={onThumbnailLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onThumbnailCropChange}
          onPressDone={onThumbnailCropDone}
          onImageClose={onThumbnailClose}
          open={open}
          onOpen={onOpenImage}
          onClose={onCloseThumbnail}
        />
      )}
      {featuredimageSrc && (
        <ImageCrop
          src={featuredimageSrc.toString()}
          crop={featuredimageCrop}
          onImageLoaded={onFeaturedimageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onFeaturedimageCropChange}
          onPressDone={onFeaturedimageCropDone}
          onImageClose={onFeaturedimageClose}
          open={open}
          onOpen={onOpenImage}
          onClose={onCloseFeaturedImage}
        />
      )}
      <DialogBox
        open={states.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteArticle}
        type={"delete"}
        title={`Sure !! Want to delete this article ?`}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
