export const localeObject = {
  defaultcontent: "",
  en: "",
  hi: "",
  te: "",
  gu: "",
  ta: "",
  ka: "",
  mr: "",
  it: "",
  ru: "",
  es: "",
  fr: "",
  pt: "",
  de: "",
  bn: "",
  ml: "",
  uk: "",
  od: "",
  enUs: "",
  enAu: "",
};
