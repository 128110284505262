import React, { useState, useEffect, ReactElement, useCallback } from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ID,
  Status,
  AddMoodRequest,
  MoodDetails,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { firebaseAuth } from "config/firebase";
import DialogBox from "components/dialogBox/dialog";
import { getAllMoodDetails } from "redux/actions/moodActions";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { classes } from "../groups/schedulePreRecordedVideo/scheduledPreRecordedVideo.styles";
import { Root } from "./moods.styles";

interface MoodProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: number;
  setPage: any;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

export const MoodsForm = React.forwardRef<HTMLDivElement, MoodProps>(
  (props, ref): ReactElement => {
    const languageType = [
      "defaultcontent",
      "en",
      "hi",
      "te",
      "gu",
      "ta",
      "ka",
      "mr",
      "it",
      "ru",
      "es",
      "fr",
      "pt",
      "de",
      "bn",
      "ml",
      "uk",
      "od",
      "enUs",
      "enAu",
    ];

    const moodCategoryList = [
      {
        displayName: "Happy",
        code: 1,
      },
      {
        displayName: "Sad",
        code: 2,
      },
    ];

    const dispatch = useDispatch();

    const [moodId, setMoodId] = useState<String>("");
    const [moodName, setMoodName] = useState<any>({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
    });
    const [moodColor, setMoodColor] = useState<String>("");
    const [states, setStates] = useState<State>(initialState);
    const [moodtype, setMoodtype] = useState<any>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [isAdding, setIsAdding] = useState(false);
    const [language, setLanguage] = useState<number>(0);

    const onClickEdit = useCallback((item: any) => {
      console.log("Edit mood details: ", item);
      setStates({ editing: true });
      setMoodId(item.id);
      setMoodName({
        ...item.name,
        [languageType[props.lang - 1]]: item.name[languageType[props.lang - 1]]
          ? item.name[languageType[props.lang - 1]]
          : item.name.defaultcontent,
      });
      setMoodColor(item.associatedColor);
      setMoodtype(item.moodtype);
    }, []);

    useEffect(() => {
      if (props.isEditing) {
        onClickEdit(props.editItem);
        //setLanguage(props.lang - 1);
      }
      setLanguage(props.lang - 1);
    }, [props.isEditing, props.editItem, onClickEdit, props.lang]);

    const validateForm = () => {
      if (moodName[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: " Mood name can not be empty",
        });
      } else {
        return true;
      }
    };

    const deleteMood = async () => {
      deleteMoodById(states.deleteItemId);
    };

    const handleDeleteClose = () => {
      setStates({ showDeleteModal: false });
    };

    const onClickDelete = (moodId: any) => {
      setStates({ showDeleteModal: true, deleteItemId: moodId });
    };

    const addMood = async (data: any) => {
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        const nameLocale: Locale = new Locale();
        nameLocale.setDefaultcontent(data.name.defaultcontent);
        nameLocale.setEn(data.name.en);
        nameLocale.setHi(data.name.hi);
        nameLocale.setTe(data.name.te);
        nameLocale.setGu(data.name.gu);
        nameLocale.setTa(data.name.ta);
        nameLocale.setKa(data.name.ka);
        nameLocale.setMr(data.name.mr);
        nameLocale.setIt(data.name.it);
        nameLocale.setRu(data.name.ru);
        nameLocale.setEs(data.name.es);
        nameLocale.setFr(data.name.fr);
        nameLocale.setPt(data.name.pt);
        nameLocale.setDe(data.name.de);
        nameLocale.setBn(data.name.bn);
        nameLocale.setMl(data.name.ml);
        nameLocale.setUk(data.name.uk);
        nameLocale.setOd(data.name.od);
        nameLocale.setEnUs(data.name.enUs);
        nameLocale.setEnAu(data.name.enAu);

        const reqBody: AddMoodRequest = new AddMoodRequest();
        reqBody.setName(nameLocale);
        reqBody.setAssociatedcolor(data.associatedColor);
        reqBody.setMoodtype(data.moodtype);

        console.log("req payload", reqBody);
        grpc.unary(GamificationService.AddMoodDetails, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<Status>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              console.log("All OK : ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "Mood added successfully",
              });
              setTimeout(() => {
                props.onClickBack();
              }, 2000);
              dispatch(getAllMoodDetails());
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in adding new mood",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Something went wrong in adding mood",
        });
        console.log("Something wrong with mood data", error);
      }
    };

    const updateMood = async (data: any) => {
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        const nameLocale: Locale = new Locale();
        nameLocale.setDefaultcontent(data.name.defaultcontent);
        nameLocale.setEn(data.name.en);
        nameLocale.setHi(data.name.hi);
        nameLocale.setTe(data.name.te);
        nameLocale.setGu(data.name.gu);
        nameLocale.setTa(data.name.ta);
        nameLocale.setKa(data.name.ka);
        nameLocale.setMr(data.name.mr);
        nameLocale.setIt(data.name.it);
        nameLocale.setRu(data.name.ru);
        nameLocale.setEs(data.name.es);
        nameLocale.setFr(data.name.fr);
        nameLocale.setPt(data.name.pt);
        nameLocale.setDe(data.name.de);
        nameLocale.setBn(data.name.bn);
        nameLocale.setMl(data.name.ml);
        nameLocale.setUk(data.name.uk);
        nameLocale.setOd(data.name.od);
        nameLocale.setEnUs(data.name.enUs);
        nameLocale.setEnAu(data.name.enAu);

        const reqBody: MoodDetails = new MoodDetails();
        reqBody.setId(props.editItem.id);
        reqBody.setName(nameLocale);
        reqBody.setAssociatedColor(data.associatedColor);
        reqBody.setMoodtype(data.moodtype);

        console.log("req payload", reqBody);
        grpc.unary(GamificationService.UpdateMoodDetails, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<MoodDetails>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              console.log("All OK : ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "Mood added successfully",
              });
              setTimeout(() => {
                props.onClickBack();
              }, 2000);
              dispatch(getAllMoodDetails());
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in adding new mood",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Something went wrong in adding mood",
        });
        console.log("Something wrong with mood data", error);
      }
    };

    const _addMood = () => {
      if (validateForm()) {
        if (props.isEditing) {
          setIsAdding(true);
          updateMood({
            name: moodName,
            associatedColor: moodColor,
            moodtype,
          });
        } else {
          setIsAdding(true);
          addMood({
            name: { ...moodName, en: moodName.defaultcontent },
            associatedColor: moodColor,
            moodtype,
          });
        }
      }
    };

    const deleteMoodById = async (moodId: String | undefined) => {
      setIsLoading(true);
      console.log(`Delete call for mood with id ${moodId}`);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ID = new ID();
        reqBody.setId(Number(moodId));
        grpc.unary(GamificationService.DeleteMood, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<Status>) => {
            const { status, message } = res;
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(`Delete mood response ${message.toObject()}`);
                setIsLoading(false);
                setStates({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                props.setPage(0);
                dispatch(getAllMoodDetails());
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: " Deleted goal successfully",
                });
              } else {
                setIsLoading(false);
                setStates({ showDeleteModal: false });
                setSnackBar({
                  open: true,
                  severity: "Error",
                  message: "Error in  deleting mood",
                });
              }
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        console.log(`Error: ${err}`);
      }
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Mood" : "Create New Mood"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Moods
                    </Typography>
                    <Typography color="inherit" style={{ cursor: "pointer" }}>
                      {props.editItem.name[languageType[language]]}
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  disabled
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(moodId)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="name"
                label="Name"
                variant="outlined"
                value={moodName[languageType[language]]}
                multiline
                onChange={(e) => {
                  props.isEditing
                    ? setMoodName((prevState) => ({
                        ...prevState,
                        [languageType[language]]: e.target.value,
                      }))
                    : setMoodName((prevState) => ({
                        ...prevState,
                        [languageType[0]]: e.target.value,
                        [languageType[language]]: e.target.value,
                      }));
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                name="associatedColor"
                variant="outlined"
                label="Associated Color"
                value={moodColor}
                onChange={(e) => {
                  setMoodColor(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Mood type</InputLabel>
                <Select
                  defaultValue=""
                  labelId="video-category-select"
                  value={moodtype}
                  label="Language"
                  onChange={(e) => {
                    setMoodtype(e.target.value as number);
                  }}
                >
                  {moodCategoryList.map((catagory: any, i: any) => (
                    <MenuItem key={i} value={catagory.code}>
                      {catagory.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => _addMood()}
            disabled={isAdding}
            color="primary"
            variant="contained"
          >
            {props.isEditing ? "Update mood" : "Add mood"}
          </Button>
        </Box>

        <DialogBox
          open={states.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={deleteMood}
          type={"delete"}
          title={`Sure !! Want to delete this mood ?`}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
