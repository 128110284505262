import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  RequestPageable,
  SelfDevelopmentContentRequest,
  SelfDevelopmentContentResponse,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import {
  setSelfDevelopmentContentList,
  setSelfDevelopmentContentListLoading,
} from "redux/reducers/selfDevelopment";

interface addFilterType {
  contentFilterName: any;
  setSnackBar: any;
  getAllFilterData: pageableType;
}

interface updateFilterType {
  id: number;
  contentFilterName: any;
  setSnackBar: any;
  getAllFilterData: pageableType;
}

interface deleteFilterType {
  id: number;
  setSnackBar: any;
  getAllFilterData: pageableType;
}

export interface pageableType {
  pageSize: number;
  pageNumber: number;
}

export const getSelfDevelopmentContentByCategory =
  (
    lang: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20,
    discover: 0 | 1 | 2 | 3,
    page: number,
    rowsPerPage: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setSelfDevelopmentContentListLoading(true));

      const pagination: RequestPageable = new RequestPageable();

      pagination.setPagenumber(page);
      pagination.setPagesize(rowsPerPage);

      const reqBody: SelfDevelopmentContentRequest =
        new SelfDevelopmentContentRequest();

      reqBody.setSelectedlanguage(lang);
      reqBody.setDiscover(discover);
      reqBody.setPageable(pagination);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.GetSelfDevelopmentContentByCategory, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<SelfDevelopmentContentResponse>) => {
          console.log(res);
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log(message.toObject());
            dispatch(setSelfDevelopmentContentList(message.toObject()));
            dispatch(setSelfDevelopmentContentListLoading(false));
          } else {
            console.log(`Error in fetching content ${statusMessage}`);
            dispatch(setSelfDevelopmentContentListLoading(false));
          }
        },
      });
    } catch (error) {
      console.log(`Error in get content by category ${error}`);
    }
  };
