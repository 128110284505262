import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  DeleteGoalByIdRequest,
  DeleteGoalByIdResponse,
  Empty,
  GetAllGoalInfoV2ResponseForAdminV2,
  GetAllGoalsResponse,
  RequestPageable,
  ResponseGoal,
} from "api/models/gamification-service/gamification_pb";
import {
  GetAllGoalDetailsResponse,
  RequestForGetAllGoalDetails,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  AddNewGoalRequest,
  Goal,
} from "api/models/gamification-service/Goal_pb";
import {
  GAMIFICATION_SERVICE_HOST,
  //GUIDED_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import {
  goalsListError,
  goalsListLoading,
  goalsListUpdate,
  goalsListLoadingV2,
  goalsListUpdateV2,
  goalsListErrorV2,
} from "redux/reducers/goalsReducer";
import { AppThunk } from "redux/store/store";
interface addGoal {
  Associatedbadgeid: number;
  Badgecount: number;
  Days: number;
  Durationlimitindays: number;
  Subtitle: any;
  Title: any;
}
export const getAllGoals = (): AppThunk => async (dispatch) => {
  try {
    const goalReq = new Empty();
    dispatch(goalsListLoading());
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    grpc.unary(GamificationService.GetAllGoals, {
      request: goalReq,
      host: GAMIFICATION_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<GetAllGoalsResponse>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          console.log(
            "data coming from get all goals",
            message.toObject().goalList
          );
          if (message) {
            dispatch(goalsListUpdate(message.toObject().goalList));
          }
        }
      },
    });
  } catch (err) {
    dispatch(goalsListError());
    console.error("error in getAllgoals", err);
  }
};
export const addGoals = async (data: addGoal) => {
  try {
    const payload = new AddNewGoalRequest();
    payload.setAssociatedbadgeid(data.Associatedbadgeid);
    payload.setBadgecount(data.Badgecount);
    payload.setDays(data.Days);
    payload.setDurationlimitindays(data.Durationlimitindays);
    payload.setSubtitle(data.Subtitle);
    payload.setTitle(data.Title);
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    grpc.unary(GamificationService.AddNewGoal, {
      request: payload,
      host: GAMIFICATION_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ResponseGoal>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          if (res.message) {
            console.log("add goal response", res.message.toObject());
          }
        }
      },
    });
  } catch (error) {
    console.error("Error in add goals", error);
  }
};

export const updateGoals = async (data: any) => {
  try {
    const payload = new Goal();
    payload.setAssociatedbadgeid(data.Associatedbadgeid);
    payload.setBadgecount(data.Badgecount);
    payload.setDays(data.Days);
    payload.setDurationlimitindays(data.Durationlimitindays);
    payload.setSubtitle(data.Subtitle);
    payload.setTitle(data.Title);
    payload.setId(data.Id);
    payload.setTypeofmeditationassociated(data.Typeofmeditationassociated);
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    grpc.unary(GamificationService.UpdateGoal, {
      request: payload,
      host: GAMIFICATION_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<ResponseGoal>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          if (res.message) {
            console.log("update goal response", res.message.toObject());
          }
        }
      },
    });
  } catch (error) {
    console.log("Error in update goal", error);
  }
};

export const deleteGoal = async (data: any) => {
  try {
    const payload = new DeleteGoalByIdRequest();
    payload.setId(data.Id);
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    grpc.unary(GamificationService.DeleteGoalByID, {
      request: payload,
      host: GAMIFICATION_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<DeleteGoalByIdResponse>) => {
        const { status, message } = res;
        if (status === grpc.Code.OK && message) {
          if (res.message) {
            console.log("delete goal response", res.message.toObject());
          }
        }
      },
    });
  } catch (error) {
    console.log("Error in delete goal", error);
  }
};

export const getAllGoalsDetails =
  (
    lang:
      | 0
      | 1
      | 2
      | 3
      | 4
      | 5
      | 6
      | 7
      | 8
      | 9
      | 10
      | 11
      | 12
      | 13
      | 14
      | 15
      | 16
      | 17
      | 18
      | 19
      | 20
  ): AppThunk =>
  async (dispatch) => {
    try {
      const goalReq: RequestForGetAllGoalDetails =
        new RequestForGetAllGoalDetails();
      goalReq.setSelectedlanguage(lang);
      dispatch(goalsListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      //console.log(`Bearer ${jwtToken}`);
      grpc.unary(GamificationService.GetAllGoalsDetails, {
        request: goalReq,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllGoalDetailsResponse>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log(
              "data coming from get all goals details",
              message.toObject().goalList
            );
            if (message) {
              dispatch(goalsListUpdate(message.toObject().goalList));
            }
          }
        },
      });
    } catch (err) {
      dispatch(goalsListError());
      console.error("error in getAllgoals details", err);
    }
  };

export const getAllGoalsInfoV2 =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(goalsListLoadingV2());
      const reqBody = new RequestPageable();
      reqBody.setPagesize(pageSize);
      reqBody.setPagenumber(pageNumber);
      console.log('reqBody', reqBody.toObject())

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.GetAllGoalInfoV2ForAdminV2, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GetAllGoalInfoV2ResponseForAdminV2>) => {
          const { status, message } = res;
          console.log(
            "Data coming from get all goal info v2",
            res
          );
          if (status === grpc.Code.OK && message) {
            console.log(
              "Data coming from get all goal info v2",
              message.toObject()
            );
            dispatch(goalsListUpdateV2(message.toObject()));
          } else {
            dispatch(goalsListErrorV2());
          }
        },
      });
    } catch (err) {
      dispatch(goalsListErrorV2());
      console.log("Error in getAllGoalInfoV2", err);
    }
  };
