import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import DialogBox from "components/dialogBox/dialog";
import moment from "moment";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTheme,
  deleteThemeById,
  getProgramId,
  updateTheme,
} from "redux/actions/programAction";
import { RootState } from "redux/store/store";
import { languageType } from "./theme.screen";

const PREFIX = "ThemeForm";
const classes = {
  toggle: `${PREFIX}-toggle`,
  formBtn: `${PREFIX}-formBtn`,
  backdrop: `${PREFIX}-backdrop`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface ThemeProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: number;
  getAllThemeData: any;
}
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
};

export const ThemeForm = React.forwardRef<HTMLDivElement, ThemeProps>(
  (props, ref): ReactElement => {
    const dispatch = useDispatch();
    const programId = useSelector(
      (state: RootState) => state.program.programList.programId
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<number>(0);
    const [states, setStates] = useState<State>(initialState);
    const [fromDate, setFromDate] = useState<any>(moment().startOf('day').toDate());
    const [toDate, setToDate] = useState<any>(moment().set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999}).toDate());
    const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [id, setId] = useState<number>(0);

    const [title, setTitle] = useState<any>({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
    });
    const [description, setDescription] = useState<any>({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
    });

    const validateForm = () => {
      if (title[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Theme title can not be empty.",
        });
      } else if (description[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Theme description can not be empty.",
        });
      } else {
        return true;
      }
    };

    const handleFormSubmit = () => {
      const toEndDate = moment(toDate).set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999}).toDate();
      if (validateForm()) {
        if (props.isEditing) {
          setIsLoading(true);
          dispatch(
            updateTheme({
              title,
              description,
              fromDate,
              toDate: toEndDate,
              programId,
              id: Number(id),
              setSnackBar,
              getAllThemeData: props.getAllThemeData,
            })
          );
        } else {
          setIsLoading(true);
          dispatch(
            addTheme({
              title,
              description,
              fromDate,
              toDate,
              programId,
              setSnackBar,
              getAllThemeData: props.getAllThemeData,
            })
          );
        }
        setTimeout(() => {
          setIsLoading(false);
          props.onClickBack();
        }, 2000);
      }
    };

    const deleteTheme = () => {
      setIsLoading(true);
      setStates({ showDeleteModal: false });
      dispatch(
        deleteThemeById({
          id,
          setSnackBar,
          getAllThemeData: props.getAllThemeData,
        })
      );
      setTimeout(() => {
        setIsLoading(false);
        props.onClickBack();
      }, 2000);
    };

    const onClickEdit = useCallback((item: any) => {
      setStates({ editing: true });
      setId(item.id);
      setTitle({
        ...item.title,
        [languageType[props.lang - 1]]: item.title[languageType[props.lang - 1]]
          ? item.title[languageType[props.lang - 1]]
          : "",
      });
      setDescription({
        ...item.description,
        [languageType[props.lang - 1]]: item.description[
          languageType[props.lang - 1]
        ]
          ? item.description[languageType[props.lang - 1]]
          : "",
      });
      setToDate(new Date((Number(item.todate?.seconds) -1) * 1000));
      setFromDate(new Date(Number(item.fromdate?.seconds) * 1000));
    }, []);

    useEffect(() => {
      dispatch(getProgramId(0, 10));
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
      setLanguage(props.lang - 1);
      console.log("editItem", props.editItem);
    }, [props.editItem, props.isEditing, props.lang]);

    return (
      <Root ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton onClick={props.onClickBack}>
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Theme" : "Add New Theme"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setStates({ showDeleteModal: true });
                  }}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>

        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm a"
                  minTime={moment().startOf('day').toDate()}
                  maxTime={moment().startOf('day').toDate()}
                  label="From date"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                  value={fromDate}
                  ampm={false}
                  onChange={(value: any) => {
                    if(value && value !== null) {
                      setFromDate(moment(value).startOf('day').toDate());
                      setToDate(moment(value).set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999}).toDate());
                    }else{
                      setFromDate(null);
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm a"
                  label="To date"
                  minTime={moment().set({hour: 23, minute: 59, second: 0}).toDate()}
                  maxTime={moment().endOf('day').toDate()}
                  minDate={toDate}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                  value={toDate}
                  ampm={false}
                  onChange={(value: any) => {
                    if(value && value !== null) {
                      setIsInvalidDate(Date.parse(value) <= fromDate);
                      setToDate(moment(value).endOf('day').toDate());
                    } else {
                      setToDate(null);
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="title"
                label="Title"
                variant="outlined"
                value={title[languageType[language]]}
                inputProps={{ maxLength: 100 }}
                className="mr-3"
                onChange={(e) => {
                  if (
                    languageType[language] === "defaultcontent" ||
                    languageType[language] === "en"
                  ) {
                    setTitle((prevState) => ({
                      ...prevState,
                      defaultcontent: e.target.value,
                      en: e.target.value,
                    }));
                  } else {
                    setTitle((prevState) => ({
                      ...prevState,
                      [languageType[language]]: e.target.value,
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="description"
                label="Description"
                variant="outlined"
                value={description[languageType[language]]}
                inputProps={{ maxLength: 200 }}
                className="mr-3"
                onChange={(e) => {
                  if (
                    languageType[language] === "defaultcontent" ||
                    languageType[language] === "en"
                  ) {
                    setDescription((prevState) => ({
                      ...prevState,
                      defaultcontent: e.target.value,
                      en: e.target.value,
                    }));
                  } else {
                    setDescription((prevState) => ({
                      ...prevState,
                      [languageType[language]]: e.target.value,
                    }));
                  }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className="p-2 d-flex justify-content-end">
        {isInvalidDate && <Alert severity="error">To date must be greater then From date</Alert>}
          <Box display="flex" justifyContent="flex-end" gap={2}>
            {props.isEditing && (
              <Button
                variant="contained"
                color="primary"
                onClick={props.onClickBack}
                disabled={isLoading}
              >
                Cancel
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.formBtn}
              onClick={handleFormSubmit}
              disabled={isLoading || !fromDate || !toDate || isInvalidDate}
            >
              {props.isEditing ? "Save" : "Create"}
            </Button>
          </Box>
        </CardActions>
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={() => {
            setStates({ showDeleteModal: false });
          }}
          onCancel={() => {
            setStates({ showDeleteModal: false });
          }}
          onConfirm={deleteTheme}
          type={"delete"}
          title={`Sure !! Want to delete this theme ?`}
        />
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
