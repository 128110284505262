import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GUIDED_MEDITATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import { AppThunk } from "redux/store/store";
import {
  ID,
  MeditaionBckgndDetails,
  URLRequest,
} from "api/models/guided-meditation-service/guided-meditation_pb";
import { Locale } from "api/models/gamification-service/common/common_pb";
import {
  setMeditationBackgrounds,
  setMeditationBackgroundsLoading,
} from "redux/reducers/meditationBackground";
import { GuidedMeditationService } from "api/models/guided-meditation-service/guided-meditation_pb_service";
import {
  SelectedMeditaionBckgndDetailsRequest,
  ListOfSelectedMeditaionBckgndDetails,
  ResponseStatus,
} from "api/models/guided-meditation-service/guided-meditation_pb";

interface addBackgroundType {
  title: any;
  url: string;
  thumbnailImageUrl: string;
  backgroundType: 1 | 2;
  setSnackBar: any;
}

interface updateBackgroundType {
  id: number;
  title: any;
  url: string;
  thumbnailImageUrl: string;
  backgroundType: 1 | 2;
  setSnackBar: any;
}

interface deleteBackgroundType {
  id: number;
  setSnackBar: any;
}

export const getAllBackgrounds = (): AppThunk => async (dispatch) => {
  dispatch(setMeditationBackgroundsLoading(true));
  const jwtToken = await firebaseAuth.currentUser?.getIdToken();
  const reqBody = new SelectedMeditaionBckgndDetailsRequest();

  reqBody.setGetonlyimage(true);
  reqBody.setGetonlymusic(true);

  grpc.unary(GuidedMeditationService.getAllMeditationBckgnd, {
    host: GUIDED_MEDITATION_SERVICE_HOST,
    request: reqBody,
    metadata: {
      Authorization: `Bearer ${jwtToken}`,
    },
    onEnd: (res: UnaryOutput<ListOfSelectedMeditaionBckgndDetails>) => {
      const { status, message } = res;
      if (status === grpc.Code.OK && message) {
        console.log(message.toObject());
        dispatch(setMeditationBackgroundsLoading(false));
        dispatch(setMeditationBackgrounds(message.toObject()));
      } else {
        console.log(`Error in fetching backgrounds`, res);
        dispatch(setMeditationBackgroundsLoading(false));
      }
    },
  });
};

export const addBackground =
  (data: addBackgroundType): AppThunk =>
  async (dispatch) => {
    try {
      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.defaultcontent);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);
      const payload = new URLRequest();
      payload.setUrl(data.url);
      payload.setThumbnailimgurl(data.thumbnailImageUrl);
      payload.setBackgroundtype(data.backgroundType);
      payload.setTitle(titleLocale);
      console.log("req payload", payload.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GuidedMeditationService.addMeditationBckgnd, {
        request: payload,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("add background response", res.message.toObject());
              dispatch(getAllBackgrounds());
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Background added successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding background : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in add background", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const updateBackground =
  (data: updateBackgroundType): AppThunk =>
  async (dispatch) => {
    console.log("data from update", data);
    try {
      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const payload = new MeditaionBckgndDetails();
      payload.setId(data.id);
      payload.setUrl(data.url);
      payload.setThumbnailimgurl(data.thumbnailImageUrl);
      payload.setBackgroundtype(data.backgroundType);
      payload.setTitle(titleLocale);
      console.log("req payload", payload.toObject());

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GuidedMeditationService.updateMeditationBckgnd, {
        request: payload,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<MeditaionBckgndDetails>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("update background response", res.message.toObject());
              dispatch(getAllBackgrounds());
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Background updated successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in updated background : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in update background", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const deleteBackground =
  (data: deleteBackgroundType): AppThunk =>
  async (dispatch) => {
    try {
      const payload = new ID();
      payload.setId(data.id);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GuidedMeditationService.deleteMeditationBckgnd, {
        request: payload,
        host: GUIDED_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("delte background response", res.message.toObject());
              dispatch(getAllBackgrounds());
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Background deleted successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in deleting background : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in delete background", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };
