import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import DialogBox from "components/dialogBox/dialog";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTask,
  deleteTaskById,
  getProgramId,
  updateTask,
} from "redux/actions/programAction";
import { RootState } from "redux/store/store";
import { languageType } from "./task.screen";
import moment from "moment";

const PREFIX = "TaskForm";
const classes = {
  toggle: `${PREFIX}-toggle`,
  formBtn: `${PREFIX}-formBtn`,
  backdrop: `${PREFIX}-backdrop`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface TaskProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: number;
  getAllTaskData: any;
}
interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
};

const activityType = [
  "MEDITATION",
  "CLEANING",
  "PRAYER",
  "VIDEO",
  "AUDIO",
  "READING",
  "DAIRY",
  "LINK",
  "SHARE",
  "OTHER",
];

const urlTypeString = [
  "MEDITATION URL",
  "CLEANING URL",
  "PRAYER URL",
  "VIDEO URL (Ex:https://www.youtube.com/watch?v=wrCMnknLBUk)",
  "AUDIO URL",
  "READING URL (Ex: https://xyz.com/example.pdf)",
  "DAIRY URL",
  "LINK URL (Ex: https://xyz.com)",
  "SHARE URL",
  "OTHER URL",
];

const languageObject = {
    defaultcontent: "",
    en: "",
    hi: "",
    te: "",
    gu: "",
    ta: "",
    ka: "",
    mr: "",
    it: "",
    ru: "",
    es: "",
    fr: "",
    pt: "",
    de: "",
    bn: "",
    ml: "",
    uk: "",
    od: "",
    enUs: "",
    enAu: "",
}

export const TaskForm = React.forwardRef<HTMLDivElement, TaskProps>(
  (props, ref): ReactElement => {
    const dispatch = useDispatch();
    const programId = useSelector(
      (state: RootState) => state.program.programList.programId
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<number>(0);
    const [states, setStates] = useState<State>(initialState);
    const [scheduleDate, setScheduleDate] = useState<any>(moment().millisecond(0).toDate());
    const [type, setType] = useState<
      0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
    >(0);
    const [isPublished, setIsPublished] = useState<boolean>(true);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [id, setId] = useState<number>(0);
    const [urlType, setUrlType] = useState<string>("MEDITATION URL");
    const [isUrlWrong, setIsUrlWrong] = useState<boolean>(false);

    const [title, setTitle] = useState<any>({...languageObject});
    const [description, setDescription] = useState<any>({...languageObject});
    const [url, setUrl] = useState<any>({...languageObject});
    const [cta, setCta] = useState<any>("");

    const validateForm = () => {
      if (title[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Task title can not be empty.",
        });
      } else if (description[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Description can not be empty.",
        });
      } else if (url[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Url can not be empty.",
        });
      } else if (cta.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "CTA can not be empty.",
        });
      } else {
        return true;
      }
    };

    const handleFormSubmit = () => {
      const activityTypeNum: any = type + 1;
      if (validateForm()) {
        if (props.isEditing) {
          console.log({
            title,
            description,
            url,
            cta,
            scheduleDate,
            type,
            isPublished,
            programId,
            id,
          });
          setIsLoading(true);
          dispatch(
            updateTask({
              title,
              description,
              url,
              cta,
              scheduleDate,
              activityTypeNum,
              isPublished,
              programId,
              id: Number(id),
              setSnackBar,
              getAlltaskData: props.getAllTaskData,
            })
          );
        } else {
          setIsLoading(true);
          dispatch(
            addTask({
              title,
              description,
              url,
              cta,
              scheduleDate,
              activityTypeNum,
              isPublished: isPublished,
              programId,
              setSnackBar,
              getAlltaskData: props.getAllTaskData,
            })
          );
        }
        setTimeout(() => {
          setIsLoading(false);
          props.onClickBack();
        }, 3000);
      }
    };

    const deleteTask = () => {
      setIsLoading(true);
      setStates({ showDeleteModal: false });
      dispatch(
        deleteTaskById({
          id,
          setSnackBar,
          getAlltaskData: props.getAllTaskData,
        })
      );
      setTimeout(() => {
        setIsLoading(false);
        props.onClickBack();
      }, 2000);
    };

    const onClickEdit = useCallback((item: any) => {
      const typeNum: any = item.taskactivitytype - 1;
      setStates({ editing: true });
      setId(item.id);
      setTitle({
        ...item.title,
        [languageType[props.lang - 1]]: item.title[languageType[props.lang - 1]]
          ? item.title[languageType[props.lang - 1]]
          : "",
      });
      setDescription({
        ...item.description,
        [languageType[props.lang - 1]]: item.description[
          languageType[props.lang - 1]
        ]
          ? item.description[languageType[props.lang - 1]]
          : "",
      });
      setUrl({
        ...item.url,
        [languageType[props.lang - 1]]: item.url[languageType[props.lang - 1]]
          ? item.url[languageType[props.lang - 1]]
          : "",
      });
      setCta(item.useraction);
      setScheduleDate(new Date(Number(item.scheduleat?.seconds) * 1000));
      setType(typeNum);
      setIsPublished(item.ispublished);
    }, []);

    useEffect(() => {
      dispatch(getProgramId(0, 10));
      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
      setLanguage(props.lang - 1);
      console.log("editItem", props.editItem);
    }, [props.editItem, props.isEditing, props.lang]);

    const handleActivityDropdown = (value: any) => {
      const typeNum: any = value.target.value;
      if(typeNum){
        setType(typeNum);
        setUrlType(urlTypeString[typeNum]);
      }else{
        setType(0);
        setUrlType(urlTypeString[0]);
      };
      if (url[languageType[language]] !== '') checkUrlValid(url[languageType[language]], typeNum);
    };

    const handleUrlInput = (e: any) => {
      const urlInput = e.target.value;

      if (
        languageType[language] === "defaultcontent" ||
        languageType[language] === "en"
      ) {
        setUrl((prevState) => ({
          ...prevState,
          defaultcontent: e.target.value,
          en: e.target.value,
        }));
      } else {
        setUrl((prevState) => ({
          ...prevState,
          [languageType[language]]: e.target.value,
        }));
      };
      checkUrlValid(urlInput, type);
    };

    const checkUrlValid = (urlInput:any, typeNum: number) =>{
      setIsUrlWrong(true);
      if(typeNum === 5){
        if(urlInput.match(/\.pdf$/i) || urlInput.match(/\.PDF$/i)){
          setIsUrlWrong(false);
        }
      } else if(typeNum === 3 ){
        if(urlInput.includes('youtube.com') || urlInput.includes('youtu.be')){
          setIsUrlWrong(false);
        }
      } else{
        setIsUrlWrong(false);
      }
    };

    return (
      <Root ref={ref}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton onClick={props.onClickBack}>
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Task" : "Add New Task"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControlLabel
              checked={isPublished}
              control={<Switch name="publish" />}
              label={
                <Typography>
                  {isPublished ? "Published" : "Unpublished"}
                </Typography>
              }
              onChange={() => setIsPublished((publish: boolean) => !publish)}
            />
            {/* {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setStates({ showDeleteModal: true });
                  }}
                >
                  Delete
                </Button>
              </>
            ) : null} */}
          </Grid>
        </Grid>

        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm a"
                  label="Schedule At"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                  value={scheduleDate}
                  onChange={(value: any) => {
                    setScheduleDate(Date.parse(value));
                    console.log("value", value);
                  }}
                  // inputVariant="outlined"
                  // TextFieldComponent={(props) => (
                  //   <TextField variant="outlined" {...props} />
                  // )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="medType">Activity Type</InputLabel>
                <Select
                  labelId="medType"
                  value={type}
                  required
                  onChange={handleActivityDropdown}
                  label="Activity Type"
                >
                  {activityType.map((item, index) => {
                    return (
                      <MenuItem key={`med${index}`} value={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="title"
                label="Title"
                variant="outlined"
                value={title[languageType[language]]}
                inputProps={{ maxLength: 100 }}
                className="mr-3"
                onChange={(e) => {
                  if (
                    languageType[language] === "defaultcontent" ||
                    languageType[language] === "en"
                  ) {
                    setTitle((prevState) => ({
                      ...prevState,
                      defaultcontent: e.target.value,
                      en: e.target.value,
                    }));
                  } else {
                    setTitle((prevState) => ({
                      ...prevState,
                      [languageType[language]]: e.target.value,
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="description"
                label="Description"
                variant="outlined"
                value={description[languageType[language]]}
                inputProps={{ maxLength: 100 }}
                className="mr-3"
                onChange={(e) => {
                  if (
                    languageType[language] === "defaultcontent" ||
                    languageType[language] === "en"
                  ) {
                    setDescription((prevState) => ({
                      ...prevState,
                      defaultcontent: e.target.value,
                      en: e.target.value,
                    }));
                  } else {
                    setDescription((prevState) => ({
                      ...prevState,
                      [languageType[language]]: e.target.value,
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                error={isUrlWrong}
                type="text"
                name="url"
                label={urlType}
                variant="outlined"
                value={url[languageType[language]]}
                className="mr-3"
                onChange={handleUrlInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="cta"
                label="CTA Text"
                variant="outlined"
                value={cta}
                inputProps={{ maxLength: 100 }}
                className="mr-3"
                onChange={(e) => {
                  setCta(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className="p-2 d-flex justify-content-end">
          <Box display="flex" justifyContent="flex-end" gap={2}>
            {props.isEditing && (
              <Button
                variant="contained"
                color="primary"
                onClick={props.onClickBack}
                disabled={isLoading}
              >
                Cancel
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.formBtn}
              onClick={handleFormSubmit}
              disabled={isLoading || !scheduleDate || isUrlWrong}
            >
              {props.isEditing ? "Save" : "Create"}
            </Button>
          </Box>
        </CardActions>
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={() => {
            setStates({ showDeleteModal: false });
          }}
          onCancel={() => {
            setStates({ showDeleteModal: false });
          }}
          onConfirm={deleteTask}
          type={"delete"}
          title={`Sure !! Want to delete this Task ?`}
        />
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
