// source: notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.Category', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.Notification', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.SubCategory', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.Topic', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.heartIntuneProfile.notification.Version', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.Notification.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.displayName = 'proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.toObject = function(includeInstance, msg) {
  var f, obj = {
    obt: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    whisperaday: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    newcontent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    announcements: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    newreleases: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences;
  return proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setObt(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhisperaday(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewcontent(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnnouncements(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewreleases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObt();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getWhisperaday();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNewcontent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAnnouncements();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNewreleases();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool obt = 1;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.getObt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.setObt = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool whisperAday = 2;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.getWhisperaday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.setWhisperaday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool newContent = 3;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.getNewcontent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.setNewcontent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool announcements = 4;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.getAnnouncements = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.setAnnouncements = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool newReleases = 5;
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.getNewreleases = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.NotificationPreferences.prototype.setNewreleases = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    body: jspb.Message.getFieldWithDefault(msg, 2, ""),
    template: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, ""),
    categorysubtype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    timetoliveinseconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, undefined) : [],
    sound: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.Notification;
  return proto.unifiedplatform.heartIntuneProfile.notification.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategorysubtype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimetoliveinseconds(value);
      break;
    case 7:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategorysubtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTimetoliveinseconds();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSound();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string template = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.setTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string categorySubType = 5;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getCategorysubtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.setCategorysubtype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 timeToLiveInSeconds = 6;
 * @return {number}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getTimetoliveinseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.setTimetoliveinseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * map<string, string> data = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};


/**
 * optional string sound = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.getSound = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.Notification} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.Notification.prototype.setSound = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locale: jspb.Message.getFieldWithDefault(msg, 3, ""),
    notification: (f = msg.getNotification()) && proto.unifiedplatform.heartIntuneProfile.notification.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest;
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.notification.Notification;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.notification.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.notification.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string locale = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Notification notification = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.Notification}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.getNotification = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.Notification} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.notification.Notification, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.Notification|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationRequest.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse;
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest;
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deviceId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse;
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicSubscribeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest;
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deviceId = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse;
  return proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.TopicUnsubscribeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceregistrationtoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    notification: (f = msg.getNotification()) && proto.unifiedplatform.heartIntuneProfile.notification.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest;
  return proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceregistrationtoken(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.notification.Notification;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.notification.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceregistrationtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.notification.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceRegistrationToken = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.getDeviceregistrationtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.setDeviceregistrationtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Notification notification = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.Notification}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.getNotification = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.Notification} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.notification.Notification, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.Notification|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationRequest.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse;
  return proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string messageId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.getMessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.setMessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ErrorInfo error = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.getError = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.DeviceNotificationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notification: (f = msg.getNotification()) && proto.unifiedplatform.heartIntuneProfile.notification.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest;
  return proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.heartIntuneProfile.notification.Notification;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.notification.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.heartIntuneProfile.notification.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deviceId = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Notification notification = 3;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.Notification}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.getNotification = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.Notification} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.notification.Notification, 3));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.Notification|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationRequest.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse;
  return proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string messageId = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.getMessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.setMessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ErrorInfo error = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.getError = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.UserNotificationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo}
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo;
  return proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo}
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.ErrorInfo.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.repeatedFields_ = [1,2,5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bccList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    ccList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    replyto: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    subject: jspb.Message.getFieldWithDefault(msg, 6, ""),
    text: jspb.Message.getFieldWithDefault(msg, 7, ""),
    html: jspb.Message.getFieldWithDefault(msg, 8, ""),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest;
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBcc(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReplyto(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtml(value);
      break;
    case 9:
      var value = new proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getReplyto();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHtml();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64(),
    contenttype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contentid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment;
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContenttype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getContenttype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContentid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string contentType = 3;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.getContenttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.setContenttype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contentId = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.getContentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment.prototype.setContentid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string bcc = 1;
 * @return {!Array<string>}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setBccList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.addBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.clearBccList = function() {
  return this.setBccList([]);
};


/**
 * repeated string cc = 2;
 * @return {!Array<string>}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setCcList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.addCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.clearCcList = function() {
  return this.setCcList([]);
};


/**
 * optional string replyTo = 4;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getReplyto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setReplyto = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string to = 5;
 * @return {!Array<string>}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getToList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setToList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.addTo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.clearToList = function() {
  return this.setToList([]);
};


/**
 * optional string subject = 6;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string text = 7;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string html = 8;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setHtml = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Attachment attachments = 9;
 * @return {!Array<!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment>}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment, 9));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment>} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
*/
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.Attachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse;
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error;
      reader.readMessage(value,proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error;
  return proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Error error = 2;
 * @return {?proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.getError = function() {
  return /** @type{?proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error, 2));
};


/**
 * @param {?proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.Error|undefined} value
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse} returns this
*/
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse} returns this
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.heartIntuneProfile.notification.EmailNotificationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Version = {
  VERSION_1_0_0: 0
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Category = {
  MEDITATION_SERVICE: 0,
  PROFILE_SERVICE: 1,
  SUBSCRIPTION_SERVICE: 2,
  CONTENT_SERVICE: 3,
  DONATION_SERVICE: 4,
  NOTIFICATION_SERVICE: 5,
  SCHEDULED_CONTENT_SERVICE: 6
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.notification.SubCategory = {
  LOW_PRECEPTOR_CONDITION: 0,
  WAITING_FOR_PRECEPTOR_TO_START: 1,
  TIMED_OUT: 2,
  IN_PROGRESS_TIME_OUT: 3,
  MEDITATION_COMPLETED: 4,
  SCHEDULED: 5,
  SEEKER_CANCELLED: 6,
  PRECEPTOR_CANCELLED: 7,
  PRECEPTOR_NOT_AVAILABLE: 8,
  PRECEPTOR_NO_SHOW: 9,
  READY_FOR_SITTING: 10,
  ZERO_PRECEPTOR_CONDITION: 11,
  FREE_SUBSCRIPTION: 15,
  WEEKLY_INSPIRATION_TOPIC_SUBCATEGORY: 16
};

/**
 * @enum {number}
 */
proto.unifiedplatform.heartIntuneProfile.notification.Topic = {
  ZERO_PRECEPTOR_TOPIC: 0,
  ONLINE_METRICS_TOPIC: 1,
  WEEKLY_INSPIRATION_TOPIC: 2
};

goog.object.extend(exports, proto.unifiedplatform.heartIntuneProfile.notification);
