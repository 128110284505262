import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  ContentCategoryRequest,
  ListOfContentResponse,
  RequestPageable,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import {
  heartsAppContentLoading,
  setHeartsAppContent,
} from "redux/reducers/heartsAppReducer";
import { AppThunk } from "redux/store/store";

export const getAllPDFs =
  (
    pageSize: number,
    pageNumber: number,
    language: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(heartsAppContentLoading(true));
      const pageable: RequestPageable = new RequestPageable();
      pageable.setPagenumber(pageNumber);
      pageable.setPagesize(pageSize);
      const reqBody: ContentCategoryRequest = new ContentCategoryRequest();
      reqBody.setContentcategory(6);
      reqBody.setSelectedlanguage(language);
      reqBody.setPageable(pageable);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.GetContentByCategory, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfContentResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log(message.toObject());
            dispatch(heartsAppContentLoading(false));
            dispatch(setHeartsAppContent(message.toObject()));
          } else {
            console.log("Error in fetching PDFs ", statusMessage);
            dispatch(heartsAppContentLoading(false));
          }
        },
      });
    } catch (err) {
      console.log("Error in fetching all content PDFs: ", err);
    }
  };

export const getAllYoutubeVideos =
  (
    pageSize: number,
    pageNumber: number,
    language: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(heartsAppContentLoading(true));
      const pageable: RequestPageable = new RequestPageable();
      pageable.setPagenumber(pageNumber);
      pageable.setPagesize(pageSize);
      const reqBody: ContentCategoryRequest = new ContentCategoryRequest();
      reqBody.setContentcategory(7);
      reqBody.setSelectedlanguage(language);
      reqBody.setPageable(pageable);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.GetContentByCategory, {
        request: reqBody,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ListOfContentResponse>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log(message.toObject());
            dispatch(heartsAppContentLoading(false));
            dispatch(setHeartsAppContent(message.toObject()));
          } else {
            console.log("Error in fetching Youtube Videos ", statusMessage);
            dispatch(heartsAppContentLoading(false));
          }
        },
      });
    } catch (err) {
      console.log("Error in fetching all content Youtube Videos: ", err);
    }
  };
