import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Radio,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import { firebaseAuth } from "config/firebase";
import { grpc } from "@improbable-eng/grpc-web";
import {
  HEARTINTUNE_PROFILE_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import DialogBox from "components/dialogBox/dialog";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "redux/store/store";

import {
  GroupsRequest,
  GroupsResponse,
} from "api/models/live-meditation-service/live-meditation_pb";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import {
  getAllGroups,
  getAllPreRecordedVideos,
} from "redux/actions/groupsAction";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import moment from "moment";
import momentz from "moment-timezone";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { classes, Root } from "./scheduledPreRecordedVideo.styles";
import { setLocalisedContent } from "./schedulePreRecordedVideos.services";
import style from "./index.module.scss";
import { ListSkeleton } from "components/listSkeleton";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import {
  GroupsResponseHeartInTune,
  ID,
  PreRecordedWithLocaleResponseHeartInTune,
  Status,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { styled } from "@mui/styles";

interface VideosProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  page: number;
  rowsPerPage: number;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: 0,
    background: "#eeeeee",
    "&.Mui-disabled": {
      border: "1px solid white",
    },
    "&.Mui-selected": {
      background: theme.palette.primary.main,
      color: "white",
    },
    "&:not(:first-of-type)": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
    },
    "&:first-of-type": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
    },
  },
}));

export const SchedulePreRecordedVideoForm = React.forwardRef<
  HTMLDivElement,
  VideosProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const languages = useSelector((state: RootState) => state.app.languages);
  const preRecordedVideoList = useSelector(
    (state: RootState) => state.groups.videosList
  );
  const [states, setStates] = useState<State>(initialState);

  const [id, setId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [language, setLanguage] = useState<any>(1);
  const [scheduledTime, setScheduledTime] = useState<any>(new Date());
  // const [scheduledDate, setScheduledDate] = useState<any>(new Date());
  const [scheduleDateTime, setscheduleDateTime] = useState<any>(new Date());
  const [scheduleTimeZone, setscheduleTimeZone] = useState<any>(new Date());
  const [timeZone, settimeZone] = useState<any>(moment.tz.guess());
  const [inputTimeZoneValue, setinputTimeZoneValue] = useState("");
  const [repeatDayValues, setRepeatDayValues] = useState<Array<number>>(
    () => []
  );

  const [isDateChanged, setIsDateChanged] = useState<boolean>(false);

  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchVideo, setSearchVideo] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [selectedVideoId, setSelectedVideoId] = useState(0);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVideoId(Number(event.target.value));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchVideo("");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setSearchVideo("");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickEdit = useCallback(
    (item: any) => {
      setStates({ editing: true });
      setLanguage(props.lang);
      setId(item.id);
      setSelectedVideoId(item.itemData.id);
      setscheduleDateTime(new Date(Number(item.scheduledTime?.seconds) * 1000));
      setScheduledTime(new Date(Number(item.scheduledTime?.seconds) * 1000));
      setRepeatDayValues(item.weekdaysList);
    },
    [props.lang]
  );

  useEffect(() => {
    if (props.isEditing) {
      console.log("props.editItem", props.editItem);
      onClickEdit(props.editItem);
    }
  }, [props.isEditing, props.editItem, onClickEdit]);

  useEffect(() => {
    dispatch(getAllPreRecordedVideos(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const validationForm = () => {
    // if (new Date(scheduleDateTime).getTime() <= Date.now()) {
    //   setSnackBar({
    //     open: true,
    //     severity: "error",
    //     message: "video can't be scheduled for past time",
    //   });
    // }else
    if (moment(scheduleDateTime).isValid() === false) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Invalid date",
      });
    } else if (!selectedVideoId) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "select a video to continue",
      });
    } else {
      return true;
    }
  };

  const _addVideo = () => {
    if (validationForm()) {
      if (isDateChanged) {
        var videoData: any = {
          prerecordedId: selectedVideoId,
          scheduleTime: moment(scheduleDateTime).tz(moment.tz.guess()).format(),
        };
      } else if (!isDateChanged) {
        var videoData: any = {
          prerecordedId: selectedVideoId,
          scheduleTime:
            moment().tz(`${timeZone}`).format("yyyy-MM-DD") +
            "T" +
            moment(scheduledTime).tz(`${timeZone}`).format("HH:mm:ssZ"),
        };
      }

      if (props.isEditing) {
        //setIsAdding(true);
        updateVideo(videoData);
      } else {
        console.log("api call");
        addVideo(videoData);
      }
    }
  };
  const addVideo = async (data: any) => {
    setIsLoading(true);
    try {
      console.log(`Request to adding new pre-recorded-video ${data}`);
      const timeOffset = moment().tz(timeZone).utcOffset();

      const currentGoogleTimeStamp: Timestamp = new Timestamp();

      currentGoogleTimeStamp.fromDate(new Date(data.scheduleTime));

      const reqBody = new GroupsRequest();
      reqBody.setPrerecordedid(selectedVideoId);
      reqBody.setScheduletime(currentGoogleTimeStamp);
      reqBody.setZonaldifference(timeOffset * 60);

      if (repeatDayValues.length === 0) {
        reqBody.setDayfrequency(1);
        reqBody.setRecurrence(false);
      } else if (repeatDayValues.length === 7) {
        reqBody.setRecurrence(true);
        reqBody.setDayfrequency(2);
      } else {
        reqBody.setRecurrence(true);
        reqBody.setDayfrequency(3);
        reqBody.setWeekdaysList(repeatDayValues);
      }

      console.log("Request for adding video", reqBody.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(LiveMeditationService.AddGroups, {
        request: reqBody,
        host: LIVE_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<GroupsResponse>) => {
          const { status, message, statusMessage } = res;
          console.log("Request for adding video", res);

          if (status === grpc.Code.OK && message) {
            if (res.message) {
              console.log("schedule video response", res.message.toObject());
              setIsLoading(false);
              //dispatch(getAllPreRecordedVideos(props.page, props.rowsPerPage));
              dispatch(getAllGroups(props.page, props.rowsPerPage, false));
              setSnackBar({
                open: true,
                severity: "success",
                message: "Pre-Recorded Video is scheduled successfully",
              });
              setTimeout(() => props.onClickBack(), 2000);
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: statusMessage.indexOf(
                "Cannot create Groups - Groups should have a difference of at least 60 minutes with existing Groups session"
              )
                ? "Video should have a difference of at least 60 minutes with existing scheduled videos."
                : `Error in scheduling video : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.log(`Error in add video ${error}`);
      // setSnackBar({
      //   open: true,
      //   severity: "error",
      //   message: error,
      // });
    }
  };

  const updateVideo = async (data: any) => {
    setIsLoading(true);
    // try {
    const preRecordedRes: PreRecordedWithLocaleResponseHeartInTune =
      new PreRecordedWithLocaleResponseHeartInTune();
    preRecordedRes.setId(selectedVideoId);

    const currentGoogleTimeStamp: Timestamp = new Timestamp();
    currentGoogleTimeStamp.fromDate(new Date(data.scheduleTime));

    const reqBody: GroupsResponseHeartInTune = new GroupsResponseHeartInTune();

    reqBody.setPrerecorded(preRecordedRes);
    reqBody.setId(props.editItem.id);
    reqBody.setScheduletime(currentGoogleTimeStamp);
    if (repeatDayValues.length === 0) {
      reqBody.setDayfrequency(1);
      reqBody.setRecurrence(false);
    } else if (repeatDayValues.length === 7) {
      reqBody.setRecurrence(true);
      reqBody.setDayfrequency(2);
    } else {
      reqBody.setRecurrence(true);
      reqBody.setDayfrequency(3);
      reqBody.setWeekdaysList(repeatDayValues);
    }

    console.log(`Request for updating video`, reqBody.toObject());

    const jwtToken = await firebaseAuth.currentUser?.getIdToken();

    grpc.unary(HeartIntuneProfileService.UpdateGroupById, {
      request: reqBody,
      host: HEARTINTUNE_PROFILE_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onEnd: (res: UnaryOutput<GroupsResponseHeartInTune>) => {
        const { status, message, statusMessage } = res;
        if (status === grpc.Code.OK && message) {
          if (message) {
            console.log("update group response", message.toObject());
            setIsLoading(false);
            // dispatch(getAllPreRecordedVideos(props.page, props.rowsPerPage));
            dispatch(getAllGroups(props.page, props.rowsPerPage, false));
            setSnackBar({
              open: true,
              severity: "success",
              message: "Group updated successfully",
            });
            setTimeout(() => props.onClickBack(), 2000);
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: `${statusMessage}`,
            });
          }
        } else {
          setIsLoading(false);
          setSnackBar({
            open: true,
            severity: "error",
            message: statusMessage.indexOf(
              "Cannot create Groups - Groups should have a difference of at least 60 minutes with existing Groups session"
            )
              ? "Video should have a difference of at least 60 minutes with existing scheduled videos."
              : `Error in scheduling video : ${statusMessage}`,
          });
        }
      },
    });
    // } catch (error) {
    //   setIsLoading(false);
    //   console.log(`Error in update video %c${error}`, "color:red");
    //   setSnackBar({
    //     open: true,
    //     severity: "error",
    //     message: "ye bro",
    //   });
    // }
  };

  const onClickDelete = (videoId: any) => {
    setStates({ showDeleteModal: true, deleteItemId: videoId });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };

  const deleteVideo = async () => {
    deleteVideoById(states.deleteItemId);
  };

  const deleteVideoById = async (videoId: String | undefined) => {
    setIsLoading(true);
    console.log(`Delete call for video with id ${videoId}`);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      //reqBody.setId()
      reqBody.setId(Number(videoId));
      grpc.unary(HeartIntuneProfileService.DeleteGroupById, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          console.log("res");
          const { status, message } = res;
          console.log(status, message);
          if (status === grpc.Code.OK) {
            if (message) {
              console.log(`Delete video response ${message.toObject()}`);
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setTimeout(() => props.onClickBack(), 2000);
              dispatch(getAllGroups(props.page, props.rowsPerPage, false));
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted video successfully",
              });
            } else {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "Error",
                message: "Error in deleting video",
              });
            }
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      console.log(`Error: ${err}`);
    }
  };

  const DaySelector = () => {
    return (
      <StyledToggleButtonGroup
        size="small"
        color="primary"
        value={repeatDayValues}
        onChange={(_, val) => {
          setRepeatDayValues(val);
        }}
      >
        <ToggleButton value={7}>S</ToggleButton>
        <ToggleButton value={1}>M</ToggleButton>
        <ToggleButton value={2}>T</ToggleButton>
        <ToggleButton value={3}>W</ToggleButton>
        <ToggleButton value={4}>T</ToggleButton>
        <ToggleButton value={5}>F</ToggleButton>
        <ToggleButton value={6}>S</ToggleButton>
      </StyledToggleButtonGroup>
    );
  };

  const renderVideosList = (data: any) => {
    // console.log("data from table", data);
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  value={searchVideo}
                  fullWidth
                  variant="outlined"
                  placeholder="Search video by title"
                  size="small"
                  onChange={(e) => {
                    setSearchVideo(e.target.value);
                    const filteredData = data.filter((item: any) =>
                      setLocalisedContent(item, props.lang)
                        ?.title.toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(getAllPreRecordedVideos(page, rowsPerPage));
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "300px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell
                      align="left"
                      //   sx={{
                      //     width: "60px",
                      //   }}
                    >
                      Select
                    </TableCell>
                    <TableCell
                      align="left"
                      //  sx={{
                      //     width: "60px",
                      //   }}
                    >
                      Image
                    </TableCell>
                    <TableCell align="left">Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchVideo !== "" ? searchData : data).map(
                    (item: any, x: number) => {
                      return (() => {
                        if (setLocalisedContent(item, props.lang)?.title) {
                          return (
                            <React.Fragment key={x}>
                              <TableRow
                                sx={{ cursor: "pointer", height: "50px" }}
                                hover
                                onClick={() => {
                                  setSelectedVideoId(item.id);
                                }}
                              >
                                <TableCell align="left">
                                  <Radio
                                    checked={selectedVideoId === item.id}
                                    onChange={handleChange}
                                    value={item.id}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Avatar
                                    alt={
                                      setLocalisedContent(item, props.lang)
                                        ?.title
                                    }
                                    src={
                                      setLocalisedContent(item, props.lang)
                                        ?.imgurl
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {setLocalisedContent(item, props.lang)?.title}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        }
                      })();
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={preRecordedVideoList.content.pageable?.totalcount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No videos to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item flexGrow={1}>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {props.isEditing ? "Edit Video" : "Schedule Video"}
              </Typography>
              {props.isEditing ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={props.onClickBack}
                  >
                    Videos
                  </Typography>
                  <Tooltip
                    title={props.editItem.title}
                    disableHoverListener={
                      props.editItem.itemData.title?.length < 20
                    }
                    placement="bottom"
                  >
                    <Box>
                      <Typography color="inherit" style={{ cursor: "pointer" }}>
                        {props.editItem.itemData.title?.slice(0, 20)}
                        {props.editItem.itemData.title?.length > 20 && "..."}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Typography color="textPrimary">Edit</Typography>
                </Breadcrumbs>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.isEditing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(props.editItem.id)}
                disabled
              >
                Delete
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sx={{ overflowX: "scroll" }}>
            <Tooltip title="Select repeat frequency for group. If nothing is selected, the group will not repeat">
              <TextField
                variant="outlined"
                label="Repeats on"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: DaySelector,
                }}
                // inputProps={{ children: children }}
              />
            </Tooltip>
          </Grid>
          <Grid item flexGrow={1}>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/* <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm a"
                  label="Schedule Date And Time"
                  renderInput={(params) => <TextField {...params} />}
                  //minDateTime={new Date(Date.now())}
                  disablePast={true}
                  value={scheduledTime}
                  // error={false}
                  // helperText={null}
                  onChange={(value: any) => {
                    console.log("date value from schedule session", value);
                    const time = moment(value)
                      .tz(`${timeZone}`)
                      .format("dd/mm/yyyy hh:mm a ");

                    console.log(`on date change time for ${timeZone}`, time);
                    const newTime = moment(value).tz(`${timeZone}`).format();
                    setscheduleDateTime(newTime);
                    setScheduledTime(value);
                    setscheduleTimeZone(value);
                  }}
                  //onError={(err)=>alert(err)}
                /> */}
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Recurrence starting date"
                  renderInput={(params) => (
                    <TextField
                      sx={{ maxWidth: "220px" }}
                      {...params}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                  disablePast
                  value={scheduledTime}
                  onChange={(value: any) => {
                    console.log("date from schedule session", value);
                    console.log(
                      moment().tz(`${timeZone}`).format("yyyy-MM-DD") +
                        "T" +
                        moment(scheduledTime)
                          .tz(`${timeZone}`)
                          .format("HH:mm:ssZ")
                    );

                    // console.log(
                    //   moment(
                    //     moment().format(
                    //       `yyyy-MM-DD ${moment(scheduledTime)
                    //         .tz(`${timeZone}`)
                    //         .format("hh:mm a")}`
                    //     )
                    //   )
                    //     .tz(moment.tz.guess())
                    //     .format()
                    // );
                    const date = moment(value)
                      .tz(`${timeZone}`)
                      .format("DD/MM/yyyy");
                    console.log(`after date change for ${timeZone}: `, date);
                    const newTime = moment(value).tz(`${timeZone}`).format();
                    console.log("newTime: ", newTime);
                    setscheduleDateTime(newTime);
                    setScheduledTime(value);
                    setscheduleTimeZone(value);
                    setIsDateChanged(true);
                  }}
                />
                <TimePicker
                  inputFormat="hh:mm a"
                  label="Recurrence time"
                  renderInput={(params) => (
                    <TextField
                      sx={{ maxWidth: "220px" }}
                      {...params}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                  value={scheduledTime}
                  onChange={(value: any) => {
                    console.log("time from schedule session", value);
                    const time = moment(value)
                      .tz(`${timeZone}`)
                      .format("hh:mm a");
                    console.log(`after time change for ${timeZone}: `, time);
                    const newTime = moment(value).tz(`${timeZone}`).format();
                    console.log("newtime: ", newTime);
                    setscheduleDateTime(newTime);
                    setScheduledTime(value);
                    setscheduleTimeZone(value);
                  }}
                />
              </LocalizationProvider>
              {/* </MuiPickersUtilsProvider> */}
            </FormControl>
          </Grid>
          <Grid item flexGrow={1}>
            <Autocomplete
              sx={{ width: "200px" }}
              disablePortal
              disableClearable
              value={timeZone}
              onChange={(event, newValue) => {
                // console.log('current time zone', moment.tz('Asia/Bahrain').format('DD/MM/yyyy hh:mm a' ))
                settimeZone(newValue);
                // const time = moment.tz(scheduleTimeZone, 'YYYY-MM-DD HH:mm:ss', `${newValue}`).format('DD/MM/yyyy hh:mm a')
                const time = moment(scheduleTimeZone)
                  .tz(`${newValue}`)
                  .format("DD/MM/yyyy hh:mm a");
                setScheduledTime(time);
                console.log(`on select change time for ${newValue}`, time);
                // const newTime=moment.tz(scheduleTimeZone, 'YYYY-MM-DD HH:mm:ss', `${newValue}`).format()
                const newTime = moment(scheduleTimeZone)
                  .tz(`${newValue}`)
                  .format();
                console.log(
                  `on select change new time for ${newValue}`,
                  newTime
                );
                setscheduleDateTime(newTime);
              }}
              inputValue={inputTimeZoneValue}
              onInputChange={(event, newInputValue) => {
                setinputTimeZoneValue(newInputValue);
              }}
              // disabled={!editScheduleSession}
              id="combo-box-demo"
              options={momentz.tz.names()}
              style={{ maxWidth: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  // variant={
                  //   editScheduleSession ? "outlined" : "standard"
                  // }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid my={2}>
          <Typography fontWeight="bold">Select Prerecorded Video</Typography>
        </Grid>
        <Grid>
          <Paper>
            {preRecordedVideoList.loading
              ? ListSkeleton()
              : renderVideosList(
                  preRecordedVideoList.content.prerecordedsList.filter(
                    (item) => item.prerecordedtype === 0
                  )
                )}
          </Paper>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.formBtn}
          onClick={props.onClickBack}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          className={classes.formBtn}
          onClick={() => _addVideo()}
          color="primary"
          variant="contained"
          //disabled={isAdding}
        >
          {props.isEditing ? "Update video" : "Schedule video"}
        </Button>
      </Box>
      <DialogBox
        open={states.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteVideo}
        type={"delete"}
        title={`Sure !! Want to delete this video ?`}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
