import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Breadcrumbs,
  Button,
  Divider,
  CardContent,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import DialogBox from "components/dialogBox/dialog";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { addFAQ, deleteFAQ, updateFAQ } from "redux/actions/faqActions";
import { RequestPageable } from "api/models/gamification-service/gamification_pb";
import { classes } from "../groups/schedulePreRecordedVideo/scheduledPreRecordedVideo.styles";
import { Root } from "./faq.styles";
import { MenuProps } from "styles/global.styles";

interface FaqPropsType {
  onClickBack: any;
  editing?: any;
  editItem?: any;
  lang: number;
  setPage: any;
  pageable: RequestPageable.AsObject;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
};

export const FaqForm = React.forwardRef<HTMLDivElement, FaqPropsType>(
  (props, ref): ReactElement => {
    const languageType = useMemo(
      () => [
        "defaultcontent",
        "defaultcontent",
        "hi",
        "te",
        "gu",
        "ta",
        "ka",
        "mr",
        "it",
        "ru",
        "es",
        "fr",
        "pt",
        "de",
        "bn",
        "ml",
        "uk",
        "od",
        "enUs",
        "enAu",
      ],
      []
    );

    const faqScreenList = [
      {
        displayName: "Home screen",
        code: 0,
      },
      {
        displayName: "Live session details screen",
        code: 1,
      },
      {
        displayName: "Mood summary screen",
        code: 2,
      },
      {
        displayName: "Contribute screen",
        code: 3,
      },
      {
        displayName: "Heartspot screen",
        code: 4,
      },
    ];

    const dispatch = useDispatch();

    const [states, setStates] = useState<State>(initialState);
    const [id, setId] = useState<number>();
    const [question, setQuestion] = useState({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
    });
    const [answer, setAnswer] = useState<any>({
      defaultcontent: [""],
      en: [""],
      hi: [""],
      te: [""],
      gu: [""],
      ta: [""],
      ka: [""],
      mr: [""],
      it: [""],
      ru: [""],
      es: [""],
      fr: [""],
      pt: [""],
      de: [""],
      bn: [""],
      ml: [""],
      uk: [""],
      od: [""],
      enUs: [""],
      enAu: [""],
    });
    const [screen, setScreen] = useState<any>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<number>(0);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [numberOfAnswers, setNumberOfAnswers] = useState<number>(1);
    const [isAdding, setIsAdding] = useState(false);

    const addAnswerHandler = (val) => {
      if (numberOfAnswers + val !== 0) {
        if (numberOfAnswers + val < numberOfAnswers) {
          const temp = JSON.parse(JSON.stringify(answer));
          temp[languageType[language]].pop();
          setAnswer(temp);
          setNumberOfAnswers((prevState) => prevState + val);
        } else {
          if (answer[languageType[language]].slice(-1)[0].trim() !== "") {
            const temp = JSON.parse(JSON.stringify(answer));

            temp[languageType[language]].push("");
            setAnswer(temp);
            setNumberOfAnswers((prevState) => prevState + val);
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "First fill the available answer fields.",
            });
          }
        }
      }
    };

    const onClickEdit = useCallback(
      (item: any) => {
        console.log("itemg", item);

        const answers = {
          defaultcontent: item.faqsanswers.defaultcontent.split("*"),
          en: item.faqsanswers.en.split("*"),
          hi:
            props.lang - 1 === 2
              ? item.faqsanswers.hi
                ? item.faqsanswers.hi.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.hi.split("*"),
          te:
            props.lang - 1 === 3
              ? item.faqsanswers.te
                ? item.faqsanswers.te.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.te.split("*"),
          gu:
            props.lang - 1 === 4
              ? item.faqsanswers.gu
                ? item.faqsanswers.gu.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.gu.split("*"),
          ta:
            props.lang - 1 === 5
              ? item.faqsanswers.ta
                ? item.faqsanswers.ta.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.ta.split("*"),
          ka:
            props.lang - 1 === 6
              ? item.faqsanswers.ka
                ? item.faqsanswers.ka.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.ka.split("*"),
          mr:
            props.lang - 1 === 7
              ? item.faqsanswers.mr
                ? item.faqsanswers.mr.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.mr.split("*"),
          it:
            props.lang - 1 === 8
              ? item.faqsanswers.it
                ? item.faqsanswers.it.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.it.split("*"),
          ru:
            props.lang - 1 === 9
              ? item.faqsanswers.ru
                ? item.faqsanswers.ru.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.ru.split("*"),
          es:
            props.lang - 1 === 10
              ? item.faqsanswers.es
                ? item.faqsanswers.es.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.es.split("*"),
          fr:
            props.lang - 1 === 11
              ? item.faqsanswers.fr
                ? item.faqsanswers.fr.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.fr.split("*"),
          pt:
            props.lang - 1 === 12
              ? item.faqsanswers.pt
                ? item.faqsanswers.pt.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.pt.split("*"),
          de:
            props.lang - 1 === 13
              ? item.faqsanswers.de
                ? item.faqsanswers.de.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.de.split("*"),
          bn:
            props.lang - 1 === 14
              ? item.faqsanswers.bn
                ? item.faqsanswers.bn.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.bn.split("*"),
          ml:
            props.lang - 1 === 15
              ? item.faqsanswers.ml
                ? item.faqsanswers.ml.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.ml.split("*"),
          uk:
            props.lang - 1 === 16
              ? item.faqsanswers.uk
                ? item.faqsanswers.uk.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.uk.split("*"),
          od:
            props.lang - 1 === 17
              ? item.faqsanswers.od
                ? item.faqsanswers.od.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.od.split("*"),
          enUs:
            props.lang - 1 === 18
              ? item.faqsanswers.enUs
                ? item.faqsanswers.enUs.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.enUs.split("*"),
          enAu:
            props.lang - 1 === 19
              ? item.faqsanswers.enAu
                ? item.faqsanswers.enAu.split("*")
                : item.faqsanswers.defaultcontent.split("*")
              : item.faqsanswers.enAu.split("*"),
        };
        //console.log(answers);
        // const answers={
        //   ...item.faqsanswers,
        //   [languageType[props.lang-1]]: item.faqsanswers[languageType[props.lang-1]]
        //     ? item.faqsanswers[languageType[props.lang-1]].split("*")
        //     : item.faqsanswers[languageType[0]].split("*"),
        // }

        setStates({ editing: true });
        setId(item.id);
        setQuestion({
          ...item.faqs,
          [languageType[props.lang - 1]]: item.faqs[
            languageType[props.lang - 1]
          ]
            ? item.faqs[languageType[props.lang - 1]]
            : item.faqs.defaultcontent,
        });
        setAnswer(answers);
        setNumberOfAnswers(answers[languageType[props.lang - 1]].length);
        setScreen(item.selectedscreen);
      },
      [languageType, props.lang]
    );

    useEffect(() => {
      if (props.editing) {
        onClickEdit(props.editItem);
        //setLanguage(props.lang - 1);
      }
      setLanguage(props.lang - 1);
    }, [onClickEdit, props.editItem, props.editing, props.lang]);

    const validateForm = () => {
      if (question[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Question can not be empty.",
        });
      } else if (answer[languageType[language]][0].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Atleast one answer is required.",
        });
      } else {
        return true;
      }
    };

    const quoteHandler = () => {
      if (validateForm()) {
        setIsLoading(true);
        setIsAdding(true);
        if (props.editing) {
          dispatch(
            updateFAQ({
              id: Number(id),
              question,
              answer,
              screen,
              setSnackBar,
              pageable: props.pageable,
            })
          );
        } else {
          setIsAdding(true);
          setIsLoading(true);
          dispatch(
            addFAQ({
              question,
              answer,
              screen,
              setSnackBar,
              pageable: props.pageable,
            })
          );
        }
        setTimeout(() => {
          setIsLoading(false);
          props.onClickBack();
        }, 2000);
      }
    };

    const deleteQuote = () => {
      setIsLoading(true);
      props.setPage(0);
      setStates({ showDeleteModal: false });
      dispatch(
        deleteFAQ({
          id: Number(id),
          setSnackBar,
          pageable: props.pageable,
        })
      );
      setTimeout(() => {
        setIsLoading(false);
        props.onClickBack();
      }, 2000);
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.editing ? "Edit FAQ" : "Add New FAQ"}
                </Typography>
                {props.editing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      FAQs
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.editing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setStates({ showDeleteModal: true });
                  }}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="question"
                label="Question"
                variant="outlined"
                value={question[languageType[language]]}
                onChange={(e) => {
                  // console.log("question", question);
                  props.editing
                    ? setQuestion((prevState) => ({
                        ...prevState,
                        [languageType[language]]: e.target.value,
                      }))
                    : setQuestion((prevState) => ({
                        ...prevState,
                        [languageType[0]]: e.target.value,
                        [languageType[language]]: e.target.value,
                      }));
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Category</InputLabel>
                <Select
                  defaultValue=""
                  labelId="video-category-select"
                  value={screen}
                  label="Language"
                  onChange={(e) => {
                    setScreen(e.target.value as number);
                  }}
                  MenuProps={MenuProps}
                >
                  {faqScreenList.map((catagory: any, i: any) => (
                    <MenuItem key={i} value={catagory.code}>
                      {catagory.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {[...Array(numberOfAnswers)].fill("").map((_, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    required
                    type="text"
                    name="answer"
                    label={`Answer ${index + 1}`}
                    variant="outlined"
                    value={answer[languageType[language]][index] ?? ""}
                    onChange={(e) => {
                      //console.log("ans", answer);
                      if (props.editing) {
                        const temp = JSON.parse(JSON.stringify(answer));
                        temp[languageType[language]][index] =
                          e.target.value.replaceAll("*", "");
                        setAnswer(temp);
                      } else {
                        const temp = JSON.parse(JSON.stringify(answer));
                        temp[languageType[0]][index] =
                          e.target.value.replaceAll("*", "");
                        temp[languageType[language]][index] =
                          e.target.value.replaceAll("*", "");
                        setAnswer(temp);
                      }
                    }}
                    className="mr-3"
                  />
                </Grid>
              );
            })}
            <Grid item>
              <Button
                className={classes.formBtn}
                onClick={() => {
                  addAnswerHandler(1);
                }}
                color="primary"
                variant="contained"
                sx={{ marginRight: 2 }}
              >
                + answer
              </Button>
              <Button
                className={classes.formBtn}
                onClick={() => {
                  addAnswerHandler(-1);
                }}
                disabled={numberOfAnswers === 1}
                color="primary"
                variant="contained"
              >
                - answer
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
            sx={{ marginRight: 2 }}
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => quoteHandler()}
            disabled={isAdding}
            color="primary"
            variant="contained"
          >
            {props.editing ? "Update FAQ" : "Add FAQ"}
          </Button>
        </Box>
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={() => {
            setStates({ showDeleteModal: false });
          }}
          onCancel={() => {
            setStates({ showDeleteModal: false });
          }}
          onConfirm={deleteQuote}
          type={"delete"}
          title={`Sure !! Want to delete this FAQ ?`}
        />
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
