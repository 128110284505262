// source: gamification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var Badge_pb = require('./Badge_pb.js');
goog.object.extend(proto, Badge_pb);
var Goal_pb = require('./Goal_pb.js');
goog.object.extend(proto, Goal_pb);
var program_pb = require('./program_pb.js');
goog.object.extend(proto, program_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.AddMoodRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.AddNewGoalInfoV2Request', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.AddNewGoalResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.AddQuoteRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.AllGoals', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.BulkUploadQuoteResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentCategoriesRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentCategoriesResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentCategoryRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentFilterLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentFilterRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentFilterResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ContentResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.CustomGoalRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.CustomGoalResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DeleteFileRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DeleteGoalByIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DeleteGoalByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DocumentInfoRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DonationCreateRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DonationDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DonationResponseWithLocale', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.DurationAndRequiredTime', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.Empty', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.FAQsLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.FAQsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.FAQsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.FileInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllContentFilterResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllContentRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllContentResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllFAQsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllGoalDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllGoalInfoV2Request', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllGoalsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetAllMoodTriggerResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetBadgeByNameRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetBadgeByNameResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetGoalByIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetGoalInfoV2Response', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetGoalStatusCount', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GoalCategoryDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GoalId', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.GoalInfoV2Response', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.HelloWorldString', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ID', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfAllContentResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfContentResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfDonationDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfIDs', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfMoodDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ListOfQuoteDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.LocaleType', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.Meditation', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MeditationCategoryForDate', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MeditationTrackerStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MeditationTrackerStatusResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MoodDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MoodTriggerLocalResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MoodTriggerRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.MoodTriggerResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.PaginationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.PersonalityQuizLocale', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.PersonalityQuizRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.PersonalityQuizResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.QuoteDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestBetweenDates', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestForGetAllGoalDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestGetBadgeByID', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestGoalByUserId', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestPageable', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestToScheduleGoal', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestUserWeeklyStats', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.RequestV2GetAllMySignUp', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponseGoal', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponseGoalDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponseGoalInfoV2', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponsePageable', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponseStatus', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.ScreenNameRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.SearchContent', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.SeekerInfoRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.SelfDevelopmentContentRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.SelfDevelopmentContentResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.SharedInfoRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.Status', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.Tag', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.TagWithContent', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.Tags', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.TagsList', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.Test', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.TestResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.TrackerInfo', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.TrackerWithBadge', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.URLRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.UpdateGoalByIdRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.UploadFileRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.UploadFileResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.gamification.updateGoalInfoV2Request', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.Test = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.Test, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.Test.displayName = 'proto.unifiedplatform.gamification.Test';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.Empty.displayName = 'proto.unifiedplatform.gamification.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.Status.displayName = 'proto.unifiedplatform.gamification.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.TestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.TestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.TestResponse.displayName = 'proto.unifiedplatform.gamification.TestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.FileInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.FileInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.FileInfo.displayName = 'proto.unifiedplatform.gamification.FileInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.UploadFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.UploadFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.UploadFileRequest.displayName = 'proto.unifiedplatform.gamification.UploadFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.UploadFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.UploadFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.UploadFileResponse.displayName = 'proto.unifiedplatform.gamification.UploadFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.Meditation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.Meditation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.Meditation.displayName = 'proto.unifiedplatform.gamification.Meditation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GoalId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GoalId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GoalId.displayName = 'proto.unifiedplatform.gamification.GoalId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponseStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponseStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponseStatus.displayName = 'proto.unifiedplatform.gamification.ResponseStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestGetBadgeByID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestGetBadgeByID.displayName = 'proto.unifiedplatform.gamification.RequestGetBadgeByID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponseGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponseGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponseGoal.displayName = 'proto.unifiedplatform.gamification.ResponseGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.AllGoals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.AllGoals.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.AllGoals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.AllGoals.displayName = 'proto.unifiedplatform.gamification.AllGoals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.AddNewGoalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.AddNewGoalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.AddNewGoalResponse.displayName = 'proto.unifiedplatform.gamification.AddNewGoalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllGoalsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllGoalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllGoalsResponse.displayName = 'proto.unifiedplatform.gamification.GetAllGoalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetGoalByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetGoalByIdRequest.displayName = 'proto.unifiedplatform.gamification.GetGoalByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.displayName = 'proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.DeleteGoalByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DeleteGoalByIdRequest.displayName = 'proto.unifiedplatform.gamification.DeleteGoalByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.DeleteGoalByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DeleteGoalByIdResponse.displayName = 'proto.unifiedplatform.gamification.DeleteGoalByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.UpdateGoalByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.UpdateGoalByIdRequest.displayName = 'proto.unifiedplatform.gamification.UpdateGoalByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetBadgeByNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetBadgeByNameResponse.displayName = 'proto.unifiedplatform.gamification.GetBadgeByNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetBadgeByNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetBadgeByNameRequest.displayName = 'proto.unifiedplatform.gamification.GetBadgeByNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.displayName = 'proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponseGoalDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponseGoalDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponseGoalDetails.displayName = 'proto.unifiedplatform.gamification.ResponseGoalDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllGoalDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.displayName = 'proto.unifiedplatform.gamification.GetAllGoalDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.displayName = 'proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.displayName = 'proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DeleteFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.DeleteFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DeleteFileRequest.displayName = 'proto.unifiedplatform.gamification.DeleteFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.displayName = 'proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestForGetAllGoalDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.displayName = 'proto.unifiedplatform.gamification.RequestForGetAllGoalDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestPageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestPageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestPageable.displayName = 'proto.unifiedplatform.gamification.RequestPageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponsePageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponsePageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponsePageable.displayName = 'proto.unifiedplatform.gamification.ResponsePageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.displayName = 'proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GoalInfoV2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GoalInfoV2Response.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GoalInfoV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GoalInfoV2Response.displayName = 'proto.unifiedplatform.gamification.GoalInfoV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.AddNewGoalInfoV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.displayName = 'proto.unifiedplatform.gamification.AddNewGoalInfoV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.updateGoalInfoV2Request.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.updateGoalInfoV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.updateGoalInfoV2Request.displayName = 'proto.unifiedplatform.gamification.updateGoalInfoV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetGoalInfoV2Response.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetGoalInfoV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetGoalInfoV2Response.displayName = 'proto.unifiedplatform.gamification.GetGoalInfoV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.displayName = 'proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.displayName = 'proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.displayName = 'proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ID.displayName = 'proto.unifiedplatform.gamification.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.LocaleType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.LocaleType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.LocaleType.displayName = 'proto.unifiedplatform.gamification.LocaleType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllGoalInfoV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.displayName = 'proto.unifiedplatform.gamification.GetAllGoalInfoV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.displayName = 'proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.displayName = 'proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.displayName = 'proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GoalCategoryDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.displayName = 'proto.unifiedplatform.gamification.GoalCategoryDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.displayName = 'proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.DonationResponseWithLocale.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.DonationResponseWithLocale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DonationResponseWithLocale.displayName = 'proto.unifiedplatform.gamification.DonationResponseWithLocale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DonationCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.DonationCreateRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.DonationCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DonationCreateRequest.displayName = 'proto.unifiedplatform.gamification.DonationCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DonationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.DonationDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.DonationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DonationDetails.displayName = 'proto.unifiedplatform.gamification.DonationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfDonationDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.displayName = 'proto.unifiedplatform.gamification.ListOfDonationDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.AddQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.AddQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.AddQuoteRequest.displayName = 'proto.unifiedplatform.gamification.AddQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfQuoteDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfQuoteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfQuoteDetails.displayName = 'proto.unifiedplatform.gamification.ListOfQuoteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.QuoteDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.QuoteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.QuoteDetails.displayName = 'proto.unifiedplatform.gamification.QuoteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ContentLocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentLocaleResponse.displayName = 'proto.unifiedplatform.gamification.ContentLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ContentRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentRequest.displayName = 'proto.unifiedplatform.gamification.ContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ContentResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentResponse.displayName = 'proto.unifiedplatform.gamification.ContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfContentResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfContentResponse.displayName = 'proto.unifiedplatform.gamification.ListOfContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfAllContentResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfAllContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfAllContentResponse.displayName = 'proto.unifiedplatform.gamification.ListOfAllContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.PersonalityQuizLocale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.PersonalityQuizLocale.displayName = 'proto.unifiedplatform.gamification.PersonalityQuizLocale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.PersonalityQuizRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.PersonalityQuizRequest.displayName = 'proto.unifiedplatform.gamification.PersonalityQuizRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.PersonalityQuizResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.PersonalityQuizResponse.displayName = 'proto.unifiedplatform.gamification.PersonalityQuizResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.HelloWorldString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.HelloWorldString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.HelloWorldString.displayName = 'proto.unifiedplatform.gamification.HelloWorldString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.displayName = 'proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ContentCategoryRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentCategoryRequest.displayName = 'proto.unifiedplatform.gamification.ContentCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ContentCategoriesRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentCategoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentCategoriesRequest.displayName = 'proto.unifiedplatform.gamification.ContentCategoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ContentCategoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentCategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentCategoriesResponse.displayName = 'proto.unifiedplatform.gamification.ContentCategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.URLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.URLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.URLRequest.displayName = 'proto.unifiedplatform.gamification.URLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.BulkUploadQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.BulkUploadQuoteResponse.displayName = 'proto.unifiedplatform.gamification.BulkUploadQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.displayName = 'proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.displayName = 'proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.SearchContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.SearchContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.SearchContent.displayName = 'proto.unifiedplatform.gamification.SearchContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllContentRequest.displayName = 'proto.unifiedplatform.gamification.GetAllContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllContentResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllContentResponse.displayName = 'proto.unifiedplatform.gamification.GetAllContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ScreenNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ScreenNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ScreenNameRequest.displayName = 'proto.unifiedplatform.gamification.ScreenNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentFilterLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentFilterLocaleResponse.displayName = 'proto.unifiedplatform.gamification.ContentFilterLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentFilterRequest.displayName = 'proto.unifiedplatform.gamification.ContentFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentFilterResponse.displayName = 'proto.unifiedplatform.gamification.ContentFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllContentFilterResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllContentFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllContentFilterResponse.displayName = 'proto.unifiedplatform.gamification.GetAllContentFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MoodTriggerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.MoodTriggerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MoodTriggerRequest.displayName = 'proto.unifiedplatform.gamification.MoodTriggerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MoodTriggerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.MoodTriggerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MoodTriggerResponse.displayName = 'proto.unifiedplatform.gamification.MoodTriggerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.MoodTriggerLocalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MoodTriggerLocalResponse.displayName = 'proto.unifiedplatform.gamification.MoodTriggerLocalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllMoodTriggerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.displayName = 'proto.unifiedplatform.gamification.GetAllMoodTriggerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.AddMoodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.AddMoodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.AddMoodRequest.displayName = 'proto.unifiedplatform.gamification.AddMoodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MoodDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.MoodDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MoodDetails.displayName = 'proto.unifiedplatform.gamification.MoodDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfMoodDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.displayName = 'proto.unifiedplatform.gamification.ListOfMoodDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.displayName = 'proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.displayName = 'proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.displayName = 'proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.FAQsLocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.FAQsLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.FAQsLocaleResponse.displayName = 'proto.unifiedplatform.gamification.FAQsLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.FAQsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.FAQsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.FAQsRequest.displayName = 'proto.unifiedplatform.gamification.FAQsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.FAQsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.FAQsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.FAQsResponse.displayName = 'proto.unifiedplatform.gamification.FAQsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllFAQsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllFAQsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllFAQsResponse.displayName = 'proto.unifiedplatform.gamification.GetAllFAQsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.displayName = 'proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.SelfDevelopmentContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.displayName = 'proto.unifiedplatform.gamification.SelfDevelopmentContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.SelfDevelopmentContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.displayName = 'proto.unifiedplatform.gamification.SelfDevelopmentContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.displayName = 'proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.Tag.displayName = 'proto.unifiedplatform.gamification.Tag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.TagWithContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.TagWithContent.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.TagWithContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.TagWithContent.displayName = 'proto.unifiedplatform.gamification.TagWithContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.TagsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.TagsList.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.TagsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.TagsList.displayName = 'proto.unifiedplatform.gamification.TagsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.Tags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.Tags.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.Tags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.Tags.displayName = 'proto.unifiedplatform.gamification.Tags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DocumentInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.DocumentInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DocumentInfoRequest.displayName = 'proto.unifiedplatform.gamification.DocumentInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.SharedInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.SharedInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.SharedInfoRequest.displayName = 'proto.unifiedplatform.gamification.SharedInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.SeekerInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.SeekerInfoRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.SeekerInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.SeekerInfoRequest.displayName = 'proto.unifiedplatform.gamification.SeekerInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.displayName = 'proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.displayName = 'proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.MeditationTrackerStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MeditationTrackerStatus.displayName = 'proto.unifiedplatform.gamification.MeditationTrackerStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.MeditationTrackerStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.displayName = 'proto.unifiedplatform.gamification.MeditationTrackerStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.displayName = 'proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.PaginationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.PaginationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.PaginationRequest.displayName = 'proto.unifiedplatform.gamification.PaginationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetGoalStatusCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetGoalStatusCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetGoalStatusCount.displayName = 'proto.unifiedplatform.gamification.GetGoalStatusCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.DurationAndRequiredTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.DurationAndRequiredTime.displayName = 'proto.unifiedplatform.gamification.DurationAndRequiredTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ResponseGoalInfoV2.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponseGoalInfoV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponseGoalInfoV2.displayName = 'proto.unifiedplatform.gamification.ResponseGoalInfoV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.displayName = 'proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfIDs.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfIDs.displayName = 'proto.unifiedplatform.gamification.ListOfIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestV2GetAllMySignUp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.displayName = 'proto.unifiedplatform.gamification.RequestV2GetAllMySignUp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.displayName = 'proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestGoalByUserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestGoalByUserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestGoalByUserId.displayName = 'proto.unifiedplatform.gamification.RequestGoalByUserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.displayName = 'proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.displayName = 'proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.RequestToScheduleGoal.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestToScheduleGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestToScheduleGoal.displayName = 'proto.unifiedplatform.gamification.RequestToScheduleGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.displayName = 'proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.TrackerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.TrackerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.TrackerInfo.displayName = 'proto.unifiedplatform.gamification.TrackerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.TrackerWithBadge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.TrackerWithBadge.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.TrackerWithBadge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.TrackerWithBadge.displayName = 'proto.unifiedplatform.gamification.TrackerWithBadge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.CustomGoalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.CustomGoalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.CustomGoalRequest.displayName = 'proto.unifiedplatform.gamification.CustomGoalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.CustomGoalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.CustomGoalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.CustomGoalResponse.displayName = 'proto.unifiedplatform.gamification.CustomGoalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.gamification.MeditationCategoryForDate.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.gamification.MeditationCategoryForDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.MeditationCategoryForDate.displayName = 'proto.unifiedplatform.gamification.MeditationCategoryForDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestUserWeeklyStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestUserWeeklyStats.displayName = 'proto.unifiedplatform.gamification.RequestUserWeeklyStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.displayName = 'proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.gamification.RequestBetweenDates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.gamification.RequestBetweenDates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.gamification.RequestBetweenDates.displayName = 'proto.unifiedplatform.gamification.RequestBetweenDates';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.Test.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.Test.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.Test} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Test.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.Test}
 */
proto.unifiedplatform.gamification.Test.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.Test;
  return proto.unifiedplatform.gamification.Test.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.Test} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.Test}
 */
proto.unifiedplatform.gamification.Test.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.Test.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.Test.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.Test} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Test.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.Test.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.Test} returns this
 */
proto.unifiedplatform.gamification.Test.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.Empty}
 */
proto.unifiedplatform.gamification.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.Empty;
  return proto.unifiedplatform.gamification.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.Empty}
 */
proto.unifiedplatform.gamification.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.Status}
 */
proto.unifiedplatform.gamification.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.Status;
  return proto.unifiedplatform.gamification.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.Status}
 */
proto.unifiedplatform.gamification.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.Status.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.Status} returns this
 */
proto.unifiedplatform.gamification.Status.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.Status.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.Status} returns this
 */
proto.unifiedplatform.gamification.Status.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.TestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.TestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.TestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: (f = msg.getTest()) && proto.unifiedplatform.gamification.Test.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.TestResponse}
 */
proto.unifiedplatform.gamification.TestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.TestResponse;
  return proto.unifiedplatform.gamification.TestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.TestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.TestResponse}
 */
proto.unifiedplatform.gamification.TestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.Test;
      reader.readMessage(value,proto.unifiedplatform.gamification.Test.deserializeBinaryFromReader);
      msg.setTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.TestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.TestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.TestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.Test.serializeBinaryToWriter
    );
  }
};


/**
 * optional Test test = 1;
 * @return {?proto.unifiedplatform.gamification.Test}
 */
proto.unifiedplatform.gamification.TestResponse.prototype.getTest = function() {
  return /** @type{?proto.unifiedplatform.gamification.Test} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.Test, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.Test|undefined} value
 * @return {!proto.unifiedplatform.gamification.TestResponse} returns this
*/
proto.unifiedplatform.gamification.TestResponse.prototype.setTest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.TestResponse} returns this
 */
proto.unifiedplatform.gamification.TestResponse.prototype.clearTest = function() {
  return this.setTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TestResponse.prototype.hasTest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.FileInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.FileInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FileInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    format: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0),
    token: jspb.Message.getFieldWithDefault(msg, 4, ""),
    chunkrange: jspb.Message.getFieldWithDefault(msg, 5, ""),
    objectpath: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.FileInfo}
 */
proto.unifiedplatform.gamification.FileInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.FileInfo;
  return proto.unifiedplatform.gamification.FileInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.FileInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.FileInfo}
 */
proto.unifiedplatform.gamification.FileInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChunkrange(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectpath(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.FileInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.FileInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FileInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChunkrange();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getObjectpath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FileInfo} returns this
 */
proto.unifiedplatform.gamification.FileInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string format = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FileInfo} returns this
 */
proto.unifiedplatform.gamification.FileInfo.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 size = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.FileInfo} returns this
 */
proto.unifiedplatform.gamification.FileInfo.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FileInfo} returns this
 */
proto.unifiedplatform.gamification.FileInfo.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string chunkRange = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.getChunkrange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FileInfo} returns this
 */
proto.unifiedplatform.gamification.FileInfo.prototype.setChunkrange = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string objectPath = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.getObjectpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FileInfo} returns this
 */
proto.unifiedplatform.gamification.FileInfo.prototype.setObjectpath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.FileInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FileInfo} returns this
 */
proto.unifiedplatform.gamification.FileInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.UploadFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.UploadFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.UploadFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    info: (f = msg.getInfo()) && proto.unifiedplatform.gamification.FileInfo.toObject(includeInstance, f),
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.UploadFileRequest}
 */
proto.unifiedplatform.gamification.UploadFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.UploadFileRequest;
  return proto.unifiedplatform.gamification.UploadFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.UploadFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.UploadFileRequest}
 */
proto.unifiedplatform.gamification.UploadFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeid(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.FileInfo;
      reader.readMessage(value,proto.unifiedplatform.gamification.FileInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.UploadFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.UploadFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.UploadFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.FileInfo.serializeBinaryToWriter
    );
  }
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string badgeId = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.getBadgeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.UploadFileRequest} returns this
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FileInfo info = 2;
 * @return {?proto.unifiedplatform.gamification.FileInfo}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.getInfo = function() {
  return /** @type{?proto.unifiedplatform.gamification.FileInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.FileInfo, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.FileInfo|undefined} value
 * @return {!proto.unifiedplatform.gamification.UploadFileRequest} returns this
*/
proto.unifiedplatform.gamification.UploadFileRequest.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.UploadFileRequest} returns this
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes file = 3;
 * @return {!(string|Uint8Array)}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes file = 3;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.unifiedplatform.gamification.UploadFileRequest} returns this
 */
proto.unifiedplatform.gamification.UploadFileRequest.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.UploadFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.UploadFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.UploadFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    range: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.UploadFileResponse}
 */
proto.unifiedplatform.gamification.UploadFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.UploadFileResponse;
  return proto.unifiedplatform.gamification.UploadFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.UploadFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.UploadFileResponse}
 */
proto.unifiedplatform.gamification.UploadFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRange(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.UploadFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.UploadFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.UploadFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRange();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.UploadFileResponse} returns this
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string range = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.getRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.UploadFileResponse} returns this
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.setRange = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.UploadFileResponse} returns this
 */
proto.unifiedplatform.gamification.UploadFileResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.Meditation.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.Meditation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.Meditation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Meditation.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationtype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.Meditation}
 */
proto.unifiedplatform.gamification.Meditation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.Meditation;
  return proto.unifiedplatform.gamification.Meditation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.Meditation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.Meditation}
 */
proto.unifiedplatform.gamification.Meditation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.Meditation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.Meditation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.Meditation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Meditation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.gamification.Meditation.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.gamification.Meditation} returns this
 */
proto.unifiedplatform.gamification.Meditation.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GoalId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GoalId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GoalId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalId.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GoalId}
 */
proto.unifiedplatform.gamification.GoalId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GoalId;
  return proto.unifiedplatform.gamification.GoalId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GoalId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GoalId}
 */
proto.unifiedplatform.gamification.GoalId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GoalId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GoalId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GoalId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 goalID = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalId.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalId} returns this
 */
proto.unifiedplatform.gamification.GoalId.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 associatedBadgeID = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalId.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalId} returns this
 */
proto.unifiedplatform.gamification.GoalId.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponseStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponseStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponseStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponseStatus}
 */
proto.unifiedplatform.gamification.ResponseStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponseStatus;
  return proto.unifiedplatform.gamification.ResponseStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponseStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponseStatus}
 */
proto.unifiedplatform.gamification.ResponseStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponseStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponseStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponseStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseStatus.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.ResponseStatus} returns this
 */
proto.unifiedplatform.gamification.ResponseStatus.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestGetBadgeByID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestGetBadgeByID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestGetBadgeByID}
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestGetBadgeByID;
  return proto.unifiedplatform.gamification.RequestGetBadgeByID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestGetBadgeByID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestGetBadgeByID}
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestGetBadgeByID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestGetBadgeByID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 badgeID = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.prototype.getBadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestGetBadgeByID} returns this
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.RequestGetBadgeByID} returns this
 */
proto.unifiedplatform.gamification.RequestGetBadgeByID.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponseGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponseGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    goal: (f = msg.getGoal()) && Goal_pb.Goal.toObject(includeInstance, f),
    badge: (f = msg.getBadge()) && Badge_pb.Badge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponseGoal}
 */
proto.unifiedplatform.gamification.ResponseGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponseGoal;
  return proto.unifiedplatform.gamification.ResponseGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponseGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponseGoal}
 */
proto.unifiedplatform.gamification.ResponseGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Goal_pb.Goal;
      reader.readMessage(value,Goal_pb.Goal.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    case 2:
      var value = new Badge_pb.Badge;
      reader.readMessage(value,Badge_pb.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponseGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponseGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Goal_pb.Goal.serializeBinaryToWriter
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Badge_pb.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * optional goal.Goal goal = 1;
 * @return {?proto.unifiedplatform.gamification.goal.Goal}
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.getGoal = function() {
  return /** @type{?proto.unifiedplatform.gamification.goal.Goal} */ (
    jspb.Message.getWrapperField(this, Goal_pb.Goal, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.goal.Goal|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoal} returns this
*/
proto.unifiedplatform.gamification.ResponseGoal.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoal} returns this
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional badge.Badge badge = 2;
 * @return {?proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.Badge} */ (
    jspb.Message.getWrapperField(this, Badge_pb.Badge, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.Badge|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoal} returns this
*/
proto.unifiedplatform.gamification.ResponseGoal.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoal} returns this
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoal.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.AllGoals.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.AllGoals.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.AllGoals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.AllGoals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AllGoals.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalList: jspb.Message.toObjectList(msg.getGoalList(),
    Goal_pb.Goal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.AllGoals}
 */
proto.unifiedplatform.gamification.AllGoals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.AllGoals;
  return proto.unifiedplatform.gamification.AllGoals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.AllGoals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.AllGoals}
 */
proto.unifiedplatform.gamification.AllGoals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Goal_pb.Goal;
      reader.readMessage(value,Goal_pb.Goal.deserializeBinaryFromReader);
      msg.addGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.AllGoals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.AllGoals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.AllGoals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AllGoals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Goal_pb.Goal.serializeBinaryToWriter
    );
  }
};


/**
 * repeated goal.Goal goal = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.goal.Goal>}
 */
proto.unifiedplatform.gamification.AllGoals.prototype.getGoalList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.goal.Goal>} */ (
    jspb.Message.getRepeatedWrapperField(this, Goal_pb.Goal, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.goal.Goal>} value
 * @return {!proto.unifiedplatform.gamification.AllGoals} returns this
*/
proto.unifiedplatform.gamification.AllGoals.prototype.setGoalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.goal.Goal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.goal.Goal}
 */
proto.unifiedplatform.gamification.AllGoals.prototype.addGoal = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.goal.Goal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.AllGoals} returns this
 */
proto.unifiedplatform.gamification.AllGoals.prototype.clearGoalList = function() {
  return this.setGoalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.AddNewGoalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.AddNewGoalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdgoalid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.AddNewGoalResponse}
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.AddNewGoalResponse;
  return proto.unifiedplatform.gamification.AddNewGoalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.AddNewGoalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.AddNewGoalResponse}
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedgoalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.AddNewGoalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.AddNewGoalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedgoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 createdGoalID = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.prototype.getCreatedgoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalResponse} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalResponse.prototype.setCreatedgoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllGoalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllGoalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalList: jspb.Message.toObjectList(msg.getGoalList(),
    proto.unifiedplatform.gamification.ResponseGoal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalsResponse}
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllGoalsResponse;
  return proto.unifiedplatform.gamification.GetAllGoalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalsResponse}
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ResponseGoal;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponseGoal.deserializeBinaryFromReader);
      msg.addGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllGoalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ResponseGoal.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseGoal goal = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ResponseGoal>}
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.prototype.getGoalList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ResponseGoal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ResponseGoal, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ResponseGoal>} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalsResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalsResponse.prototype.setGoalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ResponseGoal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ResponseGoal}
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.prototype.addGoal = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ResponseGoal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalsResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalsResponse.prototype.clearGoalList = function() {
  return this.setGoalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetGoalByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetGoalByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetGoalByIdRequest}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetGoalByIdRequest;
  return proto.unifiedplatform.gamification.GetGoalByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetGoalByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetGoalByIdRequest}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetGoalByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetGoalByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalByIdRequest} returns this
 */
proto.unifiedplatform.gamification.GetGoalByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage;
  return proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage} returns this
 */
proto.unifiedplatform.gamification.GetGoalByIdRequestWithSelectedLanguage.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DeleteGoalByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DeleteGoalByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdRequest}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DeleteGoalByIdRequest;
  return proto.unifiedplatform.gamification.DeleteGoalByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdRequest}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DeleteGoalByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdRequest} returns this
 */
proto.unifiedplatform.gamification.DeleteGoalByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DeleteGoalByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    goal: (f = msg.getGoal()) && Goal_pb.Goal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DeleteGoalByIdResponse;
  return proto.unifiedplatform.gamification.DeleteGoalByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new Goal_pb.Goal;
      reader.readMessage(value,Goal_pb.Goal.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DeleteGoalByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Goal_pb.Goal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse} returns this
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional goal.Goal goal = 2;
 * @return {?proto.unifiedplatform.gamification.goal.Goal}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.getGoal = function() {
  return /** @type{?proto.unifiedplatform.gamification.goal.Goal} */ (
    jspb.Message.getWrapperField(this, Goal_pb.Goal, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.goal.Goal|undefined} value
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse} returns this
*/
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalByIdResponse} returns this
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.DeleteGoalByIdResponse.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.UpdateGoalByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goal: (f = msg.getGoal()) && Goal_pb.Goal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest}
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.UpdateGoalByIdRequest;
  return proto.unifiedplatform.gamification.UpdateGoalByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest}
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new Goal_pb.Goal;
      reader.readMessage(value,Goal_pb.Goal.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.UpdateGoalByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Goal_pb.Goal.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest} returns this
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional goal.Goal goal = 2;
 * @return {?proto.unifiedplatform.gamification.goal.Goal}
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.getGoal = function() {
  return /** @type{?proto.unifiedplatform.gamification.goal.Goal} */ (
    jspb.Message.getWrapperField(this, Goal_pb.Goal, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.goal.Goal|undefined} value
 * @return {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest} returns this
*/
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.UpdateGoalByIdRequest} returns this
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.UpdateGoalByIdRequest.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetBadgeByNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetBadgeByNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    badge: (f = msg.getBadge()) && Badge_pb.Badge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetBadgeByNameResponse}
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetBadgeByNameResponse;
  return proto.unifiedplatform.gamification.GetBadgeByNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetBadgeByNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetBadgeByNameResponse}
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Badge_pb.Badge;
      reader.readMessage(value,Badge_pb.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetBadgeByNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetBadgeByNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Badge_pb.Badge.serializeBinaryToWriter
    );
  }
};


/**
 * optional badge.Badge badge = 1;
 * @return {?proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.Badge} */ (
    jspb.Message.getWrapperField(this, Badge_pb.Badge, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.Badge|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetBadgeByNameResponse} returns this
*/
proto.unifiedplatform.gamification.GetBadgeByNameResponse.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetBadgeByNameResponse} returns this
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetBadgeByNameResponse.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetBadgeByNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetBadgeByNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgename: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetBadgeByNameRequest}
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetBadgeByNameRequest;
  return proto.unifiedplatform.gamification.GetBadgeByNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetBadgeByNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetBadgeByNameRequest}
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetBadgeByNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetBadgeByNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string badgeName = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.prototype.getBadgename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.GetBadgeByNameRequest} returns this
 */
proto.unifiedplatform.gamification.GetBadgeByNameRequest.prototype.setBadgename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    badge: (f = msg.getBadge()) && Badge_pb.BadgeDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse;
  return proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Badge_pb.BadgeDetails;
      reader.readMessage(value,Badge_pb.BadgeDetails.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Badge_pb.BadgeDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional badge.BadgeDetails badge = 1;
 * @return {?proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.BadgeDetails} */ (
    jspb.Message.getWrapperField(this, Badge_pb.BadgeDetails, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.BadgeDetails|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse} returns this
*/
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse} returns this
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameResponse.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponseGoalDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponseGoalDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    goal: (f = msg.getGoal()) && Goal_pb.GoalDetails.toObject(includeInstance, f),
    badge: (f = msg.getBadge()) && Badge_pb.BadgeDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalDetails}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponseGoalDetails;
  return proto.unifiedplatform.gamification.ResponseGoalDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponseGoalDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalDetails}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Goal_pb.GoalDetails;
      reader.readMessage(value,Goal_pb.GoalDetails.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    case 2:
      var value = new Badge_pb.BadgeDetails;
      reader.readMessage(value,Badge_pb.BadgeDetails.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponseGoalDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponseGoalDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Goal_pb.GoalDetails.serializeBinaryToWriter
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Badge_pb.BadgeDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional goal.GoalDetails goal = 1;
 * @return {?proto.unifiedplatform.gamification.goal.GoalDetails}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.getGoal = function() {
  return /** @type{?proto.unifiedplatform.gamification.goal.GoalDetails} */ (
    jspb.Message.getWrapperField(this, Goal_pb.GoalDetails, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.goal.GoalDetails|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalDetails} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalDetails} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional badge.BadgeDetails badge = 2;
 * @return {?proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.BadgeDetails} */ (
    jspb.Message.getWrapperField(this, Badge_pb.BadgeDetails, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.BadgeDetails|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalDetails} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalDetails} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalDetails.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllGoalDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalList: jspb.Message.toObjectList(msg.getGoalList(),
    proto.unifiedplatform.gamification.ResponseGoal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalDetailsResponse}
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllGoalDetailsResponse;
  return proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalDetailsResponse}
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ResponseGoal;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponseGoal.deserializeBinaryFromReader);
      msg.addGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ResponseGoal.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseGoal goal = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ResponseGoal>}
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.prototype.getGoalList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ResponseGoal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ResponseGoal, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ResponseGoal>} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalDetailsResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.prototype.setGoalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ResponseGoal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ResponseGoal}
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.prototype.addGoal = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ResponseGoal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalDetailsResponse.prototype.clearGoalList = function() {
  return this.setGoalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    goal: (f = msg.getGoal()) && Goal_pb.GoalDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse}
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse;
  return proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse}
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new Goal_pb.GoalDetails;
      reader.readMessage(value,Goal_pb.GoalDetails.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Goal_pb.GoalDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse} returns this
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional goal.GoalDetails goal = 2;
 * @return {?proto.unifiedplatform.gamification.goal.GoalDetails}
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.getGoal = function() {
  return /** @type{?proto.unifiedplatform.gamification.goal.GoalDetails} */ (
    jspb.Message.getWrapperField(this, Goal_pb.GoalDetails, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.goal.GoalDetails|undefined} value
 * @return {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse} returns this
*/
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse} returns this
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.DeleteGoalDetailsByIdResponse.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest;
  return proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgename(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string badgeName = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.prototype.getBadgename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest} returns this
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.prototype.setBadgename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest} returns this
 */
proto.unifiedplatform.gamification.GetBadgeDetailsByNameRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DeleteFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DeleteFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DeleteFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    imgpath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DeleteFileRequest}
 */
proto.unifiedplatform.gamification.DeleteFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DeleteFileRequest;
  return proto.unifiedplatform.gamification.DeleteFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DeleteFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DeleteFileRequest}
 */
proto.unifiedplatform.gamification.DeleteFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgpath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DeleteFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DeleteFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DeleteFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImgpath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string imgPath = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.DeleteFileRequest.prototype.getImgpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DeleteFileRequest} returns this
 */
proto.unifiedplatform.gamification.DeleteFileRequest.prototype.setImgpath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isrequestcomingfromv2: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails}
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails;
  return proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails}
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrequestcomingfromv2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsrequestcomingfromv2();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails} returns this
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool isRequestComingFromV2 = 2;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.prototype.getIsrequestcomingfromv2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails} returns this
 */
proto.unifiedplatform.gamification.RequestForGetAllBadgeDetails.prototype.setIsrequestcomingfromv2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestForGetAllGoalDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestForGetAllGoalDetails}
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestForGetAllGoalDetails;
  return proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestForGetAllGoalDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestForGetAllGoalDetails}
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestForGetAllGoalDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.RequestForGetAllGoalDetails} returns this
 */
proto.unifiedplatform.gamification.RequestForGetAllGoalDetails.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestPageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestPageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestPageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestPageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.RequestPageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestPageable;
  return proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestPageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestPageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestPageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 pageSize = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestPageable.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestPageable} returns this
 */
proto.unifiedplatform.gamification.RequestPageable.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pageNumber = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestPageable.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestPageable} returns this
 */
proto.unifiedplatform.gamification.RequestPageable.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponsePageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponsePageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponsePageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.ResponsePageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponsePageable;
  return proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponsePageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponsePageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 totalCount = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponsePageable} returns this
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponsePageable} returns this
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponsePageable} returns this
 */
proto.unifiedplatform.gamification.ResponsePageable.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.repeatedFields_ = [4,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    durationandrequiredtimeList: jspb.Message.toObjectList(msg.getDurationandrequiredtimeList(),
    proto.unifiedplatform.gamification.DurationAndRequiredTime.toObject, includeInstance),
    requireddays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 6, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    badgecount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    hours: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    requiredsessionidsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    requiredtimes: jspb.Message.getFieldWithDefault(msg, 13, 0),
    publishgoal: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse;
  return proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.gamification.DurationAndRequiredTime;
      reader.readMessage(value,proto.unifiedplatform.gamification.DurationAndRequiredTime.deserializeBinaryFromReader);
      msg.addDurationandrequiredtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequireddays(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHours(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequiredsessionids(values[i]);
      }
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequiredtimes(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublishgoal(value);
      break;
    case 15:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDurationandrequiredtimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.gamification.DurationAndRequiredTime.serializeBinaryToWriter
    );
  }
  f = message.getRequireddays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getHours();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRequiredsessionidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getRequiredtimes();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getPublishgoal();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DurationAndRequiredTime durationAndRequiredTime = 4;
 * @return {!Array<!proto.unifiedplatform.gamification.DurationAndRequiredTime>}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getDurationandrequiredtimeList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.DurationAndRequiredTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.DurationAndRequiredTime, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.DurationAndRequiredTime>} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
*/
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setDurationandrequiredtimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.DurationAndRequiredTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.DurationAndRequiredTime}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.addDurationandrequiredtime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.gamification.DurationAndRequiredTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.clearDurationandrequiredtimeList = function() {
  return this.setDurationandrequiredtimeList([]);
};


/**
 * optional uint32 requiredDays = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getRequireddays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setRequireddays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 6;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 associatedBadgeID = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 badgeCount = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 duration = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double hours = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getHours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setHours = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * repeated int64 requiredSessionIds = 12;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getRequiredsessionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setRequiredsessionidsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.addRequiredsessionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.clearRequiredsessionidsList = function() {
  return this.setRequiredsessionidsList([]);
};


/**
 * optional int64 requiredTimes = 13;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getRequiredtimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setRequiredtimes = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool publishGoal = 14;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getPublishgoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setPublishgoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 15;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.repeatedFields_ = [12,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GoalInfoV2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GoalInfoV2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    requiredtimes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    requireddays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 6, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    badgecount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    hours: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    requiredsessionidsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    publishgoal: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 14, 0),
    goaltitle: jspb.Message.getFieldWithDefault(msg, 15, ""),
    goaldescription: jspb.Message.getFieldWithDefault(msg, 16, ""),
    durationandrequiredtimeList: jspb.Message.toObjectList(msg.getDurationandrequiredtimeList(),
    proto.unifiedplatform.gamification.DurationAndRequiredTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GoalInfoV2Response;
  return proto.unifiedplatform.gamification.GoalInfoV2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GoalInfoV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequiredtimes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequireddays(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHours(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequiredsessionids(values[i]);
      }
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublishgoal(value);
      break;
    case 14:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaltitle(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaldescription(value);
      break;
    case 17:
      var value = new proto.unifiedplatform.gamification.DurationAndRequiredTime;
      reader.readMessage(value,proto.unifiedplatform.gamification.DurationAndRequiredTime.deserializeBinaryFromReader);
      msg.addDurationandrequiredtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GoalInfoV2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GoalInfoV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getRequiredtimes();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRequireddays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getHours();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRequiredsessionidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getPublishgoal();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getGoaltitle();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGoaldescription();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDurationandrequiredtimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.unifiedplatform.gamification.DurationAndRequiredTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
*/
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
*/
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 requiredTimes = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getRequiredtimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setRequiredtimes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 requiredDays = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getRequireddays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setRequireddays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 6;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 associatedBadgeID = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 badgeCount = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 duration = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double hours = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getHours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setHours = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * repeated int64 requiredSessionIds = 12;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getRequiredsessionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setRequiredsessionidsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.addRequiredsessionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.clearRequiredsessionidsList = function() {
  return this.setRequiredsessionidsList([]);
};


/**
 * optional bool publishGoal = 13;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getPublishgoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setPublishgoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 14;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string goalTitle = 15;
 * @return {string}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getGoaltitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setGoaltitle = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string goalDescription = 16;
 * @return {string}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getGoaldescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setGoaldescription = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated DurationAndRequiredTime durationAndRequiredTime = 17;
 * @return {!Array<!proto.unifiedplatform.gamification.DurationAndRequiredTime>}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.getDurationandrequiredtimeList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.DurationAndRequiredTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.DurationAndRequiredTime, 17));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.DurationAndRequiredTime>} value
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
*/
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.setDurationandrequiredtimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.DurationAndRequiredTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.DurationAndRequiredTime}
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.addDurationandrequiredtime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.unifiedplatform.gamification.DurationAndRequiredTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GoalInfoV2Response.prototype.clearDurationandrequiredtimeList = function() {
  return this.setDurationandrequiredtimeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    requiredtimes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    requireddays: jspb.Message.getFieldWithDefault(msg, 4, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 5, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    badgecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 9, 0),
    requiredidsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    publishgoal: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 13, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 14, 0),
    goaltitle: jspb.Message.getFieldWithDefault(msg, 15, ""),
    goaldescription: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.AddNewGoalInfoV2Request;
  return proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequiredtimes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequireddays(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequiredids(values[i]);
      }
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublishgoal(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 14:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaltitle(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaldescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getRequiredtimes();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getRequireddays();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getRequiredidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getPublishgoal();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getGoaltitle();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGoaldescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
*/
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
*/
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 requiredTimes = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getRequiredtimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setRequiredtimes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 requiredDays = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getRequireddays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setRequireddays = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 5;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 associatedBadgeID = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 badgeCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 duration = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated int64 requiredIDs = 10;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getRequiredidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setRequiredidsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.addRequiredids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.clearRequiredidsList = function() {
  return this.setRequiredidsList([]);
};


/**
 * optional bool publishGoal = 11;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getPublishgoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setPublishgoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 12;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int64 userId = 13;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 14;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string goalTitle = 15;
 * @return {string}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getGoaltitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setGoaltitle = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string goalDescription = 17;
 * @return {string}
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.getGoaldescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.prototype.setGoaldescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.repeatedFields_ = [11,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.updateGoalInfoV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    requiredtimes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    requireddays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 6, 0),
    associatedbadgeid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    badgecount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    requiredidsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    publishgoal: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    badgewithcountList: jspb.Message.toObjectList(msg.getBadgewithcountList(),
    Badge_pb.BadgeDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.updateGoalInfoV2Request;
  return proto.unifiedplatform.gamification.updateGoalInfoV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequiredtimes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequireddays(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedbadgeid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequiredids(values[i]);
      }
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublishgoal(value);
      break;
    case 14:
      var value = new Badge_pb.BadgeDetails;
      reader.readMessage(value,Badge_pb.BadgeDetails.deserializeBinaryFromReader);
      msg.addBadgewithcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.updateGoalInfoV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getRequiredtimes();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRequireddays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAssociatedbadgeid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getRequiredidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPublishgoal();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getBadgewithcountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      Badge_pb.BadgeDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
*/
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
*/
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 requiredTimes = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getRequiredtimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setRequiredtimes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 requiredDays = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getRequireddays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setRequireddays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 6;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 associatedBadgeID = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getAssociatedbadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setAssociatedbadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 badgeCount = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 duration = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated int64 requiredIDs = 11;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getRequiredidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setRequiredidsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.addRequiredids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.clearRequiredidsList = function() {
  return this.setRequiredidsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 12;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool publishGoal = 13;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getPublishgoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setPublishgoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * repeated badge.BadgeDetails badgeWithCount = 14;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.getBadgewithcountList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, Badge_pb.BadgeDetails, 14));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} value
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
*/
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.setBadgewithcountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.addBadgewithcount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.unifiedplatform.gamification.badge.BadgeDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.updateGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.updateGoalInfoV2Request.prototype.clearBadgewithcountList = function() {
  return this.setBadgewithcountList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetGoalInfoV2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    goaltypes: (f = msg.getGoaltypes()) && proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.toObject(includeInstance, f),
    badge: (f = msg.getBadge()) && Badge_pb.Badge.toObject(includeInstance, f),
    useracceptedgoalscount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isregistered: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    badgewithcountList: jspb.Message.toObjectList(msg.getBadgewithcountList(),
    Badge_pb.Badge.toObject, includeInstance),
    misseduserscount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    accepteduserscount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    completeduserscount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totaluserscount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    percentage: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetGoalInfoV2Response;
  return proto.unifiedplatform.gamification.GetGoalInfoV2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.deserializeBinaryFromReader);
      msg.setGoaltypes(value);
      break;
    case 2:
      var value = new Badge_pb.Badge;
      reader.readMessage(value,Badge_pb.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUseracceptedgoalscount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsregistered(value);
      break;
    case 5:
      var value = new Badge_pb.Badge;
      reader.readMessage(value,Badge_pb.Badge.deserializeBinaryFromReader);
      msg.addBadgewithcount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMisseduserscount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccepteduserscount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompleteduserscount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotaluserscount(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetGoalInfoV2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoaltypes();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Badge_pb.Badge.serializeBinaryToWriter
    );
  }
  f = message.getUseracceptedgoalscount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsregistered();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBadgewithcountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Badge_pb.Badge.serializeBinaryToWriter
    );
  }
  f = message.getMisseduserscount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAccepteduserscount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCompleteduserscount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTotaluserscount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional GoalInfoV2LocaleResponse goalTypes = 1;
 * @return {?proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getGoaltypes = function() {
  return /** @type{?proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
*/
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setGoaltypes = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.clearGoaltypes = function() {
  return this.setGoaltypes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.hasGoaltypes = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional badge.Badge badge = 2;
 * @return {?proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.Badge} */ (
    jspb.Message.getWrapperField(this, Badge_pb.Badge, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.Badge|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
*/
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 userAcceptedGoalsCount = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getUseracceptedgoalscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setUseracceptedgoalscount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool isRegistered = 4;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getIsregistered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setIsregistered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated badge.Badge badgeWithCount = 5;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.Badge>}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getBadgewithcountList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.Badge>} */ (
    jspb.Message.getRepeatedWrapperField(this, Badge_pb.Badge, 5));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.Badge>} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
*/
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setBadgewithcountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.Badge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.Badge}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.addBadgewithcount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.unifiedplatform.gamification.badge.Badge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.clearBadgewithcountList = function() {
  return this.setBadgewithcountList([]);
};


/**
 * optional int64 missedUsersCount = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getMisseduserscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setMisseduserscount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 acceptedUsersCount = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getAccepteduserscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setAccepteduserscount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 completedUsersCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getCompleteduserscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setCompleteduserscount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 totalUsersCount = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getTotaluserscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setTotaluserscount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool completed = 10;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 percentage = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2Response.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    goaltypesList: jspb.Message.toObjectList(msg.getGoaltypesList(),
    proto.unifiedplatform.gamification.GetGoalInfoV2Response.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2;
  return proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.GetGoalInfoV2Response;
      reader.readMessage(value,proto.unifiedplatform.gamification.GetGoalInfoV2Response.deserializeBinaryFromReader);
      msg.addGoaltypes(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoaltypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.GetGoalInfoV2Response.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetGoalInfoV2Response goalTypes = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.GetGoalInfoV2Response>}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.getGoaltypesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.GetGoalInfoV2Response>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.GetGoalInfoV2Response, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.GetGoalInfoV2Response>} value
 * @return {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.setGoaltypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.GetGoalInfoV2Response=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2Response}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.addGoaltypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.GetGoalInfoV2Response, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.clearGoaltypesList = function() {
  return this.setGoaltypesList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseForGetAllGoalInfoV2.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    goaltypes: (f = msg.getGoaltypes()) && proto.unifiedplatform.gamification.GoalInfoV2Response.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse}
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse;
  return proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse}
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.GoalInfoV2Response;
      reader.readMessage(value,proto.unifiedplatform.gamification.GoalInfoV2Response.deserializeBinaryFromReader);
      msg.setGoaltypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGoaltypes();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.GoalInfoV2Response.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse} returns this
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GoalInfoV2Response goalTypes = 2;
 * @return {?proto.unifiedplatform.gamification.GoalInfoV2Response}
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.getGoaltypes = function() {
  return /** @type{?proto.unifiedplatform.gamification.GoalInfoV2Response} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.GoalInfoV2Response, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.GoalInfoV2Response|undefined} value
 * @return {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse} returns this
*/
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.setGoaltypes = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse} returns this
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.clearGoaltypes = function() {
  return this.setGoaltypes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.DeleteGoalInfoV2ByIdResponse.prototype.hasGoaltypes = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    localetype: (f = msg.getLocaletype()) && proto.unifiedplatform.gamification.LocaleType.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest;
  return proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.LocaleType;
      reader.readMessage(value,proto.unifiedplatform.gamification.LocaleType.deserializeBinaryFromReader);
      msg.setLocaletype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLocaletype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.LocaleType.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LocaleType localeType = 2;
 * @return {?proto.unifiedplatform.gamification.LocaleType}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.getLocaletype = function() {
  return /** @type{?proto.unifiedplatform.gamification.LocaleType} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.LocaleType, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.LocaleType|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest} returns this
*/
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.setLocaletype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.clearLocaletype = function() {
  return this.setLocaletype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.hasLocaletype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 userId = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest} returns this
 */
proto.unifiedplatform.gamification.GetGoalInfoV2ByIdRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ID}
 */
proto.unifiedplatform.gamification.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ID;
  return proto.unifiedplatform.gamification.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ID}
 */
proto.unifiedplatform.gamification.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 Id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ID.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ID} returns this
 */
proto.unifiedplatform.gamification.ID.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 UserId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.ID.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ID} returns this
 */
proto.unifiedplatform.gamification.ID.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.LocaleType.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.LocaleType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.LocaleType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.LocaleType.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedscreen: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.LocaleType}
 */
proto.unifiedplatform.gamification.LocaleType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.LocaleType;
  return proto.unifiedplatform.gamification.LocaleType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.LocaleType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.LocaleType}
 */
proto.unifiedplatform.gamification.LocaleType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (reader.readEnum());
      msg.setSelectedscreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.LocaleType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.LocaleType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.LocaleType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.LocaleType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSelectedscreen();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.LocaleType.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.LocaleType} returns this
 */
proto.unifiedplatform.gamification.LocaleType.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Screens selectedScreen = 2;
 * @return {!proto.unifiedplatform.heartintune.common.Screens}
 */
proto.unifiedplatform.gamification.LocaleType.prototype.getSelectedscreen = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Screens} value
 * @return {!proto.unifiedplatform.gamification.LocaleType} returns this
 */
proto.unifiedplatform.gamification.LocaleType.prototype.setSelectedscreen = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    locale: (f = msg.getLocale()) && proto.unifiedplatform.gamification.LocaleType.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    min: jspb.Message.getFieldWithDefault(msg, 4, 0),
    max: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ishours: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isdays: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    listofgoalidsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    hfnprofile: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    durationinminutes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    iscustomgoals: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    newversion: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllGoalInfoV2Request;
  return proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.LocaleType;
      reader.readMessage(value,proto.unifiedplatform.gamification.LocaleType.deserializeBinaryFromReader);
      msg.setLocale(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMax(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIshours(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdays(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addListofgoalids(values[i]);
      }
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHfnprofile(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationinminutes(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscustomgoals(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocale();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.LocaleType.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIshours();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsdays();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getListofgoalidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getHfnprofile();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDurationinminutes();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getIscustomgoals();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getNewversion();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional LocaleType locale = 1;
 * @return {?proto.unifiedplatform.gamification.LocaleType}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getLocale = function() {
  return /** @type{?proto.unifiedplatform.gamification.LocaleType} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.LocaleType, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.LocaleType|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setLocale = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.clearLocale = function() {
  return this.setLocale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.hasLocale = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestPageable pageable = 2;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 userId = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 min = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 max = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool isHours = 6;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getIshours = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setIshours = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool isDays = 7;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getIsdays = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setIsdays = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated int64 listOfGoalIds = 8;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getListofgoalidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setListofgoalidsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.addListofgoalids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.clearListofgoalidsList = function() {
  return this.setListofgoalidsList([]);
};


/**
 * optional bool hfnProfile = 9;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getHfnprofile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setHfnprofile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 durationInMinutes = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getDurationinminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setDurationinminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool isCustomGoals = 11;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getIscustomgoals = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setIscustomgoals = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool newVersion = 12;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.getNewversion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2Request} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2Request.prototype.setNewversion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    goaltypesList: jspb.Message.toObjectList(msg.getGoaltypesList(),
    proto.unifiedplatform.gamification.ResponseGoalInfoV2.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2;
  return proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ResponseGoalInfoV2;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponseGoalInfoV2.deserializeBinaryFromReader);
      msg.addGoaltypes(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoaltypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ResponseGoalInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseGoalInfoV2 goalTypes = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.getGoaltypesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ResponseGoalInfoV2, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.setGoaltypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ResponseGoalInfoV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.addGoaltypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ResponseGoalInfoV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.clearGoaltypesList = function() {
  return this.setGoaltypesList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalInfoV2ResponseForAdminV2.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeList: jspb.Message.toObjectList(msg.getBadgeList(),
    Badge_pb.BadgeDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse;
  return proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Badge_pb.BadgeDetails;
      reader.readMessage(value,Badge_pb.BadgeDetails.deserializeBinaryFromReader);
      msg.addBadge(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Badge_pb.BadgeDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated badge.BadgeDetails badge = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.getBadgeList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, Badge_pb.BadgeDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} value
 * @return {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.setBadgeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.addBadge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.badge.BadgeDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.clearBadgeList = function() {
  return this.setBadgeList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllBadgeDetailsForAdminResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 4, 0),
    goalcategoryid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest;
  return proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalcategoryid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGoalcategoryid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 GoalId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 UserID = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 4;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 goalCategoryId = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.getGoalcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest} returns this
 */
proto.unifiedplatform.gamification.AddGoalCategoryDetailsRequest.prototype.setGoalcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.repeatedFields_ = [2,6,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goaltypeidList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    earnedgems: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timescompleted: jspb.Message.getFieldWithDefault(msg, 5, 0),
    completedsessionidsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    dayscompleted: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lastcompleteddate: (f = msg.getLastcompleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    entryid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    entryidsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    goalstatus: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GoalCategoryDetailsResponse;
  return proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGoaltypeid(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEarnedgems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimescompleted(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCompletedsessionids(values[i]);
      }
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayscompleted(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastcompleteddate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntryid(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEntryids(values[i]);
      }
      break;
    case 11:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoaltypeidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEarnedgems();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTimescompleted();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCompletedsessionidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getDayscompleted();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLastcompleteddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEntryid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getEntryidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 GoalTypeId = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getGoaltypeidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setGoaltypeidList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.addGoaltypeid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.clearGoaltypeidList = function() {
  return this.setGoaltypeidList([]);
};


/**
 * optional int64 userId = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 earnedGems = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getEarnedgems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setEarnedgems = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 timesCompleted = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getTimescompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setTimescompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated int64 completedSessionIds = 6;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getCompletedsessionidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setCompletedsessionidsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.addCompletedsessionids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.clearCompletedsessionidsList = function() {
  return this.setCompletedsessionidsList([]);
};


/**
 * optional int32 daysCompleted = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getDayscompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setDayscompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp lastCompletedDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getLastcompleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
*/
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setLastcompleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.clearLastcompleteddate = function() {
  return this.setLastcompleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.hasLastcompleteddate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 entryId = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getEntryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setEntryid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated int64 entryIds = 10;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getEntryidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setEntryidsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.addEntryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.clearEntryidsList = function() {
  return this.setEntryidsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 11;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.gamification.GoalCategoryDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.GoalCategoryDetailsResponse.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalcategoryid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    goalid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory;
  return proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalcategoryid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalcategoryid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 goalCategoryId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.getGoalcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} returns this
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.setGoalcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} returns this
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 3;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} returns this
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 4;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} returns this
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 goalId = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory} returns this
 */
proto.unifiedplatform.gamification.ResponseForUpdateGoalCategory.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DonationResponseWithLocale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DonationResponseWithLocale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amountsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DonationResponseWithLocale;
  return proto.unifiedplatform.gamification.DonationResponseWithLocale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DonationResponseWithLocale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAmounts(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DonationResponseWithLocale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DonationResponseWithLocale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmountsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated float amounts = 4;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.getAmountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.setAmountsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.addAmounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.clearAmountsList = function() {
  return this.setAmountsList([]);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string selectedLanguage = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.getSelectedlanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationResponseWithLocale} returns this
 */
proto.unifiedplatform.gamification.DonationResponseWithLocale.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.DonationCreateRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DonationCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DonationCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DonationCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    amountsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DonationCreateRequest;
  return proto.unifiedplatform.gamification.DonationCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DonationCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAmounts(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DonationCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DonationCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DonationCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAmountsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
*/
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
*/
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated float amounts = 4;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.getAmountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.setAmountsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.addAmounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.clearAmountsList = function() {
  return this.setAmountsList([]);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string selectedLanguage = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.getSelectedlanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationCreateRequest} returns this
 */
proto.unifiedplatform.gamification.DonationCreateRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.DonationDetails.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DonationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DonationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DonationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    amountsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DonationDetails}
 */
proto.unifiedplatform.gamification.DonationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DonationDetails;
  return proto.unifiedplatform.gamification.DonationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DonationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DonationDetails}
 */
proto.unifiedplatform.gamification.DonationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAmounts(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DonationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DonationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DonationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAmountsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
*/
proto.unifiedplatform.gamification.DonationDetails.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
*/
proto.unifiedplatform.gamification.DonationDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated float amounts = 4;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.getAmountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.setAmountsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.addAmounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.clearAmountsList = function() {
  return this.setAmountsList([]);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string selectedLanguage = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.getSelectedlanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DonationDetails} returns this
 */
proto.unifiedplatform.gamification.DonationDetails.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfDonationDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    donationdetailsList: jspb.Message.toObjectList(msg.getDonationdetailsList(),
    proto.unifiedplatform.gamification.DonationDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfDonationDetailsResponse}
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfDonationDetailsResponse;
  return proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfDonationDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfDonationDetailsResponse}
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.DonationDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.DonationDetails.deserializeBinaryFromReader);
      msg.addDonationdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfDonationDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonationdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.DonationDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DonationDetails donationDetails = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.DonationDetails>}
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.prototype.getDonationdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.DonationDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.DonationDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.DonationDetails>} value
 * @return {!proto.unifiedplatform.gamification.ListOfDonationDetailsResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.prototype.setDonationdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.DonationDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.DonationDetails}
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.prototype.addDonationdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.DonationDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfDonationDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfDonationDetailsResponse.prototype.clearDonationdetailsList = function() {
  return this.setDonationdetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.AddQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.AddQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    author: (f = msg.getAuthor()) && common_common_pb.Locale.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && common_common_pb.Locale.toObject(includeInstance, f),
    tagone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagtwo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagthree: jspb.Message.getFieldWithDefault(msg, 5, ""),
    screen: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.AddQuoteRequest;
  return proto.unifiedplatform.gamification.AddQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.AddQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagtwo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagthree(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.AddQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.AddQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getTagone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagtwo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagthree();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale author = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.getAuthor = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
*/
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale quote = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.getQuote = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
*/
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string tagOne = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.getTagone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.setTagone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tagTwo = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.getTagtwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.setTagtwo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tagThree = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.getTagthree = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.setTagthree = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string screen = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.AddQuoteRequest} returns this
 */
proto.unifiedplatform.gamification.AddQuoteRequest.prototype.setScreen = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfQuoteDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfQuoteDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    quotedetailsList: jspb.Message.toObjectList(msg.getQuotedetailsList(),
    proto.unifiedplatform.gamification.QuoteDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfQuoteDetails}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfQuoteDetails;
  return proto.unifiedplatform.gamification.ListOfQuoteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfQuoteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfQuoteDetails}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.QuoteDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.QuoteDetails.deserializeBinaryFromReader);
      msg.addQuotedetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfQuoteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfQuoteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuotedetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.QuoteDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuoteDetails quoteDetails = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.QuoteDetails>}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.getQuotedetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.QuoteDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.QuoteDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.QuoteDetails>} value
 * @return {!proto.unifiedplatform.gamification.ListOfQuoteDetails} returns this
*/
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.setQuotedetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.QuoteDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.QuoteDetails}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.addQuotedetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.QuoteDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfQuoteDetails} returns this
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.clearQuotedetailsList = function() {
  return this.setQuotedetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.ListOfQuoteDetails} returns this
*/
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ListOfQuoteDetails} returns this
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ListOfQuoteDetails.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.QuoteDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.QuoteDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.QuoteDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    author: (f = msg.getAuthor()) && common_common_pb.Locale.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && common_common_pb.Locale.toObject(includeInstance, f),
    tagone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagtwo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagthree: jspb.Message.getFieldWithDefault(msg, 6, ""),
    screen: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.QuoteDetails}
 */
proto.unifiedplatform.gamification.QuoteDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.QuoteDetails;
  return proto.unifiedplatform.gamification.QuoteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.QuoteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.QuoteDetails}
 */
proto.unifiedplatform.gamification.QuoteDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagtwo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagthree(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.QuoteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.QuoteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.QuoteDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getTagone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagtwo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagthree();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale author = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.getAuthor = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
*/
proto.unifiedplatform.gamification.QuoteDetails.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale quote = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.getQuote = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
*/
proto.unifiedplatform.gamification.QuoteDetails.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string tagOne = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.getTagone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.setTagone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tagTwo = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.getTagtwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.setTagtwo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tagThree = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.getTagthree = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.setTagthree = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string screen = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetails} returns this
 */
proto.unifiedplatform.gamification.QuoteDetails.prototype.setScreen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.repeatedFields_ = [18,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    author: jspb.Message.getFieldWithDefault(msg, 4, ""),
    image: jspb.Message.getFieldWithDefault(msg, 5, ""),
    content: jspb.Message.getFieldWithDefault(msg, 6, ""),
    readingtime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tag: jspb.Message.getFieldWithDefault(msg, 10, ""),
    webviewurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    videourl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    featuredimage: jspb.Message.getFieldWithDefault(msg, 14, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 15, ""),
    videocategory: jspb.Message.getFieldWithDefault(msg, 16, 0),
    featuredcontent: jspb.Message.getFieldWithDefault(msg, 17, 0),
    filteridsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    discover: jspb.Message.getFieldWithDefault(msg, 19, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.gamification.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    documentcategory: jspb.Message.getFieldWithDefault(msg, 22, 0),
    pdfurl: jspb.Message.getFieldWithDefault(msg, 23, ""),
    attribute: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentLocaleResponse;
  return proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReadingtime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebviewurl(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideourl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeaturedimage(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 16:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (reader.readEnum());
      msg.setVideocategory(value);
      break;
    case 17:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (reader.readEnum());
      msg.setFeaturedcontent(value);
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterids(values[i]);
      }
      break;
    case 19:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (reader.readEnum());
      msg.setDiscover(value);
      break;
    case 20:
      var value = new proto.unifiedplatform.gamification.Tag;
      reader.readMessage(value,proto.unifiedplatform.gamification.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 22:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (reader.readEnum());
      msg.setDocumentcategory(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPdfurl(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReadingtime();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWebviewurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getVideourl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFeaturedimage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getVideocategory();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getFeaturedcontent();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getFilteridsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      18,
      f
    );
  }
  f = message.getDiscover();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.unifiedplatform.gamification.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      21,
      f
    );
  }
  f = message.getDocumentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getPdfurl();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAttribute();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subtitle = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string author = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string content = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 readingTime = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getReadingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setReadingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
*/
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
*/
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string tag = 10;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string webViewUrl = 11;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getWebviewurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setWebviewurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 12;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string videoUrl = 13;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getVideourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setVideourl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string featuredImage = 14;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getFeaturedimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setFeaturedimage = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string thumbnail = 15;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional unifiedplatform.heartintune.common.VideoCategory videoCategory = 16;
 * @return {!proto.unifiedplatform.heartintune.common.VideoCategory}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getVideocategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.VideoCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setVideocategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional unifiedplatform.heartintune.common.FeaturedContent featuredContent = 17;
 * @return {!proto.unifiedplatform.heartintune.common.FeaturedContent}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getFeaturedcontent = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.FeaturedContent} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setFeaturedcontent = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated int64 filterIds = 18;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getFilteridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setFilteridsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.addFilterids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.clearFilteridsList = function() {
  return this.setFilteridsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Discover discover = 19;
 * @return {!proto.unifiedplatform.heartintune.common.Discover}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getDiscover = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Discover} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setDiscover = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * repeated Tag tags = 20;
 * @return {!Array<!proto.unifiedplatform.gamification.Tag>}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.Tag, 20));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.Tag>} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
*/
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.Tag}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.unifiedplatform.gamification.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 21;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.DocumentCategory documentCategory = 22;
 * @return {!proto.unifiedplatform.heartintune.common.DocumentCategory}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getDocumentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DocumentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setDocumentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string pdfUrl = 23;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getPdfurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setPdfurl = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string attribute = 24;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.getAttribute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentLocaleResponse.prototype.setAttribute = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ContentRequest.repeatedFields_ = [16,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    author: (f = msg.getAuthor()) && common_common_pb.Locale.toObject(includeInstance, f),
    content: (f = msg.getContent()) && common_common_pb.Locale.toObject(includeInstance, f),
    image: jspb.Message.getFieldWithDefault(msg, 5, ""),
    readingtime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 7, ""),
    iswebview: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    webviewurl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 10, 0),
    videourl: (f = msg.getVideourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    featureimage: jspb.Message.getFieldWithDefault(msg, 12, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 13, ""),
    videocategory: jspb.Message.getFieldWithDefault(msg, 14, 0),
    featuredcontent: jspb.Message.getFieldWithDefault(msg, 15, 0),
    filteridsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    discover: jspb.Message.getFieldWithDefault(msg, 17, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.gamification.Tag.toObject, includeInstance),
    documentcategory: jspb.Message.getFieldWithDefault(msg, 19, 0),
    pdfurl: jspb.Message.getFieldWithDefault(msg, 20, ""),
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentRequest}
 */
proto.unifiedplatform.gamification.ContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentRequest;
  return proto.unifiedplatform.gamification.ContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentRequest}
 */
proto.unifiedplatform.gamification.ContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReadingtime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIswebview(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebviewurl(value);
      break;
    case 10:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 11:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setVideourl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatureimage(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 14:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (reader.readEnum());
      msg.setVideocategory(value);
      break;
    case 15:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (reader.readEnum());
      msg.setFeaturedcontent(value);
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterids(values[i]);
      }
      break;
    case 17:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (reader.readEnum());
      msg.setDiscover(value);
      break;
    case 18:
      var value = new proto.unifiedplatform.gamification.Tag;
      reader.readMessage(value,proto.unifiedplatform.gamification.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 19:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (reader.readEnum());
      msg.setDocumentcategory(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPdfurl(value);
      break;
    case 21:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReadingtime();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIswebview();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getWebviewurl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getVideourl();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getFeatureimage();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVideocategory();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getFeaturedcontent();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getFilteridsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      16,
      f
    );
  }
  f = message.getDiscover();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.unifiedplatform.gamification.Tag.serializeBinaryToWriter
    );
  }
  f = message.getDocumentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getPdfurl();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
*/
proto.unifiedplatform.gamification.ContentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subtitle = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
*/
proto.unifiedplatform.gamification.ContentRequest.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale author = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getAuthor = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
*/
proto.unifiedplatform.gamification.ContentRequest.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale content = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getContent = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
*/
proto.unifiedplatform.gamification.ContentRequest.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.hasContent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string image = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 readingTime = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getReadingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setReadingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string tag = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isWebView = 8;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getIswebview = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setIswebview = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string webViewUrl = 9;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getWebviewurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setWebviewurl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 10;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale videoUrl = 11;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getVideourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 11));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
*/
proto.unifiedplatform.gamification.ContentRequest.prototype.setVideourl = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearVideourl = function() {
  return this.setVideourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.hasVideourl = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string featureImage = 12;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getFeatureimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setFeatureimage = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string thumbnail = 13;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional unifiedplatform.heartintune.common.VideoCategory videoCategory = 14;
 * @return {!proto.unifiedplatform.heartintune.common.VideoCategory}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getVideocategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.VideoCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setVideocategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional unifiedplatform.heartintune.common.FeaturedContent featuredContent = 15;
 * @return {!proto.unifiedplatform.heartintune.common.FeaturedContent}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getFeaturedcontent = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.FeaturedContent} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setFeaturedcontent = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated int64 filterIds = 16;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getFilteridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setFilteridsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.addFilterids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearFilteridsList = function() {
  return this.setFilteridsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Discover discover = 17;
 * @return {!proto.unifiedplatform.heartintune.common.Discover}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getDiscover = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Discover} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setDiscover = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated Tag tags = 18;
 * @return {!Array<!proto.unifiedplatform.gamification.Tag>}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.Tag, 18));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.Tag>} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
*/
proto.unifiedplatform.gamification.ContentRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.Tag}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.unifiedplatform.gamification.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.DocumentCategory documentCategory = 19;
 * @return {!proto.unifiedplatform.heartintune.common.DocumentCategory}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getDocumentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DocumentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setDocumentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string pdfUrl = 20;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getPdfurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.setPdfurl = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 21;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 21));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
*/
proto.unifiedplatform.gamification.ContentRequest.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentRequest} returns this
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentRequest.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 21) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ContentResponse.repeatedFields_ = [18,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    author: (f = msg.getAuthor()) && common_common_pb.Locale.toObject(includeInstance, f),
    content: (f = msg.getContent()) && common_common_pb.Locale.toObject(includeInstance, f),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    readingtime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedat: (f = msg.getModifiedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tag: jspb.Message.getFieldWithDefault(msg, 10, ""),
    webviewurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    videourl: (f = msg.getVideourl()) && common_common_pb.Locale.toObject(includeInstance, f),
    featureimage: jspb.Message.getFieldWithDefault(msg, 14, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 15, ""),
    videocategory: jspb.Message.getFieldWithDefault(msg, 16, 0),
    featuredcontent: jspb.Message.getFieldWithDefault(msg, 17, 0),
    filteridsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    discover: jspb.Message.getFieldWithDefault(msg, 19, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.gamification.Tag.toObject, includeInstance),
    tagidsList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    documentcategory: jspb.Message.getFieldWithDefault(msg, 22, 0),
    pdfurl: jspb.Message.getFieldWithDefault(msg, 23, ""),
    attribute: (f = msg.getAttribute()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.ContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentResponse;
  return proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReadingtime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebviewurl(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 13:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setVideourl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatureimage(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 16:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (reader.readEnum());
      msg.setVideocategory(value);
      break;
    case 17:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (reader.readEnum());
      msg.setFeaturedcontent(value);
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterids(values[i]);
      }
      break;
    case 19:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (reader.readEnum());
      msg.setDiscover(value);
      break;
    case 20:
      var value = new proto.unifiedplatform.gamification.Tag;
      reader.readMessage(value,proto.unifiedplatform.gamification.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagids(values[i]);
      }
      break;
    case 22:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (reader.readEnum());
      msg.setDocumentcategory(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPdfurl(value);
      break;
    case 24:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReadingtime();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWebviewurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getVideourl();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getFeatureimage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getVideocategory();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getFeaturedcontent();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getFilteridsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      18,
      f
    );
  }
  f = message.getDiscover();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.unifiedplatform.gamification.Tag.serializeBinaryToWriter
    );
  }
  f = message.getTagidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      21,
      f
    );
  }
  f = message.getDocumentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getPdfurl();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subtitle = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale author = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getAuthor = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale content = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getContent = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasContent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string image = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 readingTime = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getReadingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setReadingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp modifiedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getModifiedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setModifiedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearModifiedat = function() {
  return this.setModifiedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasModifiedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string tag = 10;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string webViewUrl = 11;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getWebviewurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setWebviewurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 12;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale videoUrl = 13;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getVideourl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 13));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setVideourl = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearVideourl = function() {
  return this.setVideourl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasVideourl = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string featureImage = 14;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getFeatureimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setFeatureimage = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string thumbnail = 15;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional unifiedplatform.heartintune.common.VideoCategory videoCategory = 16;
 * @return {!proto.unifiedplatform.heartintune.common.VideoCategory}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getVideocategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.VideoCategory} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.VideoCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setVideocategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional unifiedplatform.heartintune.common.FeaturedContent featuredContent = 17;
 * @return {!proto.unifiedplatform.heartintune.common.FeaturedContent}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getFeaturedcontent = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.FeaturedContent} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.FeaturedContent} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setFeaturedcontent = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated int64 filterIds = 18;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getFilteridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setFilteridsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.addFilterids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearFilteridsList = function() {
  return this.setFilteridsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.Discover discover = 19;
 * @return {!proto.unifiedplatform.heartintune.common.Discover}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getDiscover = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Discover} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setDiscover = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * repeated Tag tags = 20;
 * @return {!Array<!proto.unifiedplatform.gamification.Tag>}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.Tag, 20));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.Tag>} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.Tag}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.unifiedplatform.gamification.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tagIds = 21;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getTagidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setTagidsList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.addTagids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearTagidsList = function() {
  return this.setTagidsList([]);
};


/**
 * optional unifiedplatform.heartintune.common.DocumentCategory documentCategory = 22;
 * @return {!proto.unifiedplatform.heartintune.common.DocumentCategory}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getDocumentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DocumentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setDocumentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string pdfUrl = 23;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getPdfurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.setPdfurl = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale attribute = 24;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.getAttribute = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 24));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
*/
proto.unifiedplatform.gamification.ContentResponse.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentResponse} returns this
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentResponse.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 24) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfContentResponse.repeatedFields_ = [2,3,4,5,10,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featuredcontent: (f = msg.getFeaturedcontent()) && proto.unifiedplatform.gamification.ContentLocaleResponse.toObject(includeInstance, f),
    daajitalksvideoslistList: jspb.Message.toObjectList(msg.getDaajitalksvideoslistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    allcontentlistList: jspb.Message.toObjectList(msg.getAllcontentlistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    articlelistList: jspb.Message.toObjectList(msg.getArticlelistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    bloglistList: jspb.Message.toObjectList(msg.getBloglistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f),
    totalarticlecount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalblogscount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalvideoscount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    whyimeditatevideoslistList: jspb.Message.toObjectList(msg.getWhyimeditatevideoslistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    totalwhyimeditatevideoscount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pdfarticlelistList: jspb.Message.toObjectList(msg.getPdfarticlelistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    youtubevideoslistList: jspb.Message.toObjectList(msg.getYoutubevideoslistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    totalpdfarticlecount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    totalyoutubevideocount: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfContentResponse;
  return proto.unifiedplatform.gamification.ListOfContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.setFeaturedcontent(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addDaajitalksvideoslist(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addAllcontentlist(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addArticlelist(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addBloglist(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalarticlecount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalblogscount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalvideoscount(value);
      break;
    case 10:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addWhyimeditatevideoslist(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalwhyimeditatevideoscount(value);
      break;
    case 12:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addPdfarticlelist(value);
      break;
    case 13:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addYoutubevideoslist(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalpdfarticlecount(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalyoutubevideocount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeaturedcontent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getDaajitalksvideoslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getAllcontentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getArticlelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getBloglistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
  f = message.getTotalarticlecount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTotalblogscount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTotalvideoscount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getWhyimeditatevideoslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalwhyimeditatevideoscount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPdfarticlelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getYoutubevideoslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalpdfarticlecount();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getTotalyoutubevideocount();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};


/**
 * optional ContentLocaleResponse featuredContent = 1;
 * @return {?proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getFeaturedcontent = function() {
  return /** @type{?proto.unifiedplatform.gamification.ContentLocaleResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.ContentLocaleResponse|undefined} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setFeaturedcontent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearFeaturedcontent = function() {
  return this.setFeaturedcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.hasFeaturedcontent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ContentLocaleResponse daajiTalksVideosList = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getDaajitalksvideoslistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setDaajitalksvideoslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.addDaajitalksvideoslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearDaajitalksvideoslistList = function() {
  return this.setDaajitalksvideoslistList([]);
};


/**
 * repeated ContentLocaleResponse allContentList = 3;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getAllcontentlistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setAllcontentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.addAllcontentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearAllcontentlistList = function() {
  return this.setAllcontentlistList([]);
};


/**
 * repeated ContentLocaleResponse articleList = 4;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getArticlelistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setArticlelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.addArticlelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearArticlelistList = function() {
  return this.setArticlelistList([]);
};


/**
 * repeated ContentLocaleResponse blogList = 5;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getBloglistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 5));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setBloglistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.addBloglist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearBloglistList = function() {
  return this.setBloglistList([]);
};


/**
 * optional ResponsePageable pageable = 6;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 6));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 totalArticleCount = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getTotalarticlecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setTotalarticlecount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 totalBlogsCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getTotalblogscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setTotalblogscount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 totalVideosCount = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getTotalvideoscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setTotalvideoscount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated ContentLocaleResponse whyIMeditateVideosList = 10;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getWhyimeditatevideoslistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 10));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setWhyimeditatevideoslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.addWhyimeditatevideoslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearWhyimeditatevideoslistList = function() {
  return this.setWhyimeditatevideoslistList([]);
};


/**
 * optional int64 totalWhyIMeditateVideosCount = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getTotalwhyimeditatevideoscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setTotalwhyimeditatevideoscount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated ContentLocaleResponse pdfArticleList = 12;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getPdfarticlelistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 12));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setPdfarticlelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.addPdfarticlelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearPdfarticlelistList = function() {
  return this.setPdfarticlelistList([]);
};


/**
 * repeated ContentLocaleResponse youtubeVideosList = 13;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getYoutubevideoslistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 13));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setYoutubevideoslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.addYoutubevideoslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.clearYoutubevideoslistList = function() {
  return this.setYoutubevideoslistList([]);
};


/**
 * optional int64 totalPdfArticleCount = 14;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getTotalpdfarticlecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setTotalpdfarticlecount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 totalYoutubeVideoCount = 15;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.getTotalyoutubevideocount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfContentResponse.prototype.setTotalyoutubevideocount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfAllContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfAllContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentresponseList: jspb.Message.toObjectList(msg.getContentresponseList(),
    proto.unifiedplatform.gamification.ContentResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentResponse}
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfAllContentResponse;
  return proto.unifiedplatform.gamification.ListOfAllContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfAllContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentResponse}
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader);
      msg.addContentresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfAllContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfAllContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContentResponse contentResponse = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentResponse>}
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.prototype.getContentresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfAllContentResponse.prototype.setContentresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.prototype.addContentresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ContentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfAllContentResponse.prototype.clearContentresponseList = function() {
  return this.setContentresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.PersonalityQuizLocale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizLocale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    meterrating: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizLocale}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.PersonalityQuizLocale;
  return proto.unifiedplatform.gamification.PersonalityQuizLocale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizLocale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizLocale}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterrating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.PersonalityQuizLocale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizLocale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMeterrating();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizLocale} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizLocale} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizLocale} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string meterRating = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.getMeterrating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizLocale} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizLocale.prototype.setMeterrating = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.PersonalityQuizRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    meterrating: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizRequest}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.PersonalityQuizRequest;
  return proto.unifiedplatform.gamification.PersonalityQuizRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizRequest}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterrating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.PersonalityQuizRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeterrating();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizRequest} returns this
*/
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizRequest} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizRequest} returns this
*/
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizRequest} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string meterRating = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.getMeterrating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizRequest} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizRequest.prototype.setMeterrating = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.PersonalityQuizResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    meterrating: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.PersonalityQuizResponse;
  return proto.unifiedplatform.gamification.PersonalityQuizResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterrating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.PersonalityQuizResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getMeterrating();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse} returns this
*/
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse} returns this
*/
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string meterRating = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.getMeterrating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse} returns this
 */
proto.unifiedplatform.gamification.PersonalityQuizResponse.prototype.setMeterrating = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.HelloWorldString.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.HelloWorldString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.HelloWorldString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.HelloWorldString.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.HelloWorldString}
 */
proto.unifiedplatform.gamification.HelloWorldString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.HelloWorldString;
  return proto.unifiedplatform.gamification.HelloWorldString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.HelloWorldString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.HelloWorldString}
 */
proto.unifiedplatform.gamification.HelloWorldString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.HelloWorldString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.HelloWorldString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.HelloWorldString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.HelloWorldString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.HelloWorldString.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.HelloWorldString} returns this
 */
proto.unifiedplatform.gamification.HelloWorldString.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    personalityquizlistList: jspb.Message.toObjectList(msg.getPersonalityquizlistList(),
    proto.unifiedplatform.gamification.PersonalityQuizResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse}
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse;
  return proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse}
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.PersonalityQuizResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.PersonalityQuizResponse.deserializeBinaryFromReader);
      msg.addPersonalityquizlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonalityquizlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.PersonalityQuizResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PersonalityQuizResponse PersonalityQuizList = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.PersonalityQuizResponse>}
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.prototype.getPersonalityquizlistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.PersonalityQuizResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.PersonalityQuizResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.PersonalityQuizResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.prototype.setPersonalityquizlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.PersonalityQuizResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.PersonalityQuizResponse}
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.prototype.addPersonalityquizlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.PersonalityQuizResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfPersonalityQuizResponse.prototype.clearPersonalityquizlistList = function() {
  return this.setPersonalityquizlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f),
    filteridsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentCategoryRequest;
  return proto.unifiedplatform.gamification.ContentCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContentid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getFilteridsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 contentId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.getContentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.setContentid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 3;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional RequestPageable pageable = 4;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 4));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
*/
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated int64 filterIds = 5;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.getFilteridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.setFilteridsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.addFilterids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryRequest.prototype.clearFilteridsList = function() {
  return this.setFilteridsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentCategoriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentCategoriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentcategoryList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    videocategoryList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    searchcontent: (f = msg.getSearchcontent()) && proto.unifiedplatform.gamification.SearchContent.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentCategoriesRequest;
  return proto.unifiedplatform.gamification.ContentCategoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentCategoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.unifiedplatform.heartintune.common.ContentCategory>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addContentcategory(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<!proto.unifiedplatform.heartintune.common.VideoCategory>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVideocategory(values[i]);
      }
      break;
    case 4:
      var value = new proto.unifiedplatform.gamification.SearchContent;
      reader.readMessage(value,proto.unifiedplatform.gamification.SearchContent.deserializeBinaryFromReader);
      msg.setSearchcontent(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentCategoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentCategoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getContentcategoryList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getVideocategoryList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getSearchcontent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.gamification.SearchContent.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 1;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated unifiedplatform.heartintune.common.ContentCategory contentCategory = 2;
 * @return {!Array<!proto.unifiedplatform.heartintune.common.ContentCategory>}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.getContentcategoryList = function() {
  return /** @type {!Array<!proto.unifiedplatform.heartintune.common.ContentCategory>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartintune.common.ContentCategory>} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.setContentcategoryList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.addContentcategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.clearContentcategoryList = function() {
  return this.setContentcategoryList([]);
};


/**
 * repeated unifiedplatform.heartintune.common.VideoCategory videoCategory = 3;
 * @return {!Array<!proto.unifiedplatform.heartintune.common.VideoCategory>}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.getVideocategoryList = function() {
  return /** @type {!Array<!proto.unifiedplatform.heartintune.common.VideoCategory>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartintune.common.VideoCategory>} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.setVideocategoryList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.VideoCategory} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.addVideocategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.clearVideocategoryList = function() {
  return this.setVideocategoryList([]);
};


/**
 * optional SearchContent searchContent = 4;
 * @return {?proto.unifiedplatform.gamification.SearchContent}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.getSearchcontent = function() {
  return /** @type{?proto.unifiedplatform.gamification.SearchContent} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.SearchContent, 4));
};


/**
 * @param {?proto.unifiedplatform.gamification.SearchContent|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
*/
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.setSearchcontent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.clearSearchcontent = function() {
  return this.setSearchcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.hasSearchcontent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RequestPageable pageable = 5;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 5));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
*/
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesRequest} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentCategoriesRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentCategoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentCategoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentlistList: jspb.Message.toObjectList(msg.getContentlistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    totalcontentcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesResponse}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentCategoriesResponse;
  return proto.unifiedplatform.gamification.ContentCategoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentCategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesResponse}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addContentlist(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcontentcount(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentCategoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentCategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalcontentcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContentLocaleResponse contentList = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.getContentlistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesResponse} returns this
*/
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.setContentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.addContentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesResponse} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.clearContentlistList = function() {
  return this.setContentlistList([]);
};


/**
 * optional int64 totalContentCount = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.getTotalcontentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesResponse} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.setTotalcontentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ResponsePageable pageable = 3;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 3));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesResponse} returns this
*/
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentCategoriesResponse} returns this
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentCategoriesResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.URLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.URLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.URLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.URLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.URLRequest}
 */
proto.unifiedplatform.gamification.URLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.URLRequest;
  return proto.unifiedplatform.gamification.URLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.URLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.URLRequest}
 */
proto.unifiedplatform.gamification.URLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.URLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.URLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.URLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.URLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.URLRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.URLRequest} returns this
 */
proto.unifiedplatform.gamification.URLRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.BulkUploadQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.BulkUploadQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalquotes: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.BulkUploadQuoteResponse}
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.BulkUploadQuoteResponse;
  return proto.unifiedplatform.gamification.BulkUploadQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.BulkUploadQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.BulkUploadQuoteResponse}
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalquotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.BulkUploadQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.BulkUploadQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalquotes();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 totalQuotes = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.prototype.getTotalquotes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.BulkUploadQuoteResponse} returns this
 */
proto.unifiedplatform.gamification.BulkUploadQuoteResponse.prototype.setTotalquotes = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    localetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest}
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest;
  return proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest}
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setLocaletype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLocaletype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 Id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest} returns this
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType localeType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.prototype.getLocaletype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest} returns this
 */
proto.unifiedplatform.gamification.MoodIdWithSelectedLanguageRequest.prototype.setLocaletype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mooddetailresponseList: jspb.Message.toObjectList(msg.getMooddetailresponseList(),
    proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse}
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse;
  return proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse}
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.deserializeBinaryFromReader);
      msg.addMooddetailresponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMooddetailresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated MoodDetailsWithSelectedLanguageResponse moodDetailResponse = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse>}
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.getMooddetailresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse>} value
 * @return {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse} returns this
*/
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.setMooddetailresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse}
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.addMooddetailresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.clearMooddetailresponseList = function() {
  return this.setMooddetailresponseList([]);
};


/**
 * optional int64 totalCount = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.MoodDetailsListWithLocaleResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.SearchContent.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.SearchContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.SearchContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SearchContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchbytitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchbydocumentcategory: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.SearchContent}
 */
proto.unifiedplatform.gamification.SearchContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.SearchContent;
  return proto.unifiedplatform.gamification.SearchContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.SearchContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.SearchContent}
 */
proto.unifiedplatform.gamification.SearchContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchbytitle(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (reader.readEnum());
      msg.setSearchbydocumentcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.SearchContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.SearchContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.SearchContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SearchContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchbytitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearchbydocumentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string searchByTitle = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.SearchContent.prototype.getSearchbytitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.SearchContent} returns this
 */
proto.unifiedplatform.gamification.SearchContent.prototype.setSearchbytitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.DocumentCategory searchByDocumentCategory = 2;
 * @return {!proto.unifiedplatform.heartintune.common.DocumentCategory}
 */
proto.unifiedplatform.gamification.SearchContent.prototype.getSearchbydocumentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.DocumentCategory} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.DocumentCategory} value
 * @return {!proto.unifiedplatform.gamification.SearchContent} returns this
 */
proto.unifiedplatform.gamification.SearchContent.prototype.setSearchbydocumentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    getallcontent: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllContentRequest}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllContentRequest;
  return proto.unifiedplatform.gamification.GetAllContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllContentRequest}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetallcontent(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetallcontent();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool getAllContent = 1;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.getGetallcontent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.setGetallcontent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentRequest} returns this
*/
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllContentRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllContentRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllContentResponse.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featuredcontent: (f = msg.getFeaturedcontent()) && proto.unifiedplatform.gamification.ContentLocaleResponse.toObject(includeInstance, f),
    articlelistList: jspb.Message.toObjectList(msg.getArticlelistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    bloglistList: jspb.Message.toObjectList(msg.getBloglistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    daajitalksvideoslistList: jspb.Message.toObjectList(msg.getDaajitalksvideoslistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllContentResponse;
  return proto.unifiedplatform.gamification.GetAllContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.setFeaturedcontent(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addArticlelist(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addBloglist(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addDaajitalksvideoslist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeaturedcontent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getArticlelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getBloglistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getDaajitalksvideoslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContentLocaleResponse featuredContent = 1;
 * @return {?proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.getFeaturedcontent = function() {
  return /** @type{?proto.unifiedplatform.gamification.ContentLocaleResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.ContentLocaleResponse|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.setFeaturedcontent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.clearFeaturedcontent = function() {
  return this.setFeaturedcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.hasFeaturedcontent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ContentLocaleResponse articleList = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.getArticlelistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.setArticlelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.addArticlelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.clearArticlelistList = function() {
  return this.setArticlelistList([]);
};


/**
 * repeated ContentLocaleResponse blogList = 3;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.getBloglistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.setBloglistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.addBloglist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.clearBloglistList = function() {
  return this.setBloglistList([]);
};


/**
 * repeated ContentLocaleResponse daajiTalksVideosList = 4;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.getDaajitalksvideoslistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.setDaajitalksvideoslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.addDaajitalksvideoslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllContentResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllContentResponse.prototype.clearDaajitalksvideoslistList = function() {
  return this.setDaajitalksvideoslistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ScreenNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ScreenNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ScreenNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ScreenNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    screenname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ScreenNameRequest}
 */
proto.unifiedplatform.gamification.ScreenNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ScreenNameRequest;
  return proto.unifiedplatform.gamification.ScreenNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ScreenNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ScreenNameRequest}
 */
proto.unifiedplatform.gamification.ScreenNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreenname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ScreenNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ScreenNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ScreenNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ScreenNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScreenname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string screenName = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.ScreenNameRequest.prototype.getScreenname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ScreenNameRequest} returns this
 */
proto.unifiedplatform.gamification.ScreenNameRequest.prototype.setScreenname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentFilterLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentfiltername: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse}
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentFilterLocaleResponse;
  return proto.unifiedplatform.gamification.ContentFilterLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse}
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentfiltername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentFilterLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContentfiltername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string contentFilterName = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.prototype.getContentfiltername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ContentFilterLocaleResponse.prototype.setContentfiltername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentfiltername: (f = msg.getContentfiltername()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentFilterRequest}
 */
proto.unifiedplatform.gamification.ContentFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentFilterRequest;
  return proto.unifiedplatform.gamification.ContentFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentFilterRequest}
 */
proto.unifiedplatform.gamification.ContentFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setContentfiltername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentfiltername();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale contentFilterName = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentFilterRequest.prototype.getContentfiltername = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentFilterRequest} returns this
*/
proto.unifiedplatform.gamification.ContentFilterRequest.prototype.setContentfiltername = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentFilterRequest} returns this
 */
proto.unifiedplatform.gamification.ContentFilterRequest.prototype.clearContentfiltername = function() {
  return this.setContentfiltername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentFilterRequest.prototype.hasContentfiltername = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentfiltername: (f = msg.getContentfiltername()) && common_common_pb.Locale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentFilterResponse}
 */
proto.unifiedplatform.gamification.ContentFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentFilterResponse;
  return proto.unifiedplatform.gamification.ContentFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentFilterResponse}
 */
proto.unifiedplatform.gamification.ContentFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setContentfiltername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContentfiltername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ContentFilterResponse} returns this
 */
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale contentFilterName = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.getContentfiltername = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentFilterResponse} returns this
*/
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.setContentfiltername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentFilterResponse} returns this
 */
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.clearContentfiltername = function() {
  return this.setContentfiltername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentFilterResponse.prototype.hasContentfiltername = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllContentFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentfilterlocaleresponseList: jspb.Message.toObjectList(msg.getContentfilterlocaleresponseList(),
    proto.unifiedplatform.gamification.ContentFilterLocaleResponse.toObject, includeInstance),
    contentfilterresponseList: jspb.Message.toObjectList(msg.getContentfilterresponseList(),
    proto.unifiedplatform.gamification.ContentFilterResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllContentFilterResponse;
  return proto.unifiedplatform.gamification.GetAllContentFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentFilterLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentFilterLocaleResponse.deserializeBinaryFromReader);
      msg.addContentfilterlocaleresponse(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ContentFilterResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentFilterResponse.deserializeBinaryFromReader);
      msg.addContentfilterresponse(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllContentFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentfilterlocaleresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentFilterLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getContentfilterresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ContentFilterResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContentFilterLocaleResponse contentFilterLocaleResponse = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentFilterLocaleResponse>}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.getContentfilterlocaleresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentFilterLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentFilterLocaleResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentFilterLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.setContentfilterlocaleresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentFilterLocaleResponse}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.addContentfilterlocaleresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ContentFilterLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.clearContentfilterlocaleresponseList = function() {
  return this.setContentfilterlocaleresponseList([]);
};


/**
 * repeated ContentFilterResponse contentFilterResponse = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentFilterResponse>}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.getContentfilterresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentFilterResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentFilterResponse, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentFilterResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.setContentfilterresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentFilterResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentFilterResponse}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.addContentfilterresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.ContentFilterResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.clearContentfilterresponseList = function() {
  return this.setContentfilterresponseList([]);
};


/**
 * optional ResponsePageable pageable = 3;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 3));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllContentFilterResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllContentFilterResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MoodTriggerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MoodTriggerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tiggername: (f = msg.getTiggername()) && common_common_pb.Locale.toObject(includeInstance, f),
    emojiUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerRequest}
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MoodTriggerRequest;
  return proto.unifiedplatform.gamification.MoodTriggerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MoodTriggerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerRequest}
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTiggername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MoodTriggerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MoodTriggerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTiggername();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getEmojiUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale tiggerName = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.getTiggername = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerRequest} returns this
*/
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.setTiggername = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerRequest} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.clearTiggername = function() {
  return this.setTiggername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.hasTiggername = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string emoji_url = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.getEmojiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerRequest} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerRequest.prototype.setEmojiUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MoodTriggerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MoodTriggerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tiggername: (f = msg.getTiggername()) && common_common_pb.Locale.toObject(includeInstance, f),
    emojiUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerResponse}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MoodTriggerResponse;
  return proto.unifiedplatform.gamification.MoodTriggerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MoodTriggerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerResponse}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTiggername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MoodTriggerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MoodTriggerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTiggername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getEmojiUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerResponse} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale tiggerName = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.getTiggername = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerResponse} returns this
*/
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.setTiggername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerResponse} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.clearTiggername = function() {
  return this.setTiggername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.hasTiggername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string emoji_url = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.getEmojiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerResponse} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerResponse.prototype.setEmojiUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MoodTriggerLocalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tiggername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emojiUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse}
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MoodTriggerLocalResponse;
  return proto.unifiedplatform.gamification.MoodTriggerLocalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse}
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiggername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MoodTriggerLocalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTiggername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmojiUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tiggerName = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.getTiggername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.setTiggername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string emoji_url = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.getEmojiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse} returns this
 */
proto.unifiedplatform.gamification.MoodTriggerLocalResponse.prototype.setEmojiUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    moodtriggerresponseList: jspb.Message.toObjectList(msg.getMoodtriggerresponseList(),
    proto.unifiedplatform.gamification.MoodTriggerResponse.toObject, includeInstance),
    moodtriggerlocalresponseList: jspb.Message.toObjectList(msg.getMoodtriggerlocalresponseList(),
    proto.unifiedplatform.gamification.MoodTriggerLocalResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllMoodTriggerResponse;
  return proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.MoodTriggerResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.MoodTriggerResponse.deserializeBinaryFromReader);
      msg.addMoodtriggerresponse(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.MoodTriggerLocalResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.MoodTriggerLocalResponse.deserializeBinaryFromReader);
      msg.addMoodtriggerlocalresponse(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoodtriggerresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.MoodTriggerResponse.serializeBinaryToWriter
    );
  }
  f = message.getMoodtriggerlocalresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.MoodTriggerLocalResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MoodTriggerResponse moodTriggerResponse = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.MoodTriggerResponse>}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.getMoodtriggerresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.MoodTriggerResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.MoodTriggerResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.MoodTriggerResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.setMoodtriggerresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.MoodTriggerResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.MoodTriggerResponse}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.addMoodtriggerresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.MoodTriggerResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.clearMoodtriggerresponseList = function() {
  return this.setMoodtriggerresponseList([]);
};


/**
 * repeated MoodTriggerLocalResponse moodTriggerLocalResponse = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.MoodTriggerLocalResponse>}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.getMoodtriggerlocalresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.MoodTriggerLocalResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.MoodTriggerLocalResponse, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.MoodTriggerLocalResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.setMoodtriggerlocalresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.MoodTriggerLocalResponse}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.addMoodtriggerlocalresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.MoodTriggerLocalResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.clearMoodtriggerlocalresponseList = function() {
  return this.setMoodtriggerlocalresponseList([]);
};


/**
 * optional ResponsePageable pageable = 3;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 3));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllMoodTriggerResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllMoodTriggerResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.AddMoodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.AddMoodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddMoodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && common_common_pb.Locale.toObject(includeInstance, f),
    associatedcolor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    moodtype: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.AddMoodRequest}
 */
proto.unifiedplatform.gamification.AddMoodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.AddMoodRequest;
  return proto.unifiedplatform.gamification.AddMoodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.AddMoodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.AddMoodRequest}
 */
proto.unifiedplatform.gamification.AddMoodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedcolor(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (reader.readEnum());
      msg.setMoodtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.AddMoodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.AddMoodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.AddMoodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedcolor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoodtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale name = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.getName = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.AddMoodRequest} returns this
*/
proto.unifiedplatform.gamification.AddMoodRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.AddMoodRequest} returns this
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string associatedColor = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.getAssociatedcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.AddMoodRequest} returns this
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.setAssociatedcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.MoodType moodType = 3;
 * @return {!proto.unifiedplatform.heartintune.common.MoodType}
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.getMoodtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MoodType} value
 * @return {!proto.unifiedplatform.gamification.AddMoodRequest} returns this
 */
proto.unifiedplatform.gamification.AddMoodRequest.prototype.setMoodtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MoodDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MoodDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && common_common_pb.Locale.toObject(includeInstance, f),
    associatedColor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    moodtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MoodDetails}
 */
proto.unifiedplatform.gamification.MoodDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MoodDetails;
  return proto.unifiedplatform.gamification.MoodDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MoodDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MoodDetails}
 */
proto.unifiedplatform.gamification.MoodDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedColor(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (reader.readEnum());
      msg.setMoodtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MoodDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MoodDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMoodtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MoodDetails} returns this
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale name = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.getName = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.MoodDetails} returns this
*/
proto.unifiedplatform.gamification.MoodDetails.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.MoodDetails} returns this
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string associated_color = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.getAssociatedColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.MoodDetails} returns this
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.setAssociatedColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MoodType moodType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MoodType}
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.getMoodtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MoodType} value
 * @return {!proto.unifiedplatform.gamification.MoodDetails} returns this
 */
proto.unifiedplatform.gamification.MoodDetails.prototype.setMoodtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mooddetailresponseList: jspb.Message.toObjectList(msg.getMooddetailresponseList(),
    proto.unifiedplatform.gamification.MoodDetails.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse}
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfMoodDetailsResponse;
  return proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse}
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.MoodDetails;
      reader.readMessage(value,proto.unifiedplatform.gamification.MoodDetails.deserializeBinaryFromReader);
      msg.addMooddetailresponse(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMooddetailresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.MoodDetails.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated MoodDetails moodDetailResponse = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.MoodDetails>}
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.getMooddetailresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.MoodDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.MoodDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.MoodDetails>} value
 * @return {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.setMooddetailresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.MoodDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.MoodDetails}
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.addMooddetailresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.MoodDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.clearMooddetailresponseList = function() {
  return this.setMooddetailresponseList([]);
};


/**
 * optional int64 totalCount = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfMoodDetailsResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfMoodDetailsResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    associatedColor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    moodtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse;
  return proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedColor(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (reader.readEnum());
      msg.setMoodtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssociatedColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMoodtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse} returns this
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse} returns this
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string associated_color = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.getAssociatedColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse} returns this
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.setAssociatedColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.MoodType moodType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.MoodType}
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.getMoodtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MoodType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MoodType} value
 * @return {!proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse} returns this
 */
proto.unifiedplatform.gamification.MoodDetailsWithSelectedLanguageResponse.prototype.setMoodtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    screenname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    localetype: (f = msg.getLocaletype()) && proto.unifiedplatform.gamification.LocaleType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest}
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest;
  return proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest}
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreenname(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.LocaleType;
      reader.readMessage(value,proto.unifiedplatform.gamification.LocaleType.deserializeBinaryFromReader);
      msg.setLocaletype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScreenname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocaletype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.LocaleType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string screenName = 1;
 * @return {string}
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.getScreenname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest} returns this
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.setScreenname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LocaleType localeType = 2;
 * @return {?proto.unifiedplatform.gamification.LocaleType}
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.getLocaletype = function() {
  return /** @type{?proto.unifiedplatform.gamification.LocaleType} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.LocaleType, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.LocaleType|undefined} value
 * @return {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest} returns this
*/
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.setLocaletype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest} returns this
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.clearLocaletype = function() {
  return this.setLocaletype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ScreenNameLocaleTypeRequest.prototype.hasLocaletype = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    author: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quote: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagtwo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagthree: jspb.Message.getFieldWithDefault(msg, 6, ""),
    screen: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport;
  return proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuote(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagtwo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagthree(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagtwo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagthree();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} returns this
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string author = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} returns this
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string quote = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.getQuote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} returns this
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.setQuote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tagOne = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.getTagone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} returns this
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.setTagone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tagTwo = 5;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.getTagtwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} returns this
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.setTagtwo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tagThree = 6;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.getTagthree = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} returns this
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.setTagthree = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string screen = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport} returns this
 */
proto.unifiedplatform.gamification.QuoteDetailsWithLocaleSupport.prototype.setScreen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.FAQsLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.FAQsLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    faqs: jspb.Message.getFieldWithDefault(msg, 2, ""),
    faqsanswersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    screen: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selectedscreen: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.FAQsLocaleResponse;
  return proto.unifiedplatform.gamification.FAQsLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.FAQsLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaqs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFaqsanswers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (reader.readEnum());
      msg.setSelectedscreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.FAQsLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.FAQsLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFaqs();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFaqsanswersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelectedscreen();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string FAQs = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.getFaqs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.setFaqs = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string FAQsAnswers = 3;
 * @return {!Array<string>}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.getFaqsanswersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.setFaqsanswersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.addFaqsanswers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.clearFaqsanswersList = function() {
  return this.setFaqsanswersList([]);
};


/**
 * optional string screen = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.setScreen = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.Screens selectedScreen = 5;
 * @return {!proto.unifiedplatform.heartintune.common.Screens}
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.getSelectedscreen = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Screens} value
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsLocaleResponse.prototype.setSelectedscreen = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.FAQsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.FAQsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FAQsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    faqs: (f = msg.getFaqs()) && common_common_pb.Locale.toObject(includeInstance, f),
    faqsanswers: (f = msg.getFaqsanswers()) && common_common_pb.Locale.toObject(includeInstance, f),
    screen: jspb.Message.getFieldWithDefault(msg, 3, ""),
    selectedscreen: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.FAQsRequest}
 */
proto.unifiedplatform.gamification.FAQsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.FAQsRequest;
  return proto.unifiedplatform.gamification.FAQsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.FAQsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.FAQsRequest}
 */
proto.unifiedplatform.gamification.FAQsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setFaqs(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setFaqsanswers(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (reader.readEnum());
      msg.setSelectedscreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.FAQsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.FAQsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FAQsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaqs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getFaqsanswers();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSelectedscreen();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Locale FAQs = 1;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.getFaqs = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 1));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.FAQsRequest} returns this
*/
proto.unifiedplatform.gamification.FAQsRequest.prototype.setFaqs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.FAQsRequest} returns this
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.clearFaqs = function() {
  return this.setFaqs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.hasFaqs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale FAQsAnswers = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.getFaqsanswers = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.FAQsRequest} returns this
*/
proto.unifiedplatform.gamification.FAQsRequest.prototype.setFaqsanswers = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.FAQsRequest} returns this
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.clearFaqsanswers = function() {
  return this.setFaqsanswers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.hasFaqsanswers = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string screen = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FAQsRequest} returns this
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.setScreen = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.Screens selectedScreen = 4;
 * @return {!proto.unifiedplatform.heartintune.common.Screens}
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.getSelectedscreen = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Screens} value
 * @return {!proto.unifiedplatform.gamification.FAQsRequest} returns this
 */
proto.unifiedplatform.gamification.FAQsRequest.prototype.setSelectedscreen = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.FAQsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.FAQsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FAQsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    faqs: (f = msg.getFaqs()) && common_common_pb.Locale.toObject(includeInstance, f),
    faqsanswers: (f = msg.getFaqsanswers()) && common_common_pb.Locale.toObject(includeInstance, f),
    screen: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selectedscreen: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.FAQsResponse}
 */
proto.unifiedplatform.gamification.FAQsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.FAQsResponse;
  return proto.unifiedplatform.gamification.FAQsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.FAQsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.FAQsResponse}
 */
proto.unifiedplatform.gamification.FAQsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setFaqs(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setFaqsanswers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (reader.readEnum());
      msg.setSelectedscreen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.FAQsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.FAQsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.FAQsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFaqs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getFaqsanswers();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelectedscreen();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.FAQsResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale FAQs = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.getFaqs = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.FAQsResponse} returns this
*/
proto.unifiedplatform.gamification.FAQsResponse.prototype.setFaqs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.FAQsResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.clearFaqs = function() {
  return this.setFaqs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.hasFaqs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale FAQsAnswers = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.getFaqsanswers = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.gamification.FAQsResponse} returns this
*/
proto.unifiedplatform.gamification.FAQsResponse.prototype.setFaqsanswers = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.FAQsResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.clearFaqsanswers = function() {
  return this.setFaqsanswers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.hasFaqsanswers = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string screen = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.FAQsResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.setScreen = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.Screens selectedScreen = 5;
 * @return {!proto.unifiedplatform.heartintune.common.Screens}
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.getSelectedscreen = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Screens} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Screens} value
 * @return {!proto.unifiedplatform.gamification.FAQsResponse} returns this
 */
proto.unifiedplatform.gamification.FAQsResponse.prototype.setSelectedscreen = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllFAQsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllFAQsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faqsandanswersList: jspb.Message.toObjectList(msg.getFaqsandanswersList(),
    proto.unifiedplatform.gamification.FAQsResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsResponse}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllFAQsResponse;
  return proto.unifiedplatform.gamification.GetAllFAQsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllFAQsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsResponse}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.FAQsResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.FAQsResponse.deserializeBinaryFromReader);
      msg.addFaqsandanswers(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllFAQsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllFAQsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaqsandanswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.FAQsResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FAQsResponse faqsAndAnswers = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.FAQsResponse>}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.getFaqsandanswersList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.FAQsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.FAQsResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.FAQsResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.setFaqsandanswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.FAQsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.FAQsResponse}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.addFaqsandanswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.FAQsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.clearFaqsandanswersList = function() {
  return this.setFaqsandanswersList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllFAQsResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faqslocaleresponseList: jspb.Message.toObjectList(msg.getFaqslocaleresponseList(),
    proto.unifiedplatform.gamification.FAQsLocaleResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse}
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse;
  return proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse}
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.FAQsLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.FAQsLocaleResponse.deserializeBinaryFromReader);
      msg.addFaqslocaleresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaqslocaleresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.FAQsLocaleResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FAQsLocaleResponse faqsLocaleResponse = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.FAQsLocaleResponse>}
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.prototype.getFaqslocaleresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.FAQsLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.FAQsLocaleResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.FAQsLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.prototype.setFaqslocaleresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.FAQsLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.FAQsLocaleResponse}
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.prototype.addFaqslocaleresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.FAQsLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllFAQsWithLanguageSupportResponse.prototype.clearFaqslocaleresponseList = function() {
  return this.setFaqslocaleresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    discover: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.SelfDevelopmentContentRequest;
  return proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (reader.readEnum());
      msg.setDiscover(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscover();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.Discover discover = 1;
 * @return {!proto.unifiedplatform.heartintune.common.Discover}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.getDiscover = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Discover} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Discover} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.setDiscover = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 4;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentRequest} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentRequest.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.repeatedFields_ = [2,3,4,5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featured: (f = msg.getFeatured()) && proto.unifiedplatform.gamification.ContentLocaleResponse.toObject(includeInstance, f),
    videolistList: jspb.Message.toObjectList(msg.getVideolistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    articlelistList: jspb.Message.toObjectList(msg.getArticlelistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    storylistList: jspb.Message.toObjectList(msg.getStorylistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    booklistList: jspb.Message.toObjectList(msg.getBooklistList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f),
    featuredcontentList: jspb.Message.toObjectList(msg.getFeaturedcontentList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance),
    totalarticlecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalstorycount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalbookcount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalvideoscount: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.SelfDevelopmentContentResponse;
  return proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.setFeatured(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addVideolist(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addArticlelist(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addStorylist(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addBooklist(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 7:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addFeaturedcontent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalarticlecount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalstorycount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalbookcount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalvideoscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatured();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getVideolistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getArticlelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getStorylistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getBooklistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
  f = message.getFeaturedcontentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalarticlecount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTotalstorycount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTotalbookcount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTotalvideoscount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional ContentLocaleResponse featured = 1;
 * @return {?proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getFeatured = function() {
  return /** @type{?proto.unifiedplatform.gamification.ContentLocaleResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.ContentLocaleResponse|undefined} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setFeatured = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.clearFeatured = function() {
  return this.setFeatured(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.hasFeatured = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ContentLocaleResponse videoList = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getVideolistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setVideolistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.addVideolist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.clearVideolistList = function() {
  return this.setVideolistList([]);
};


/**
 * repeated ContentLocaleResponse articleList = 3;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getArticlelistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setArticlelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.addArticlelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.clearArticlelistList = function() {
  return this.setArticlelistList([]);
};


/**
 * repeated ContentLocaleResponse storyList = 4;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getStorylistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setStorylistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.addStorylist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.clearStorylistList = function() {
  return this.setStorylistList([]);
};


/**
 * repeated ContentLocaleResponse bookList = 5;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getBooklistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 5));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setBooklistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.addBooklist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.clearBooklistList = function() {
  return this.setBooklistList([]);
};


/**
 * optional ResponsePageable pageable = 6;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 6));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ContentLocaleResponse featuredContent = 7;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getFeaturedcontentList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 7));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setFeaturedcontentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.addFeaturedcontent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.clearFeaturedcontentList = function() {
  return this.setFeaturedcontentList([]);
};


/**
 * optional int64 totalArticleCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getTotalarticlecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setTotalarticlecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 totalStoryCount = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getTotalstorycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setTotalstorycount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 totalBookCount = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getTotalbookcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setTotalbookcount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 totalVideosCount = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.getTotalvideoscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponse} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponse.prototype.setTotalvideoscount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.repeatedFields_ = [1,2,3,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    videolistList: jspb.Message.toObjectList(msg.getVideolistList(),
    proto.unifiedplatform.gamification.ContentResponse.toObject, includeInstance),
    articlelistList: jspb.Message.toObjectList(msg.getArticlelistList(),
    proto.unifiedplatform.gamification.ContentResponse.toObject, includeInstance),
    storylistList: jspb.Message.toObjectList(msg.getStorylistList(),
    proto.unifiedplatform.gamification.ContentResponse.toObject, includeInstance),
    booklistList: jspb.Message.toObjectList(msg.getBooklistList(),
    proto.unifiedplatform.gamification.ContentResponse.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f),
    featuredcontentsList: jspb.Message.toObjectList(msg.getFeaturedcontentsList(),
    proto.unifiedplatform.gamification.ContentResponse.toObject, includeInstance),
    totalarticlecount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalstorycount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalbookcount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalvideoscount: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage;
  return proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader);
      msg.addVideolist(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ContentResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader);
      msg.addArticlelist(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ContentResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader);
      msg.addStorylist(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.gamification.ContentResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader);
      msg.addBooklist(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.gamification.ContentResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader);
      msg.addFeaturedcontents(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalarticlecount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalstorycount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalbookcount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalvideoscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideolistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter
    );
  }
  f = message.getArticlelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter
    );
  }
  f = message.getStorylistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter
    );
  }
  f = message.getBooklistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
  f = message.getFeaturedcontentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalarticlecount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTotalstorycount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTotalbookcount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTotalvideoscount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * repeated ContentResponse videoList = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getVideolistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setVideolistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.addVideolist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ContentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.clearVideolistList = function() {
  return this.setVideolistList([]);
};


/**
 * repeated ContentResponse articleList = 2;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getArticlelistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentResponse, 2));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setArticlelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.addArticlelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unifiedplatform.gamification.ContentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.clearArticlelistList = function() {
  return this.setArticlelistList([]);
};


/**
 * repeated ContentResponse storyList = 3;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getStorylistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentResponse, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setStorylistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.addStorylist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.gamification.ContentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.clearStorylistList = function() {
  return this.setStorylistList([]);
};


/**
 * repeated ContentResponse bookList = 4;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getBooklistList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentResponse, 4));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setBooklistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.addBooklist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.unifiedplatform.gamification.ContentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.clearBooklistList = function() {
  return this.setBooklistList([]);
};


/**
 * optional ResponsePageable pageable = 5;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 5));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated ContentResponse featuredContents = 6;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentResponse>}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getFeaturedcontentsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentResponse, 6));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentResponse>} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
*/
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setFeaturedcontentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.addFeaturedcontents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.unifiedplatform.gamification.ContentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.clearFeaturedcontentsList = function() {
  return this.setFeaturedcontentsList([]);
};


/**
 * optional int64 totalArticleCount = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getTotalarticlecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setTotalarticlecount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 totalStoryCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getTotalstorycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setTotalstorycount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 totalBookCount = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getTotalbookcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setTotalbookcount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 totalVideosCount = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.getTotalvideoscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage} returns this
 */
proto.unifiedplatform.gamification.SelfDevelopmentContentResponseWithAllLanguage.prototype.setTotalvideoscount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.Tag}
 */
proto.unifiedplatform.gamification.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.Tag;
  return proto.unifiedplatform.gamification.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.Tag}
 */
proto.unifiedplatform.gamification.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.Tag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.Tag} returns this
 */
proto.unifiedplatform.gamification.Tag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.Tag.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.Tag} returns this
 */
proto.unifiedplatform.gamification.Tag.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.TagWithContent.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.TagWithContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.TagWithContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TagWithContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.unifiedplatform.gamification.ContentResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.TagWithContent}
 */
proto.unifiedplatform.gamification.TagWithContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.TagWithContent;
  return proto.unifiedplatform.gamification.TagWithContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.TagWithContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.TagWithContent}
 */
proto.unifiedplatform.gamification.TagWithContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.ContentResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentResponse.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.TagWithContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.TagWithContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TagWithContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.unifiedplatform.gamification.ContentResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TagWithContent} returns this
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.TagWithContent} returns this
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ContentResponse contents = 3;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentResponse>}
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentResponse, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentResponse>} value
 * @return {!proto.unifiedplatform.gamification.TagWithContent} returns this
*/
proto.unifiedplatform.gamification.TagWithContent.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentResponse}
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.unifiedplatform.gamification.ContentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.TagWithContent} returns this
 */
proto.unifiedplatform.gamification.TagWithContent.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.TagsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.TagsList.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.TagsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.TagsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TagsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.gamification.Tag.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.TagsList}
 */
proto.unifiedplatform.gamification.TagsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.TagsList;
  return proto.unifiedplatform.gamification.TagsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.TagsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.TagsList}
 */
proto.unifiedplatform.gamification.TagsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.Tag;
      reader.readMessage(value,proto.unifiedplatform.gamification.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.TagsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.TagsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.TagsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TagsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.Tag.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tag tags = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.Tag>}
 */
proto.unifiedplatform.gamification.TagsList.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.Tag, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.Tag>} value
 * @return {!proto.unifiedplatform.gamification.TagsList} returns this
*/
proto.unifiedplatform.gamification.TagsList.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.Tag}
 */
proto.unifiedplatform.gamification.TagsList.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.TagsList} returns this
 */
proto.unifiedplatform.gamification.TagsList.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.gamification.ResponsePageable}
 */
proto.unifiedplatform.gamification.TagsList.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.TagsList} returns this
*/
proto.unifiedplatform.gamification.TagsList.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.TagsList} returns this
 */
proto.unifiedplatform.gamification.TagsList.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TagsList.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.Tags.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.Tags.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.Tags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.Tags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Tags.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.gamification.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.Tags}
 */
proto.unifiedplatform.gamification.Tags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.Tags;
  return proto.unifiedplatform.gamification.Tags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.Tags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.Tags}
 */
proto.unifiedplatform.gamification.Tags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.Tag;
      reader.readMessage(value,proto.unifiedplatform.gamification.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.Tags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.Tags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.Tags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.Tags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tag tags = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.Tag>}
 */
proto.unifiedplatform.gamification.Tags.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.Tag, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.Tag>} value
 * @return {!proto.unifiedplatform.gamification.Tags} returns this
*/
proto.unifiedplatform.gamification.Tags.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.Tag}
 */
proto.unifiedplatform.gamification.Tags.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.Tags} returns this
 */
proto.unifiedplatform.gamification.Tags.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DocumentInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DocumentInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firebaseId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DocumentInfoRequest}
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DocumentInfoRequest;
  return proto.unifiedplatform.gamification.DocumentInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DocumentInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DocumentInfoRequest}
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebaseId(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DocumentInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DocumentInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirebaseId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 content_id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.getContentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.DocumentInfoRequest} returns this
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.setContentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firebase_id = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.getFirebaseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.DocumentInfoRequest} returns this
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.setFirebaseId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 3;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.DocumentInfoRequest} returns this
 */
proto.unifiedplatform.gamification.DocumentInfoRequest.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.SharedInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.SharedInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SharedInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f),
    preceptorid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.SharedInfoRequest}
 */
proto.unifiedplatform.gamification.SharedInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.SharedInfoRequest;
  return proto.unifiedplatform.gamification.SharedInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.SharedInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.SharedInfoRequest}
 */
proto.unifiedplatform.gamification.SharedInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreceptorid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.SharedInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.SharedInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SharedInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getPreceptorid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional RequestPageable pageable = 1;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.SharedInfoRequest} returns this
*/
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.SharedInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string preceptorId = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.getPreceptorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.SharedInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SharedInfoRequest.prototype.setPreceptorid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.SeekerInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.SeekerInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seekerIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    preceptorId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.SeekerInfoRequest;
  return proto.unifiedplatform.gamification.SeekerInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.SeekerInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeekerIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreceptorId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContentId(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.SeekerInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.SeekerInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeekerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPreceptorId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * repeated string seeker_ids = 1;
 * @return {!Array<string>}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.getSeekerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.setSeekerIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.addSeekerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.clearSeekerIdsList = function() {
  return this.setSeekerIdsList([]);
};


/**
 * optional string preceptor_id = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.getPreceptorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.setPreceptorId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 content_id = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.getContentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.setContentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 4;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.SeekerInfoRequest} returns this
 */
proto.unifiedplatform.gamification.SeekerInfoRequest.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentlocaleresponseList: jspb.Message.toObjectList(msg.getContentlocaleresponseList(),
    proto.unifiedplatform.gamification.ContentLocaleResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse;
  return proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ContentLocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.ContentLocaleResponse.deserializeBinaryFromReader);
      msg.addContentlocaleresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentlocaleresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ContentLocaleResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContentLocaleResponse contentLocaleResponse = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>}
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.prototype.getContentlocaleresponseList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ContentLocaleResponse, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ContentLocaleResponse>} value
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse} returns this
*/
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.prototype.setContentlocaleresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ContentLocaleResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentLocaleResponse}
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.prototype.addContentlocaleresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ContentLocaleResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse} returns this
 */
proto.unifiedplatform.gamification.ListOfAllContentLocaleResponse.prototype.clearContentlocaleresponseList = function() {
  return this.setContentlocaleresponseList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentcategory: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    requestpageable: (f = msg.getRequestpageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds;
  return proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addContentids(values[i]);
      }
      break;
    case 3:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setRequestpageable(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getContentidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getRequestpageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 1;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated int64 contentIds = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.getContentidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.setContentidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.addContentids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.clearContentidsList = function() {
  return this.setContentidsList([]);
};


/**
 * optional RequestPageable requestPageable = 3;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.getRequestpageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} returns this
*/
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.setRequestpageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.clearRequestpageable = function() {
  return this.setRequestpageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.hasRequestpageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds} returns this
 */
proto.unifiedplatform.gamification.ContentCategoryWithListOfContentIds.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MeditationTrackerStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MeditationTrackerStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minutes: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatus}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MeditationTrackerStatus;
  return proto.unifiedplatform.gamification.MeditationTrackerStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MeditationTrackerStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatus}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MeditationTrackerStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MeditationTrackerStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMinutes();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatus} returns this
*/
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatus} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 2;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatus} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 minutes = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.getMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatus} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatus.prototype.setMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meditationtrackerstatusList: jspb.Message.toObjectList(msg.getMeditationtrackerstatusList(),
    proto.unifiedplatform.gamification.MeditationTrackerStatus.toObject, includeInstance),
    totaldurationinminutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    progressdurationinminutes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percentcompleted: jspb.Message.getFieldWithDefault(msg, 4, 0),
    goalinfo: (f = msg.getGoalinfo()) && proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MeditationTrackerStatusResponse;
  return proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.MeditationTrackerStatus;
      reader.readMessage(value,proto.unifiedplatform.gamification.MeditationTrackerStatus.deserializeBinaryFromReader);
      msg.addMeditationtrackerstatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotaldurationinminutes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgressdurationinminutes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentcompleted(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse;
      reader.readMessage(value,proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.deserializeBinaryFromReader);
      msg.setGoalinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeditationtrackerstatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.MeditationTrackerStatus.serializeBinaryToWriter
    );
  }
  f = message.getTotaldurationinminutes();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getProgressdurationinminutes();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPercentcompleted();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGoalinfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MeditationTrackerStatus meditationTrackerStatus = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.MeditationTrackerStatus>}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.getMeditationtrackerstatusList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.MeditationTrackerStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.MeditationTrackerStatus, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.MeditationTrackerStatus>} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} returns this
*/
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.setMeditationtrackerstatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.MeditationTrackerStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatus}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.addMeditationtrackerstatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.MeditationTrackerStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.clearMeditationtrackerstatusList = function() {
  return this.setMeditationtrackerstatusList([]);
};


/**
 * optional int32 totalDurationInMinutes = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.getTotaldurationinminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.setTotaldurationinminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 progressDurationInMinutes = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.getProgressdurationinminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.setProgressdurationinminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 percentCompleted = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.getPercentcompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.setPercentcompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional GoalInfoV2LocaleResponse goalInfo = 5;
 * @return {?proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.getGoalinfo = function() {
  return /** @type{?proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse, 5));
};


/**
 * @param {?proto.unifiedplatform.gamification.GoalInfoV2LocaleResponse|undefined} value
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} returns this
*/
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.setGoalinfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.MeditationTrackerStatusResponse} returns this
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.clearGoalinfo = function() {
  return this.setGoalinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.MeditationTrackerStatusResponse.prototype.hasGoalinfo = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectlanguage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest;
  return proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectlanguage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.gamification.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.gamification.PaginationRequest.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSelectlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.gamification.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 2;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectLanguage = 3;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.getSelectlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.setSelectlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 goalId = 4;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PaginationRequest pageable = 6;
 * @return {?proto.unifiedplatform.gamification.PaginationRequest}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.PaginationRequest, 6));
};


/**
 * @param {?proto.unifiedplatform.gamification.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.PaginationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.PaginationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.PaginationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PaginationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagenumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.PaginationRequest}
 */
proto.unifiedplatform.gamification.PaginationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.PaginationRequest;
  return proto.unifiedplatform.gamification.PaginationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.PaginationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.PaginationRequest}
 */
proto.unifiedplatform.gamification.PaginationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.PaginationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.PaginationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.PaginationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.PaginationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 pageNumber = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.PaginationRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.PaginationRequest} returns this
 */
proto.unifiedplatform.gamification.PaginationRequest.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.PaginationRequest.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.PaginationRequest} returns this
 */
proto.unifiedplatform.gamification.PaginationRequest.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetGoalStatusCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetGoalStatusCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    useracceptedgoalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usermissedgoalcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usercompletedgoalcount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetGoalStatusCount}
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetGoalStatusCount;
  return proto.unifiedplatform.gamification.GetGoalStatusCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetGoalStatusCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetGoalStatusCount}
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUseracceptedgoalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsermissedgoalcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedgoalcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetGoalStatusCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetGoalStatusCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseracceptedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsermissedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsercompletedgoalcount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 userAcceptedGoalCount = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.getUseracceptedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalStatusCount} returns this
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.setUseracceptedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userMissedGoalCount = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.getUsermissedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalStatusCount} returns this
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.setUsermissedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userCompletedGoalCount = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.getUsercompletedgoalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetGoalStatusCount} returns this
 */
proto.unifiedplatform.gamification.GetGoalStatusCount.prototype.setUsercompletedgoalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.DurationAndRequiredTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.DurationAndRequiredTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requiredtimes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usercompletedrequiredtimescount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.DurationAndRequiredTime}
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.DurationAndRequiredTime;
  return proto.unifiedplatform.gamification.DurationAndRequiredTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.DurationAndRequiredTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.DurationAndRequiredTime}
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequiredtimes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedrequiredtimescount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.DurationAndRequiredTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.DurationAndRequiredTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequiredtimes();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsercompletedrequiredtimescount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 duration = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.DurationAndRequiredTime} returns this
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 requiredTimes = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.getRequiredtimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.DurationAndRequiredTime} returns this
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.setRequiredtimes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 userCompletedRequiredTimesCount = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.getUsercompletedrequiredtimescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.DurationAndRequiredTime} returns this
 */
proto.unifiedplatform.gamification.DurationAndRequiredTime.prototype.setUsercompletedrequiredtimescount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.repeatedFields_ = [9,12,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponseGoalInfoV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    goaltypes: (f = msg.getGoaltypes()) && proto.unifiedplatform.gamification.GoalInfoV2Response.toObject(includeInstance, f),
    badge: (f = msg.getBadge()) && Badge_pb.BadgeDetails.toObject(includeInstance, f),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalscheduledtime: (f = msg.getGoalscheduledtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    daystocomplete: jspb.Message.getFieldWithDefault(msg, 5, 0),
    completedgoalpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    usercompletedrequiredtimescount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    earnedbadgecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    listofgoalscheduleddatesList: jspb.Message.toObjectList(msg.getListofgoalscheduleddatesList(),
    proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.toObject, includeInstance),
    totalregisteredusercount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isregistered: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    badgewithcountList: jspb.Message.toObjectList(msg.getBadgewithcountList(),
    Badge_pb.BadgeDetailsV2.toObject, includeInstance),
    defaultbadge: (f = msg.getDefaultbadge()) && Badge_pb.BadgeDetailsV2.toObject(includeInstance, f),
    associatedid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    badgesList: jspb.Message.toObjectList(msg.getBadgesList(),
    Badge_pb.BadgeDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponseGoalInfoV2;
  return proto.unifiedplatform.gamification.ResponseGoalInfoV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.GoalInfoV2Response;
      reader.readMessage(value,proto.unifiedplatform.gamification.GoalInfoV2Response.deserializeBinaryFromReader);
      msg.setGoaltypes(value);
      break;
    case 2:
      var value = new Badge_pb.BadgeDetails;
      reader.readMessage(value,Badge_pb.BadgeDetails.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setGoalscheduledtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDaystocomplete(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompletedgoalpercentage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsercompletedrequiredtimescount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEarnedbadgecount(value);
      break;
    case 9:
      var value = new proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent;
      reader.readMessage(value,proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader);
      msg.addListofgoalscheduleddates(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalregisteredusercount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsregistered(value);
      break;
    case 12:
      var value = new Badge_pb.BadgeDetailsV2;
      reader.readMessage(value,Badge_pb.BadgeDetailsV2.deserializeBinaryFromReader);
      msg.addBadgewithcount(value);
      break;
    case 13:
      var value = new Badge_pb.BadgeDetailsV2;
      reader.readMessage(value,Badge_pb.BadgeDetailsV2.deserializeBinaryFromReader);
      msg.setDefaultbadge(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssociatedid(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 16:
      var value = new Badge_pb.BadgeDetails;
      reader.readMessage(value,Badge_pb.BadgeDetails.deserializeBinaryFromReader);
      msg.addBadges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponseGoalInfoV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoaltypes();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.GoalInfoV2Response.serializeBinaryToWriter
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Badge_pb.BadgeDetails.serializeBinaryToWriter
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getGoalscheduledtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDaystocomplete();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCompletedgoalpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getUsercompletedrequiredtimescount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEarnedbadgecount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getListofgoalscheduleddatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter
    );
  }
  f = message.getTotalregisteredusercount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIsregistered();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBadgewithcountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Badge_pb.BadgeDetailsV2.serializeBinaryToWriter
    );
  }
  f = message.getDefaultbadge();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Badge_pb.BadgeDetailsV2.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedid();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getBadgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      Badge_pb.BadgeDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional GoalInfoV2Response goalTypes = 1;
 * @return {?proto.unifiedplatform.gamification.GoalInfoV2Response}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getGoaltypes = function() {
  return /** @type{?proto.unifiedplatform.gamification.GoalInfoV2Response} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.GoalInfoV2Response, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.GoalInfoV2Response|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setGoaltypes = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.clearGoaltypes = function() {
  return this.setGoaltypes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.hasGoaltypes = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional badge.BadgeDetails badge = 2;
 * @return {?proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getBadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.BadgeDetails} */ (
    jspb.Message.getWrapperField(this, Badge_pb.BadgeDetails, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.BadgeDetails|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp goalScheduledTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getGoalscheduledtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setGoalscheduledtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.clearGoalscheduledtime = function() {
  return this.setGoalscheduledtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.hasGoalscheduledtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 daysToComplete = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getDaystocomplete = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setDaystocomplete = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double completedGoalPercentage = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getCompletedgoalpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setCompletedgoalpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 userCompletedRequiredTimesCount = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getUsercompletedrequiredtimescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setUsercompletedrequiredtimescount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 earnedBadgeCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getEarnedbadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setEarnedbadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated SelectedDateAndTimeWithCalendarEvent listOfGoalScheduledDates = 9;
 * @return {!Array<!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent>}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getListofgoalscheduleddatesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent, 9));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent>} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setListofgoalscheduleddatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.addListofgoalscheduleddates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.clearListofgoalscheduleddatesList = function() {
  return this.setListofgoalscheduleddatesList([]);
};


/**
 * optional int64 totalRegisteredUserCount = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getTotalregisteredusercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setTotalregisteredusercount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool isRegistered = 11;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getIsregistered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setIsregistered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated badge.BadgeDetailsV2 badgeWithCount = 12;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getBadgewithcountList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, Badge_pb.BadgeDetailsV2, 12));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setBadgewithcountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.addBadgewithcount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.unifiedplatform.gamification.badge.BadgeDetailsV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.clearBadgewithcountList = function() {
  return this.setBadgewithcountList([]);
};


/**
 * optional badge.BadgeDetailsV2 defaultBadge = 13;
 * @return {?proto.unifiedplatform.gamification.badge.BadgeDetailsV2}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getDefaultbadge = function() {
  return /** @type{?proto.unifiedplatform.gamification.badge.BadgeDetailsV2} */ (
    jspb.Message.getWrapperField(this, Badge_pb.BadgeDetailsV2, 13));
};


/**
 * @param {?proto.unifiedplatform.gamification.badge.BadgeDetailsV2|undefined} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setDefaultbadge = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.clearDefaultbadge = function() {
  return this.setDefaultbadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.hasDefaultbadge = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int64 associatedId = 14;
 * @return {number}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getAssociatedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setAssociatedid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool completed = 15;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated badge.BadgeDetails badges = 16;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.getBadgesList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, Badge_pb.BadgeDetails, 16));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetails>} value
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
*/
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.setBadgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetails}
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.addBadges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.unifiedplatform.gamification.badge.BadgeDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2} returns this
 */
proto.unifiedplatform.gamification.ResponseGoalInfoV2.prototype.clearBadgesList = function() {
  return this.setBadgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsegoalinfov2List: jspb.Message.toObjectList(msg.getResponsegoalinfov2List(),
    proto.unifiedplatform.gamification.ResponseGoalInfoV2.toObject, includeInstance),
    totalgoalscount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse;
  return proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ResponseGoalInfoV2;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponseGoalInfoV2.deserializeBinaryFromReader);
      msg.addResponsegoalinfov2(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalgoalscount(value);
      break;
    case 3:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 6:
      var value = new proto.unifiedplatform.gamification.PaginationRequest;
      reader.readMessage(value,proto.unifiedplatform.gamification.PaginationRequest.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsegoalinfov2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ResponseGoalInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getTotalgoalscount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.unifiedplatform.gamification.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseGoalInfoV2 responseGoalInfoV2 = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.getResponsegoalinfov2List = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ResponseGoalInfoV2, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.setResponsegoalinfov2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ResponseGoalInfoV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.addResponsegoalinfov2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ResponseGoalInfoV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.clearResponsegoalinfov2List = function() {
  return this.setResponsegoalinfov2List([]);
};


/**
 * optional int64 totalGoalsCount = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.getTotalgoalscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.setTotalgoalscount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 3;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PaginationRequest pageable = 6;
 * @return {?proto.unifiedplatform.gamification.PaginationRequest}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.PaginationRequest, 6));
};


/**
 * @param {?proto.unifiedplatform.gamification.PaginationRequest|undefined} value
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} returns this
*/
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse} returns this
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.GetAllGoalStatusByUserResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfIDs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfIDs}
 */
proto.unifiedplatform.gamification.ListOfIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfIDs;
  return proto.unifiedplatform.gamification.ListOfIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfIDs}
 */
proto.unifiedplatform.gamification.ListOfIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 userID = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.ListOfIDs} returns this
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 IDs = 2;
 * @return {!Array<number>}
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.unifiedplatform.gamification.ListOfIDs} returns this
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ListOfIDs} returns this
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfIDs} returns this
 */
proto.unifiedplatform.gamification.ListOfIDs.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startdatetime: (f = msg.getStartdatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddatetime: (f = msg.getEnddatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestV2GetAllMySignUp;
  return proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdatetime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddatetime(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartdatetime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startDateTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.getStartdatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.setStartdatetime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.clearStartdatetime = function() {
  return this.setStartdatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.hasStartdatetime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDateTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.getEnddatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.setEnddatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.clearEnddatetime = function() {
  return this.setEnddatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.hasEnddatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional RequestPageable pageable = 5;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 5));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestV2GetAllMySignUp.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.toObject = function(includeInstance, msg) {
  var f, obj = {
    goaldetailsactiveongivendayList: jspb.Message.toObjectList(msg.getGoaldetailsactiveongivendayList(),
    proto.unifiedplatform.gamification.ResponseGoalInfoV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp}
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp;
  return proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp}
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ResponseGoalInfoV2;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponseGoalInfoV2.deserializeBinaryFromReader);
      msg.addGoaldetailsactiveongivenday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoaldetailsactiveongivendayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ResponseGoalInfoV2.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseGoalInfoV2 goalDetailsActiveOnGivenDay = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>}
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.prototype.getGoaldetailsactiveongivendayList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.ResponseGoalInfoV2, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.ResponseGoalInfoV2>} value
 * @return {!proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp} returns this
*/
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.prototype.setGoaldetailsactiveongivendayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.ResponseGoalInfoV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.ResponseGoalInfoV2}
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.prototype.addGoaldetailsactiveongivenday = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.ResponseGoalInfoV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp} returns this
 */
proto.unifiedplatform.gamification.ResponseV2GetAllMySignUp.prototype.clearGoaldetailsactiveongivendayList = function() {
  return this.setGoaldetailsactiveongivendayList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestGoalByUserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestGoalByUserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goalid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalstatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestGoalByUserId}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestGoalByUserId;
  return proto.unifiedplatform.gamification.RequestGoalByUserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestGoalByUserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestGoalByUserId}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (reader.readEnum());
      msg.setGoalstatus(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestGoalByUserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestGoalByUserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGoalstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestGoalByUserId} returns this
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 goalId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestGoalByUserId} returns this
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 id = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestGoalByUserId} returns this
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalStatus goalStatus = 4;
 * @return {!proto.unifiedplatform.heartintune.common.GoalStatus}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.getGoalstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalStatus} value
 * @return {!proto.unifiedplatform.gamification.RequestGoalByUserId} returns this
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.setGoalstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 5;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.RequestGoalByUserId} returns this
 */
proto.unifiedplatform.gamification.RequestGoalByUserId.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goaltitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage;
  return proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaltitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGoaltitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 goalId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string goalTitle = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.getGoaltitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.setGoaltitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 day = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.toObject = function(includeInstance, msg) {
  var f, obj = {
    getinprogressgoalcardforhomepageList: jspb.Message.toObjectList(msg.getGetinprogressgoalcardforhomepageList(),
    proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage;
  return proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage;
      reader.readMessage(value,proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.deserializeBinaryFromReader);
      msg.addGetinprogressgoalcardforhomepage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetinprogressgoalcardforhomepageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetInProgressGoalCardForHomePage getInProgressGoalCardForHomePage = 1;
 * @return {!Array<!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage>}
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.prototype.getGetinprogressgoalcardforhomepageList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage>} value
 * @return {!proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage} returns this
*/
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.prototype.setGetinprogressgoalcardforhomepageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage}
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.prototype.addGetinprogressgoalcardforhomepage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.gamification.GetInProgressGoalCardForHomePage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage} returns this
 */
proto.unifiedplatform.gamification.ListOfGetInProgressGoalCardForHomePage.prototype.clearGetinprogressgoalcardforhomepageList = function() {
  return this.setGetinprogressgoalcardforhomepageList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestToScheduleGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestToScheduleGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    goalcategory: jspb.Message.getFieldWithDefault(msg, 4, 0),
    selecteddateandtimewithcalendareventList: jspb.Message.toObjectList(msg.getSelecteddateandtimewithcalendareventList(),
    proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.toObject, includeInstance),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startofday: (f = msg.getStartofday()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endofday: (f = msg.getEndofday()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hfn: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    todayseconds: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestToScheduleGoal;
  return proto.unifiedplatform.gamification.RequestToScheduleGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestToScheduleGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (reader.readEnum());
      msg.setGoalcategory(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent;
      reader.readMessage(value,proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader);
      msg.addSelecteddateandtimewithcalendarevent(value);
      break;
    case 6:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartofday(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndofday(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHfn(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTodayseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestToScheduleGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestToScheduleGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGoalcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSelecteddateandtimewithcalendareventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStartofday();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndofday();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHfn();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getTodayseconds();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
};


/**
 * optional int64 goalID = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userID = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.GoalCategory goalCategory = 4;
 * @return {!proto.unifiedplatform.heartintune.common.GoalCategory}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getGoalcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.GoalCategory} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.GoalCategory} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setGoalcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated SelectedDateAndTimeWithCalendarEvent selectedDateAndTimeWithCalendarEvent = 5;
 * @return {!Array<!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent>}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getSelecteddateandtimewithcalendareventList = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent, 5));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent>} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
*/
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setSelecteddateandtimewithcalendareventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.addSelecteddateandtimewithcalendarevent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.clearSelecteddateandtimewithcalendareventList = function() {
  return this.setSelecteddateandtimewithcalendareventList([]);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 6;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp startOfDay = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getStartofday = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
*/
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setStartofday = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.clearStartofday = function() {
  return this.setStartofday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.hasStartofday = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp endOfDay = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getEndofday = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
*/
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setEndofday = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.clearEndofday = function() {
  return this.setEndofday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.hasEndofday = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool hfn = 20;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getHfn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setHfn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional int64 todaySeconds = 21;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.getTodayseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestToScheduleGoal} returns this
 */
proto.unifiedplatform.gamification.RequestToScheduleGoal.prototype.setTodayseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    selecteddateandtime: (f = msg.getSelecteddateandtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    calendareventid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    durationminutes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    completedstatus: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent;
  return proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSelecteddateandtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendareventid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationminutes(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedstatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelecteddateandtime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCalendareventid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDurationminutes();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompletedstatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp selectedDateAndTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.getSelecteddateandtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} returns this
*/
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.setSelecteddateandtime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.clearSelecteddateandtime = function() {
  return this.setSelecteddateandtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.hasSelecteddateandtime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string calendarEventId = 2;
 * @return {string}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.getCalendareventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.setCalendareventid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 durationMinutes = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.getDurationminutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.setDurationminutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool completedStatus = 4;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.getCompletedstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.setCompletedstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} returns this
*/
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent} returns this
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.SelectedDateAndTimeWithCalendarEvent.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.TrackerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.TrackerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TrackerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    meditationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    playcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pausecount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    completedpercentage: jspb.Message.getFieldWithDefault(msg, 8, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    meditationid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    livesessionid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    useractivity: jspb.Message.getFieldWithDefault(msg, 12, 0),
    logmeditationtype: jspb.Message.getFieldWithDefault(msg, 13, 0),
    meditationcategory: jspb.Message.getFieldWithDefault(msg, 14, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 15, 0),
    badgeid: jspb.Message.getFieldWithDefault(msg, 16, 0),
    badgecount: jspb.Message.getFieldWithDefault(msg, 17, 0),
    startofday: (f = msg.getStartofday()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endofday: (f = msg.getEndofday()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    goalid: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.TrackerInfo}
 */
proto.unifiedplatform.gamification.TrackerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.TrackerInfo;
  return proto.unifiedplatform.gamification.TrackerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.TrackerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.TrackerInfo}
 */
proto.unifiedplatform.gamification.TrackerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (reader.readEnum());
      msg.setMeditationtype(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaycount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPausecount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalseconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompletedpercentage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeditationid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivesessionid(value);
      break;
    case 12:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (reader.readEnum());
      msg.setUseractivity(value);
      break;
    case 13:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (reader.readEnum());
      msg.setLogmeditationtype(value);
      break;
    case 14:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (reader.readEnum());
      msg.setMeditationcategory(value);
      break;
    case 15:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeid(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBadgecount(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartofday(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndofday(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.TrackerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.TrackerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TrackerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlaycount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPausecount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalseconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCompletedpercentage();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getMeditationid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getLivesessionid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUseractivity();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getLogmeditationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getMeditationcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getBadgeid();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getStartofday();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndofday();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationType meditationType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationType}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getMeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationType} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setMeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
*/
proto.unifiedplatform.gamification.TrackerInfo.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
*/
proto.unifiedplatform.gamification.TrackerInfo.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 playCount = 5;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getPlaycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setPlaycount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 pauseCount = 6;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getPausecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setPausecount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 totalSeconds = 7;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getTotalseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setTotalseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 completedPercentage = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getCompletedpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setCompletedpercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 userId = 9;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 meditationId = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getMeditationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setMeditationid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 liveSessionId = 11;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getLivesessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setLivesessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional unifiedplatform.heartintune.common.Activity userActivity = 12;
 * @return {!proto.unifiedplatform.heartintune.common.Activity}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getUseractivity = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.Activity} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.Activity} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setUseractivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional unifiedplatform.heartintune.common.LogMeditation logMeditationType = 13;
 * @return {!proto.unifiedplatform.heartintune.common.LogMeditation}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getLogmeditationtype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LogMeditation} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LogMeditation} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setLogmeditationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional unifiedplatform.heartintune.common.MeditationCategory meditationCategory = 14;
 * @return {!proto.unifiedplatform.heartintune.common.MeditationCategory}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getMeditationcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.MeditationCategory} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setMeditationcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 15;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int64 badgeId = 16;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getBadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 badgeCount = 17;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional google.protobuf.Timestamp startOfDay = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getStartofday = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
*/
proto.unifiedplatform.gamification.TrackerInfo.prototype.setStartofday = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.clearStartofday = function() {
  return this.setStartofday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.hasStartofday = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp endOfDay = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getEndofday = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
*/
proto.unifiedplatform.gamification.TrackerInfo.prototype.setEndofday = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.clearEndofday = function() {
  return this.setEndofday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.hasEndofday = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional int64 goalId = 20;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerInfo} returns this
 */
proto.unifiedplatform.gamification.TrackerInfo.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.TrackerWithBadge.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.TrackerWithBadge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.TrackerWithBadge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TrackerWithBadge.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackerinfo: (f = msg.getTrackerinfo()) && proto.unifiedplatform.gamification.TrackerInfo.toObject(includeInstance, f),
    badgeid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    badgename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badgeurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    goalcompletedstatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    donationpopup: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    goaltitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    badgecount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    badgedetailsv2List: jspb.Message.toObjectList(msg.getBadgedetailsv2List(),
    Badge_pb.BadgeDetailsV2.toObject, includeInstance),
    completedgoalid: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.TrackerWithBadge;
  return proto.unifiedplatform.gamification.TrackerWithBadge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.TrackerWithBadge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.TrackerInfo;
      reader.readMessage(value,proto.unifiedplatform.gamification.TrackerInfo.deserializeBinaryFromReader);
      msg.setTrackerinfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBadgeid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeurl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoalcompletedstatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDonationpopup(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoaltitle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBadgecount(value);
      break;
    case 9:
      var value = new Badge_pb.BadgeDetailsV2;
      reader.readMessage(value,Badge_pb.BadgeDetailsV2.deserializeBinaryFromReader);
      msg.addBadgedetailsv2(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedgoalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.TrackerWithBadge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.TrackerWithBadge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.TrackerWithBadge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackerinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.TrackerInfo.serializeBinaryToWriter
    );
  }
  f = message.getBadgeid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBadgename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadgeurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGoalcompletedstatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDonationpopup();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getGoaltitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBadgecount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getBadgedetailsv2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Badge_pb.BadgeDetailsV2.serializeBinaryToWriter
    );
  }
  f = message.getCompletedgoalid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional TrackerInfo trackerInfo = 1;
 * @return {?proto.unifiedplatform.gamification.TrackerInfo}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getTrackerinfo = function() {
  return /** @type{?proto.unifiedplatform.gamification.TrackerInfo} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.TrackerInfo, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.TrackerInfo|undefined} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
*/
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setTrackerinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.clearTrackerinfo = function() {
  return this.setTrackerinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.hasTrackerinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 badgeId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getBadgeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setBadgeid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string badgeName = 3;
 * @return {string}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getBadgename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setBadgename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string badgeURL = 4;
 * @return {string}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getBadgeurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setBadgeurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool goalCompletedStatus = 5;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getGoalcompletedstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setGoalcompletedstatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool donationPopup = 6;
 * @return {boolean}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getDonationpopup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setDonationpopup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string goalTitle = 7;
 * @return {string}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getGoaltitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setGoaltitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 badgeCount = 8;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getBadgecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setBadgecount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated badge.BadgeDetailsV2 badgeDetailsV2 = 9;
 * @return {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getBadgedetailsv2List = function() {
  return /** @type{!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, Badge_pb.BadgeDetailsV2, 9));
};


/**
 * @param {!Array<!proto.unifiedplatform.gamification.badge.BadgeDetailsV2>} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
*/
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setBadgedetailsv2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.badge.BadgeDetailsV2}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.addBadgedetailsv2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.unifiedplatform.gamification.badge.BadgeDetailsV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.clearBadgedetailsv2List = function() {
  return this.setBadgedetailsv2List([]);
};


/**
 * optional int64 completedGoalId = 10;
 * @return {number}
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.getCompletedgoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.TrackerWithBadge} returns this
 */
proto.unifiedplatform.gamification.TrackerWithBadge.prototype.setCompletedgoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.CustomGoalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.CustomGoalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.CustomGoalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    schedulev2: (f = msg.getSchedulev2()) && proto.unifiedplatform.gamification.RequestToScheduleGoal.toObject(includeInstance, f),
    goaldetails: (f = msg.getGoaldetails()) && proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.CustomGoalRequest}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.CustomGoalRequest;
  return proto.unifiedplatform.gamification.CustomGoalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.CustomGoalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.CustomGoalRequest}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.RequestToScheduleGoal;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestToScheduleGoal.deserializeBinaryFromReader);
      msg.setSchedulev2(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.AddNewGoalInfoV2Request;
      reader.readMessage(value,proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.deserializeBinaryFromReader);
      msg.setGoaldetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.CustomGoalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.CustomGoalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.CustomGoalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchedulev2();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.RequestToScheduleGoal.serializeBinaryToWriter
    );
  }
  f = message.getGoaldetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.AddNewGoalInfoV2Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestToScheduleGoal scheduleV2 = 1;
 * @return {?proto.unifiedplatform.gamification.RequestToScheduleGoal}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.getSchedulev2 = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestToScheduleGoal} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestToScheduleGoal, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestToScheduleGoal|undefined} value
 * @return {!proto.unifiedplatform.gamification.CustomGoalRequest} returns this
*/
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.setSchedulev2 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.CustomGoalRequest} returns this
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.clearSchedulev2 = function() {
  return this.setSchedulev2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.hasSchedulev2 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddNewGoalInfoV2Request goalDetails = 2;
 * @return {?proto.unifiedplatform.gamification.AddNewGoalInfoV2Request}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.getGoaldetails = function() {
  return /** @type{?proto.unifiedplatform.gamification.AddNewGoalInfoV2Request} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.AddNewGoalInfoV2Request, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.AddNewGoalInfoV2Request|undefined} value
 * @return {!proto.unifiedplatform.gamification.CustomGoalRequest} returns this
*/
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.setGoaldetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.CustomGoalRequest} returns this
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.clearGoaldetails = function() {
  return this.setGoaldetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.CustomGoalRequest.prototype.hasGoaldetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.CustomGoalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.CustomGoalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.CustomGoalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsegoalinfov2: (f = msg.getResponsegoalinfov2()) && proto.unifiedplatform.gamification.ResponseGoalInfoV2.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.unifiedplatform.gamification.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.CustomGoalResponse}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.CustomGoalResponse;
  return proto.unifiedplatform.gamification.CustomGoalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.CustomGoalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.CustomGoalResponse}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.gamification.ResponseGoalInfoV2;
      reader.readMessage(value,proto.unifiedplatform.gamification.ResponseGoalInfoV2.deserializeBinaryFromReader);
      msg.setResponsegoalinfov2(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.gamification.Status;
      reader.readMessage(value,proto.unifiedplatform.gamification.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.CustomGoalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.CustomGoalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.CustomGoalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsegoalinfov2();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.gamification.ResponseGoalInfoV2.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.gamification.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseGoalInfoV2 responseGoalInfoV2 = 1;
 * @return {?proto.unifiedplatform.gamification.ResponseGoalInfoV2}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.getResponsegoalinfov2 = function() {
  return /** @type{?proto.unifiedplatform.gamification.ResponseGoalInfoV2} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.ResponseGoalInfoV2, 1));
};


/**
 * @param {?proto.unifiedplatform.gamification.ResponseGoalInfoV2|undefined} value
 * @return {!proto.unifiedplatform.gamification.CustomGoalResponse} returns this
*/
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.setResponsegoalinfov2 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.CustomGoalResponse} returns this
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.clearResponsegoalinfov2 = function() {
  return this.setResponsegoalinfov2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.hasResponsegoalinfov2 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.unifiedplatform.gamification.Status}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.getStatus = function() {
  return /** @type{?proto.unifiedplatform.gamification.Status} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.Status, 2));
};


/**
 * @param {?proto.unifiedplatform.gamification.Status|undefined} value
 * @return {!proto.unifiedplatform.gamification.CustomGoalResponse} returns this
*/
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.CustomGoalResponse} returns this
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.CustomGoalResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.MeditationCategoryForDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.MeditationCategoryForDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    meditationcategorylistList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.MeditationCategoryForDate;
  return proto.unifiedplatform.gamification.MeditationCategoryForDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.MeditationCategoryForDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.unifiedplatform.heartintune.common.MeditationCategory>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMeditationcategorylist(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.MeditationCategoryForDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.MeditationCategoryForDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMeditationcategorylistList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate} returns this
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate} returns this
*/
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate} returns this
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated unifiedplatform.heartintune.common.MeditationCategory meditationCategoryList = 3;
 * @return {!Array<!proto.unifiedplatform.heartintune.common.MeditationCategory>}
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.getMeditationcategorylistList = function() {
  return /** @type {!Array<!proto.unifiedplatform.heartintune.common.MeditationCategory>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.unifiedplatform.heartintune.common.MeditationCategory>} value
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate} returns this
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.setMeditationcategorylistList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.MeditationCategory} value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate} returns this
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.addMeditationcategorylist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.gamification.MeditationCategoryForDate} returns this
 */
proto.unifiedplatform.gamification.MeditationCategoryForDate.prototype.clearMeditationcategorylistList = function() {
  return this.setMeditationcategorylistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestUserWeeklyStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestUserWeeklyStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentdate: (f = msg.getCurrentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    noofdays: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestUserWeeklyStats}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestUserWeeklyStats;
  return proto.unifiedplatform.gamification.RequestUserWeeklyStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestUserWeeklyStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestUserWeeklyStats}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentdate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoofdays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestUserWeeklyStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestUserWeeklyStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrentdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNoofdays();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 UserId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp currentDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.getCurrentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestUserWeeklyStats} returns this
*/
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.setCurrentdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.clearCurrentdate = function() {
  return this.setCurrentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.hasCurrentdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 noOfdays = 3;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.getNoofdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestUserWeeklyStats} returns this
 */
proto.unifiedplatform.gamification.RequestUserWeeklyStats.prototype.setNoofdays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest}
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest;
  return proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest}
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest} returns this
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest} returns this
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest} returns this
 */
proto.unifiedplatform.gamification.IdAndSelectedLanguageRequest.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.gamification.RequestBetweenDates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.gamification.RequestBetweenDates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestBetweenDates.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startdatetime: (f = msg.getStartdatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddatetime: (f = msg.getEnddatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.gamification.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.gamification.RequestBetweenDates;
  return proto.unifiedplatform.gamification.RequestBetweenDates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.gamification.RequestBetweenDates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdatetime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddatetime(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 5:
      var value = new proto.unifiedplatform.gamification.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.gamification.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.gamification.RequestBetweenDates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.gamification.RequestBetweenDates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.gamification.RequestBetweenDates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartdatetime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.unifiedplatform.gamification.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 userId = 1;
 * @return {number}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp startDateTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.getStartdatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
*/
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.setStartdatetime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.clearStartdatetime = function() {
  return this.setStartdatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.hasStartdatetime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDateTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.getEnddatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
*/
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.setEnddatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.clearEnddatetime = function() {
  return this.setEnddatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.hasEnddatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 4;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional RequestPageable pageable = 5;
 * @return {?proto.unifiedplatform.gamification.RequestPageable}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.gamification.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.gamification.RequestPageable, 5));
};


/**
 * @param {?proto.unifiedplatform.gamification.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
*/
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.gamification.RequestBetweenDates} returns this
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.gamification.RequestBetweenDates.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.unifiedplatform.gamification);
