// package: unifiedplatform.heartIntuneProfile
// file: heartintune-profile.proto

var heartintune_profile_pb = require("./heartintune-profile_pb");
var stats_pb = require("./stats_pb");
var journal_pb = require("./journal_pb");
var tracker_pb = require("./tracker_pb");
var notification_pb = require("./notification_pb");
var donation_pb = require("./donation_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var HeartIntuneProfileService = (function () {
  function HeartIntuneProfileService() {}
  HeartIntuneProfileService.serviceName = "unifiedplatform.heartIntuneProfile.HeartIntuneProfileService";
  return HeartIntuneProfileService;
}());

HeartIntuneProfileService.Test = {
  methodName: "Test",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.Empty,
  responseType: heartintune_profile_pb.TestResponse
};

HeartIntuneProfileService.ConnectUser = {
  methodName: "ConnectUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ConnectUserRequest,
  responseType: heartintune_profile_pb.ConnectUserResponse
};

HeartIntuneProfileService.TryUsNow = {
  methodName: "TryUsNow",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ConnectUserRequest,
  responseType: heartintune_profile_pb.ConnectUserResponse
};

HeartIntuneProfileService.UserLogin = {
  methodName: "UserLogin",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ConnectUserRequest,
  responseType: heartintune_profile_pb.ConnectUserResponse
};

HeartIntuneProfileService.UserLogout = {
  methodName: "UserLogout",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ConnectUserRequest,
  responseType: heartintune_profile_pb.ConnectUserResponse
};

HeartIntuneProfileService.UpdateUser = {
  methodName: "UpdateUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UpdateUserRequest,
  responseType: heartintune_profile_pb.User
};

HeartIntuneProfileService.UpdateUserLanguage = {
  methodName: "UpdateUserLanguage",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UpdateUserLanguageRequest,
  responseType: heartintune_profile_pb.User
};

HeartIntuneProfileService.setUserRole = {
  methodName: "setUserRole",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestSetUserRole,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetUserDetailsByID = {
  methodName: "GetUserDetailsByID",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.UserInfo
};

HeartIntuneProfileService.GetUserDetails = {
  methodName: "GetUserDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForGetUserDetails,
  responseType: heartintune_profile_pb.ListOfUserInfo
};

HeartIntuneProfileService.SearchUserByDetails = {
  methodName: "SearchUserByDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForSearchUserByDetails,
  responseType: heartintune_profile_pb.ResponseForSearchUserByDetails
};

HeartIntuneProfileService.UpdateStats = {
  methodName: "UpdateStats",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: stats_pb.MeditationStatsRequest,
  responseType: stats_pb.StatsUpdateStatus
};

HeartIntuneProfileService.GetStats = {
  methodName: "GetStats",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FetchStatsFor,
  responseType: heartintune_profile_pb.UserStatsInfo
};

HeartIntuneProfileService.UnregisterForLiveSession = {
  methodName: "UnregisterForLiveSession",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RegistrationDetails,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.UnregisterForLiveSessionAndGetEventIds = {
  methodName: "UnregisterForLiveSessionAndGetEventIds",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RegistrationDetails,
  responseType: heartintune_profile_pb.ResponseForUnRegisterLiveSessionId
};

HeartIntuneProfileService.GetFavourites = {
  methodName: "GetFavourites",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Favourites
};

HeartIntuneProfileService.SetFavourites = {
  methodName: "SetFavourites",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestSetFavourite,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SetUnFavourites = {
  methodName: "SetUnFavourites",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestSetFavourite,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetRegisteredLiveMeditationID = {
  methodName: "GetRegisteredLiveMeditationID",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForRegisterLiveMeditationId,
  responseType: heartintune_profile_pb.ListOfIDs
};

HeartIntuneProfileService.GetCompletedLiveMeditationID = {
  methodName: "GetCompletedLiveMeditationID",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.ListOfIDs
};

HeartIntuneProfileService.GetCompletedLiveMeditationIDByPageable = {
  methodName: "GetCompletedLiveMeditationIDByPageable",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserIDByPageable,
  responseType: heartintune_profile_pb.ResponseListOfIDs
};

HeartIntuneProfileService.StartGoal = {
  methodName: "StartGoal",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestSubscribeToGoal,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.UpdateGoal = {
  methodName: "UpdateGoal",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUpdateToGoal,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetListOfGoalsInProgress = {
  methodName: "GetListOfGoalsInProgress",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.ListOfIDs
};

HeartIntuneProfileService.AllocateBadgeToUser = {
  methodName: "AllocateBadgeToUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SetProgressGuidedMeditation = {
  methodName: "SetProgressGuidedMeditation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUsersWithMeditation,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SetCompletedGuidedMeditation = {
  methodName: "SetCompletedGuidedMeditation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUsersWithMeditation,
  responseType: heartintune_profile_pb.ResponseCompletedLiveMeditation
};

HeartIntuneProfileService.RegisterForCompletedLiveMeditation = {
  methodName: "RegisterForCompletedLiveMeditation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RegistrationDetails,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.RegisterForLiveMeditation = {
  methodName: "RegisterForLiveMeditation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RegistrationDetails,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.RegisterForLiveMeditationWithEventIds = {
  methodName: "RegisterForLiveMeditationWithEventIds",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForRegistrationDetails,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.AddJournal = {
  methodName: "AddJournal",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.RequestJournal,
  responseType: journal_pb.JournalInfo
};

HeartIntuneProfileService.UpdateJournal = {
  methodName: "UpdateJournal",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.JournalInfo,
  responseType: journal_pb.JournalInfo
};

HeartIntuneProfileService.ToggleJournalFavouriteStatus = {
  methodName: "ToggleJournalFavouriteStatus",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.RequestJournalFavoriteStatus,
  responseType: journal_pb.JournalInfo
};

HeartIntuneProfileService.ToggleJournalArchiveStatus = {
  methodName: "ToggleJournalArchiveStatus",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.RequestJournalArchiveStatus,
  responseType: journal_pb.JournalInfo
};

HeartIntuneProfileService.DeleteJournal = {
  methodName: "DeleteJournal",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.RequestJournalId,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetJournalByDateRange = {
  methodName: "GetJournalByDateRange",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.RequestJournalDateRange,
  responseType: journal_pb.ListOfJournals
};

HeartIntuneProfileService.GetAllJournalsByUserId = {
  methodName: "GetAllJournalsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: journal_pb.ListOfJournals
};

HeartIntuneProfileService.AddUserTrackerDetails = {
  methodName: "AddUserTrackerDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: tracker_pb.RequestTrackerInfo,
  responseType: heartintune_profile_pb.TrackerWithBadge
};

HeartIntuneProfileService.UpdateUserTrackerDetails = {
  methodName: "UpdateUserTrackerDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: tracker_pb.TrackerInfo,
  responseType: heartintune_profile_pb.TrackerWithBadge
};

HeartIntuneProfileService.GetStatsByUserId = {
  methodName: "GetStatsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.ResponseUserStats
};

HeartIntuneProfileService.GetWeeklyStatsByUserId = {
  methodName: "GetWeeklyStatsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserWeeklyStats,
  responseType: heartintune_profile_pb.ResponseUserWeeklyStats
};

HeartIntuneProfileService.GetMonthlyStatsByUserId = {
  methodName: "GetMonthlyStatsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserWeeklyStats,
  responseType: heartintune_profile_pb.ResponseUserMonthlyStats
};

HeartIntuneProfileService.GetAllBadgesByUserId = {
  methodName: "GetAllBadgesByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.ResponseBadgesByUserId
};

HeartIntuneProfileService.GetAllBadgesV2ByUserId = {
  methodName: "GetAllBadgesV2ByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.ResponseBadgesByUserId
};

HeartIntuneProfileService.SaveDonation = {
  methodName: "SaveDonation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.SaveDonationRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.DeleteUserAccount = {
  methodName: "DeleteUserAccount",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.DeleteUserAccountFromFireBase = {
  methodName: "DeleteUserAccountFromFireBase",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForDeleteUserAccountFromFireBase,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SendUserJournalsToEmail = {
  methodName: "SendUserJournalsToEmail",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.RequestJournalDateRange,
  responseType: heartintune_profile_pb.Empty
};

HeartIntuneProfileService.GetGoalByUserId = {
  methodName: "GetGoalByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestGoalByUserId,
  responseType: heartintune_profile_pb.ResponseScheduleGoal
};

HeartIntuneProfileService.GetDonationDetailsByUserId = {
  methodName: "GetDonationDetailsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: donation_pb.DonationResponseUserDetails
};

HeartIntuneProfileService.GetUserDetailsByEmail = {
  methodName: "GetUserDetailsByEmail",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserDetailsByEmail,
  responseType: heartintune_profile_pb.ResponseUserDetailsByEmail
};

HeartIntuneProfileService.DeleteScheduleData = {
  methodName: "DeleteScheduleData",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForDeleteSchedule,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.DeleteUserRegisteredLiveSessionId = {
  methodName: "DeleteUserRegisteredLiveSessionId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForDeleteLiveSessionId,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.ClearUserDataForDeletedLiveSessions = {
  methodName: "ClearUserDataForDeletedLiveSessions",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForClearUserDataForDeletedLiveSessions,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.HotFixMigrationsForMobileUsers = {
  methodName: "HotFixMigrationsForMobileUsers",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForHotFixMigrationsForMobileUsers,
  responseType: heartintune_profile_pb.ResponseForHotFixMigrationsForMobileUsers
};

HeartIntuneProfileService.SendQuestionsToCoachEmail = {
  methodName: "SendQuestionsToCoachEmail",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestForSendEmailToCoachWithQuestionsAndUserRegisterCount,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.CancelLiveSessionById = {
  methodName: "CancelLiveSessionById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SendUserNotificationFromHeartInTune = {
  methodName: "SendUserNotificationFromHeartInTune",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.HeartInTuneNotificationRequest,
  responseType: notification_pb.DeviceNotificationResponse
};

HeartIntuneProfileService.GetAllUsersByListofIds = {
  methodName: "GetAllUsersByListofIds",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ListOfIDs,
  responseType: heartintune_profile_pb.ListOfUserInfo
};

HeartIntuneProfileService.SendTopicNotification = {
  methodName: "SendTopicNotification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: notification_pb.TopicNotificationRequest,
  responseType: notification_pb.TopicNotificationResponse
};

HeartIntuneProfileService.SendDeviceNotification = {
  methodName: "SendDeviceNotification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: notification_pb.DeviceNotificationRequest,
  responseType: notification_pb.DeviceNotificationResponse
};

HeartIntuneProfileService.SendUserNotification = {
  methodName: "SendUserNotification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: notification_pb.UserNotificationRequest,
  responseType: notification_pb.UserNotificationResponse
};

HeartIntuneProfileService.SubscribeToTopic = {
  methodName: "SubscribeToTopic",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: notification_pb.TopicSubscribeRequest,
  responseType: notification_pb.TopicSubscribeResponse
};

HeartIntuneProfileService.UnSubscribeFromTopic = {
  methodName: "UnSubscribeFromTopic",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: notification_pb.TopicUnsubscribeRequest,
  responseType: notification_pb.TopicUnsubscribeResponse
};

HeartIntuneProfileService.SendEmailNotification = {
  methodName: "SendEmailNotification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: notification_pb.EmailNotificationRequest,
  responseType: notification_pb.EmailNotificationResponse
};

HeartIntuneProfileService.DonateUsingHeartInTuneApp = {
  methodName: "DonateUsingHeartInTuneApp",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.DonationInfoRequest,
  responseType: donation_pb.DonateResponse
};

HeartIntuneProfileService.getAllUsersCount = {
  methodName: "getAllUsersCount",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.Empty,
  responseType: heartintune_profile_pb.totalUsers
};

HeartIntuneProfileService.PublishAnnouncementInBhandara = {
  methodName: "PublishAnnouncementInBhandara",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.AnnouncementRequest,
  responseType: heartintune_profile_pb.AnnouncementDetails
};

HeartIntuneProfileService.GetAllNotificationsByUserId = {
  methodName: "GetAllNotificationsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserIDByPageable,
  responseType: heartintune_profile_pb.ListOfNotificationPageableResponse
};

HeartIntuneProfileService.UpdateNotificationReadById = {
  methodName: "UpdateNotificationReadById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ReadNotificationRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.ScheduleGoalV2 = {
  methodName: "ScheduleGoalV2",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestToScheduleGoal,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.AddLiveSessionCalendarEventId = {
  methodName: "AddLiveSessionCalendarEventId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.AddLiveSessionCalendarEventIdRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetAllMySignUpV2 = {
  methodName: "GetAllMySignUpV2",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestV2GetAllMySignUp,
  responseType: heartintune_profile_pb.ResponseV2GetAllMySignUp
};

HeartIntuneProfileService.GetLiveSessionDetailsV2ByCoachId = {
  methodName: "GetLiveSessionDetailsV2ByCoachId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.LiveSessionDetailsAndUserRegistrationStatusRequest,
  responseType: heartintune_profile_pb.LiveSessionDetailsAndUserRegistrationStatusResponse
};

HeartIntuneProfileService.ScriptForSubscribingToTopicByAllHeartInTuneUsersOrSingleUser = {
  methodName: "ScriptForSubscribingToTopicByAllHeartInTuneUsersOrSingleUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.SubscribeToTopicByAllUsers,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SaveCarousel = {
  methodName: "SaveCarousel",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.CarouselDetails,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetCarousel = {
  methodName: "GetCarousel",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.Empty,
  responseType: heartintune_profile_pb.CarouselDetails
};

HeartIntuneProfileService.SendOTPForEmailVerification = {
  methodName: "SendOTPForEmailVerification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestToGenerateOtpForEmail,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.ValidatedEmailOTP = {
  methodName: "ValidatedEmailOTP",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestOtpForEmailValidation,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.UpdateUserTrackerDetailsV2 = {
  methodName: "UpdateUserTrackerDetailsV2",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: tracker_pb.TrackerInfo,
  responseType: heartintune_profile_pb.TrackerWithBadge
};

HeartIntuneProfileService.AddStayConnectedWithUsDetails = {
  methodName: "AddStayConnectedWithUsDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.StayConnectedWithUsRequest,
  responseType: heartintune_profile_pb.StayConnectedWithUsDetails
};

HeartIntuneProfileService.UpdateStayConnectedWithUsDetails = {
  methodName: "UpdateStayConnectedWithUsDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.StayConnectedWithUsDetails,
  responseType: heartintune_profile_pb.StayConnectedWithUsDetails
};

HeartIntuneProfileService.RateLiveMeditationSessionV2 = {
  methodName: "RateLiveMeditationSessionV2",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.LiveSessionRatingRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetAllGoalStatusByUser = {
  methodName: "GetAllGoalStatusByUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GetAllGoalStatusByUserRequest,
  responseType: heartintune_profile_pb.GetAllGoalStatusByUserResponse
};

HeartIntuneProfileService.GetGoalCountByUser = {
  methodName: "GetGoalCountByUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.GetGoalCountByUserResponse
};

HeartIntuneProfileService.GetUserAcceptedGoalIds = {
  methodName: "GetUserAcceptedGoalIds",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GetAllGoalStatusByUserRequest,
  responseType: heartintune_profile_pb.ListOfIDs
};

HeartIntuneProfileService.EndGoalById = {
  methodName: "EndGoalById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetInProgressGoalCardForHomePage = {
  methodName: "GetInProgressGoalCardForHomePage",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestV2GetAllMySignUp,
  responseType: heartintune_profile_pb.ListOfGetInProgressGoalCardForHomePage
};

HeartIntuneProfileService.UserMoodSelected = {
  methodName: "UserMoodSelected",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.AddUserMoodRequest,
  responseType: heartintune_profile_pb.UserMoodDetails
};

HeartIntuneProfileService.GetUserMoodBySpecifiedTime = {
  methodName: "GetUserMoodBySpecifiedTime",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserMoodRequestBySpecifiedTime,
  responseType: heartintune_profile_pb.UserMoodResponseBySpecifiedTime
};

HeartIntuneProfileService.DeleteUserMoodBySpecifiedTime = {
  methodName: "DeleteUserMoodBySpecifiedTime",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserMoodRequestBySpecifiedTime,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetMoodCheckInsWithPercent = {
  methodName: "GetMoodCheckInsWithPercent",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserMoodRequestBySpecifiedTime,
  responseType: heartintune_profile_pb.MoodCheckInsWithPercentResponse
};

HeartIntuneProfileService.AddDailyReminder = {
  methodName: "AddDailyReminder",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ReminderRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.EditDailyReminder = {
  methodName: "EditDailyReminder",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ReminderDetails,
  responseType: heartintune_profile_pb.ReminderDetails
};

HeartIntuneProfileService.ShowDailyReminder = {
  methodName: "ShowDailyReminder",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ReminderTypeRequest,
  responseType: heartintune_profile_pb.ReminderDetails
};

HeartIntuneProfileService.DeleteDailyReminder = {
  methodName: "DeleteDailyReminder",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ReminderTypeRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SaveUserPreferredBackground = {
  methodName: "SaveUserPreferredBackground",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserPreferredBackgroundRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetUserPreferredBackground = {
  methodName: "GetUserPreferredBackground",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserIdRequest,
  responseType: heartintune_profile_pb.UserPreferredBackgroundResponse
};

HeartIntuneProfileService.AddFavoriteContent = {
  methodName: "AddFavoriteContent",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FavoriteRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetAllFavoriteContentByUser = {
  methodName: "GetAllFavoriteContentByUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FavoriteRequest,
  responseType: heartintune_profile_pb.ListOfContentLocaleResponse
};

HeartIntuneProfileService.UnRegisteredFavoriteContent = {
  methodName: "UnRegisteredFavoriteContent",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FavoriteRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.IsFavoriteContentExistOrNotForUser = {
  methodName: "IsFavoriteContentExistOrNotForUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FavoriteContentIsExistOrNotForUserRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SaveUserPersonalityQuiz = {
  methodName: "SaveUserPersonalityQuiz",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserPersonalityRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.UpdateUserPersonalityQuiz = {
  methodName: "UpdateUserPersonalityQuiz",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserPersonalityRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.DeleteUserPersonalityQuizForAdmin = {
  methodName: "DeleteUserPersonalityQuizForAdmin",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.getAllPersonalityQuizByUser = {
  methodName: "getAllPersonalityQuizByUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GetAllUserPersonalityQuizRequest,
  responseType: heartintune_profile_pb.ListOfHeartInTunePersonalityQuizLocale
};

HeartIntuneProfileService.LogMeditation = {
  methodName: "LogMeditation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: tracker_pb.TrackerInfo,
  responseType: heartintune_profile_pb.TrackerWithBadge
};

HeartIntuneProfileService.GetUserCompletedMeditationCount = {
  methodName: "GetUserCompletedMeditationCount",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestV2GetAllMySignUp,
  responseType: heartintune_profile_pb.totalUsers
};

HeartIntuneProfileService.SendEmailToHeartspotTeam = {
  methodName: "SendEmailToHeartspotTeam",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.EmailHeartspotRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetQuoteByUserActivity = {
  methodName: "GetQuoteByUserActivity",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.QuoteActivityRequest,
  responseType: heartintune_profile_pb.QuotesResponse
};

HeartIntuneProfileService.DeleteUserMoodById = {
  methodName: "DeleteUserMoodById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.UpdateJournalForUserMood = {
  methodName: "UpdateJournalForUserMood",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserMoodJournalRequest,
  responseType: heartintune_profile_pb.UserMoodDetails
};

HeartIntuneProfileService.DeleteContentByIdAndCategory = {
  methodName: "DeleteContentByIdAndCategory",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FavoriteContentIsExistOrNotForUserRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.RegisterAndJoinActionsForUserGroups = {
  methodName: "RegisterAndJoinActionsForUserGroups",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserGroupsRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.ListOfUserRegisterGroupIds = {
  methodName: "ListOfUserRegisterGroupIds",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.TimeWithIdRequest,
  responseType: heartintune_profile_pb.ListOfGroupIDsResponse
};

HeartIntuneProfileService.DeleteGroupById = {
  methodName: "DeleteGroupById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetGroupByUser = {
  methodName: "GetGroupByUser",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserIdRequest,
  responseType: heartintune_profile_pb.GroupsResponseHeartInTune
};

HeartIntuneProfileService.GetUserInfoByGroupId = {
  methodName: "GetUserInfoByGroupId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FetchUserByGroupIdRequest,
  responseType: heartintune_profile_pb.ListOfUserInfo
};

HeartIntuneProfileService.UpdateGroupById = {
  methodName: "UpdateGroupById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GroupsResponseHeartInTune,
  responseType: heartintune_profile_pb.GroupsResponseHeartInTune
};

HeartIntuneProfileService.UnPublishGroupById = {
  methodName: "UnPublishGroupById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.FetchUserByGroupIdRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetUpcomingGroupsByUserId = {
  methodName: "GetUpcomingGroupsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserGroupsRequest,
  responseType: heartintune_profile_pb.ListOfGroupIDsResponse
};

HeartIntuneProfileService.UpdateCircle = {
  methodName: "UpdateCircle",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.CircleResponse,
  responseType: heartintune_profile_pb.CircleResponse
};

HeartIntuneProfileService.CreateCircleActivity = {
  methodName: "CreateCircleActivity",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.CircleActivityRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetCircleNameByUserId = {
  methodName: "GetCircleNameByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.CircleResponse
};

HeartIntuneProfileService.UpdateCircleActivity = {
  methodName: "UpdateCircleActivity",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.CircleActivityRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.DeleteCircleActivity = {
  methodName: "DeleteCircleActivity",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.LeaveCircleActivity = {
  methodName: "LeaveCircleActivity",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.DeleteCircle = {
  methodName: "DeleteCircle",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.CircleInvitation = {
  methodName: "CircleInvitation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.CircleInvitationRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.UserActionForCircleInvitation = {
  methodName: "UserActionForCircleInvitation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserActionForCircleInvitationRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetAllNotificationRequestByUserId = {
  methodName: "GetAllNotificationRequestByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserIDByPageable,
  responseType: heartintune_profile_pb.ListOfNotificationRequestAlertsDetails
};

HeartIntuneProfileService.SearchUserDetails = {
  methodName: "SearchUserDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.SearchUserByDetailsRequest,
  responseType: heartintune_profile_pb.ResponseForSearchUserByDetails
};

HeartIntuneProfileService.GetUsersFromMyCircle = {
  methodName: "GetUsersFromMyCircle",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserIDByPageable,
  responseType: heartintune_profile_pb.GetUsersFromMyCircleResponse
};

HeartIntuneProfileService.CircleActivityInvitation = {
  methodName: "CircleActivityInvitation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.CircleInvitationRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.EditParticipantsForCircleInvitation = {
  methodName: "EditParticipantsForCircleInvitation",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.EditCircleOrActivityInvitationRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetMyUpcomingActivityDetails = {
  methodName: "GetMyUpcomingActivityDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GetUpcomingActivityDetailsRequest,
  responseType: heartintune_profile_pb.ListOfCircleActivityDetailsResponse
};

HeartIntuneProfileService.GetMyAcceptedUpcomingActivityDetails = {
  methodName: "GetMyAcceptedUpcomingActivityDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GetUpcomingActivityDetailsRequest,
  responseType: heartintune_profile_pb.ListOfCircleActivityDetailsResponse
};

HeartIntuneProfileService.GetActivityDetailsByIDWithLanguageSupport = {
  methodName: "GetActivityDetailsByIDWithLanguageSupport",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.UserIdRequest,
  responseType: heartintune_profile_pb.CircleActivityDetails
};

HeartIntuneProfileService.clearFcmTokens = {
  methodName: "clearFcmTokens",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.repeatedFcmToken,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetUserScheduledInfo = {
  methodName: "GetUserScheduledInfo",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserScheduleInfo,
  responseType: heartintune_profile_pb.UserScheduledInfo
};

HeartIntuneProfileService.GcpCreateTopic = {
  methodName: "GcpCreateTopic",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GcpPubSubRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GcpPublishTopic = {
  methodName: "GcpPublishTopic",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GcpPubSubRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GcpCreateSubscriptionAndSubscribeToTopic = {
  methodName: "GcpCreateSubscriptionAndSubscribeToTopic",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GcpPubSubRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GcpDeleteSubscription = {
  methodName: "GcpDeleteSubscription",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GcpPubSubRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.UpdateFcmToken = {
  methodName: "UpdateFcmToken",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetMySRCMUserDetails = {
  methodName: "GetMySRCMUserDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.MYSRCMDetails
};

HeartIntuneProfileService.SaveListOfJournals = {
  methodName: "SaveListOfJournals",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: journal_pb.SaveListOfJournalsRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SaveMeditationReminderDetails = {
  methodName: "SaveMeditationReminderDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.MeditationReminderDetails,
  responseType: heartintune_profile_pb.MeditationReminderDetails
};

HeartIntuneProfileService.ValidaUserAcceptedGoalAndMoveToMissedGoal = {
  methodName: "ValidaUserAcceptedGoalAndMoveToMissedGoal",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.ID,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.AddListOfUsersTrackerDetails = {
  methodName: "AddListOfUsersTrackerDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.AddAndUpdateListOfUsersTrackerDetails,
  responseType: heartintune_profile_pb.AddAndUpdateListOfUsersTrackerDetails
};

HeartIntuneProfileService.UpdateListOfUsersTrackerDetails = {
  methodName: "UpdateListOfUsersTrackerDetails",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.AddAndUpdateListOfUsersTrackerDetails,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetCountOnDiscoverByUserId = {
  methodName: "GetCountOnDiscoverByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.DiscoverCountResponse
};

HeartIntuneProfileService.ClearAllNotificationsByUserId = {
  methodName: "ClearAllNotificationsByUserId",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.TimeWithIdRequest,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.SeekerToPractitioner = {
  methodName: "SeekerToPractitioner",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.MySRCMPractitioner,
  responseType: heartintune_profile_pb.Status
};

HeartIntuneProfileService.GetUserById = {
  methodName: "GetUserById",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.RequestUserID,
  responseType: heartintune_profile_pb.User
};

HeartIntuneProfileService.SaveUserNotification = {
  methodName: "SaveUserNotification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.NotificationPageableResponse,
  responseType: heartintune_profile_pb.Empty
};

HeartIntuneProfileService.TrackerCallFromGamification = {
  methodName: "TrackerCallFromGamification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: tracker_pb.TrackerRequestFromGamification,
  responseType: tracker_pb.TrackerResponseForGamification
};

HeartIntuneProfileService.SendGoalsNotification = {
  methodName: "SendGoalsNotification",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: heartintune_profile_pb.GoalSchedulesNotifications,
  responseType: heartintune_profile_pb.Empty
};

HeartIntuneProfileService.SaveOrUpdateDonationProfile = {
  methodName: "SaveOrUpdateDonationProfile",
  service: HeartIntuneProfileService,
  requestStream: false,
  responseStream: false,
  requestType: donation_pb.UserProfile,
  responseType: heartintune_profile_pb.Status
};

exports.HeartIntuneProfileService = HeartIntuneProfileService;

function HeartIntuneProfileServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

HeartIntuneProfileServiceClient.prototype.test = function test(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.Test, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.connectUser = function connectUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ConnectUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.tryUsNow = function tryUsNow(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.TryUsNow, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.userLogin = function userLogin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UserLogin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.userLogout = function userLogout(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UserLogout, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateUser = function updateUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateUserLanguage = function updateUserLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateUserLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.setUserRole = function setUserRole(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.setUserRole, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserDetailsByID = function getUserDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserDetails = function getUserDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.searchUserByDetails = function searchUserByDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SearchUserByDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateStats = function updateStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getStats = function getStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.unregisterForLiveSession = function unregisterForLiveSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UnregisterForLiveSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.unregisterForLiveSessionAndGetEventIds = function unregisterForLiveSessionAndGetEventIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UnregisterForLiveSessionAndGetEventIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getFavourites = function getFavourites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetFavourites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.setFavourites = function setFavourites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SetFavourites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.setUnFavourites = function setUnFavourites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SetUnFavourites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getRegisteredLiveMeditationID = function getRegisteredLiveMeditationID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetRegisteredLiveMeditationID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getCompletedLiveMeditationID = function getCompletedLiveMeditationID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetCompletedLiveMeditationID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getCompletedLiveMeditationIDByPageable = function getCompletedLiveMeditationIDByPageable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetCompletedLiveMeditationIDByPageable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.startGoal = function startGoal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.StartGoal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateGoal = function updateGoal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateGoal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getListOfGoalsInProgress = function getListOfGoalsInProgress(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetListOfGoalsInProgress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.allocateBadgeToUser = function allocateBadgeToUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AllocateBadgeToUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.setProgressGuidedMeditation = function setProgressGuidedMeditation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SetProgressGuidedMeditation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.setCompletedGuidedMeditation = function setCompletedGuidedMeditation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SetCompletedGuidedMeditation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.registerForCompletedLiveMeditation = function registerForCompletedLiveMeditation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.RegisterForCompletedLiveMeditation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.registerForLiveMeditation = function registerForLiveMeditation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.RegisterForLiveMeditation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.registerForLiveMeditationWithEventIds = function registerForLiveMeditationWithEventIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.RegisterForLiveMeditationWithEventIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.addJournal = function addJournal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AddJournal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateJournal = function updateJournal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateJournal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.toggleJournalFavouriteStatus = function toggleJournalFavouriteStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ToggleJournalFavouriteStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.toggleJournalArchiveStatus = function toggleJournalArchiveStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ToggleJournalArchiveStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteJournal = function deleteJournal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteJournal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getJournalByDateRange = function getJournalByDateRange(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetJournalByDateRange, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllJournalsByUserId = function getAllJournalsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllJournalsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.addUserTrackerDetails = function addUserTrackerDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AddUserTrackerDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateUserTrackerDetails = function updateUserTrackerDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateUserTrackerDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getStatsByUserId = function getStatsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetStatsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getWeeklyStatsByUserId = function getWeeklyStatsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetWeeklyStatsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getMonthlyStatsByUserId = function getMonthlyStatsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetMonthlyStatsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllBadgesByUserId = function getAllBadgesByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllBadgesByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllBadgesV2ByUserId = function getAllBadgesV2ByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllBadgesV2ByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveDonation = function saveDonation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveDonation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteUserAccount = function deleteUserAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteUserAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteUserAccountFromFireBase = function deleteUserAccountFromFireBase(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteUserAccountFromFireBase, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendUserJournalsToEmail = function sendUserJournalsToEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendUserJournalsToEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getGoalByUserId = function getGoalByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetGoalByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getDonationDetailsByUserId = function getDonationDetailsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetDonationDetailsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserDetailsByEmail = function getUserDetailsByEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserDetailsByEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteScheduleData = function deleteScheduleData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteScheduleData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteUserRegisteredLiveSessionId = function deleteUserRegisteredLiveSessionId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteUserRegisteredLiveSessionId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.clearUserDataForDeletedLiveSessions = function clearUserDataForDeletedLiveSessions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ClearUserDataForDeletedLiveSessions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.hotFixMigrationsForMobileUsers = function hotFixMigrationsForMobileUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.HotFixMigrationsForMobileUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendQuestionsToCoachEmail = function sendQuestionsToCoachEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendQuestionsToCoachEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.cancelLiveSessionById = function cancelLiveSessionById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.CancelLiveSessionById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendUserNotificationFromHeartInTune = function sendUserNotificationFromHeartInTune(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendUserNotificationFromHeartInTune, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllUsersByListofIds = function getAllUsersByListofIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllUsersByListofIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendTopicNotification = function sendTopicNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendTopicNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendDeviceNotification = function sendDeviceNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendDeviceNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendUserNotification = function sendUserNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendUserNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.subscribeToTopic = function subscribeToTopic(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SubscribeToTopic, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.unSubscribeFromTopic = function unSubscribeFromTopic(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UnSubscribeFromTopic, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendEmailNotification = function sendEmailNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendEmailNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.donateUsingHeartInTuneApp = function donateUsingHeartInTuneApp(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DonateUsingHeartInTuneApp, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllUsersCount = function getAllUsersCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.getAllUsersCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.publishAnnouncementInBhandara = function publishAnnouncementInBhandara(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.PublishAnnouncementInBhandara, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllNotificationsByUserId = function getAllNotificationsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllNotificationsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateNotificationReadById = function updateNotificationReadById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateNotificationReadById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.scheduleGoalV2 = function scheduleGoalV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ScheduleGoalV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.addLiveSessionCalendarEventId = function addLiveSessionCalendarEventId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AddLiveSessionCalendarEventId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllMySignUpV2 = function getAllMySignUpV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllMySignUpV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getLiveSessionDetailsV2ByCoachId = function getLiveSessionDetailsV2ByCoachId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetLiveSessionDetailsV2ByCoachId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.scriptForSubscribingToTopicByAllHeartInTuneUsersOrSingleUser = function scriptForSubscribingToTopicByAllHeartInTuneUsersOrSingleUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ScriptForSubscribingToTopicByAllHeartInTuneUsersOrSingleUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveCarousel = function saveCarousel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveCarousel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getCarousel = function getCarousel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetCarousel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendOTPForEmailVerification = function sendOTPForEmailVerification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendOTPForEmailVerification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.validatedEmailOTP = function validatedEmailOTP(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ValidatedEmailOTP, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateUserTrackerDetailsV2 = function updateUserTrackerDetailsV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateUserTrackerDetailsV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.addStayConnectedWithUsDetails = function addStayConnectedWithUsDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AddStayConnectedWithUsDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateStayConnectedWithUsDetails = function updateStayConnectedWithUsDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateStayConnectedWithUsDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.rateLiveMeditationSessionV2 = function rateLiveMeditationSessionV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.RateLiveMeditationSessionV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllGoalStatusByUser = function getAllGoalStatusByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllGoalStatusByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getGoalCountByUser = function getGoalCountByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetGoalCountByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserAcceptedGoalIds = function getUserAcceptedGoalIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserAcceptedGoalIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.endGoalById = function endGoalById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.EndGoalById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getInProgressGoalCardForHomePage = function getInProgressGoalCardForHomePage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetInProgressGoalCardForHomePage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.userMoodSelected = function userMoodSelected(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UserMoodSelected, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserMoodBySpecifiedTime = function getUserMoodBySpecifiedTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserMoodBySpecifiedTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteUserMoodBySpecifiedTime = function deleteUserMoodBySpecifiedTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteUserMoodBySpecifiedTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getMoodCheckInsWithPercent = function getMoodCheckInsWithPercent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetMoodCheckInsWithPercent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.addDailyReminder = function addDailyReminder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AddDailyReminder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.editDailyReminder = function editDailyReminder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.EditDailyReminder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.showDailyReminder = function showDailyReminder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ShowDailyReminder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteDailyReminder = function deleteDailyReminder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteDailyReminder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveUserPreferredBackground = function saveUserPreferredBackground(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveUserPreferredBackground, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserPreferredBackground = function getUserPreferredBackground(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserPreferredBackground, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.addFavoriteContent = function addFavoriteContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AddFavoriteContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllFavoriteContentByUser = function getAllFavoriteContentByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllFavoriteContentByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.unRegisteredFavoriteContent = function unRegisteredFavoriteContent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UnRegisteredFavoriteContent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.isFavoriteContentExistOrNotForUser = function isFavoriteContentExistOrNotForUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.IsFavoriteContentExistOrNotForUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveUserPersonalityQuiz = function saveUserPersonalityQuiz(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveUserPersonalityQuiz, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateUserPersonalityQuiz = function updateUserPersonalityQuiz(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateUserPersonalityQuiz, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteUserPersonalityQuizForAdmin = function deleteUserPersonalityQuizForAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteUserPersonalityQuizForAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllPersonalityQuizByUser = function getAllPersonalityQuizByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.getAllPersonalityQuizByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.logMeditation = function logMeditation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.LogMeditation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserCompletedMeditationCount = function getUserCompletedMeditationCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserCompletedMeditationCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendEmailToHeartspotTeam = function sendEmailToHeartspotTeam(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendEmailToHeartspotTeam, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getQuoteByUserActivity = function getQuoteByUserActivity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetQuoteByUserActivity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteUserMoodById = function deleteUserMoodById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteUserMoodById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateJournalForUserMood = function updateJournalForUserMood(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateJournalForUserMood, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteContentByIdAndCategory = function deleteContentByIdAndCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteContentByIdAndCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.registerAndJoinActionsForUserGroups = function registerAndJoinActionsForUserGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.RegisterAndJoinActionsForUserGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.listOfUserRegisterGroupIds = function listOfUserRegisterGroupIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ListOfUserRegisterGroupIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteGroupById = function deleteGroupById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteGroupById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getGroupByUser = function getGroupByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetGroupByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserInfoByGroupId = function getUserInfoByGroupId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserInfoByGroupId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateGroupById = function updateGroupById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateGroupById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.unPublishGroupById = function unPublishGroupById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UnPublishGroupById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUpcomingGroupsByUserId = function getUpcomingGroupsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUpcomingGroupsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateCircle = function updateCircle(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateCircle, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.createCircleActivity = function createCircleActivity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.CreateCircleActivity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getCircleNameByUserId = function getCircleNameByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetCircleNameByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateCircleActivity = function updateCircleActivity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateCircleActivity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteCircleActivity = function deleteCircleActivity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteCircleActivity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.leaveCircleActivity = function leaveCircleActivity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.LeaveCircleActivity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.deleteCircle = function deleteCircle(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.DeleteCircle, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.circleInvitation = function circleInvitation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.CircleInvitation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.userActionForCircleInvitation = function userActionForCircleInvitation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UserActionForCircleInvitation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getAllNotificationRequestByUserId = function getAllNotificationRequestByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetAllNotificationRequestByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.searchUserDetails = function searchUserDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SearchUserDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUsersFromMyCircle = function getUsersFromMyCircle(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUsersFromMyCircle, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.circleActivityInvitation = function circleActivityInvitation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.CircleActivityInvitation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.editParticipantsForCircleInvitation = function editParticipantsForCircleInvitation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.EditParticipantsForCircleInvitation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getMyUpcomingActivityDetails = function getMyUpcomingActivityDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetMyUpcomingActivityDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getMyAcceptedUpcomingActivityDetails = function getMyAcceptedUpcomingActivityDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetMyAcceptedUpcomingActivityDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getActivityDetailsByIDWithLanguageSupport = function getActivityDetailsByIDWithLanguageSupport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetActivityDetailsByIDWithLanguageSupport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.clearFcmTokens = function clearFcmTokens(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.clearFcmTokens, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserScheduledInfo = function getUserScheduledInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserScheduledInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.gcpCreateTopic = function gcpCreateTopic(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GcpCreateTopic, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.gcpPublishTopic = function gcpPublishTopic(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GcpPublishTopic, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.gcpCreateSubscriptionAndSubscribeToTopic = function gcpCreateSubscriptionAndSubscribeToTopic(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GcpCreateSubscriptionAndSubscribeToTopic, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.gcpDeleteSubscription = function gcpDeleteSubscription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GcpDeleteSubscription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateFcmToken = function updateFcmToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateFcmToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getMySRCMUserDetails = function getMySRCMUserDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetMySRCMUserDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveListOfJournals = function saveListOfJournals(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveListOfJournals, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveMeditationReminderDetails = function saveMeditationReminderDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveMeditationReminderDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.validaUserAcceptedGoalAndMoveToMissedGoal = function validaUserAcceptedGoalAndMoveToMissedGoal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ValidaUserAcceptedGoalAndMoveToMissedGoal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.addListOfUsersTrackerDetails = function addListOfUsersTrackerDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.AddListOfUsersTrackerDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.updateListOfUsersTrackerDetails = function updateListOfUsersTrackerDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.UpdateListOfUsersTrackerDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getCountOnDiscoverByUserId = function getCountOnDiscoverByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetCountOnDiscoverByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.clearAllNotificationsByUserId = function clearAllNotificationsByUserId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.ClearAllNotificationsByUserId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.seekerToPractitioner = function seekerToPractitioner(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SeekerToPractitioner, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.getUserById = function getUserById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.GetUserById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveUserNotification = function saveUserNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveUserNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.trackerCallFromGamification = function trackerCallFromGamification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.TrackerCallFromGamification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.sendGoalsNotification = function sendGoalsNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SendGoalsNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HeartIntuneProfileServiceClient.prototype.saveOrUpdateDonationProfile = function saveOrUpdateDonationProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HeartIntuneProfileService.SaveOrUpdateDonationProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.HeartIntuneProfileServiceClient = HeartIntuneProfileServiceClient;

