import {
  Button,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { BaseSyntheticEvent, useState } from "react";
import { ReactElement } from "react";
import { useAuth } from "services/AuthService";
import { isValidEmail } from "utils/helpers";

import "firebase/auth";
import "firebase/database";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Alert } from "@mui/material";
import { getAllUserList } from "redux/actions/userAction";
import { useDispatch } from "react-redux";
import { grpc } from "@improbable-eng/grpc-web";
import {
  ConnectUserRequest,
  ConnectUserResponse,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { firebaseAuth } from "config/firebase";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { createUserUpdate } from "redux/reducers/userReducer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddUser = React.forwardRef<HTMLDivElement, any>(
  ({ onClickBack }, ref): ReactElement => {
    const dispatch = useDispatch();
    const { emailSignUp } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [role, setRole] = React.useState<string>("");
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [formErrors, setFormErrors] = useState<{
      firstName: boolean;
      lastname: boolean;
      email: boolean;
      password: boolean;
    }>();
    const [showPassword, setshowPassword] = useState(false);
    function handleSubmit(e: BaseSyntheticEvent) {
      e.preventDefault();
      const {
        firstName: { value: firstName },
        lastName: { value: lastName },
        email: { value: email },
        password: { value: password },
      } = e.target.elements;

      if (firstName && lastName && email && isValidEmail(email) && password) {
        setLoading(true);
        setFormErrors({
          firstName: firstName === "",
          lastname: lastName === "",
          email: email === "" || !isValidEmail(email),
          password: password === "",
        });
        emailSignUp &&
          emailSignUp(email, password)
            .then((userCredentials) => {
              console.log("firebase res for add user", userCredentials);
              userCredentials.user
                ?.updateProfile({
                  displayName: `${firstName} ${lastName}`,
                })
                .then((res) => {
                  console.log("res", res);
                  //setLoading(false);
                  // setSnackBar({
                  //   open: true,
                  //   severity: "success",
                  //   message: "User added successfully",
                  // });
                  // dispatch(getAllUserList(10, 0))
                  // setTimeout(()=>{onClickBack()},2000)
                  connectUser(
                    userCredentials.user?.uid,
                    email,
                    firstName,
                    lastName
                  );
                })
                .catch((err) => {
                  setLoading(false);
                  setSnackBar({
                    open: true,
                    severity: "success",
                    message: "error in adding user",
                  });
                  // setErrorMessage(err?.message || err.toString());
                  // setShowError(true);
                });
            })
            .catch((err) => {
              setLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: err.message,
              });
              // setErrorMessage(err?.message || err.toString());
              // setShowError(true);
            });
      } else {
        setFormErrors({
          firstName: firstName === "",
          lastname: lastName === "",
          email: email === "" || !isValidEmail(email),
          password: password === "",
        });
      }
    }

    const connectUser = async (
      uid: any,
      email: any,
      firstName: string,
      lastName: string
    ) => {
      let data:any = {
        uid: uid,
        email: email,
        status: 2,
        provider: 4,
        //Addressline: role === "USER" ? "" : role,
        role:Number(role),
        displayName: `${firstName} ${lastName}`,
      };
      try {
        console.log("reuest data to connect user", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ConnectUserRequest = new ConnectUserRequest();
        //reqBody.setUserid(userId);
        reqBody.setDisplayname(data.displayName);
        reqBody.setEmail(data.email);
        reqBody.setStatus(2);
        reqBody.setProvider(4);
        reqBody.setFirebaseid(data.uid);
        //reqBody.setAddressline(data.Addressline);
        reqBody.setUserrole(data.role);
        grpc.unary(HeartIntuneProfileService.ConnectUser, {
          host: HEARTINTUNE_PROFILE_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },

          onEnd: (res: UnaryOutput<ConnectUserResponse>) => {
            const { status, statusMessage, message } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log(
                  "data coming from connect user ",
                  message.toObject()
                );
                dispatch(createUserUpdate());
                setLoading(false);
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "User added successfully",
                });
                dispatch(getAllUserList(10, 0));
                setTimeout(() => {
                  onClickBack();
                }, 2000);
              } else {
                setSnackBar({
                  open: true,
                  severity: "error",
                  message: "error in adding user",
                });
              }
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <div ref={ref}>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid container spacing={1} className="align-items-center p-3">
          <Grid item>
            <IconButton onClick={() => onClickBack()} size="large">
              <KeyboardBackspaceIcon color="action" />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6">Add User</Typography>
          </Grid>
          {/* <Typography variant="h6">Add User</Typography> */}
        </Grid>
        <Divider />
        <CardContent className="p-0">
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} className="p-3">
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  error={formErrors?.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  error={formErrors?.lastname}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={formErrors?.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  error={formErrors?.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setshowPassword(!showPassword)}
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Role
                  </InputLabel>
                  <Select
                    value={role}
                    onChange={(event: SelectChangeEvent<string>) =>
                      setRole(event.target.value as string)
                    }
                    label="Select role"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="1">SUPER_ADMIN</MenuItem>
                    <MenuItem value="2">ADMIN</MenuItem>
                    <MenuItem value="3">CONTENT_OWNER</MenuItem>
                    <MenuItem value="4">USER</MenuItem>
                    {/* <MenuItem value="PRECEPTOR">PRECEPTOR</MenuItem> */}
                    <MenuItem value="6">CUSTOM_ROLE</MenuItem>
                    <MenuItem value="8">EVENTS_ANNOUNCEMENTS_ADMIN_ROLE</MenuItem>
                    <MenuItem value="9">BHE_CONTENT_MANAGER</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Divider />
            {/* <Grid item xs={12}> */}
            <Grid container justifyContent="flex-end" className="p-3">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Create User
              </Button>
              {/* {loading && <CircularProgress size={24} />} */}
            </Grid>
            {/* </Grid> */}
          </form>
        </CardContent>
        {/* 
      <Snackbar
        key="signup-snack"
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        autoHideDuration={6000}
        open={showError}
        onClose={() => {
          setShowError(false);
        }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar> */}

        <Snackbar
          open={snackBar.open}
          autoHideDuration={6000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
);

export default AddUser;
