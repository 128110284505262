import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { ListSkeleton } from "components/listSkeleton";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";
import { FaqForm } from "./faqForm";
import { getAllFAQ } from "redux/actions/faqActions";
import style from "./index.module.scss";
import { MenuProps } from "styles/global.styles";
interface State {
  editing?: boolean;
  editItem?: any;
  lang: number;
}

export default function FaqScreen(): ReactElement {
  const languageType = [
    "defaultcontent",
    "en",
    "hi",
    "te",
    "gu",
    "ta",
    "ka",
    "mr",
    "it",
    "ru",
    "es",
    "fr",
    "pt",
    "de",
    "bn",
    "ml",
    "uk",
    "od",
    "enUs",
    "enAu",
  ];
  const screens = [
    "Home screen",
    "Live session details screen",
    "Mood summary screen",
    "Contribute screen",
    "Heartspot screen",
  ];
  const dispatch = useDispatch();
  const FAQ = useSelector((state: RootState) => state.faq);
  const languages = useSelector((state: RootState) => state.app.languages);
  const initialState: State = {
    editing: false,
    editItem: null,
    lang: 1,
  };

  const [language, setLanguage] = useState<any>(1);

  const [states, setStates] = useState<State>(initialState);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchFAQ, setSearchFAQ] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onClickBack = () => {
    setCurrentIndex(0);
    setStates({
      editing: false,
      editItem: null,
      lang: language,
    });
  };

  useEffect(() => {
    dispatch(
      getAllFAQ({
        pagenumber: page,
        pagesize: rowsPerPage,
      })
    );
  }, [dispatch, page, rowsPerPage]);

  const renderFAQList = (data: any) => {
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  fullWidth
                  value={searchFAQ}
                  variant="outlined"
                  placeholder="Search FAQ by question"
                  size="small"
                  onChange={(e) => {
                    setSearchFAQ(e.target.value);
                    setPage(0);
                    const filteredData = data.filter((item: any) =>
                      item.faqs[languageType[language - 1]]
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(
                      getAllFAQ({
                        pagenumber: page,
                        pagesize: rowsPerPage,
                      })
                    );
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              // className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Question</TableCell>
                    <TableCell width={"50%"} align="left">
                      Screen
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchFAQ !== "" ? searchData : data).map(
                    (item: any, x: any) => (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer", height: "50px" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                              lang: language,
                            });
                            setCurrentIndex(1);
                          }}
                        >
                          <TableCell align="left">
                            {item.faqs[languageType[language - 1]]
                              ? item.faqs[languageType[language - 1]]
                              : item.faqs[languageType[0]]}
                          </TableCell>
                          <TableCell align="left">
                            {screens[item.selectedscreen]}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={FAQ.faqList.pageable?.totalcount ?? 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No FAQs to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      {FAQ.loading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5">
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">FAQs</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setCurrentIndex(1);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add FAQ
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <FormControl variant="outlined" className="m-3" fullWidth>
                    <InputLabel id="language-select">Language</InputLabel>
                    {languages && (
                      <Select
                        defaultValue=""
                        labelId="language-select"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                          setLanguage(e.target.value as number);
                        }}
                        MenuProps={MenuProps}
                      >
                        {languages.map((lang: any, i: any) => (
                          <MenuItem key={i} value={lang.code}>
                            {lang.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    {FAQ.loading
                      ? ListSkeleton()
                      : renderFAQList(FAQ.faqList.faqsandanswersList)}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <FaqForm
                onClickBack={onClickBack}
                setPage={setPage}
                pageable={{
                  pagenumber: page,
                  pagesize: rowsPerPage,
                }}
                {...states}
                lang={language}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
