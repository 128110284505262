// source: hfn-events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_common_pb = require('./common/common_pb.js');
goog.object.extend(proto, common_common_pb);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.AnnouncementDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.AnnouncementRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ArchivesEventsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ArchivesEventsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.Content', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.Empty', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.EssentialDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.EssentialDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.EssentialRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.HelpLineDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.HelpLineRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ID', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.KeyLocationDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.KeyLocationRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ListOfContent', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.LostAndFoundDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.LostAndFoundRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.RequestPageable', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ResponsePageable', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ScheduleEventDetails', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.ScheduleEventRequest', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.Status', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.Tag', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.TagsList', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.Test', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.TestResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse', null, global);
goog.exportSymbol('proto.unifiedplatform.HFNEvents.searchLostAndFoundItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.Test = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.Test, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.Test.displayName = 'proto.unifiedplatform.HFNEvents.Test';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.Empty.displayName = 'proto.unifiedplatform.HFNEvents.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.TestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.TestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.TestResponse.displayName = 'proto.unifiedplatform.HFNEvents.TestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ScheduleEventDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ScheduleEventDetails.displayName = 'proto.unifiedplatform.HFNEvents.ScheduleEventDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ScheduleEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ScheduleEventRequest.displayName = 'proto.unifiedplatform.HFNEvents.ScheduleEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ID.displayName = 'proto.unifiedplatform.HFNEvents.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.EssentialRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.EssentialRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.EssentialRequest.displayName = 'proto.unifiedplatform.HFNEvents.EssentialRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.EssentialDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.EssentialDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.EssentialDetails.displayName = 'proto.unifiedplatform.HFNEvents.EssentialDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.HelpLineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.HelpLineRequest.displayName = 'proto.unifiedplatform.HFNEvents.HelpLineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.HelpLineDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.HelpLineDetails.displayName = 'proto.unifiedplatform.HFNEvents.HelpLineDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.KeyLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.KeyLocationRequest.displayName = 'proto.unifiedplatform.HFNEvents.KeyLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.KeyLocationDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.KeyLocationDetails.displayName = 'proto.unifiedplatform.HFNEvents.KeyLocationDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.displayName = 'proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.Status.displayName = 'proto.unifiedplatform.HFNEvents.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.RequestPageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.RequestPageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.RequestPageable.displayName = 'proto.unifiedplatform.HFNEvents.RequestPageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ResponsePageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ResponsePageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ResponsePageable.displayName = 'proto.unifiedplatform.HFNEvents.ResponsePageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.EssentialDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.displayName = 'proto.unifiedplatform.HFNEvents.EssentialDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.displayName = 'proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.displayName = 'proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.LostAndFoundDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.LostAndFoundDetails.displayName = 'proto.unifiedplatform.HFNEvents.LostAndFoundDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.LostAndFoundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.LostAndFoundRequest.displayName = 'proto.unifiedplatform.HFNEvents.LostAndFoundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.displayName = 'proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.searchLostAndFoundItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.displayName = 'proto.unifiedplatform.HFNEvents.searchLostAndFoundItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.AnnouncementDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.AnnouncementDetails.displayName = 'proto.unifiedplatform.HFNEvents.AnnouncementDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.AnnouncementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.AnnouncementRequest.displayName = 'proto.unifiedplatform.HFNEvents.AnnouncementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.displayName = 'proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.displayName = 'proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.displayName = 'proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.displayName = 'proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.displayName = 'proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.displayName = 'proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.displayName = 'proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.displayName = 'proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.displayName = 'proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ArchivesEventsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.displayName = 'proto.unifiedplatform.HFNEvents.ArchivesEventsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.displayName = 'proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.displayName = 'proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.displayName = 'proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ArchivesEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.displayName = 'proto.unifiedplatform.HFNEvents.ArchivesEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.displayName = 'proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.displayName = 'proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.Tag.displayName = 'proto.unifiedplatform.HFNEvents.Tag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.TagsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.TagsList.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.TagsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.TagsList.displayName = 'proto.unifiedplatform.HFNEvents.TagsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.Content.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.Content.displayName = 'proto.unifiedplatform.HFNEvents.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.displayName = 'proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.ListOfContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.unifiedplatform.HFNEvents.ListOfContent.repeatedFields_, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.ListOfContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.ListOfContent.displayName = 'proto.unifiedplatform.HFNEvents.ListOfContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.displayName = 'proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.Test.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.Test.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.Test} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Test.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.Test}
 */
proto.unifiedplatform.HFNEvents.Test.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.Test;
  return proto.unifiedplatform.HFNEvents.Test.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.Test} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.Test}
 */
proto.unifiedplatform.HFNEvents.Test.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.Test.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.Test.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.Test} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Test.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.Test.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.Test} returns this
 */
proto.unifiedplatform.HFNEvents.Test.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.Empty}
 */
proto.unifiedplatform.HFNEvents.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.Empty;
  return proto.unifiedplatform.HFNEvents.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.Empty}
 */
proto.unifiedplatform.HFNEvents.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.TestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.TestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.TestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.TestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: (f = msg.getTest()) && proto.unifiedplatform.HFNEvents.Test.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.TestResponse}
 */
proto.unifiedplatform.HFNEvents.TestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.TestResponse;
  return proto.unifiedplatform.HFNEvents.TestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.TestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.TestResponse}
 */
proto.unifiedplatform.HFNEvents.TestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.Test;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.Test.deserializeBinaryFromReader);
      msg.setTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.TestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.TestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.TestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.TestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.Test.serializeBinaryToWriter
    );
  }
};


/**
 * optional Test test = 1;
 * @return {?proto.unifiedplatform.HFNEvents.Test}
 */
proto.unifiedplatform.HFNEvents.TestResponse.prototype.getTest = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.Test} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.Test, 1));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.Test|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.TestResponse} returns this
*/
proto.unifiedplatform.HFNEvents.TestResponse.prototype.setTest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.TestResponse} returns this
 */
proto.unifiedplatform.HFNEvents.TestResponse.prototype.clearTest = function() {
  return this.setTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.TestResponse.prototype.hasTest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ScheduleEventDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scheduletime: (f = msg.getScheduletime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    directionurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    livestreamingurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ScheduleEventDetails;
  return proto.unifiedplatform.HFNEvents.ScheduleEventDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduletime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectionurl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivestreamingurl(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ScheduleEventDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScheduletime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDirectionurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLivestreamingurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp scheduleTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getScheduletime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
*/
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setScheduletime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.clearScheduletime = function() {
  return this.setScheduletime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.hasScheduletime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string directionUrl = 5;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getDirectionurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setDirectionurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string liveStreamingUrl = 6;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getLivestreamingurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setLivestreamingurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double latitude = 7;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double longitude = 8;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventDetails.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ScheduleEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduletime: (f = msg.getScheduletime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    directionurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    livestreamingurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ScheduleEventRequest;
  return proto.unifiedplatform.HFNEvents.ScheduleEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduletime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectionurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLivestreamingurl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ScheduleEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduletime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDirectionurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLivestreamingurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subTitle = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp scheduleTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.getScheduletime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
*/
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.setScheduletime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.clearScheduletime = function() {
  return this.setScheduletime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.hasScheduletime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string directionUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.getDirectionurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.setDirectionurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string liveStreamingUrl = 5;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.getLivestreamingurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.setLivestreamingurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double longitude = 7;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ScheduleEventRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ID}
 */
proto.unifiedplatform.HFNEvents.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ID;
  return proto.unifiedplatform.HFNEvents.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ID}
 */
proto.unifiedplatform.HFNEvents.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ID.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ID} returns this
 */
proto.unifiedplatform.HFNEvents.ID.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.EssentialRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.EssentialRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    essentialtitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    smsnumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    whatsappnumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    landlinenumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    directionurl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.EssentialRequest;
  return proto.unifiedplatform.HFNEvents.EssentialRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.EssentialRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEssentialtitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmsnumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhatsappnumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandlinenumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectionurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.EssentialRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.EssentialRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEssentialtitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSmsnumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWhatsappnumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLandlinenumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDirectionurl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string essentialTitle = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getEssentialtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setEssentialtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string imageUrl = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mobileNumber = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string smsNumber = 5;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getSmsnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setSmsnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string whatsAppNumber = 6;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getWhatsappnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setWhatsappnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string landLineNumber = 7;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getLandlinenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setLandlinenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string directionUrl = 8;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.getDirectionurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialRequest} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialRequest.prototype.setDirectionurl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.EssentialDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.EssentialDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    essentialtitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mobilenumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    smsnumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    whatsappnumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    landlinenumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    directionurl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.EssentialDetails;
  return proto.unifiedplatform.HFNEvents.EssentialDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.EssentialDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEssentialtitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilenumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmsnumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhatsappnumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandlinenumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectionurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.EssentialDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.EssentialDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEssentialtitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMobilenumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSmsnumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWhatsappnumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLandlinenumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDirectionurl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string essentialTitle = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getEssentialtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setEssentialtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string imageUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mobileNumber = 5;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getMobilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setMobilenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string smsNumber = 6;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getSmsnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setSmsnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string whatsAppNumber = 7;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getWhatsappnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setWhatsappnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string landLineNumber = 8;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getLandlinenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setLandlinenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string directionUrl = 9;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.getDirectionurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetails.prototype.setDirectionurl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.HelpLineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.HelpLineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keylocationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    helplinetype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineRequest}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.HelpLineRequest;
  return proto.unifiedplatform.HFNEvents.HelpLineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.HelpLineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineRequest}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeylocationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (reader.readEnum());
      msg.setHelplinetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.HelpLineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.HelpLineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeylocationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHelplinetype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineRequest} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string keyLocationId = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.getKeylocationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineRequest} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.setKeylocationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contactNumber = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineRequest} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.setContactnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.BhandaraHelpLineType helpLineType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType}
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.getHelplinetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineRequest} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineRequest.prototype.setHelplinetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.HelpLineDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.HelpLineDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keylocationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactnumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    helplinetype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineDetails}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.HelpLineDetails;
  return proto.unifiedplatform.HFNEvents.HelpLineDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.HelpLineDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineDetails}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeylocationid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (reader.readEnum());
      msg.setHelplinetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.HelpLineDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.HelpLineDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeylocationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHelplinetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineDetails} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineDetails} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string keyLocationId = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.getKeylocationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineDetails} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.setKeylocationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contactNumber = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineDetails} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.setContactnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.BhandaraHelpLineType helpLineType = 5;
 * @return {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType}
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.getHelplinetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} value
 * @return {!proto.unifiedplatform.HFNEvents.HelpLineDetails} returns this
 */
proto.unifiedplatform.HFNEvents.HelpLineDetails.prototype.setHelplinetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.KeyLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closeat: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    directionurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    keylocationcategoryid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.KeyLocationRequest;
  return proto.unifiedplatform.HFNEvents.KeyLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectionurl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKeylocationcategoryid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.KeyLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpenat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCloseat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDirectionurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getKeylocationcategoryid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string openAt = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getOpenat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setOpenat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closeAt = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getCloseat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setCloseat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string imageUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double latitude = 5;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double longitude = 6;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string directionUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getDirectionurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setDirectionurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 keyLocationCategoryId = 8;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getKeylocationcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setKeylocationcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.KeyLocationDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    openat: jspb.Message.getFieldWithDefault(msg, 3, ""),
    closeat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    directionurl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    keylocationcategoryid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    keylocationcategorydetail: (f = msg.getKeylocationcategorydetail()) && proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.KeyLocationDetails;
  return proto.unifiedplatform.HFNEvents.KeyLocationDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectionurl(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKeylocationcategoryid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = new proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.deserializeBinaryFromReader);
      msg.setKeylocationcategorydetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.KeyLocationDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpenat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCloseat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDirectionurl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getKeylocationcategoryid();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getKeylocationcategorydetail();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string openAt = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getOpenat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setOpenat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string closeAt = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getCloseat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setCloseat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string imageUrl = 5;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double longitude = 7;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string directionUrl = 8;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getDirectionurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setDirectionurl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 keyLocationCategoryId = 9;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getKeylocationcategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setKeylocationcategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional KeyLocationCategoryDetails keyLocationCategoryDetail = 11;
 * @return {?proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.getKeylocationcategorydetail = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails, 11));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
*/
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.setKeylocationcategorydetail = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.clearKeylocationcategorydetail = function() {
  return this.setKeylocationcategorydetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.KeyLocationDetails.prototype.hasKeylocationcategorydetail = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keylocationdetailsList: jspb.Message.toObjectList(msg.getKeylocationdetailsList(),
    proto.unifiedplatform.HFNEvents.KeyLocationDetails.toObject, includeInstance),
    pageableresponse: (f = msg.getPageableresponse()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse;
  return proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.KeyLocationDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.KeyLocationDetails.deserializeBinaryFromReader);
      msg.addKeylocationdetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageableresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeylocationdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.KeyLocationDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageableresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated KeyLocationDetails keyLocationDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.KeyLocationDetails>}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.getKeylocationdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.KeyLocationDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.KeyLocationDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.KeyLocationDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.setKeylocationdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.addKeylocationdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.KeyLocationDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.clearKeylocationdetailsList = function() {
  return this.setKeylocationdetailsList([]);
};


/**
 * optional ResponsePageable pageableResponse = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.getPageableresponse = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.setPageableresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.clearPageableresponse = function() {
  return this.setPageableresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationsResponse.prototype.hasPageableresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.Status}
 */
proto.unifiedplatform.HFNEvents.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.Status;
  return proto.unifiedplatform.HFNEvents.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.Status}
 */
proto.unifiedplatform.HFNEvents.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.Status.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.unifiedplatform.HFNEvents.Status} returns this
 */
proto.unifiedplatform.HFNEvents.Status.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.RequestPageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.RequestPageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.RequestPageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.RequestPageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.RequestPageable}
 */
proto.unifiedplatform.HFNEvents.RequestPageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.RequestPageable;
  return proto.unifiedplatform.HFNEvents.RequestPageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.RequestPageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.RequestPageable}
 */
proto.unifiedplatform.HFNEvents.RequestPageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.RequestPageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.RequestPageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.RequestPageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.RequestPageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 pageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.RequestPageable.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.RequestPageable} returns this
 */
proto.unifiedplatform.HFNEvents.RequestPageable.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.RequestPageable.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.RequestPageable} returns this
 */
proto.unifiedplatform.HFNEvents.RequestPageable.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ResponsePageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedpagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedpagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ResponsePageable;
  return proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ResponsePageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelectedpagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ResponsePageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedpagesize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelectedpagenumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 totalCount = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ResponsePageable} returns this
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 selectedPageSize = 2;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.getSelectedpagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ResponsePageable} returns this
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.setSelectedpagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 selectedPageNumber = 3;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.getSelectedpagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ResponsePageable} returns this
 */
proto.unifiedplatform.HFNEvents.ResponsePageable.prototype.setSelectedpagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    essentialdetailsList: jspb.Message.toObjectList(msg.getEssentialdetailsList(),
    proto.unifiedplatform.HFNEvents.EssentialDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.EssentialDetailsResponse;
  return proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.EssentialDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.EssentialDetails.deserializeBinaryFromReader);
      msg.addEssentialdetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEssentialdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.EssentialDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EssentialDetails essentialDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.EssentialDetails>}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.getEssentialdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.EssentialDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.EssentialDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.EssentialDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse} returns this
*/
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.setEssentialdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.EssentialDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetails}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.addEssentialdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.EssentialDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.clearEssentialdetailsList = function() {
  return this.setEssentialdetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse} returns this
*/
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.EssentialDetailsResponse} returns this
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.EssentialDetailsResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledstarttime: (f = msg.getScheduledstarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scheduledendtime: (f = msg.getScheduledendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest;
  return proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledstarttime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledendtime(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.HFNEvents.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledstarttime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScheduledendtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.HFNEvents.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp scheduledStartTime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.getScheduledstarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.setScheduledstarttime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.clearScheduledstarttime = function() {
  return this.setScheduledstarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.hasScheduledstarttime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp scheduledEndTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.getScheduledendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.setScheduledendtime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.clearScheduledendtime = function() {
  return this.setScheduledendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.hasScheduledendtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.HFNEvents.RequestPageable}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledeventdetailsList: jspb.Message.toObjectList(msg.getScheduledeventdetailsList(),
    proto.unifiedplatform.HFNEvents.ScheduleEventDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse;
  return proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.ScheduleEventDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ScheduleEventDetails.deserializeBinaryFromReader);
      msg.addScheduledeventdetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledeventdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.ScheduleEventDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScheduleEventDetails scheduledEventDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.ScheduleEventDetails>}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.getScheduledeventdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.ScheduleEventDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.ScheduleEventDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.ScheduleEventDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.setScheduledeventdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.ScheduleEventDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.addScheduledeventdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.ScheduleEventDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.clearScheduledeventdetailsList = function() {
  return this.setScheduledeventdetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllScheduleEventResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.LostAndFoundDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keylocation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactnumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bhandarahelplinetype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.LostAndFoundDetails;
  return proto.unifiedplatform.HFNEvents.LostAndFoundDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeylocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (reader.readEnum());
      msg.setBhandarahelplinetype(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.LostAndFoundDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeylocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBhandarahelplinetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string KeyLocation = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.getKeylocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.setKeylocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contactNumber = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.setContactnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.BhandaraHelpLineType bhandaraHelpLineType = 5;
 * @return {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.getBhandarahelplinetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.setBhandarahelplinetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} returns this
*/
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundDetails.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.LostAndFoundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keylocation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bhandarahelplinetype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.LostAndFoundRequest;
  return proto.unifiedplatform.HFNEvents.LostAndFoundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeylocation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    case 4:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (reader.readEnum());
      msg.setBhandarahelplinetype(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.LostAndFoundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeylocation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBhandarahelplinetype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string KeyLocation = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.getKeylocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.setKeylocation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contactNumber = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.setContactnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional unifiedplatform.heartintune.common.BhandaraHelpLineType bhandaraHelpLineType = 4;
 * @return {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.getBhandarahelplinetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.setBhandarahelplinetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} returns this
*/
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundRequest} returns this
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.LostAndFoundRequest.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lostandfounddetailsList: jspb.Message.toObjectList(msg.getLostandfounddetailsList(),
    proto.unifiedplatform.HFNEvents.LostAndFoundDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse;
  return proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.LostAndFoundDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.LostAndFoundDetails.deserializeBinaryFromReader);
      msg.addLostandfounddetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLostandfounddetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.LostAndFoundDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LostAndFoundDetails lostAndFoundDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.LostAndFoundDetails>}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.getLostandfounddetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.LostAndFoundDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.LostAndFoundDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.LostAndFoundDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse} returns this
*/
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.setLostandfounddetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.LostAndFoundDetails}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.addLostandfounddetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.LostAndFoundDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse} returns this
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.clearLostandfounddetailsList = function() {
  return this.setLostandfounddetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse} returns this
*/
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse} returns this
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.getAllLostAndFoundDetailsResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchitem: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bhandarahelplinetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.searchLostAndFoundItem;
  return proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchitem(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (reader.readEnum());
      msg.setBhandarahelplinetype(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.HFNEvents.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchitem();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBhandarahelplinetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.HFNEvents.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string searchItem = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.getSearchitem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem} returns this
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.setSearchitem = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.BhandaraHelpLineType bhandaraHelpLineType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.getBhandarahelplinetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} value
 * @return {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem} returns this
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.setBhandarahelplinetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.HFNEvents.RequestPageable}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem} returns this
*/
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.searchLostAndFoundItem} returns this
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.searchLostAndFoundItem.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.AnnouncementDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    announcementtext: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduledat: (f = msg.getScheduledat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    locationurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publishstatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.AnnouncementDetails;
  return proto.unifiedplatform.HFNEvents.AnnouncementDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnouncementtext(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationurl(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (reader.readEnum());
      msg.setPublishstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.AnnouncementDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnnouncementtext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduledat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocationurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublishstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string announcementText = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.getAnnouncementtext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.setAnnouncementtext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp scheduledAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.getScheduledat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} returns this
*/
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.setScheduledat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.clearScheduledat = function() {
  return this.setScheduledat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.hasScheduledat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string locationUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.getLocationurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.setLocationurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.PublishContent publishStatus = 5;
 * @return {!proto.unifiedplatform.heartintune.common.PublishContent}
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.getPublishstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PublishContent} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementDetails.prototype.setPublishstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.AnnouncementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    announcementtext: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduledat: (f = msg.getScheduledat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    locationurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publishstatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementRequest}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.AnnouncementRequest;
  return proto.unifiedplatform.HFNEvents.AnnouncementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementRequest}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnouncementtext(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationurl(value);
      break;
    case 5:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (reader.readEnum());
      msg.setPublishstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.AnnouncementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnouncementtext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduledat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocationurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublishstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string announcementText = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.getAnnouncementtext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.setAnnouncementtext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp scheduledAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.getScheduledat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} returns this
*/
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.setScheduledat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.clearScheduledat = function() {
  return this.setScheduledat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.hasScheduledat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string locationUrl = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.getLocationurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.setLocationurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional unifiedplatform.heartintune.common.PublishContent publishStatus = 5;
 * @return {!proto.unifiedplatform.heartintune.common.PublishContent}
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.getPublishstatus = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.PublishContent} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.PublishContent} value
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementRequest} returns this
 */
proto.unifiedplatform.HFNEvents.AnnouncementRequest.prototype.setPublishstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    categoryname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails;
  return proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCategoryname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string categoryName = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.prototype.getCategoryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.prototype.setCategoryname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest;
  return proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string categoryName = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.prototype.getCategoryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest} returns this
 */
proto.unifiedplatform.HFNEvents.KeyLocationCategoryRequest.prototype.setCategoryname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    keylocationcategorydetailsList: jspb.Message.toObjectList(msg.getKeylocationcategorydetailsList(),
    proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails;
  return proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.deserializeBinaryFromReader);
      msg.addKeylocationcategorydetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeylocationcategorydetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated KeyLocationCategoryDetails keyLocationCategoryDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails>}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.prototype.getKeylocationcategorydetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.prototype.setKeylocationcategorydetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.prototype.addKeylocationcategorydetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.KeyLocationCategoryDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllKeyLocationCategoriesDetails.prototype.clearKeylocationcategorydetailsList = function() {
  return this.setKeylocationcategorydetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bhandarahelplinetype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest}
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest;
  return proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest}
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (reader.readEnum());
      msg.setBhandarahelplinetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBhandarahelplinetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.BhandaraHelpLineType bhandaraHelpLineType = 1;
 * @return {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType}
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.prototype.getBhandarahelplinetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} value
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest} returns this
 */
proto.unifiedplatform.HFNEvents.BhandaraHelpLineTypeRequest.prototype.setBhandarahelplinetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    announcementdetailsList: jspb.Message.toObjectList(msg.getAnnouncementdetailsList(),
    proto.unifiedplatform.HFNEvents.AnnouncementDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse;
  return proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.AnnouncementDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.AnnouncementDetails.deserializeBinaryFromReader);
      msg.addAnnouncementdetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnouncementdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.AnnouncementDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnnouncementDetails announcementDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.AnnouncementDetails>}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.getAnnouncementdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.AnnouncementDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.AnnouncementDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.AnnouncementDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.setAnnouncementdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.AnnouncementDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.AnnouncementDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.addAnnouncementdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.AnnouncementDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.clearAnnouncementdetailsList = function() {
  return this.setAnnouncementdetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllPublishAnnouncementResponse.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail;
  return proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail} returns this
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail} returns this
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string imageUrl = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail} returns this
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest;
  return proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest} returns this
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string imageUrl = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest} returns this
 */
proto.unifiedplatform.HFNEvents.BhandaraCategoryRequest.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bhandaracategorydetailList: jspb.Message.toObjectList(msg.getBhandaracategorydetailList(),
    proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse;
  return proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse}
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.deserializeBinaryFromReader);
      msg.addBhandaracategorydetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBhandaracategorydetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BhandaraCategoryDetail bhandaraCategoryDetail = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail>}
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.prototype.getBhandaracategorydetailList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail>} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.prototype.setBhandaracategorydetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail}
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.prototype.addBhandaracategorydetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.BhandaraCategoryDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllBhandaraCategoryResponse.prototype.clearBhandaracategorydetailList = function() {
  return this.setBhandaracategorydetailList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.RequestPageable.toObject(includeInstance, f),
    bhandarahelplinetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest}
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest;
  return proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest}
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (reader.readEnum());
      msg.setBhandarahelplinetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.RequestPageable.serializeBinaryToWriter
    );
  }
  f = message.getBhandarahelplinetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional RequestPageable pageable = 1;
 * @return {?proto.unifiedplatform.HFNEvents.RequestPageable}
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.RequestPageable, 1));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional unifiedplatform.heartintune.common.BhandaraHelpLineType bhandaraHelpLineType = 2;
 * @return {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType}
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.getBhandarahelplinetype = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.BhandaraHelpLineType} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllLostOrFoundItemDetailsRequest.prototype.setBhandarahelplinetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ArchivesEventsDetails;
  return proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEventname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string eventName = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.prototype.getEventname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.prototype.setEventname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subeventname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    archiveseventsdetails: (f = msg.getArchiveseventsdetails()) && proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails;
  return proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubeventname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = new proto.unifiedplatform.HFNEvents.ArchivesEventsDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.deserializeBinaryFromReader);
      msg.setArchiveseventsdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubeventname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArchiveseventsdetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string subEventName = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.getSubeventname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.setSubeventname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ArchivesEventsDetails archivesEventsDetails = 4;
 * @return {?proto.unifiedplatform.HFNEvents.ArchivesEventsDetails}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.getArchiveseventsdetails = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ArchivesEventsDetails} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ArchivesEventsDetails, 4));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ArchivesEventsDetails|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} returns this
*/
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.setArchiveseventsdetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.clearArchiveseventsdetails = function() {
  return this.setArchiveseventsdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.prototype.hasArchiveseventsdetails = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subeventname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest;
  return proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubeventname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubeventname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string subEventName = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.getSubeventname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.setSubeventname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    archivessubeventsdetailsrequestList: jspb.Message.toObjectList(msg.getArchivessubeventsdetailsrequestList(),
    proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest}
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest;
  return proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest}
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.deserializeBinaryFromReader);
      msg.addArchivessubeventsdetailsrequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArchivessubeventsdetailsrequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ArchivesSubEventsDetailsRequest archivesSubEventsDetailsRequest = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest>}
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.prototype.getArchivessubeventsdetailsrequestList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest>} value
 * @return {!proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest} returns this
*/
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.prototype.setArchivessubeventsdetailsrequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest}
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.prototype.addArchivessubeventsdetailsrequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetailsRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ListOfArchivesSubEventsDetailsRequest.prototype.clearArchivessubeventsdetailsrequestList = function() {
  return this.setArchivessubeventsdetailsrequestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsRequest}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ArchivesEventsRequest;
  return proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsRequest}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string eventName = 1;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.prototype.getEventname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsRequest} returns this
 */
proto.unifiedplatform.HFNEvents.ArchivesEventsRequest.prototype.setEventname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    archivessubeventsdetailsList: jspb.Message.toObjectList(msg.getArchivessubeventsdetailsList(),
    proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails;
  return proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.deserializeBinaryFromReader);
      msg.addArchivessubeventsdetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArchivessubeventsdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ArchivesSubEventsDetails archivesSubEventsDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails>}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.getArchivessubeventsdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.setArchivessubeventsdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.addArchivessubeventsdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.ArchivesSubEventsDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.clearArchivessubeventsdetailsList = function() {
  return this.setArchivessubeventsdetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesSubEventsDetails.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    archiveseventsdetailsList: jspb.Message.toObjectList(msg.getArchiveseventsdetailsList(),
    proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails;
  return proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.ArchivesEventsDetails;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.deserializeBinaryFromReader);
      msg.addArchiveseventsdetails(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArchiveseventsdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.ArchivesEventsDetails.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ArchivesEventsDetails archivesEventsDetails = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails>}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.getArchiveseventsdetailsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.ArchivesEventsDetails, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails>} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.setArchiveseventsdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.ArchivesEventsDetails}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.addArchiveseventsdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.ArchivesEventsDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.clearArchiveseventsdetailsList = function() {
  return this.setArchiveseventsdetailsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails} returns this
*/
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails} returns this
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.GetAllArchivesEventsDetails.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.Tag}
 */
proto.unifiedplatform.HFNEvents.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.Tag;
  return proto.unifiedplatform.HFNEvents.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.Tag}
 */
proto.unifiedplatform.HFNEvents.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.Tag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.Tag} returns this
 */
proto.unifiedplatform.HFNEvents.Tag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.Tag.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.Tag} returns this
 */
proto.unifiedplatform.HFNEvents.Tag.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.TagsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.TagsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.TagsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.TagsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.HFNEvents.Tag.toObject, includeInstance),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.TagsList}
 */
proto.unifiedplatform.HFNEvents.TagsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.TagsList;
  return proto.unifiedplatform.HFNEvents.TagsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.TagsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.TagsList}
 */
proto.unifiedplatform.HFNEvents.TagsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.Tag;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.TagsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.TagsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.TagsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.Tag.serializeBinaryToWriter
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tag tags = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.Tag>}
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.Tag, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.Tag>} value
 * @return {!proto.unifiedplatform.HFNEvents.TagsList} returns this
*/
proto.unifiedplatform.HFNEvents.TagsList.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.Tag}
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.TagsList} returns this
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional ResponsePageable pageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.TagsList} returns this
*/
proto.unifiedplatform.HFNEvents.TagsList.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.TagsList} returns this
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.TagsList.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.Content.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    subtitle: (f = msg.getSubtitle()) && common_common_pb.Locale.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && common_common_pb.Locale.toObject(includeInstance, f),
    contenturl: (f = msg.getContenturl()) && common_common_pb.Locale.toObject(includeInstance, f),
    thumbnailimageurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    backgroundimageurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    readingtime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    publishedat: (f = msg.getPublishedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 11, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.HFNEvents.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.Content}
 */
proto.unifiedplatform.HFNEvents.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.Content;
  return proto.unifiedplatform.HFNEvents.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.Content}
 */
proto.unifiedplatform.HFNEvents.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setSubtitle(value);
      break;
    case 4:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new common_common_pb.Locale;
      reader.readMessage(value,common_common_pb.Locale.deserializeBinaryFromReader);
      msg.setContenturl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimageurl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundimageurl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReadingtime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPublishedat(value);
      break;
    case 11:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 12:
      var value = new proto.unifiedplatform.HFNEvents.Tag;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getSubtitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getContenturl();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_common_pb.Locale.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailimageurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBackgroundimageurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getReadingtime();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPublishedat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.unifiedplatform.HFNEvents.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.Locale title = 2;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getTitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 2));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
*/
proto.unifiedplatform.HFNEvents.Content.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale subTitle = 3;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getSubtitle = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 3));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
*/
proto.unifiedplatform.HFNEvents.Content.prototype.setSubtitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.clearSubtitle = function() {
  return this.setSubtitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale description = 4;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getDescription = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 4));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
*/
proto.unifiedplatform.HFNEvents.Content.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional unifiedplatform.heartintune.common.Locale contentUrl = 5;
 * @return {?proto.unifiedplatform.heartintune.common.Locale}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getContenturl = function() {
  return /** @type{?proto.unifiedplatform.heartintune.common.Locale} */ (
    jspb.Message.getWrapperField(this, common_common_pb.Locale, 5));
};


/**
 * @param {?proto.unifiedplatform.heartintune.common.Locale|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
*/
proto.unifiedplatform.HFNEvents.Content.prototype.setContenturl = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.clearContenturl = function() {
  return this.setContenturl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.hasContenturl = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string thumbnailImageUrl = 6;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getThumbnailimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.setThumbnailimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string backgroundImageUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getBackgroundimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.setBackgroundimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float duration = 8;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 readingTime = 9;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getReadingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.setReadingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp publishedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getPublishedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
*/
proto.unifiedplatform.HFNEvents.Content.prototype.setPublishedat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.clearPublishedat = function() {
  return this.setPublishedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.hasPublishedat = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 11;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated Tag tags = 12;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.Tag>}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.Tag, 12));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.Tag>} value
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
*/
proto.unifiedplatform.HFNEvents.Content.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.Tag}
 */
proto.unifiedplatform.HFNEvents.Content.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.unifiedplatform.HFNEvents.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.Content} returns this
 */
proto.unifiedplatform.HFNEvents.Content.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contenturl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumbnailimageurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    backgroundimageurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    readingtime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    publishedat: (f = msg.getPublishedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    contentcategory: jspb.Message.getFieldWithDefault(msg, 11, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.unifiedplatform.HFNEvents.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport;
  return proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContenturl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailimageurl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundimageurl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReadingtime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPublishedat(value);
      break;
    case 11:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 12:
      var value = new proto.unifiedplatform.HFNEvents.Tag;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContenturl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumbnailimageurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBackgroundimageurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getReadingtime();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPublishedat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.unifiedplatform.HFNEvents.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subTitle = 3;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contentUrl = 5;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getContenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setContenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumbnailImageUrl = 6;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getThumbnailimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setThumbnailimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string backgroundImageUrl = 7;
 * @return {string}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getBackgroundimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setBackgroundimageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float duration = 8;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 readingTime = 9;
 * @return {number}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getReadingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setReadingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp publishedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getPublishedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
*/
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setPublishedat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.clearPublishedat = function() {
  return this.setPublishedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.hasPublishedat = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 11;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated Tag tags = 12;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.Tag>}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.Tag, 12));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.Tag>} value
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
*/
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.Tag}
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.unifiedplatform.HFNEvents.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport} returns this
 */
proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.unifiedplatform.HFNEvents.ListOfContent.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.ListOfContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.ListOfContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ListOfContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentwithlanguagesupportList: jspb.Message.toObjectList(msg.getContentwithlanguagesupportList(),
    proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.toObject, includeInstance),
    responsepageable: (f = msg.getResponsepageable()) && proto.unifiedplatform.HFNEvents.ResponsePageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.ListOfContent}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.ListOfContent;
  return proto.unifiedplatform.HFNEvents.ListOfContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.ListOfContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.ListOfContent}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.deserializeBinaryFromReader);
      msg.addContentwithlanguagesupport(value);
      break;
    case 2:
      var value = new proto.unifiedplatform.HFNEvents.ResponsePageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.ResponsePageable.deserializeBinaryFromReader);
      msg.setResponsepageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.ListOfContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.ListOfContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.ListOfContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentwithlanguagesupportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport.serializeBinaryToWriter
    );
  }
  f = message.getResponsepageable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.unifiedplatform.HFNEvents.ResponsePageable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContentWithLanguageSupport contentWithLanguageSupport = 1;
 * @return {!Array<!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport>}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.getContentwithlanguagesupportList = function() {
  return /** @type{!Array<!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport, 1));
};


/**
 * @param {!Array<!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport>} value
 * @return {!proto.unifiedplatform.HFNEvents.ListOfContent} returns this
*/
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.setContentwithlanguagesupportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.addContentwithlanguagesupport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unifiedplatform.HFNEvents.ContentWithLanguageSupport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.unifiedplatform.HFNEvents.ListOfContent} returns this
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.clearContentwithlanguagesupportList = function() {
  return this.setContentwithlanguagesupportList([]);
};


/**
 * optional ResponsePageable responsePageable = 2;
 * @return {?proto.unifiedplatform.HFNEvents.ResponsePageable}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.getResponsepageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.ResponsePageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.ResponsePageable, 2));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.ResponsePageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.ListOfContent} returns this
*/
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.setResponsepageable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.ListOfContent} returns this
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.clearResponsepageable = function() {
  return this.setResponsepageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.ListOfContent.prototype.hasResponsepageable = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.toObject = function(opt_includeInstance) {
  return proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentcategory: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selectedlanguage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageable: (f = msg.getPageable()) && proto.unifiedplatform.HFNEvents.RequestPageable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport;
  return proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (reader.readEnum());
      msg.setContentcategory(value);
      break;
    case 2:
      var value = /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (reader.readEnum());
      msg.setSelectedlanguage(value);
      break;
    case 3:
      var value = new proto.unifiedplatform.HFNEvents.RequestPageable;
      reader.readMessage(value,proto.unifiedplatform.HFNEvents.RequestPageable.deserializeBinaryFromReader);
      msg.setPageable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSelectedlanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPageable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.unifiedplatform.HFNEvents.RequestPageable.serializeBinaryToWriter
    );
  }
};


/**
 * optional unifiedplatform.heartintune.common.ContentCategory contentCategory = 1;
 * @return {!proto.unifiedplatform.heartintune.common.ContentCategory}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.getContentcategory = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.ContentCategory} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.ContentCategory} value
 * @return {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport} returns this
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.setContentcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional unifiedplatform.heartintune.common.LocaleType selectedLanguage = 2;
 * @return {!proto.unifiedplatform.heartintune.common.LocaleType}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.getSelectedlanguage = function() {
  return /** @type {!proto.unifiedplatform.heartintune.common.LocaleType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.unifiedplatform.heartintune.common.LocaleType} value
 * @return {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport} returns this
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.setSelectedlanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RequestPageable pageable = 3;
 * @return {?proto.unifiedplatform.HFNEvents.RequestPageable}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.getPageable = function() {
  return /** @type{?proto.unifiedplatform.HFNEvents.RequestPageable} */ (
    jspb.Message.getWrapperField(this, proto.unifiedplatform.HFNEvents.RequestPageable, 3));
};


/**
 * @param {?proto.unifiedplatform.HFNEvents.RequestPageable|undefined} value
 * @return {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport} returns this
*/
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.setPageable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport} returns this
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.clearPageable = function() {
  return this.setPageable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unifiedplatform.HFNEvents.RequestForGetContentWithLocaleSupport.prototype.hasPageable = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.unifiedplatform.HFNEvents);
