// package: unifiedplatform.guidedMeditation
// file: guided-meditation.proto

var guided_meditation_pb = require("./guided-meditation_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var GuidedMeditationService = (function () {
  function GuidedMeditationService() {}
  GuidedMeditationService.serviceName = "unifiedplatform.guidedMeditation.GuidedMeditationService";
  return GuidedMeditationService;
}());

GuidedMeditationService.Test = {
  methodName: "Test",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.Empty,
  responseType: guided_meditation_pb.TestResponse
};

GuidedMeditationService.GetMeditationFiles = {
  methodName: "GetMeditationFiles",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.SelectMeditationFiles,
  responseType: guided_meditation_pb.SelectedFiles
};

GuidedMeditationService.GetAllMeditationFiles = {
  methodName: "GetAllMeditationFiles",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestPageable,
  responseType: guided_meditation_pb.SelectedFiles
};

GuidedMeditationService.addMeditationFile = {
  methodName: "addMeditationFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.AddMeditationFileRequest,
  responseType: guided_meditation_pb.MeditationFile
};

GuidedMeditationService.updateMeditationFile = {
  methodName: "updateMeditationFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.MeditationFile,
  responseType: guided_meditation_pb.MeditationFile
};

GuidedMeditationService.deleteMeditationFileByID = {
  methodName: "deleteMeditationFileByID",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.DeleteMeditationFileByIdResponse
};

GuidedMeditationService.updatePurposeFile = {
  methodName: "updatePurposeFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.PurposeFile,
  responseType: guided_meditation_pb.PurposeFile
};

GuidedMeditationService.deletePurposeByID = {
  methodName: "deletePurposeByID",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.DeletePurposeFileByIdResponse
};

GuidedMeditationService.MarkFavourite = {
  methodName: "MarkFavourite",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.MeditationFileAndUserID,
  responseType: guided_meditation_pb.ResponseStatus
};

GuidedMeditationService.GetMeditationFilesByID = {
  methodName: "GetMeditationFilesByID",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.SelectedFiles
};

GuidedMeditationService.GetMeditationById = {
  methodName: "GetMeditationById",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.MeditationFile
};

GuidedMeditationService.addPurposeFile = {
  methodName: "addPurposeFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.AddPurposeFileRequest,
  responseType: guided_meditation_pb.PurposeFile
};

GuidedMeditationService.getPurposeFile = {
  methodName: "getPurposeFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.PurposeFile
};

GuidedMeditationService.getAllPurposeFile = {
  methodName: "getAllPurposeFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestGetAllPurpose,
  responseType: guided_meditation_pb.ResponseAllPurposeFiles
};

GuidedMeditationService.addDurationFile = {
  methodName: "addDurationFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.AddDurationFileRequest,
  responseType: guided_meditation_pb.DurationFile
};

GuidedMeditationService.updateDurationFile = {
  methodName: "updateDurationFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.DurationFile,
  responseType: guided_meditation_pb.DurationFile
};

GuidedMeditationService.getDurationFile = {
  methodName: "getDurationFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.DurationFile
};

GuidedMeditationService.getAllDurationFile = {
  methodName: "getAllDurationFile",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.Empty,
  responseType: guided_meditation_pb.ResponseAllDurationFiles
};

GuidedMeditationService.addMeditationFileDetails = {
  methodName: "addMeditationFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.AddMeditationFileDetailsRequest,
  responseType: guided_meditation_pb.MeditationFileDetails
};

GuidedMeditationService.updateMeditationFileDetails = {
  methodName: "updateMeditationFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.MeditationFileDetails,
  responseType: guided_meditation_pb.MeditationFileDetails
};

GuidedMeditationService.getAllMeditationFileDetails = {
  methodName: "getAllMeditationFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestForGetAllMeditationFiles,
  responseType: guided_meditation_pb.SelectedMeditationFiles
};

GuidedMeditationService.deleteMeditationFileDetailsByID = {
  methodName: "deleteMeditationFileDetailsByID",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.DeleteMeditationFileDetailsByIdResponse
};

GuidedMeditationService.GetMeditationFileDetailsById = {
  methodName: "GetMeditationFileDetailsById",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestForGetMeditationFileDetailsWithSelectedLanguage,
  responseType: guided_meditation_pb.MeditationFile
};

GuidedMeditationService.GetMeditationFilesDetails = {
  methodName: "GetMeditationFilesDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.SelectMeditationFileDetails,
  responseType: guided_meditation_pb.SelectedFileDetailsResponse
};

GuidedMeditationService.addPurposeFileDetails = {
  methodName: "addPurposeFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.AddPurposeFileDetailsRequest,
  responseType: guided_meditation_pb.PurposeFileDetails
};

GuidedMeditationService.getPurposeFileDetailsById = {
  methodName: "getPurposeFileDetailsById",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.PurposeFileDetails
};

GuidedMeditationService.updatePurposeFileDetails = {
  methodName: "updatePurposeFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.PurposeFileDetails,
  responseType: guided_meditation_pb.PurposeFileDetails
};

GuidedMeditationService.deletePurposeDetailsByID = {
  methodName: "deletePurposeDetailsByID",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.DeletePurposeFileDetailByIdResponse
};

GuidedMeditationService.getAllPurposeFileDetails = {
  methodName: "getAllPurposeFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestGetAllPurposeFileDetails,
  responseType: guided_meditation_pb.ResponseAllPurposeFiles
};

GuidedMeditationService.addDurationFileDetails = {
  methodName: "addDurationFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.AddDurationFileDetailsRequest,
  responseType: guided_meditation_pb.DurationFile
};

GuidedMeditationService.UpdateDurationFileDetails = {
  methodName: "UpdateDurationFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.DurationFile,
  responseType: guided_meditation_pb.DurationFile
};

GuidedMeditationService.getDurationFileDetailsById = {
  methodName: "getDurationFileDetailsById",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.DurationFile
};

GuidedMeditationService.getAllDurationFileDetails = {
  methodName: "getAllDurationFileDetails",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.Empty,
  responseType: guided_meditation_pb.ResponseAllDurationFilesDetails
};

GuidedMeditationService.DeleteDurationFileDetailsById = {
  methodName: "DeleteDurationFileDetailsById",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.ResponseStatus
};

GuidedMeditationService.getGuidedMeditationFileDetailsV2 = {
  methodName: "getGuidedMeditationFileDetailsV2",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.LocalType,
  responseType: guided_meditation_pb.SelectedFileDetailsResponse
};

GuidedMeditationService.getPurposeMeditationFileDetailsByPageableV2 = {
  methodName: "getPurposeMeditationFileDetailsByPageableV2",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestForGetMeditationFileDetails,
  responseType: guided_meditation_pb.SelectedFileDetailsResponseV2
};

GuidedMeditationService.addMeditationFileDetailsV2 = {
  methodName: "addMeditationFileDetailsV2",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.AddMeditationFileDetailsRequestV2,
  responseType: guided_meditation_pb.MeditationFileDetailsV2
};

GuidedMeditationService.updateMeditationFileDetailsV2 = {
  methodName: "updateMeditationFileDetailsV2",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.MeditationFileDetailsV2,
  responseType: guided_meditation_pb.MeditationFileDetailsV2
};

GuidedMeditationService.GetAllMeditationFileDetailsV2 = {
  methodName: "GetAllMeditationFileDetailsV2",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestForGetAllMeditationFiles,
  responseType: guided_meditation_pb.SelectedFileDetailsResponseV2
};

GuidedMeditationService.addMeditationBckgnd = {
  methodName: "addMeditationBckgnd",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.URLRequest,
  responseType: guided_meditation_pb.ResponseStatus
};

GuidedMeditationService.getMeditationBckgndById = {
  methodName: "getMeditationBckgndById",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.MeditaionBckgndDetails
};

GuidedMeditationService.updateMeditationBckgnd = {
  methodName: "updateMeditationBckgnd",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.MeditaionBckgndDetails,
  responseType: guided_meditation_pb.MeditaionBckgndDetails
};

GuidedMeditationService.deleteMeditationBckgnd = {
  methodName: "deleteMeditationBckgnd",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.ID,
  responseType: guided_meditation_pb.ResponseStatus
};

GuidedMeditationService.getAllMeditationBckgnd = {
  methodName: "getAllMeditationBckgnd",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.SelectedMeditaionBckgndDetailsRequest,
  responseType: guided_meditation_pb.ListOfSelectedMeditaionBckgndDetails
};

GuidedMeditationService.getAllMeditationBckgndWithSelectedLanguage = {
  methodName: "getAllMeditationBckgndWithSelectedLanguage",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.SelectedMeditaionBckgndDetailsRequest,
  responseType: guided_meditation_pb.ListOfSelectedMeditaionBckgndDetailsLocale
};

GuidedMeditationService.CreateOrUpdateMeditationTags = {
  methodName: "CreateOrUpdateMeditationTags",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.Tag,
  responseType: guided_meditation_pb.Tag
};

GuidedMeditationService.GetAllMeditationTags = {
  methodName: "GetAllMeditationTags",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestPageable,
  responseType: guided_meditation_pb.TagsList
};

GuidedMeditationService.GetMeditationByTagId = {
  methodName: "GetMeditationByTagId",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.Tag,
  responseType: guided_meditation_pb.TagWithMeditation
};

GuidedMeditationService.AddOrUpdateMultipleTags = {
  methodName: "AddOrUpdateMultipleTags",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.TagsRequest,
  responseType: guided_meditation_pb.TagsRequest
};

GuidedMeditationService.GetAllMeditationCount = {
  methodName: "GetAllMeditationCount",
  service: GuidedMeditationService,
  requestStream: false,
  responseStream: false,
  requestType: guided_meditation_pb.RequestForGetAllMeditationFiles,
  responseType: guided_meditation_pb.ResponsePageable
};

exports.GuidedMeditationService = GuidedMeditationService;

function GuidedMeditationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

GuidedMeditationServiceClient.prototype.test = function test(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.Test, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getMeditationFiles = function getMeditationFiles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetMeditationFiles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllMeditationFiles = function getAllMeditationFiles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetAllMeditationFiles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addMeditationFile = function addMeditationFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addMeditationFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updateMeditationFile = function updateMeditationFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.updateMeditationFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.deleteMeditationFileByID = function deleteMeditationFileByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.deleteMeditationFileByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updatePurposeFile = function updatePurposeFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.updatePurposeFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.deletePurposeByID = function deletePurposeByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.deletePurposeByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.markFavourite = function markFavourite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.MarkFavourite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getMeditationFilesByID = function getMeditationFilesByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetMeditationFilesByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getMeditationById = function getMeditationById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetMeditationById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addPurposeFile = function addPurposeFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addPurposeFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getPurposeFile = function getPurposeFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getPurposeFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllPurposeFile = function getAllPurposeFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getAllPurposeFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addDurationFile = function addDurationFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addDurationFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updateDurationFile = function updateDurationFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.updateDurationFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getDurationFile = function getDurationFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getDurationFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllDurationFile = function getAllDurationFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getAllDurationFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addMeditationFileDetails = function addMeditationFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addMeditationFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updateMeditationFileDetails = function updateMeditationFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.updateMeditationFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllMeditationFileDetails = function getAllMeditationFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getAllMeditationFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.deleteMeditationFileDetailsByID = function deleteMeditationFileDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.deleteMeditationFileDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getMeditationFileDetailsById = function getMeditationFileDetailsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetMeditationFileDetailsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getMeditationFilesDetails = function getMeditationFilesDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetMeditationFilesDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addPurposeFileDetails = function addPurposeFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addPurposeFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getPurposeFileDetailsById = function getPurposeFileDetailsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getPurposeFileDetailsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updatePurposeFileDetails = function updatePurposeFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.updatePurposeFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.deletePurposeDetailsByID = function deletePurposeDetailsByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.deletePurposeDetailsByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllPurposeFileDetails = function getAllPurposeFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getAllPurposeFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addDurationFileDetails = function addDurationFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addDurationFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updateDurationFileDetails = function updateDurationFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.UpdateDurationFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getDurationFileDetailsById = function getDurationFileDetailsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getDurationFileDetailsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllDurationFileDetails = function getAllDurationFileDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getAllDurationFileDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.deleteDurationFileDetailsById = function deleteDurationFileDetailsById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.DeleteDurationFileDetailsById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getGuidedMeditationFileDetailsV2 = function getGuidedMeditationFileDetailsV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getGuidedMeditationFileDetailsV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getPurposeMeditationFileDetailsByPageableV2 = function getPurposeMeditationFileDetailsByPageableV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getPurposeMeditationFileDetailsByPageableV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addMeditationFileDetailsV2 = function addMeditationFileDetailsV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addMeditationFileDetailsV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updateMeditationFileDetailsV2 = function updateMeditationFileDetailsV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.updateMeditationFileDetailsV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllMeditationFileDetailsV2 = function getAllMeditationFileDetailsV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetAllMeditationFileDetailsV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addMeditationBckgnd = function addMeditationBckgnd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.addMeditationBckgnd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getMeditationBckgndById = function getMeditationBckgndById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getMeditationBckgndById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.updateMeditationBckgnd = function updateMeditationBckgnd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.updateMeditationBckgnd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.deleteMeditationBckgnd = function deleteMeditationBckgnd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.deleteMeditationBckgnd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllMeditationBckgnd = function getAllMeditationBckgnd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getAllMeditationBckgnd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllMeditationBckgndWithSelectedLanguage = function getAllMeditationBckgndWithSelectedLanguage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.getAllMeditationBckgndWithSelectedLanguage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.createOrUpdateMeditationTags = function createOrUpdateMeditationTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.CreateOrUpdateMeditationTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllMeditationTags = function getAllMeditationTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetAllMeditationTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getMeditationByTagId = function getMeditationByTagId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetMeditationByTagId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.addOrUpdateMultipleTags = function addOrUpdateMultipleTags(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.AddOrUpdateMultipleTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GuidedMeditationServiceClient.prototype.getAllMeditationCount = function getAllMeditationCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GuidedMeditationService.GetAllMeditationCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.GuidedMeditationServiceClient = GuidedMeditationServiceClient;

