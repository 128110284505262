import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import AddIcon from "@mui/icons-material/Add";
import { getAllMoodDetails } from "redux/actions/moodActions";
import { ListSkeleton } from "components/listSkeleton";
import { Box } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";
import { MoodsForm } from "./moodsForm";
import { MenuProps } from "styles/global.styles";

interface State {
  editing?: boolean;
  editItem?: any;
  lang?: any;
}

export default function MoodsScreen(): ReactElement {
  const languageType = [
    "defaultcontent",
    "en",
    "hi",
    "te",
    "gu",
    "ta",
    "ka",
    "mr",
    "it",
    "ru",
    "es",
    "fr",
    "pt",
    "de",
    "bn",
    "ml",
    "uk",
    "od",
    "enUs",
    "enAu",
  ];

  const dispatch = useDispatch();
  const moodsList = useSelector((state: RootState) => state.moods.moodsList);
  const languages = useSelector((state: RootState) => state.app.languages);

  const initialState: State = {
    editing: false,
    editItem: null,
    lang: 1,
  };

  const [language, setLanguage] = useState<any>(1);

  const [currentIndex, setcurrentIndex] = useState(0);
  const [searchMood, setSearchMood] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [states, setStates] = useState<State>(initialState);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickBack = () => {
    setcurrentIndex(0);
    setSearchMood("");
    setStates({
      editing: false,
      editItem: null,
      lang: language,
    });
  };

  useEffect(() => {
    dispatch(getAllMoodDetails());
  }, [dispatch]);

  const renderMoodsList = (data: any) => {
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  fullWidth
                  variant="outlined"
                  placeholder="Search mood by title"
                  size="small"
                  value={searchMood}
                  onChange={(e) => {
                    setSearchMood(e.target.value);
                    setPage(0);
                    const filteredData = data.filter((item: any) =>
                      item.name[languageType[language - 1]]
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(getAllMoodDetails());
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell
                      align="left"
                      sx={{
                        width: "50%",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "50%",
                      }}
                    >
                      Mood Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchMood !== "" ? searchData : data)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: any, x: any) => (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer", height: "50px" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                              lang: language,
                            });
                            setcurrentIndex(1);
                          }}
                        >
                          <TableCell align="left">
                            {item.name[languageType[language - 1]]
                              ? item.name[languageType[language - 1]]
                              : item.name[languageType[0]]}
                          </TableCell>
                          <TableCell align="left">
                            {item.moodtype === 1 ? "Happy" : "Sad"}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={(searchMood !== "" ? searchData : data).length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No moods to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5">
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">All Moods</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setcurrentIndex(1);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Create Mood
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <FormControl variant="outlined" className="m-3" fullWidth>
                    <InputLabel id="language-select">Language</InputLabel>
                    {languages && (
                      <Select
                        defaultValue=""
                        labelId="language-select"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                          setLanguage(e.target.value as number);
                        }}
                        MenuProps={MenuProps}
                      >
                        {languages.map((lang: any, i: any) => (
                          <MenuItem key={i} value={lang.code}>
                            {lang.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    {moodsList.loading
                      ? ListSkeleton()
                      : renderMoodsList(moodsList.moods)}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <MoodsForm
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBack={onClickBack}
                lang={language}
                setPage={setPage}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
