import { grpc } from "@improbable-eng/grpc-web";
import {
  Locale,
  RequestPageable,
} from "api/models/gamification-service/common/common_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { firebaseAuth } from "config/firebase";
import {
  setProgramId,
  taskLoading,
  taskUpdate,
  themeLoading,
  themeUpdate,
} from "redux/reducers/programReducer";
import { AppThunk } from "redux/store/store";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import {
  GetAllProgramsRequest,
  GetAllThemesRequest,
  TaskDetails,
  ThemeDetails,
} from "api/models/gamification-service/program_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ID } from "api/models/gamification-service/gamification_pb";

interface addTaskType {
  title: any;
  description: any;
  url: any;
  cta: string;
  scheduleDate: any;
  activityTypeNum: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  isPublished: boolean;
  programId?: number;
  setSnackBar: any;
  getAlltaskData: any;
}

interface addThemeType {
  title: any;
  description: any;
  fromDate: any;
  toDate: any;
  programId?: number;
  setSnackBar: any;
  getAllThemeData: any;
}

interface updateTaskType extends addTaskType {
  id: number;
}

interface deleteTaskType {
  id: number;
  setSnackBar: any;
  getAlltaskData: any;
}
interface deleteThemeType {
  id: number;
  setSnackBar: any;
  getAllThemeData: any;
}
interface updateThemeType extends addThemeType {
  id: number;
}

export const getAllTasks =
  (pageNumber: number, pageSize: number): AppThunk =>
  async (dispatch) => {
    try {
      const taskReq: RequestPageable = new RequestPageable();
      taskReq.setPagenumber(pageNumber);
      taskReq.setPagesize(pageSize);
      dispatch(taskLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      console.log("getAll tasks", taskReq);
      grpc.unary(GamificationService.GetTaskList, {
        request: taskReq,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log("get tasks response", message.toObject());
            if (message) {
              dispatch(
                taskUpdate(message.toObject())
              );
            }
          }
        },
      });
    } catch (error) {
      dispatch(taskLoading());
      console.error("Error in get tasks", error);
    }
  };

export const getProgramId =
  (pageNumber: number, pageSize: number): AppThunk =>
  async (dispatch) => {
    try {
      const pgmReq: GetAllProgramsRequest = new GetAllProgramsRequest();
      const pageReq: RequestPageable = new RequestPageable();
      pageReq.setPagenumber(pageNumber);
      pageReq.setPagesize(pageSize);
      pgmReq.setPageable(pageReq);
      console.log("get program request", pgmReq);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.GetAllPrograms, {
        request: pgmReq,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log(
              "get program response",
              message.toObject(),
              // message.toObject().adminprogramsList[0].id
            );
            if (message) {
              dispatch(
                setProgramId(message.toObject().programlistwithalllanguageList[0].id)
              );
            }
          }
        },
      });
    } catch (error) {
      console.error("Error in get program", error);
    }
  };

export const addTask =
  (data: addTaskType): AppThunk =>
  async (dispatch) => {
    try {
      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultcontent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);

      const urlLocale: Locale = new Locale();
      urlLocale.setDefaultcontent(data.url.defaultcontent);
      urlLocale.setEn(data.url.en);
      urlLocale.setHi(data.url.hi);
      urlLocale.setTe(data.url.te);
      urlLocale.setGu(data.url.gu);
      urlLocale.setTa(data.url.ta);
      urlLocale.setKa(data.url.ka);
      urlLocale.setMr(data.url.mr);
      urlLocale.setIt(data.url.it);
      urlLocale.setRu(data.url.ru);
      urlLocale.setEs(data.url.es);
      urlLocale.setFr(data.url.fr);
      urlLocale.setPt(data.url.pt);
      urlLocale.setDe(data.url.de);
      urlLocale.setBn(data.url.bn);
      urlLocale.setMl(data.url.ml);
      urlLocale.setUk(data.url.uk);
      urlLocale.setOd(data.url.od);
      urlLocale.setEnUs(data.url.enUs);
      urlLocale.setEnAu(data.url.enAu);
      const {pageNumber, pageSize} = data.getAlltaskData;
      const scheduleat: Timestamp = new Timestamp();
      scheduleat.fromDate(new Date(data.scheduleDate));
      const payload = new TaskDetails();
      payload.setTitle(titleLocale);
      payload.setDescription(descriptionLocale);
      payload.setUrl(urlLocale);
      payload.setUseraction(data.cta.trim());
      payload.setScheduleat(scheduleat);
      payload.setIspublished(data.isPublished);
      payload.setProgramid(data.programId || 0);
      payload.setTaskactivitytype(data.activityTypeNum);
      console.log("add task payload", payload, scheduleat, data.getAlltaskData);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.CreateTask, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("add task response", message.toObject());
            if (res.message) {
              dispatch(getAllTasks(pageNumber, pageSize));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Task added successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding task : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in add task", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const updateTask =
  (data: updateTaskType): AppThunk =>
  async (dispatch) => {
    try {
      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultcontent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);

      const urlLocale: Locale = new Locale();
      urlLocale.setDefaultcontent(data.url.defaultcontent);
      urlLocale.setEn(data.url.en);
      urlLocale.setHi(data.url.hi);
      urlLocale.setTe(data.url.te);
      urlLocale.setGu(data.url.gu);
      urlLocale.setTa(data.url.ta);
      urlLocale.setKa(data.url.ka);
      urlLocale.setMr(data.url.mr);
      urlLocale.setIt(data.url.it);
      urlLocale.setRu(data.url.ru);
      urlLocale.setEs(data.url.es);
      urlLocale.setFr(data.url.fr);
      urlLocale.setPt(data.url.pt);
      urlLocale.setDe(data.url.de);
      urlLocale.setBn(data.url.bn);
      urlLocale.setMl(data.url.ml);
      urlLocale.setUk(data.url.uk);
      urlLocale.setOd(data.url.od);
      urlLocale.setEnUs(data.url.enUs);
      urlLocale.setEnAu(data.url.enAu);

      const {pageNumber, pageSize} = data.getAlltaskData;
      const scheduleat: Timestamp = new Timestamp();
      scheduleat.fromDate(new Date(data.scheduleDate));
      const payload = new TaskDetails();
      payload.setId(data.id);
      payload.setTitle(titleLocale);
      payload.setDescription(descriptionLocale);
      payload.setUrl(urlLocale);
      payload.setUseraction(data.cta.trim());
      payload.setScheduleat(scheduleat);
      payload.setIspublished(data.isPublished || false);
      payload.setProgramid(data.programId || 0);
      payload.setTaskactivitytype(data.activityTypeNum);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.UpdateTask, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            if (res.message) {
              dispatch(getAllTasks(pageNumber, pageSize));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Task updated successfully",
              });
            }
          } else {
            const errorMsg = statusMessage.split(":")[1].trim();
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `${errorMsg}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in update task", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const deleteTaskById =
  (item: deleteTaskType): AppThunk =>
  async (dispatch) => {
    try {
      const payload: ID = new ID();
      payload.setId(item.id);
      const {pageNumber, pageSize} = item.getAlltaskData;
      console.log("delete task payload", payload);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.DeleteTask, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("delete task response", message.toObject());
            if (res.message) {
              dispatch(getAllTasks(pageNumber, pageSize));
              item.setSnackBar({
                open: true,
                severity: "success",
                message: "Task deleted successfully",
              });
            }
          } else {
            item.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in deleting task : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in delete task", error);
      item.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

// Theme details

export const getAllTheme =
  (pageNumber: number, pageSize: number): AppThunk =>
  async (dispatch) => {
    try {
      const themeReq: GetAllThemesRequest = new GetAllThemesRequest();
      const pageReq: RequestPageable = new RequestPageable();
      pageReq.setPagenumber(pageNumber);
      pageReq.setPagesize(pageSize);
      themeReq.setPageable(pageReq);
      dispatch(themeLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      console.log("getAll theme", themeReq, jwtToken);
      grpc.unary(GamificationService.GetAllThemes, {
        request: themeReq,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log("get theme response", message.toObject());
            if (message) {
              dispatch(themeUpdate(message.toObject()));
            }
          }
        },
      });
    } catch (error) {
      dispatch(themeLoading());
      console.error("Error in get theme", error);
    }
  };

export const addTheme =
  (data: addThemeType): AppThunk =>
  async (dispatch) => {
    try {
      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultcontent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);

      const {pageNumber, pageSize} = data.getAllThemeData;
      const fromDate: Timestamp = new Timestamp();
      fromDate.fromDate(new Date(data.fromDate));

      const toDate: Timestamp = new Timestamp();
      toDate.fromDate(new Date(data.toDate));

      const payload = new ThemeDetails();
      payload.setTitle(titleLocale);
      payload.setDescription(descriptionLocale);
      payload.setProgramid(data.programId || 0);
      payload.setFromdate(fromDate);
      payload.setTodate(toDate);
      console.log("add theme payload", payload);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.CreateTheme, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("add theme response", message.toObject());
            if (res.message) {
              dispatch(getAllTheme(pageNumber, pageSize));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Theme added successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in adding theme : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in add task", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const updateTheme =
  (data: updateThemeType): AppThunk =>
  async (dispatch) => {
    try {
      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const descriptionLocale: Locale = new Locale();
      descriptionLocale.setDefaultcontent(data.description.defaultcontent);
      descriptionLocale.setEn(data.description.en);
      descriptionLocale.setHi(data.description.hi);
      descriptionLocale.setTe(data.description.te);
      descriptionLocale.setGu(data.description.gu);
      descriptionLocale.setTa(data.description.ta);
      descriptionLocale.setKa(data.description.ka);
      descriptionLocale.setMr(data.description.mr);
      descriptionLocale.setIt(data.description.it);
      descriptionLocale.setRu(data.description.ru);
      descriptionLocale.setEs(data.description.es);
      descriptionLocale.setFr(data.description.fr);
      descriptionLocale.setPt(data.description.pt);
      descriptionLocale.setDe(data.description.de);
      descriptionLocale.setBn(data.description.bn);
      descriptionLocale.setMl(data.description.ml);
      descriptionLocale.setUk(data.description.uk);
      descriptionLocale.setOd(data.description.od);
      descriptionLocale.setEnUs(data.description.enUs);
      descriptionLocale.setEnAu(data.description.enAu);
      const {pageNumber, pageSize} = data.getAllThemeData;
      const fromDate: Timestamp = new Timestamp();
      fromDate.fromDate(new Date(data.fromDate));

      const toDate: Timestamp = new Timestamp();
      toDate.fromDate(new Date(data.toDate));

      const payload = new ThemeDetails();
      payload.setId(data.id);
      payload.setTitle(titleLocale);
      payload.setDescription(descriptionLocale);
      payload.setProgramid(data.programId || 0);
      payload.setFromdate(fromDate);
      payload.setTodate(toDate);
      console.log("add theme payload", payload);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(GamificationService.UpdateTheme, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("update theme response", message.toObject());
            if (res.message) {
              dispatch(getAllTheme(pageNumber, pageSize));
              data.setSnackBar({
                open: true,
                severity: "success",
                message: "Theme updated successfully",
              });
            }
          } else {
            data.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in updating theme : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in update task", error);
      data.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };

export const deleteThemeById =
  (item: deleteThemeType): AppThunk =>
  async (dispatch) => {
    try {
      const payload: ID = new ID();
      payload.setId(item.id);
      const {pageNumber, pageSize} = item.getAllThemeData;
      console.log("delete theme payload", payload);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(GamificationService.DeleteTheme, {
        request: payload,
        host: GAMIFICATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: any) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            dispatch(getAllTheme(pageNumber, pageSize));
              item.setSnackBar({
                open: true,
                severity: "success",
                message: "Theme deleted successfully",
            });
          } else {
            item.setSnackBar({
              open: true,
              severity: "error",
              message: `Error in deleting theme : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error in delete theme", error);
      item.setSnackBar({
        open: true,
        severity: "error",
        message: { error },
      });
    }
  };
