import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Alert } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { firebaseAuth } from "config/firebase";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { grpc } from "@improbable-eng/grpc-web";
import {
  AddNewGoalInfoV2Request,
  DeleteGoalInfoV2ByIdResponse,
  ID,
  ResponseGoalInfoV2,
  updateGoalInfoV2Request,
} from "api/models/gamification-service/gamification_pb";
import {
  getAllGoalsDetails,
  getAllGoalsInfoV2,
} from "redux/actions/goalsAction";
import DialogBox from "components/dialogBox/dialog";
import { getBadgesAsync } from "redux/actions/dashboardActions";
const PREFIX = "GoalsForm";

const classes = {
  toggle: `${PREFIX}-toggle`,
  formBtn: `${PREFIX}-formBtn`,
  backdrop: `${PREFIX}-backdrop`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface GoalProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  pageSize: number;
  pageNumber: number;
  setPageNumber: any;
  isPageBackRequired: boolean;
}
interface deleteState {
  showDeleteModal?: boolean;
  deleteItemId?: number | null;
}

const goalCategories = [
  "UNKNOWN_GOAL_TYPE",
  "PURPOSEFUL_MEDITATION",
  "LIVE_MEDITATION_GOAL",
  "MEDITATION_STREAK_GOAL",
  "OFFLINE_HEARTFULNESS_PRACTICE",
  "JOURNAL_OBSERVATIONS",
  "SOCIAL_MEDIA_ACTIONS",
  "SHARE_LIVE_MEDITATION",
  "REFER_APP",
  "MEDITATE_ON_YOUR_OWN",
  "COMPOSITE_GOAL",
  "GUIDED_MEDITATION",
];

const meditationCategories = [
  "GUIDED PRACTICE",
  "PRAYER",
  "CLEANING",
  "MEDITATION",
];

const localeObject = {
  defaultcontent: "",
  en: "",
  hi: "",
  te: "",
  gu: "",
  ta: "",
  ka: "",
  mr: "",
  it: "",
  ru: "",
  es: "",
  fr: "",
  pt: "",
  de: "",
  bn: "",
  ml: "",
  uk: "",
  od: "",
  enUs: "",
  enAu: "",
};

export const GoalsFormV2 = React.forwardRef<HTMLDivElement, GoalProps>(
  (props, ref) => {
    const initialDeleteState = {
      showDeleteModal: false,
      deleteItemId: 0,
    };

    const dispatch = useDispatch();

    const languages = useSelector((state: RootState) => state.app.languages);

    const [goalId, setGoalId] = useState<any>("");
    const [language, setLanguage] = useState<any>(2);
    const [title, setTitle] = useState<any>("");
    const [type, setType] = useState<
      0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
    >(11);
    const [description, setDescription] = useState<string>("");
    const [days, setDays] = useState<number | string>(0);
    const [times, setTimes] = useState<number | string>(0);
    const [durationLimit, setDurationLimit] = useState<number | string>(0);

    const [editGoal, setEditGoal] = useState<boolean>(false);
    const [deleteGoal, setdeleteGoal] =
      useState<deleteState>(initialDeleteState);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPublished, setIsPublished] = useState<boolean>(false);
    const [isAdding, setIsAdding] = useState(false);
    const [meditationType, SetMeditationType] = useState<0 | 1 | 2 | 3>(0);

    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    const onClickEdit = useCallback((item: any) => {
      console.log("Edit goal details: ", item);
      setEditGoal(true);
      setTitle(item.goaltypes.title.en);
      setDescription(item.goaltypes.description.en);
      setGoalId(item.goaltypes.id);
      setDays(item.goaltypes.requireddays);
      setDurationLimit(item.goaltypes.duration);
      setTimes(item.goaltypes.requiredtimes);
      setType(item.goaltypes.goalcategory);
      setIsPublished(item?.goaltypes?.publishgoal);
      SetMeditationType(item.goaltypes.meditationcategory);
    }, []);

    useEffect(() => {
      dispatch(getBadgesAsync(1));

      if (props.isEditing) {
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit, dispatch]);

    useEffect(() => {
      if (props.isEditing) {
        if (props.isEditing)
          switch (language) {
            case 1:
              setTitle(props.editItem.goaltypes.title.defaultcontent);
              setDescription(
                props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 2:
              setTitle(props.editItem.goaltypes.title.en);
              setDescription(props.editItem.goaltypes.description.en);
              break;
            case 3:
              setTitle(
                props.editItem.goaltypes.title.hi
                  ? props.editItem.goaltypes.title.hi
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.hi
                  ? props.editItem.goaltypes.description.hi
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 4:
              setTitle(
                props.editItem.goaltypes.title.te
                  ? props.editItem.goaltypes.title.te
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.te
                  ? props.editItem.goaltypes.description.te
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 5:
              setTitle(
                props.editItem.goaltypes.title.gu
                  ? props.editItem.goaltypes.title.gu
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.gu
                  ? props.editItem.goaltypes.description.gu
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 6:
              setTitle(
                props.editItem.goaltypes.title.ta
                  ? props.editItem.goaltypes.title.ta
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.ta
                  ? props.editItem.goaltypes.description.ta
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 7:
              setTitle(
                props.editItem.goaltypes.title.ka
                  ? props.editItem.goaltypes.title.ka
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.ka
                  ? props.editItem.goaltypes.description.ka
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 8:
              setTitle(
                props.editItem.goaltypes.title.mr
                  ? props.editItem.goaltypes.title.mr
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.mr
                  ? props.editItem.goaltypes.description.mr
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 9:
              setTitle(
                props.editItem.goaltypes.title.it
                  ? props.editItem.goaltypes.title.it
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.it
                  ? props.editItem.goaltypes.description.it
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 10:
              setTitle(
                props.editItem.goaltypes.title.ru
                  ? props.editItem.goaltypes.title.ru
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.ru
                  ? props.editItem.goaltypes.description.ru
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 11:
              setTitle(
                props.editItem.goaltypes.title.es
                  ? props.editItem.goaltypes.title.es
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.es
                  ? props.editItem.goaltypes.description.es
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 12:
              setTitle(
                props.editItem.goaltypes.title.fr
                  ? props.editItem.goaltypes.title.fr
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.fr
                  ? props.editItem.goaltypes.description.fr
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 13:
              setTitle(
                props.editItem.goaltypes.title.pt
                  ? props.editItem.goaltypes.title.pt
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.pt
                  ? props.editItem.goaltypes.description.pt
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 14:
              setTitle(
                props.editItem.goaltypes.title.de
                  ? props.editItem.goaltypes.title.de
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.de
                  ? props.editItem.goaltypes.description.de
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 15:
              setTitle(
                props.editItem.goaltypes.title.bn
                  ? props.editItem.goaltypes.title.bn
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.bn
                  ? props.editItem.goaltypes.description.bn
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 16:
              setTitle(
                props.editItem.goaltypes.title.ml
                  ? props.editItem.goaltypes.title.ml
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.ml
                  ? props.editItem.goaltypes.description.ml
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 17:
              setTitle(
                props.editItem.goaltypes.title.uk
                  ? props.editItem.goaltypes.title.uk
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.uk
                  ? props.editItem.goaltypes.description.uk
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 18:
              setTitle(
                props.editItem.goaltypes.title.od
                  ? props.editItem.goaltypes.title.od
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.od
                  ? props.editItem.goaltypes.description.od
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 19:
              setTitle(
                props.editItem.goaltypes.title.enUs
                  ? props.editItem.goaltypes.title.enUs
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.enUs
                  ? props.editItem.goaltypes.description.enUs
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
            case 20:
              setTitle(
                props.editItem.goaltypes.title.enAu
                  ? props.editItem.goaltypes.title.enAu
                  : props.editItem.goaltypes.title.defaultcontent
              );
              setDescription(
                props.editItem.goaltypes.description.enAu
                  ? props.editItem.goaltypes.description.enAu
                  : props.editItem.goaltypes.description.defaultcontent
              );
              break;
          }
        console.log(props.editItem.goaltypes);
      }
    }, [language, props.editItem?.goaltypes, props.isEditing]);

    const addGoal = () => {
      var goalData: any = {
        title: {
          ...localeObject,
          defaultContent: title,
          en: title,
        },
        description: {
          ...localeObject,
          defaultContent: description,
          en: description,
        },
        days: Number(days),
        times: Number(times),
        duration: Number(durationLimit),
        goalCategory: type,
      };
      _addGoals(goalData);
    };

    const decideLocale = () => {
      switch (language) {
        case 1:
          return {
            title: {
              ...localeObject,
              defaultcontent: title,
              en: title,
            },
            description: {
              ...localeObject,
              defaultcontent: description,
              en: description,
            },
          };
        case 2:
          return {
            title: {
              ...localeObject,
              defaultcontent: title,
              en: title,
            },
            description: {
              ...localeObject,
              defaultcontent: description,
              en: description,
            },
          };
        case 3:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              hi: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              hi: description,
            },
          };
        case 4:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              te: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              te: description,
            },
          };

        case 5:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              gu: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              gu: description,
            },
          };
        case 6:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              ta: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              ta: description,
            },
          };
        case 7:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              ka: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              ka: description,
            },
          };
        case 8:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              mr: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              mr: description,
            },
          };
        case 9:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              it: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              it: description,
            },
          };
        case 10:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              ru: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              ru: description,
            },
          };
        case 11:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              es: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              es: description,
            },
          };
        case 12:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              fr: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              fr: description,
            },
          };
        case 13:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              pt: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              pt: description,
            },
          };
        case 14:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              de: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              de: description,
            },
          };
        case 15:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              bn: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              bn: description,
            },
          };
        case 16:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              ml: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              ml: description,
            },
          };
        case 17:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              uk: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              uk: description,
            },
          };
        case 18:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              od: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              od: description,
            },
          };
        case 19:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              enUs: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              enUs: description,
            },
          };
        case 20:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.goaltypes.title.defaultcontent,
              enAu: title,
            },
            description: {
              ...localeObject,
              defaultcontent:
                props.editItem.goaltypes.description.defaultcontent,
              enAu: description,
            },
          };
      }
    };

    const updateGoal = () => {
      const data = {
        id: goalId,
        title: decideLocale()?.title,
        description: decideLocale()?.description,
        days: Number(days),
        times: Number(times),
        duration: Number(durationLimit),
        goalCategory: type,
      };
      console.log("Update Details data: ", data);
      _updateGoals(data);
    };

    const onClickDelete = (goalId: number) => {
      setdeleteGoal({ showDeleteModal: true, deleteItemId: goalId });
    };
    const handleDeleteClose = () => {
      setdeleteGoal({ showDeleteModal: false });
    };
    const deleteGoals = () => {
      setdeleteGoal({ showDeleteModal: false });
      let data = {
        ID: deleteGoal.deleteItemId,
      };
      _deleteGoal(data);
    };

    const _addGoals = async (data: any) => {
      setIsLoading(true);

      setIsAdding(true);
      try {
        console.log("request data to add goal details", data);
        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultContent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultContent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.de);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);

        const payload = new AddNewGoalInfoV2Request();
        payload.setTitle(titleLocale);
        payload.setDescription(descriptionLocale);
        payload.setRequireddays(data.days);
        payload.setRequiredtimes(data.times);
        payload.setGoalcategory(data.goalCategory);
        payload.setAssociatedbadgeid(7);
        payload.setDuration(data.duration);
        payload.setMeditationcategory(0);
        payload.setPublishgoal(true);
        payload.setMeditationcategory(meditationType);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.AddNewGoalInfoV2, {
          request: payload,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseGoalInfoV2>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            console.log("grpc code", grpc.Code);

            if (status === grpc.Code.OK && message) {
              if (res.message) {
                setIsLoading(false);
                dispatch(getAllGoalsDetails(language));
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "goal added successfully",
                });
                dispatch(getAllGoalsInfoV2(props.pageSize, props.pageNumber));
                setTimeout(() => props.onClickBack(), 2000);
              }
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in adding goal : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.error("Error in add goals", error);
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error in adding goals",
        });
      }
    };

    const _updateGoals = async (data: any) => {
      setIsLoading(true);

      setIsAdding(true);
      try {
        const payload: updateGoalInfoV2Request = new updateGoalInfoV2Request();

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultcontent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultcontent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.de);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);

        payload.setId(data.id);
        payload.setTitle(titleLocale);
        payload.setDescription(descriptionLocale);
        payload.setRequireddays(data.days);
        payload.setRequiredtimes(data.times);
        payload.setGoalcategory(data.goalCategory);
        payload.setAssociatedbadgeid(7);
        payload.setDuration(data.duration);
        payload.setPublishgoal(isPublished);
        payload.setMeditationcategory(meditationType);
        console.log("request payload for update goal", payload.toObject());

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.UpdateGoalInfoV2, {
          request: payload,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseGoalInfoV2>) => {
            const { status, message, statusMessage } = res;

            if (status === grpc.Code.OK && message) {
              if (message) {
                setIsLoading(false);
                console.log("Update goal details response", message.toObject());
                dispatch(getAllGoalsDetails(language));
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "goal updated successfully",
                });
                dispatch(getAllGoalsInfoV2(props.pageSize, props.pageNumber));
                setTimeout(() => props.onClickBack(), 2000);
              }
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in updating goal:${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.error("Error in update goals", error);
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error in updating goals",
        });
      }
    };

    const _deleteGoal = async (data: any) => {
      try {
        console.log("Delete goal request", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ID = new ID();
        reqBody.setId(data.ID);
        setIsLoading(true);
        grpc.unary(GamificationService.DeleteGoalInfoV2ByID, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<DeleteGoalInfoV2ByIdResponse>) => {
            const { status, message, statusMessage } = res;
            if (status === grpc.Code.OK && message) {
              if (message) {
                console.log("delete goal details response", message.toObject());
                setIsLoading(false);
                dispatch(getAllGoalsDetails(language));
                setdeleteGoal({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: " Deleted goal successfully",
                });
                if (props.isPageBackRequired && props.pageNumber) {
                  dispatch(
                    getAllGoalsInfoV2(props.pageSize, props.pageNumber - 1)
                  );
                  props.setPageNumber(props.pageNumber - 1);
                } else {
                  getAllGoalsInfoV2(props.pageSize, props.pageNumber);
                }
              }
            } else {
              setIsLoading(false);
              setSnackBar({
                open: true,
                severity: "error",
                message: `error in  deleting Goal : ${statusMessage}`,
              });
              setdeleteGoal({ showDeleteModal: false });
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: "error in  deleting Goal",
        });
        console.log(err);
      }
    };
    return (
      <Root ref={ref}>
        <Backdrop open={isLoading} className={classes.backdrop}>
          <CircularProgress />
        </Backdrop>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className="p-2 align-items-center"
        >
          <Grid item>
            <Grid
              container
              className={props.isEditing ? "" : "align-items-center"}
            >
              <Grid item>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Goal" : "Create New Goal"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Goals
                    </Typography>
                    <Typography color="inherit" style={{ cursor: "pointer" }}>
                      {props.editItem.goaltypes.title.en}
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControlLabel
              checked={isPublished}
              control={<Switch name="publish" />}
              label={
                <Typography>
                  {isPublished ? "Published" : "Unpublished"}
                </Typography>
              }
              onChange={() => setIsPublished((publish: boolean) => !publish)}
            />
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(goalId)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="title"
                label="Goal Title"
                variant="outlined"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                disabled={props.editItem ? false : true}
              >
                <InputLabel id="language-select">Language</InputLabel>
                {languages && (
                  <Select
                    defaultValue=""
                    labelId="language-select"
                    value={language}
                    label="Language"
                    inputProps={{
                      readOnly: props.editItem ? false : true,
                    }}
                    onChange={(e) => {
                      setLanguage(e.target.value as number);
                    }}
                  >
                    {languages.map((lang, i) => (
                      <MenuItem key={i} value={lang.code}>
                        {lang.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={days}
                onChange={(e) => {
                  setDays(+e.target.value > 0 ? +e.target.value : "");
                }}
                label="Required Days"
                name="Days"
                required
                variant="outlined"
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={times}
                onChange={(e) => {
                  setTimes(+e.target.value > 0 ? +e.target.value : "");
                  console.log(e.target.value);
                }}
                label="Required Times"
                name="Times"
                required
                variant="outlined"
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={durationLimit}
                onChange={(e) => {
                  console.log("duration limit", e.target.value);
                  setDurationLimit(+e.target.value > 0 ? +e.target.value : "");
                }}
                label="Duration (in minutes)"
                name="durationLimitInDays"
                required
                variant="outlined"
                InputProps={{
                  readOnly: !props.isEditing || editGoal ? false : true,
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="medType">Goal Category</InputLabel>
                <Select
                  labelId="medType"
                  value={type}
                  required
                  disabled
                  onChange={(value: any) => {
                    // setType(value.target.value);
                    console.log("selected val", value.target.value);
                  }}
                  label="Goal Category"
                  inputProps={{
                    readOnly: !props.isEditing || editGoal ? false : true,
                  }}
                >
                  {goalCategories.map((item, index) => {
                    return (
                      <MenuItem key={`goal${index}`} value={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              // md={6}
            >
              <Grid>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="title"
                  label="Goal Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  InputProps={{
                    readOnly: !props.isEditing || editGoal ? false : true,
                  }}
                  className="mr-3"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="medType">Meditation Category</InputLabel>
                <Select
                  labelId="medType"
                  value={meditationType}
                  required
                  onChange={(value: any) => {
                    SetMeditationType(value.target.value);
                    // console.log("selected val", value.target.value);
                  }}
                  label="Meditation Category"
                >
                  {meditationCategories.map((item, index) => {
                    return (
                      <MenuItem key={`meditation${index}`} value={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item md={12} xs={12}>
              <Card variant="outlined">
                <Grid
                  container
                  className="pl-3 pr-2 py-3"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => {
                    props.isEditing && !editGoal
                      ? setexpand(false)
                      : setexpand(!expand);
                  }}
                >
                  <Grid item>
                    <Typography color="textSecondary">
                      Associated Badge
                    </Typography>
                    {selectedBadge ? (
                      <Grid container alignItems="center" className="mt-2">
                        <Grid className="mr-2">
                          <Avatar src={selectedBadge.icon} />
                        </Grid>
                        <Typography>{selectedBadge.name}</Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item>
                    {expand ? (
                      <ArrowDropUpIcon color="action" />
                    ) : (
                      <ArrowDropDownIcon color="action" />
                    )}
                  </Grid>
                </Grid>
                <Divider />
                {expand ? (
                  <CardContent>
                    <Box
                      maxHeight={350}
                      style={{ overflowY: "auto" }}
                      className={style.scrollbar}
                    >
                      {badges.badgesList.badges.map((item, index) => (
                        <React.Fragment key={`a${index}`}>
                          <Grid container className="p-1" alignItems="center">
                            <Grid item xs={2} md={1}>
                              <Avatar src={item.icon} />
                            </Grid>
                            <Grid item xs={9} md={10}>
                              <Typography variant="body1">
                                {item.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Radio
                                checked={`a${badgeId}` === `a${item.id}`}
                                onChange={(e: any) => {
                                  setBadgeId(e.target.value);
                                  setexpand(false);
                                  setselectedBadge(item);
                                }}
                                value={item.id}
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </Box>
                  </CardContent>
                ) : null}
              </Card>
            </Grid> */}
          </Grid>
          <DialogBox
            open={deleteGoal.showDeleteModal || false}
            onClose={handleDeleteClose}
            onCancel={handleDeleteClose}
            onConfirm={deleteGoals}
            type={"delete"}
            title={`Sure !! Want to delete this Item ?`}
          />
        </CardContent>
        <Divider />
        <CardActions className=" p-2 d-flex justify-content-end">
          <Box display="flex" justifyContent="flex-end">
            {props.isEditing ? (
              <>
                <Button
                  onClick={props.onClickBack}
                  color="primary"
                  variant="contained"
                  disabled={!editGoal}
                  className="mr-2"
                >
                  cancel
                </Button>
                <Button
                  onClick={() => {
                    console.log(props);
                    updateGoal();
                  }}
                  color="primary"
                  variant="contained"
                  disabled={
                    !editGoal ||
                    title === "" ||
                    description === "" ||
                    durationLimit === 0 ||
                    days === 0 ||
                    isAdding
                  }
                >
                  save
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  addGoal();
                }}
                color="primary"
                variant="contained"
                disabled={
                  title === "" ||
                  description === "" ||
                  +durationLimit < 1 ||
                  +days < 1 ||
                  +times < 1 ||
                  isAdding
                }
              >
                Add Goal
              </Button>
            )}
          </Box>
        </CardActions>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
