import { useEffect, useState } from "react";

export const useThemeDetector = () => {
  const getCurrentTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e: MediaQueryListEvent) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addEventListener("change", mqListener);
    return () => darkThemeMq.removeEventListener("change", mqListener);
  }, []);
  return isDarkTheme;
};

export const isValidEmail = (email: string) =>
  // eslint-disable-next-line
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    email
  );
export function fileToUint8Array(file: File) {
  let reader = new FileReader();
  return new Promise<Uint8Array>((resolve, reject) => {
    try {
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt: any) => {
        if (evt.target.readyState === FileReader.DONE) {
          let arrayBuffer = evt.target.result,
            arr = new Uint8Array(arrayBuffer);
          resolve(arr);
        }
      };
    } catch (e) {
      reject(e);
      console.log("something went wrong", e);
    }
  });
}

export function dataURLtoFile(dataurl: any, filename: any) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let croppedImg = new File([u8arr], filename, { type: mime });
  console.log("cropped image file", croppedImg);
  return croppedImg;
}
export function getCroppedImg(image: any, crop: any) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx: any = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  });
}

export const getFileName = (filename: string): string =>
  filename.substring(0, filename.lastIndexOf("."));

export const getFileExtension = (filename: string): string =>
  filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
  filename;

export const bytesToSize = (bytes: number): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`);
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

export const toUploadFile = (file: File, objectPath: string): UploadFile => ({
  file,
  objectPath,
  token: `${Math.round(Math.random() * 10000)}_${new Date().getTime()}`,
});

export const minifyText = (text: string, length: number) => {
  //console.log('text',text.length,'length',length);
  const str = text.slice(0, length);
  //const str1=str.slice(0,str.length-1).join(' ');
  return `${str}${text.length <= length ? "" : "..."}`;
};

export function getRouteforRoles(role: number) {
  switch (role) {
    case 3:
      return "guided";
    case 8:
      return "events";
    case 9:
      return "theme";
    default:
      return "live";
  }
}
