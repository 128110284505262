import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Grow,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import "firebase/auth";
import "firebase/database";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddUser from "./addUser";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import {
  getAllUserList,
  getUserDetailsByIdV2,
  searchUserDetails,
} from "redux/actions/userAction";
import { ListSkeleton } from "components/listSkeleton";
// import style from "./index.module.scss";
import DialogBox from "components/dialogBox/dialog";
import { firebaseAuth } from "config/firebase";
import {
  RequestForDeleteUserAccountFromFireBase,
  RequestUserID,
} from "api/models/heartintune-profile-service/heartintune-profile_pb";
import { HeartIntuneProfileService } from "api/models/heartintune-profile-service/heartintune-profile_pb_service";
import { HEARTINTUNE_PROFILE_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";
import { grpc } from "@improbable-eng/grpc-web";
import { Alert } from "@mui/material";
import NotificationModal from "components/notificationModal/notificationModal";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import style from "../resources/articles/index.module.scss";

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: number;
  showModal?: boolean;
  userId?: number;
}

const userRole = [
  "UNKNOWN_ROLE",
  "SUPER_ADMIN",
  "ADMIN",
  "CONTENT_OWNER",
  " USER",
  "PRECEPTOR",
  " CUSTOM_ROLE",
  " SCHEDULER",
  "EVENTS_ANNOUNCEMENTS_ADMIN_ROLE",
  "BHE_CONTENT_MANAGER"
];

let searchInterval;

export default function UsersScreen(): ReactElement {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.user);

  const initialDeleteState = {
    showDeleteModal: false,
    deleteItemId: 0,
  };
  const deleteState = {
    showDeleteModal: false,
    deleteItemId: 0,
  };
  const userDetails = {
    showModal: false,
    userId: 0,
  };

  const [currentIndex, setcurrentIndex] = useState(0);
  const [searchFilter, setSearchFilter] = useState("name");
  const [searchUser, setsearchUser] = useState("");
  const [pageNumber, setpageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(10);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteUser, setdeleteUser] = useState<State>(initialDeleteState);
  const [deleteFromFirebase, setdeleteFromFirebase] =
    useState<State>(deleteState);
  const [deleteUserSuccess, setdeleteUserSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openNotifiationPopup, setopenNotifiationPopup] =
    useState<State>(userDetails);
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });
  //console.log('searched user data',state.searchedUsersList.users.userList)

  useEffect(() => {
    if (searchUser !== "") {
      dispatch(
        searchUserDetails({
          searchFilter,
          searchInput: searchUser,
          pageNumber,
          pageSize,
        })
      );
    } else {
      dispatch(getAllUserList(pageSize, pageNumber + 1));
    }
  }, [dispatch, pageNumber, pageSize, searchFilter]);

  console.log("user data", state);

  const onClickAddUser = () => {
    setcurrentIndex(1);
  };
  const onClickBack = () => {
    setsearchUser("");
    dispatch(getAllUserList(pageSize, pageNumber));
    setTimeout(() => {
      setcurrentIndex(0);
    }, 100);
  };

  const handleChangeFilter = (event: SelectChangeEvent) => {
    setSearchFilter(event.target.value as string);
    setsearchUser("");
  };

  const handleChangePage = (event: any, newPage: number) => {
    setpageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setpageSize(+event.target.value);
    setpageNumber(0);
  };

  const onDeleteUser = (userId: number, firebaseId: number) => {
    console.log("usere id", userId);
    //o1Jpq4fALuR1ON8IfnYcO9sXJvs1
    setdeleteUser({ showDeleteModal: true, deleteItemId: userId });
    // setdeleteFromFirebase({ showDeleteModal: false, deleteItemId: firebaseId });
  };
  const handleDeleteClose = () => {
    setdeleteUser({ showDeleteModal: false });
  };
  const _deleteUser = () => {
    const data = {
      UserID: deleteUser.deleteItemId,
    };
    _deleteUserAccount(data);
  };
  const closeModal = () => {
    setopenNotifiationPopup({ showModal: false, userId: 0 });
  };
  //-------------------------- delete from firebase----------------------------->
  const handleDelete = () => {
    dispatch(getAllUserList(pageSize, pageNumber));
    setdeleteFromFirebase({ showDeleteModal: false });
  };
  const _deleteFirebaseId = () => {
    console.log("firebase id", deleteFromFirebase.deleteItemId);
    const data = {
      fireBaseId: deleteFromFirebase.deleteItemId,
    };
    _deleteUserFromFirebase(data);
    setsearchUser("");
    dispatch(getAllUserList(pageSize, pageNumber));
  };

  const _deleteUserAccount = async (data: any) => {
    try {
      console.log("Delete User data", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestUserID = new RequestUserID();
      reqBody.setUserid(data.UserID);
      setIsLoading(true);
      grpc.unary(HeartIntuneProfileService.DeleteUserAccount, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ProtobufMessage>) => {
          const { status, message, statusMessage } = res;

          console.log("statusMessage", statusMessage);
          console.log("status", status);
          console.log("grpc.Code", grpc.Code);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("delete user response", message.toObject());
              setIsLoading(false);
              setdeleteUser({ showDeleteModal: false });
              setTimeout(() => {
                setdeleteUserSuccess(true);
                setdeleteFromFirebase({
                  ...deleteFromFirebase,
                  showDeleteModal: true,
                });
              }, 2000);
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted user successfully",
              });
            }
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in  deleting User",
            });
            setdeleteUser({ showDeleteModal: false });
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in  deleting User",
      });
      console.log(err);
    }
  };

  const _deleteUserFromFirebase = async (data: any) => {
    try {
      console.log("Delete User data", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestForDeleteUserAccountFromFireBase =
        new RequestForDeleteUserAccountFromFireBase();
      reqBody.setFirebaseid(data.fireBaseId);
      setIsLoading(true);
      grpc.unary(HeartIntuneProfileService.DeleteUserAccountFromFireBase, {
        host: HEARTINTUNE_PROFILE_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ProtobufMessage>) => {
          const { status, message, statusMessage } = res;

          console.log("statusMessage", statusMessage);
          console.log("status", status);
          console.log("grpc.Code", grpc.Code);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "delete user firebase id response",
                message.toObject()
              );
              setIsLoading(false);
              setdeleteUserSuccess(false);
              dispatch(getAllUserList(pageSize, pageNumber));

              setdeleteFromFirebase({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted user from firebase successfully",
              });
            }
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: "error in  deleting User from firebase",
            });
            setdeleteFromFirebase({ showDeleteModal: false });
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      setSnackBar({
        open: true,
        severity: "error",
        message: "error in  deleting User from firebase",
      });
      console.log(err);
    }
  };
  const searchUserHandler = (searchInput) => {
    clearTimeout(searchInterval);
    if (searchInput !== "") {
      searchInterval = setTimeout(() => {
        setpageNumber(0);
        dispatch(
          searchUserDetails({
            searchFilter,
            searchInput,
            pageNumber: 0,
            pageSize,
          })
        );
      }, 500);
    } else {
      setpageNumber(0);
      dispatch(getAllUserList(pageSize, 0));
    }
  };

  const userLang = (selectedLang: number) => {
    switch (selectedLang) {
      case 1:
        return "English";
      case 2:
        return "English";
      case 3:
        return "Hindi";
      case 4:
        return "Telugu";
      case 5:
        return "Gujarati";
      case 6:
        return "Tamil";
      case 7:
        return "Kannada";
      case 8:
        return "Marathi";
      case 9:
        return "Italian";
      case 10:
        return "Russian";
      case 11:
        return "Spanish";
      case 12:
        return "French";
      case 13:
        return "Portuguese";
      case 14:
        return "German";
      case 15:
        return "Bengali";
      case 16:
        return "Malayalam";
      case 17:
        return "Ukrainian";
      case 18:
        return "Odiya";
      case 19:
        return "English US";
      case 20:
        return "English AUS";
    }
  };

  const userProvider = (providerId: number) => {
    switch (providerId) {
      case 0:
        return "Unknown";
      case 1:
        return "Facebook";
      case 2:
        return "Google";
      case 3:
        return "Apple";
      case 4:
        return "Email";
      case 5:
        return "Mobile";
      default:
        return "N/A";
    }
  };

  const renderUserList = (data: any) => {
    return (
      <div>
        {data.length ? (
          <div>
            <TableContainer style={{ maxHeight: "400px" }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="center">User Id</TableCell>
                    <TableCell align="left"> Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Mobile No.</TableCell>
                    <TableCell align="center">Language</TableCell>
                    <TableCell align="center">Provider</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="center">Delete</TableCell>
                    {/* <TableCell align="center">Clear Cache</TableCell> */}
                    <TableCell align="center">Send Notification</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((user: any, x: any) => {
                    //console.log('first',user)
                    return (
                      <React.Fragment key={x}>
                        <TableRow hover>
                          <TableCell align="center">
                            <Avatar
                              alt={user.displayname}
                              src={user.profileurl}
                            />
                          </TableCell>
                          <TableCell align="center">{user.id}</TableCell>
                          <TableCell>{user.displayname}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.mobilenumber}</TableCell>
                          <TableCell align="center">
                            {userLang(user.selectedlanguage)}
                          </TableCell>
                          <TableCell align="center">
                            {userProvider(user.provider)}
                          </TableCell>
                          <TableCell align="left">
                            {userRole[user.role]}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={() => {
                                onDeleteUser(user.id, user.firebaseid);
                                setdeleteFromFirebase({
                                  showDeleteModal: false,
                                  deleteItemId: user.firebaseid,
                                });
                              }}
                              size="large"
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                          {/* <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              clearCache(user.id, setSnackBar);
                            }}
                            color="primary"
                            size="large"
                          >
                            <CachedSharpIcon />
                          </IconButton>
                        </TableCell> */}
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setopenNotifiationPopup({
                                  showModal: true,
                                  userId: user.id,
                                });
                                dispatch(getUserDetailsByIdV2(user.id));
                              }}
                              size="large"
                            >
                              <AddAlertIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={state.usersList.users.totalcount}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </div>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No user data
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          {/* <VideoPlayers
            src="https://heartintune-dev-storage.storage.googleapis.com/Meditate%20with%20purpose/1%20Reduce%20Stress/relax/playlist.m3u8"
            onAudioClose={() => {}}
          /> */}
          <Card className="mt-3 mb-5">
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                {isLoading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      zIndex: 5,
                    }}
                  />
                )}
                <Grid container className="align-items-center p-3">
                  <Typography variant="h6">All Users</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickAddUser}
                    startIcon={<AddIcon />}
                    className="ml-3"
                  >
                    Add User
                  </Button>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    <Grid
                      style={{ backgroundColor: "transparent" }}
                      className="d-flex py-2 pl-3 align-items-center"
                    >
                      <Grid
                        sx={{
                          width: "200px",
                          mr: 2,
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>Filter</InputLabel>
                          <Select
                            value={searchFilter}
                            label="Filter"
                            size="small"
                            onChange={handleChangeFilter}
                          >
                            <MenuItem value={"name"}>Name</MenuItem>
                            <MenuItem value={"email"}>Email</MenuItem>
                            <MenuItem value={"phone"}>Phone Number</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid className="d-flex flex-grow-1">
                        <TextField
                          type="search"
                          fullWidth
                          variant="outlined"
                          placeholder={`Search user by ${searchFilter}`}
                          size="small"
                          value={searchUser}
                          onChange={(e) => {
                            setsearchUser(e.target.value.toLowerCase());
                            searchUserHandler(e.target.value.toLowerCase());
                          }}
                          className="mr-2"
                        />
                      </Grid>
                      <Grid className="d-flex align-items-center">
                        <IconButton
                          onClick={() => {
                            setsearchUser("");
                            setpageNumber(0);
                            dispatch(getAllUserList(pageSize, 0));
                          }}
                          size="large"
                        >
                          <Tooltip title="Refresh">
                            <RefreshIcon />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {state.usersList.loading
                      ? ListSkeleton()
                      : renderUserList(state.usersList.users.userinfoList)}
                  </Paper>
                </CardContent>
                <DialogBox
                  open={deleteUser.showDeleteModal || false}
                  onClose={handleDeleteClose}
                  onCancel={handleDeleteClose}
                  onConfirm={_deleteUser}
                  type={"delete"}
                  title={`Sure !! Want to delete this Id ?`}
                />
                {deleteUserSuccess && (
                  <DialogBox
                    open={deleteFromFirebase.showDeleteModal || false}
                    onClose={handleDelete}
                    onCancel={handleDelete}
                    onConfirm={_deleteFirebaseId}
                    type={"delete firebase"}
                    title={`Do you want to delete this Id from firebase?`}
                  />
                )}
                <NotificationModal
                  open={openNotifiationPopup.showModal || false}
                  onClose={closeModal}
                  setSnackBar={setSnackBar}
                  setsearchUser={setsearchUser}
                />
                <Snackbar
                  open={snackBar.open}
                  autoHideDuration={10000}
                  onClose={() => {
                    setSnackBar({ ...snackBar, open: false });
                  }}
                  anchorOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <Alert
                    severity={snackBar.severity}
                    onClose={() => {
                      setSnackBar({ ...snackBar, open: false });
                    }}
                  >
                    {snackBar.message}
                  </Alert>
                </Snackbar>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <AddUser onClickBack={onClickBack} />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
