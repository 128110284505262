import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImageOutlined, VideoFileOutlined } from "@mui/icons-material";
import {
  ContentCategoryRequest,
  ContentRequest,
  ContentResponse,
  DeleteFileRequest,
  ResponseStatus,
  Status,
  Tag,
} from "api/models/gamification-service/gamification_pb";
import { firebaseAuth } from "config/firebase";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import DialogBox from "components/dialogBox/dialog";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import { useFileUploadService } from "services/FileUplaodService";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import VideoPlayers from "components/video-player/videoPlayer";
import {
  getAllFilters,
  getContentByCategory,
} from "redux/actions/contentActions";
import { useDispatch, useSelector } from "react-redux";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { RootState } from "redux/store/store";
import { Crop } from "react-image-crop";
import ImageCrop from "components/image-crop/image-crop";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { getAllTags } from "redux/actions/tagActions";

interface VideosProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20;
  page: number;
  rowsPerPage: number;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const PREFIX = "VideosForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
    //overflow: "scroll",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export const VideosForm = React.forwardRef<HTMLDivElement, VideosProps>(
  (props, ref): ReactElement => {
    const hiddenVideoInput: any = React.useRef(null);
    const hiddenFileInputThumbnail: any = React.useRef(null);
    const hiddenFileInputImage: any = React.useRef(null);
    const hiddenFileInputFeaturedimage: any = React.useRef(null);

    const languageType = useMemo(
      () => [
        "defaultcontent",
        "en",
        "hi",
        "te",
        "gu",
        "ta",
        "ka",
        "mr",
        "it",
        "ru",
        "es",
        "fr",
        "pt",
        "de",
        "bn",
        "ml",
        "uk",
        "od",
        "enUs",
        "enAu",
      ],
      []
    );

    const filterList = useSelector(
      (state: RootState) =>
        state.content.filters.filtersList.contentfilterresponseList
    );

    const tagsList = useSelector((state: RootState) => state.tags.tagList);

    const videoCategoryList = [
      {
        displayName: "Featured video",
        code: 1,
      },
      {
        displayName: "Daaji talks",
        code: 2,
      },
      // {
      //   displayName: "All videos",
      //   code: 3,
      // },
      {
        displayName: "Why I meditate ?",
        code: 4,
      },
    ];

    const cropDef: Crop = {
      x: 25,
      y: 25,
      height: 20,
      width: 20,
      unit: "%",
    };

    const videoFileDef: any = {
      file: null,
      fileUrl: null || "",
    };

    const dispatch = useDispatch();
    const languages = useSelector((state: RootState) => state.app.languages);
    const { appendFilesToUploadManager } = useFileUploadService();
    const [states, setStates] = useState<State>(initialState);
    const [videoCategory, setVideoCategory] = useState<any>(1);
    const [id, setId] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [readingTime, setReadingTime] = useState<string>("0");
    // const [tag, setTag] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState({
      image: false,
      selectedImage: false,
      video: false,
      thumbnail: false,
      featuredimage: false,
    });
    const [language, setLanguage] = useState<any>(1);
    const [selectedFilers, setSlectedFilters] = useState<string[]>([]);
    const [selectedFilersId, setSlectedFiltersId] = useState<any[]>([]);

    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [videoUploadUrl, setVideoUploadUrl] = useState<string>("");
    const [videoFile, setVideoFile] = useState(videoFileDef);

    const [imageUrl, setImageUrl] = useState<any>(null);
    const [imageSrc, setImageSrc] = useState<any>(null);
    const [imageCrop, setImageCrop] = useState<Crop>(cropDef);
    const [imageRef, setImageRef] = useState<any>();
    const [imageFile, setImageFile] = useState<any>(null);

    const [thumbnailUrl, setThumbnailUrl] = useState<any>(null);
    const [thumbnailSrc, setThumbnailSrc] = useState<any>(null);
    const [thumbnailCrop, setThumbnailCrop] = useState<Crop>(cropDef);
    const [thumbnailRef, setThumbnailRef] = useState<any>();
    const [thumbnailFile, setThumbnailFile] = useState<any>(null);

    const [featuredimageUrl, setFeaturedimageUrl] = useState<any>(null);
    const [featuredimageSrc, setFeaturedimageSrc] = useState<any>(null);
    const [featuredimageCrop, setFeaturedimageCrop] = useState<Crop>(cropDef);
    const [featuredimageRef, setFeaturedimageRef] = useState<any>();
    const [featuredimageFile, setFeaturedimageFile] = useState<any>(null);
    const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);
    const [activityType, setActivityType] = useState<string>("");

    const [open, setOpen] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const onClickEdit = useCallback(
      (item: any) => {
        setStates({ editing: true });
        setLanguage(props.lang);
        setId(item.id);
        setTitle(item.title);
        setDescription(item.subtitle);
        setReadingTime(item.readingtime);
        setVideoUploadUrl(item.videourl);
        setImageUrl(item.image);
        setThumbnailUrl(item.thumbnail);
        setFeaturedimageUrl(item.featuredimage);
        setVideoCategory(item.videocategory);
        setSlectedFiltersId(item.filteridsList);
        setSelectedTagsId(item.tagidsList);
        setSelectedTags(item.tagsList.map((item) => item.name));
        setActivityType(item.attribute);
      },
      [props.lang]
    );

    useEffect(() => {
      if (props.isEditing) {
        console.log(props.editItem);
        onClickEdit(props.editItem);
      }
    }, [props.isEditing, props.editItem, onClickEdit]);

    useEffect(() => {
      dispatch(getAllFilters({ pageNumber: 0, pageSize: 100 }));
      dispatch(getAllTags(10, page));
    }, [page]);

    useEffect(() => {
      const temp = filterList.filter((item) =>
        selectedFilersId.includes(item.id)
      );

      const arrOfString = temp.map(
        (item: any) => item.contentfiltername[languageType[language - 1]]
      );
      setSlectedFilters(arrOfString);
    }, [filterList, language, languageType, selectedFilersId]);

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };

    const onVideoClose = () => {
      setVideoFile(videoFileDef);
      setVideoUploadUrl("");
    };

    const handleChangeForMultipleSelect = (event: any) => {
      const {
        target: { value },
      } = event;
      console.log(value);

      const arrOfNum: number[] = (
        typeof value === "string" ? value.split(",") : value
      ).map((str) => {
        return Number(str);
      });

      setSlectedFiltersId(arrOfNum);
    };

    const handleUploadClickThumbnail = () => {
      hiddenFileInputThumbnail.current.click();
    };

    const handleUploadClickImage = () => {
      hiddenFileInputImage.current.click();
    };

    const handleUploadClickFeaturedimage = () => {
      hiddenFileInputFeaturedimage.current.click();
    };

    const onCropComplete = async () => {};

    const onOpenImage = () => {
      setOpen(true);
    };
    const onCloseImage = () => {
      setOpen(false);
      setImageSrc("");
      setImageCrop(cropDef);
      setIsUploading({
        ...isUploading,
        thumbnail: false,
        image: false,
        selectedImage: false,
        featuredimage: false,
      });
    };
    const onCloseThumbnail = () => {
      setOpen(false);
      setThumbnailSrc("");
      setThumbnailCrop(cropDef);
      setIsUploading({
        ...isUploading,
        image: false,
        thumbnail: false,
        selectedImage: false,
        featuredimage: false,
      });
    };
    const onCloseFeaturedImage = () => {
      setOpen(false);
      setFeaturedimageSrc("");
      setFeaturedimageCrop(cropDef);
      setIsUploading({
        ...isUploading,
        image: false,
        thumbnail: false,
        selectedImage: false,
        featuredimage: false,
      });
    };

    const onThumbnailLoaded = (image: any) => {
      setThumbnailRef(image);
    };

    const onSelectThumbnail = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, thumbnail: true });
        setThumbnailFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setThumbnailSrc(reader.result);
          setOpen(true);
        };
      }
    };

    const onThumbnailCropDone = async () => {
      const cr: any = thumbnailCrop;
      if (thumbnailRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(thumbnailRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${thumbnailFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setThumbnailUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          `${
            language === 1
              ? "resources/article-images/thumbnails"
              : `resources/article-images/thumbnails/${languages[
                  language - 1
                ].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setThumbnailUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, thumbnail: false });
            } else {
              setIsUploading({ ...isUploading, thumbnail: false });
            }
          }
        );
      }
      setThumbnailSrc(null);
    };

    const onThumbnailCropChange = (crop: any) => {
      setThumbnailCrop(crop);
    };

    const onThumbnailClose = () => {
      setThumbnailSrc(null);
      _deleteFileFromGcp(thumbnailUrl);
      setThumbnailUrl("");
      setThumbnailFile(null);
      setThumbnailCrop(cropDef);
      setIsUploading({ ...isUploading, thumbnail: false });
    };

    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onSelectImage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, image: true });
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setImageSrc(reader.result);
          setOpen(true);
        };
      }
    };

    const onImageCropDone = async () => {
      const cr: any = imageCrop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setImageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          `${
            language === 1
              ? "resources/article-images/images"
              : `resources/article-images/images/${languages[
                  language - 1
                ].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setImageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, image: false });
            } else {
              setIsUploading({ ...isUploading, image: false });
            }
          }
        );
      }
      setImageSrc(null);
    };

    const onImageCropChange = (crop: any) => {
      setImageCrop(crop);
    };

    const onImageClose = () => {
      setImageSrc(null);
      _deleteFileFromGcp(imageUrl);
      setImageUrl("");
      setImageFile(null);
      setImageCrop(cropDef);
      setIsUploading({ ...isUploading, image: false });
    };

    const onFeaturedimageLoaded = (image: any) => {
      setFeaturedimageRef(image);
    };

    const onSelectFeaturedimage = (event: any) => {
      if (event.target.files[0]) {
        setIsUploading({ ...isUploading, featuredimage: true });
        setFeaturedimageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setFeaturedimageSrc(reader.result);
          setOpen(true);
        };
      }
    };

    const handleMultipleTagChange = (event: any) => {
      const {
        target: { value },
      } = event;
      // console.log(value);

      const arrOfNum: number[] = (
        typeof value === "string" ? value.split(",") : value
      ).map((str) => {
        return Number(str);
      });
      const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
      const tagIndex = selectedTagsId.indexOf(val[0]);

      setSelectedTagsId(arrOfNum);

      if (tagIndex > -1) {
        setSelectedTags(() => {
          const updatedTags = [...selectedTags];
          updatedTags.splice(tagIndex, 1);
          // console.log("updated Tags: ", updatedTags);
          return updatedTags;
        });
      } else if (tagIndex === -1) {
        setSelectedTags(() => {
          const temp = tagsList.tags.tagsList.filter((item) =>
            value.includes(item.id)
          );

          const arrOfString = temp.map((item: any) => item.name);
          return [...new Set([...selectedTags, ...arrOfString])];
        });
      }
    };

    const tagsArray = () => {
      let arr: Tag[] = [];
      for (const tagIndex in selectedTags) {
        const temp = new Tag();
        temp.setId(selectedTagsId[tagIndex]);
        temp.setName(selectedTags[tagIndex]);
        arr.push(temp);
      }

      console.log("tagsArray: ", arr);
      return arr;
    };

    const onFeaturedimageCropDone = async () => {
      const cr: any = featuredimageCrop;
      if (featuredimageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(featuredimageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date()
            .getTime()
            .toString()}_${featuredimageFile.name.replaceAll(" ", "")}`
        );
        setFeaturedimageUrl(croppedUrl);
        const uploadFile = toUploadFile(
          croppedFile,
          `${
            language === 1
              ? "resources/article-images/featured-images"
              : `resources/article-images/featured-images/${languages[
                  language - 1
                ].displayName
                  .toLowerCase()
                  .replaceAll(" ", "")}`
          }`
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              setFeaturedimageUrl(res.detail.response);
              console.log("image url res", res.detail.response);
              setIsUploading({ ...isUploading, featuredimage: false });
            } else {
              setIsUploading({ ...isUploading, featuredimage: false });
            }
          }
        );
      }
      setFeaturedimageSrc(null);
    };

    const onFeaturedimageCropChange = (crop: any) => {
      setFeaturedimageCrop(crop);
    };

    const onFeaturedimageClose = () => {
      setFeaturedimageSrc(null);
      _deleteFileFromGcp(featuredimageUrl);
      setFeaturedimageUrl("");
      setFeaturedimageFile(null);
      setFeaturedimageCrop(cropDef);
      setIsUploading({ ...isUploading, featuredimage: false });
    };

    const validateForm = () => {
      if (title.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video title can not be empty.",
        });
      } else if (readingTime <= "0") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video watching time can not be less than or equal to zero.",
        });
      } else if (Number(readingTime) > 10000000) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video watching time can not be more than 10000000",
        });
      } else if (Number(readingTime) - Math.floor(Number(readingTime)) !== 0) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video watching time can not be in decimal.",
        });
        // } else if (tag === "") {
        //   setSnackBar({
        //     open: true,
        //     severity: "error",
        //     message: "Video tag can not be empty.",
        //   });
      } else if (activityType.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Activity type  can not be empty",
        });
      } else if (videoUploadUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video can not be empty",
        });
      } else if (!imageUrl) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video image can not be empty.",
        });
      } else if (!thumbnailUrl) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video thumbnail can not be empty.",
        });
      } else if (!featuredimageUrl) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video feature image can not be empty.",
        });
      } else if (description.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Video description can not be empty",
        });
      } else if (activityType === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Activity type can not be empty",
        });
      } else {
        return true;
      }
    };

    const _addVideo = () => {
      var videoData: any = {};
      if (validateForm()) {
        switch (props.lang) {
          case 1:
            videoData = {
              title: {
                defaultContent: title,
                en: title,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: description,
                en: description,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: videoUploadUrl,
                en: videoUploadUrl,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: activityType,
                en: activityType,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 2:
            videoData = {
              title: {
                defaultContent: title,
                en: title,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: description,
                en: description,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: videoUploadUrl,
                en: videoUploadUrl,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: activityType,
                en: activityType,
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 3:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: title,
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: description,
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: videoUploadUrl,
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: activityType,
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 4:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: title,
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: description,
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: videoUploadUrl,
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: activityType,
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 5:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: title,
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: description,
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: videoUploadUrl,
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: activityType,
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 6:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: title,
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: description,
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: videoUploadUrl,
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: activityType,
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 7:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: title,
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: description,
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: videoUploadUrl,
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: activityType,
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 8:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: title,
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: description,
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: videoUploadUrl,
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: activityType,
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 9:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: title,
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: description,
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: videoUploadUrl,
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: activityType,
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 10:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: title,
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: description,
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: videoUploadUrl,
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: activityType,
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 11:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: title,
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: description,
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: videoUploadUrl,
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: activityType,
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 12:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: title,
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: description,
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: videoUploadUrl,
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: activityType,
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 13:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: title,
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: description,
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: videoUploadUrl,
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: activityType,
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 14:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: title,
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: description,
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: videoUploadUrl,
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: activityType,
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 15:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: title,
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: description,
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: videoUploadUrl,
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: activityType,
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 16:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: title,
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: description,
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: videoUploadUrl,
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: activityType,
                uk: "",
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 17:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: title,
                od: "",
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: description,
                od: "",
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: videoUploadUrl,
                od: "",
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: activityType,
                od: "",
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 18:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: title,
                enUs: "",
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: description,
                enUs: "",
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: videoUploadUrl,
                enUs: "",
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: activityType,
                enUs: "",
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 19:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: title,
                enAu: "",
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: description,
                enAu: "",
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: videoUploadUrl,
                enAu: "",
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: activityType,
                enAu: "",
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
          case 20:
            videoData = {
              title: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: title,
              },
              description: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: description,
              },
              videourl: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: videoUploadUrl,
              },
              attribute: {
                defaultContent: "",
                en: "",
                hi: "",
                te: "",
                gu: "",
                ta: "",
                ka: "",
                mr: "",
                it: "",
                ru: "",
                es: "",
                fr: "",
                pt: "",
                de: "",
                bn: "",
                ml: "",
                uk: "",
                od: "",
                enUs: "",
                enAu: activityType,
              },
              image: imageUrl,
              thumbnail: thumbnailUrl,
              featuredimage: featuredimageUrl,
              videoCategory: videoCategory,
              readingTime: readingTime,
              selectedFilersId: selectedFilersId,
              isWebView: false,
              webViewUrl: "",
              contentCategory: 4,
            };
            break;
        }

        if (props.isEditing) {
          setIsAdding(true);
          updateVideo(videoData);
        } else {
          setIsAdding(true);
          const videoData = {
            title: {
              defaultContent: title,
              en: title,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            description: {
              defaultContent: description,
              en: description,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            videourl: {
              defaultContent: videoUploadUrl,
              en: videoUploadUrl,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
            attribute: {
              defaultContent: activityType,
              en: activityType,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },

            image: imageUrl,
            thumbnail: thumbnailUrl,
            featuredimage: featuredimageUrl,
            videoCategory: videoCategory,
            readingTime: readingTime,
            selectedFilersId: selectedFilersId,
            isWebView: false,
            webViewUrl: "",
            contentCategory: 4,
          };
          addVideo(videoData);
        }
      }
    };

    const updateVideo = async (data: any) => {
      setIsLoading(true);
      try {
        console.log(`Request to updating video ${data.title.hi}`);

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultContent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultContent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.de);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);

        const authorLocale: Locale = new Locale();
        authorLocale.setDefaultcontent("");
        authorLocale.setEn("");
        authorLocale.setHi("");
        authorLocale.setTe("");
        authorLocale.setGu("");
        authorLocale.setTa("");
        authorLocale.setKa("");
        authorLocale.setMr("");
        authorLocale.setIt("");
        authorLocale.setRu("");
        authorLocale.setEs("");
        authorLocale.setFr("");
        authorLocale.setPt("");
        authorLocale.setDe("");
        authorLocale.setBn("");
        authorLocale.setMl("");
        authorLocale.setUk("");
        authorLocale.setOd("");
        authorLocale.setEnUs("");
        authorLocale.setEnAu("");

        const contentLocale: Locale = new Locale();
        contentLocale.setDefaultcontent("");
        contentLocale.setEn("");
        contentLocale.setHi("");
        contentLocale.setTe("");
        contentLocale.setGu("");
        contentLocale.setTa("");
        contentLocale.setKa("");
        contentLocale.setMr("");
        contentLocale.setIt("");
        contentLocale.setRu("");
        contentLocale.setEs("");
        contentLocale.setFr("");
        contentLocale.setPt("");
        contentLocale.setDe("");
        contentLocale.setBn("");
        contentLocale.setMl("");
        contentLocale.setUk("");
        contentLocale.setOd("");
        contentLocale.setEnUs("");
        contentLocale.setEnAu("");

        const videourlLocale: Locale = new Locale();
        videourlLocale.setDefaultcontent(data.videourl.defaultContent);
        videourlLocale.setEn(data.videourl.en);
        videourlLocale.setHi(data.videourl.hi);
        videourlLocale.setTe(data.videourl.te);
        videourlLocale.setGu(data.videourl.gu);
        videourlLocale.setTa(data.videourl.ta);
        videourlLocale.setKa(data.videourl.ka);
        videourlLocale.setMr(data.videourl.mr);
        videourlLocale.setIt(data.videourl.it);
        videourlLocale.setRu(data.videourl.ru);
        videourlLocale.setEs(data.videourl.es);
        videourlLocale.setFr(data.videourl.fr);
        videourlLocale.setPt(data.videourl.pt);
        videourlLocale.setDe(data.videourl.de);
        videourlLocale.setBn(data.videourl.bn);
        videourlLocale.setMl(data.videourl.ml);
        videourlLocale.setUk(data.videourl.uk);
        videourlLocale.setOd(data.videourl.od);
        videourlLocale.setEnUs(data.videourl.enUs);
        videourlLocale.setEnAu(data.videourl.enAu);

        const attributeLocale: Locale = new Locale();
        attributeLocale.setDefaultcontent(data.attribute.defaultContent);
        attributeLocale.setEn(data.attribute.en);
        attributeLocale.setHi(data.attribute.hi);
        attributeLocale.setTe(data.attribute.te);
        attributeLocale.setGu(data.attribute.gu);
        attributeLocale.setTa(data.attribute.ta);
        attributeLocale.setKa(data.attribute.ka);
        attributeLocale.setMr(data.attribute.mr);
        attributeLocale.setIt(data.attribute.it);
        attributeLocale.setRu(data.attribute.ru);
        attributeLocale.setEs(data.attribute.es);
        attributeLocale.setFr(data.attribute.fr);
        attributeLocale.setPt(data.attribute.pt);
        attributeLocale.setDe(data.attribute.de);
        attributeLocale.setBn(data.attribute.bn);
        attributeLocale.setMl(data.attribute.ml);
        attributeLocale.setUk(data.attribute.uk);
        attributeLocale.setOd(data.attribute.od);
        attributeLocale.setEnUs(data.attribute.enUs);
        attributeLocale.setEnAu(data.attribute.enAu);

        const reqBody = new ContentResponse();
        reqBody.setId(props.editItem.id);
        reqBody.setTitle(titleLocale);
        reqBody.setSubtitle(descriptionLocale);
        reqBody.setAuthor(authorLocale);
        reqBody.setContent(contentLocale);
        reqBody.setAttribute(attributeLocale);
        reqBody.setImage(data.image);
        reqBody.setReadingtime(data.readingTime);
        reqBody.setWebviewurl(data.webViewUrl);
        reqBody.setContentcategory(4);
        reqBody.setVideourl(videourlLocale);
        reqBody.setThumbnail(data.thumbnail);
        reqBody.setFeatureimage(data.featuredimage);
        reqBody.setVideocategory(data.videoCategory);
        reqBody.setFilteridsList(data.selectedFilersId);
        reqBody.setTagsList(tagsArray());

        console.log(`Request for updating video`, reqBody.toObject());
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        grpc.unary(GamificationService.UpdateContent, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ContentResponse>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (res.message) {
                console.log("update video response", res.message.toObject());
                setIsLoading(false);
                dispatch(
                  getContentByCategory(
                    props.lang,
                    4,
                    props.page,
                    props.rowsPerPage
                  )
                );
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "Video updated successfully",
                });
                setTimeout(() => props.onClickBack(), 2000);
              }
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in updating video : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.log(`Error in update video ${error}`);
        setSnackBar({
          open: true,
          severity: "error",
          message: { error },
        });
      }
    };

    const addVideo = async (data: any) => {
      setIsLoading(true);
      try {
        console.log(`Request to adding new video ${data}`);

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultContent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultContent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.de);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);

        const authorLocale: Locale = new Locale();
        authorLocale.setDefaultcontent("");
        authorLocale.setEn("");
        authorLocale.setHi("");
        authorLocale.setTe("");
        authorLocale.setGu("");
        authorLocale.setTa("");
        authorLocale.setKa("");
        authorLocale.setMr("");
        authorLocale.setIt("");
        authorLocale.setRu("");
        authorLocale.setEs("");
        authorLocale.setFr("");
        authorLocale.setPt("");
        authorLocale.setDe("");
        authorLocale.setBn("");
        authorLocale.setMl("");
        authorLocale.setUk("");
        authorLocale.setOd("");
        authorLocale.setEnUs("");
        authorLocale.setEnAu("");

        const contentLocale: Locale = new Locale();
        contentLocale.setDefaultcontent("");
        contentLocale.setEn("");
        contentLocale.setHi("");
        contentLocale.setTe("");
        contentLocale.setGu("");
        contentLocale.setTa("");
        contentLocale.setKa("");
        contentLocale.setMr("");
        contentLocale.setIt("");
        contentLocale.setRu("");
        contentLocale.setEs("");
        contentLocale.setFr("");
        contentLocale.setPt("");
        contentLocale.setDe("");
        contentLocale.setBn("");
        contentLocale.setMl("");
        contentLocale.setUk("");
        contentLocale.setOd("");
        contentLocale.setEnUs("");
        contentLocale.setEnAu("");

        const videourlLocale: Locale = new Locale();
        videourlLocale.setDefaultcontent(data.videourl.defaultContent);
        videourlLocale.setEn(data.videourl.en);
        videourlLocale.setHi(data.videourl.hi);
        videourlLocale.setTe(data.videourl.te);
        videourlLocale.setGu(data.videourl.gu);
        videourlLocale.setTa(data.videourl.ta);
        videourlLocale.setKa(data.videourl.ka);
        videourlLocale.setMr(data.videourl.mr);
        videourlLocale.setIt(data.videourl.it);
        videourlLocale.setRu(data.videourl.ru);
        videourlLocale.setEs(data.videourl.es);
        videourlLocale.setFr(data.videourl.fr);
        videourlLocale.setPt(data.videourl.pt);
        videourlLocale.setDe(data.videourl.de);
        videourlLocale.setBn(data.videourl.bn);
        videourlLocale.setMl(data.videourl.ml);
        videourlLocale.setUk(data.videourl.uk);
        videourlLocale.setOd(data.videourl.od);
        videourlLocale.setEnUs(data.videourl.enUs);
        videourlLocale.setEnAu(data.videourl.enAu);

        const attributeLocale: Locale = new Locale();
        attributeLocale.setDefaultcontent(data.attribute.defaultContent);
        attributeLocale.setEn(data.attribute.en);
        attributeLocale.setHi(data.attribute.hi);
        attributeLocale.setTe(data.attribute.te);
        attributeLocale.setGu(data.attribute.gu);
        attributeLocale.setTa(data.attribute.ta);
        attributeLocale.setKa(data.attribute.ka);
        attributeLocale.setMr(data.attribute.mr);
        attributeLocale.setIt(data.attribute.it);
        attributeLocale.setRu(data.attribute.ru);
        attributeLocale.setEs(data.attribute.es);
        attributeLocale.setFr(data.attribute.fr);
        attributeLocale.setPt(data.attribute.pt);
        attributeLocale.setDe(data.attribute.de);
        attributeLocale.setBn(data.attribute.bn);
        attributeLocale.setMl(data.attribute.ml);
        attributeLocale.setUk(data.attribute.uk);
        attributeLocale.setOd(data.attribute.od);
        attributeLocale.setEnUs(data.attribute.enUs);
        attributeLocale.setEnAu(data.attribute.enAu);

        const reqBody = new ContentRequest();
        reqBody.setTitle(titleLocale);
        reqBody.setSubtitle(descriptionLocale);
        reqBody.setAuthor(authorLocale);
        reqBody.setContent(contentLocale);
        reqBody.setAttribute(attributeLocale);
        reqBody.setImage(data.image);
        reqBody.setReadingtime(data.readingTime);
        reqBody.setIswebview(data.isWebView);
        reqBody.setWebviewurl(data.webViewUrl);
        reqBody.setContentcategory(4);
        reqBody.setVideourl(videourlLocale);
        reqBody.setThumbnail(data.thumbnail);
        reqBody.setFeatureimage(data.featuredimage);
        reqBody.setVideocategory(data.videoCategory);
        reqBody.setFilteridsList(data.selectedFilersId);
        reqBody.setTagsList(tagsArray());

        console.log("Request for adding video", reqBody.toObject());
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();

        grpc.unary(GamificationService.AddContent, {
          request: reqBody,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ContentResponse>) => {
            const { status, message, statusMessage } = res;
            console.log("Request for adding video", res);

            if (status === grpc.Code.OK && message) {
              if (res.message) {
                console.log("add video response", res.message.toObject());
                setIsLoading(false);
                dispatch(
                  getContentByCategory(
                    props.lang,
                    4,
                    props.page,
                    props.rowsPerPage
                  )
                );
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: "Video added successfully",
                });
                setTimeout(() => props.onClickBack(), 2000);
              }
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: `Error in adding video : ${statusMessage}`,
              });
            }
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.log(`Error in add video ${error}`);
        setSnackBar({
          open: true,
          severity: "error",
          message: { error },
        });
      }
    };

    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
            }
          },
        });
      } catch (err) {
        console.log("Error ", err);
      }
    };

    const onVideoSelection = async (event: any) => {
      setIsUploading({ ...isUploading, video: true });
      const reader: any = new FileReader();
      const data = new File(
        [event.target.files[0]],
        event.target.files[0].name.replaceAll(" ", "")
      );

      reader.readAsDataURL(data);
      reader.onloadend = function () {
        setVideoFile({
          fileUrl: reader.result,
          file: data,
        });
      };
      const uploadFile = toUploadFile(
        data,
        `${
          props.lang === 1
            ? "resources/videos"
            : `resources/videos/${languages[props.lang - 1].displayName
                .toLowerCase()
                .replaceAll(" ", "")}`
        }`
      );
      appendFilesToUploadManager([uploadFile]);
      window.addEventListener(
        uploadFile.token,
        (res: CustomEventInit<UploadEventResponse>) => {
          if (res.detail?.code === 200) {
            setVideoUploadUrl(res.detail.response);
            setIsUploading({ ...isUploading, video: false });
          } else {
            setIsUploading({ ...isUploading, video: false });
          }
        }
      );
    };
    const handleVideoClick = () => {
      hiddenVideoInput.current.click();
    };

    const onClickDelete = (videoId: any) => {
      setStates({ showDeleteModal: true, deleteItemId: videoId });
    };

    const handleDeleteClose = () => {
      setStates({ showDeleteModal: false });
    };

    const deleteVideo = async () => {
      deleteVideoById(states.deleteItemId);
    };

    const deleteVideoById = async (videoId: String | undefined) => {
      setIsLoading(true);
      console.log(`Delete call for video with id ${videoId}`);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: ContentCategoryRequest = new ContentCategoryRequest();
        reqBody.setContentid(Number(videoId));
        reqBody.setContentcategory(4);
        grpc.unary(GamificationService.DeleteContentByIDAndCategory, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<Status>) => {
            const { status, message } = res;
            if (status === grpc.Code.OK) {
              if (message) {
                console.log(`Delete video response ${message.toObject()}`);
                setIsLoading(false);
                _deleteFileFromGcp(videoUploadUrl);
                setStates({ showDeleteModal: false });
                setTimeout(() => props.onClickBack(), 2000);
                dispatch(
                  getContentByCategory(
                    props.lang,
                    4,
                    props.page,
                    props.rowsPerPage
                  )
                );
                setSnackBar({
                  open: true,
                  severity: "success",
                  message: " Deleted video successfully",
                });
              } else {
                setIsLoading(false);
                setStates({ showDeleteModal: false });
                setSnackBar({
                  open: true,
                  severity: "Error",
                  message: "Error in deleting video",
                });
              }
            }
          },
        });
      } catch (err) {
        setIsLoading(false);
        console.log(`Error: ${err}`);
      }
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Video" : "Add New Video"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Videos
                    </Typography>
                    <Tooltip
                      title={props.editItem.title}
                      disableHoverListener={props.editItem.title?.length < 20}
                      placement="bottom"
                    >
                      <Box>
                        <Typography
                          color="inherit"
                          style={{ cursor: "pointer" }}
                        >
                          {props.editItem.title?.slice(0, 20)}
                          {props.editItem.title?.length > 20 && "..."}
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onClickDelete(id)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="title"
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                type="number"
                name="readingTime"
                label="Watching Time (sec)"
                variant="outlined"
                value={readingTime}
                onChange={(e) => {
                  setReadingTime(e.target.value > "0" ? e.target.value : "");
                }}
                className="mr-3"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Filters
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedFilersId}
                  onChange={handleChangeForMultipleSelect}
                  input={<OutlinedInput label="Filters" />}
                  renderValue={() => selectedFilers.join(", ")}
                >
                  {filterList.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      <Checkbox checked={selectedFilersId.indexOf(x.id) > -1} />
                      <ListItemText
                        primary={String(
                          x.contentfiltername[languageType[language - 1]]
                        )}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="activityType"
                value={activityType}
                onChange={(e) => {
                  setActivityType(e.target.value);
                }}
                label="Activity Type"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
                <Select
                  labelId="tags-multiple-checkbox-label"
                  id="tags-multiple-checkbox"
                  multiple
                  value={selectedTagsId}
                  onChange={handleMultipleTagChange}
                  input={<OutlinedInput label="Tags" />}
                  renderValue={() => selectedTags.join(",")}
                  MenuProps={{
                    sx: {
                      maxHeight: "500px",
                    },
                  }}
                >
                  {tagsList.loading ? (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    tagsList.tags.tagsList.map((x: any) => (
                      <MenuItem key={x.id} value={x.id}>
                        <Checkbox checked={selectedTagsId.indexOf(x.id) > -1} />
                        <ListItemText primary={String(x.name)} />
                      </MenuItem>
                    ))
                  )}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={tagsList.tags.pageable?.totalcount!}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={10}
                    style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                  />
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="language-select">Category</InputLabel>
                <Select
                  defaultValue=""
                  labelId="video-category-select"
                  value={videoCategory}
                  label="Language"
                  onChange={(e) => {
                    setVideoCategory(e.target.value as number);
                  }}
                >
                  {videoCategoryList.map((catagory: any, i: any) => (
                    <MenuItem key={i} value={catagory.code}>
                      {catagory.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {videoUploadUrl ? (
                    <>
                      <VideoPlayers
                        src={videoUploadUrl}
                        onAudioClose={onVideoClose}
                      />
                    </>
                  ) : (
                    <>
                      {isUploading.video === true ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            className="pt-5"
                            alignItems="center"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.thumbNail}
                          onClick={() => {
                            handleVideoClick();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                            Upload Video
                          </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <VideoFileOutlined />
                          </Avatar>
                          <input
                            accept="video/*"
                            id="audioFile"
                            type="file"
                            hidden
                            onChange={onVideoSelection}
                            ref={hiddenVideoInput}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Video
                </Typography>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {imageUrl ? (
                    <PreviewProfileImage
                      onImageClose={onImageClose}
                      src={imageUrl}
                      rectangular
                    />
                  ) : (
                    <>
                      {isUploading.image ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClickImage();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectImage}
                            ref={hiddenFileInputImage}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Image
                </Typography>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {thumbnailUrl ? (
                    <PreviewProfileImage
                      onImageClose={onThumbnailClose}
                      src={thumbnailUrl}
                      rectangular
                    />
                  ) : (
                    <>
                      {isUploading.thumbnail ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClickThumbnail();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectThumbnail}
                            ref={hiddenFileInputThumbnail}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Thumbnail
                </Typography>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Card variant="outlined" className={classes.root}>
                  {featuredimageUrl ? (
                    <PreviewProfileImage
                      onImageClose={onFeaturedimageClose}
                      src={featuredimageUrl}
                      rectangular
                    />
                  ) : (
                    <>
                      {isUploading.featuredimage ? (
                        <Card className={classes.thumbNail}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className="pt-5"
                          >
                            <Typography className="pt-4">
                              Uploading...
                            </Typography>
                          </Grid>
                        </Card>
                      ) : (
                        <CardActionArea
                          className={classes.root}
                          onClick={() => {
                            handleUploadClickFeaturedimage();
                          }}
                        >
                          {/* <InputLabel htmlFor="thumbnail">
                            Upload image
                          </InputLabel> */}
                          <Avatar
                            className={classes.actionIcon}
                            variant="square"
                          >
                            <ImageOutlined />
                          </Avatar>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            hidden
                            onChange={onSelectFeaturedimage}
                            ref={hiddenFileInputFeaturedimage}
                          />
                        </CardActionArea>
                      )}
                    </>
                  )}
                </Card>
                <Typography variant="overline" align="center" gutterBottom>
                  Feature image
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                multiline
                minRows={2}
                type="text"
                name="descrption"
                label="Video Descrption"
                variant="outlined"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className="mr-3"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => _addVideo()}
            color="primary"
            variant="contained"
            disabled={isAdding}
          >
            {props.isEditing ? "Update video" : "Add video"}
          </Button>
        </Box>
        {imageSrc && (
          <ImageCrop
            src={imageSrc.toString()}
            crop={imageCrop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onImageCropChange}
            onPressDone={onImageCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={onOpenImage}
            onClose={onCloseImage}
          />
        )}
        {thumbnailSrc && (
          <ImageCrop
            src={thumbnailSrc.toString()}
            crop={thumbnailCrop}
            onImageLoaded={onThumbnailLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onThumbnailCropChange}
            onPressDone={onThumbnailCropDone}
            onImageClose={onThumbnailClose}
            open={open}
            onOpen={onOpenImage}
            onClose={onCloseThumbnail}
          />
        )}
        {featuredimageSrc && (
          <ImageCrop
            src={featuredimageSrc.toString()}
            crop={featuredimageCrop}
            onImageLoaded={onFeaturedimageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onFeaturedimageCropChange}
            onPressDone={onFeaturedimageCropDone}
            onImageClose={onFeaturedimageClose}
            open={open}
            onOpen={onOpenImage}
            onClose={onCloseFeaturedImage}
          />
        )}
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
          onConfirm={deleteVideo}
          type={"delete"}
          title={`Sure !! Want to delete this video ?`}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
