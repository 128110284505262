import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";

import { ListSkeleton } from "components/listSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import {
  getAllGroups,
  getAllPreRecordedVideos,
} from "redux/actions/groupsAction";
import { SchedulePreRecordedVideoForm } from "./schedulePreRecordedVideoForm";
import GroupItem from "components/preRecordedVideoComponents/GroupItem";
import { languageType } from "config/language";
import { MenuProps } from "styles/global.styles";

interface State {
  editing?: boolean;
  editItem?: any;
}

export default function SchedulePreRecordedVideoScreen(): ReactElement {
  const dispatch = useDispatch();

  const scheduledPreRecordedVideoList = useSelector(
    (state: RootState) => state.groups.scheduledVideoList
  );
  //console.log('preRecordedVideoList',preRecordedVideoList.content.prerecordedsList)
  const initialState: State = {
    editing: false,
    editItem: null,
  };

  const languages = useSelector((state: RootState) => state.app.languages);
  const [language, setLanguage] = useState<any>(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [states, setStates] = useState<State>(initialState);
  const [searchVideo, setSearchVideo] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isUnpublished, setIsUnpublished] = useState<boolean>(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchVideo("");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setSearchVideo("");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickBack = () => {
    setCurrentIndex(0);
    setSearchVideo("");
    setStates({
      editing: false,
      editItem: null,
    });
  };

  useEffect(() => {
    dispatch(getAllGroups(page, rowsPerPage, isUnpublished));
  }, [dispatch, page, rowsPerPage, isUnpublished]);

  const setLocalisedContent = (item: any) => {
    switch (language) {
      case 1:
        return {
          id: item.id,
          title: item.title.defaultcontent,
          description: item.description.defaultcontent,
          meditationsteps: item.meditationsteps.defaultcontent,
          videourl: item.videourl.defaultcontent,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 2:
        return {
          id: item.id,
          title: item.title.en,
          description: item.description.en,
          meditationsteps: item.meditationsteps.en,
          videourl: item.videourl.en,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 3:
        return {
          id: item.id,
          title: item.title.hi,
          description: item.description.hi,
          meditationsteps: item.meditationsteps.hi,
          videourl: item.videourl.hi,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 4:
        return {
          id: item.id,
          title: item.title.te,
          description: item.description.te,
          meditationsteps: item.meditationsteps.te,

          videourl: item.videourl.te,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 5:
        return {
          id: item.id,
          title: item.title.gu,
          description: item.description.gu,
          meditationsteps: item.meditationsteps.gu,

          videourl: item.videourl.gu,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 6:
        return {
          id: item.id,
          title: item.title.ta,
          description: item.description.ta,
          meditationsteps: item.meditationsteps.ta,
          videourl: item.videourl.ta,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 7:
        return {
          id: item.id,
          title: item.title.ka,
          description: item.description.ka,
          meditationsteps: item.meditationsteps.ka,
          videourl: item.videourl.ka,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 8:
        return {
          id: item.id,
          title: item.title.mr,
          description: item.description.mr,
          meditationsteps: item.meditationsteps.mr,
          videourl: item.videourl.mr,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 9:
        return {
          id: item.id,
          title: item.title.it,
          description: item.description.it,
          meditationsteps: item.meditationsteps.it,
          videourl: item.videourl.it,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 10:
        return {
          id: item.id,
          title: item.title.ru,
          description: item.description.ru,
          meditationsteps: item.meditationsteps.ru,
          videourl: item.videourl.ru,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 11:
        return {
          id: item.id,
          title: item.title.es,
          description: item.description.es,
          meditationsteps: item.meditationsteps.es,
          videourl: item.videourl.es,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 12:
        return {
          id: item.id,
          title: item.title.fr,
          description: item.description.fr,
          meditationsteps: item.meditationsteps.fr,
          videourl: item.videourl.fr,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 13:
        return {
          id: item.id,
          title: item.title.pt,
          description: item.description.pt,
          meditationsteps: item.meditationsteps.pt,
          videourl: item.videourl.pt,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 14:
        return {
          id: item.id,
          title: item.title.de,
          description: item.description.de,
          meditationsteps: item.meditationsteps.de,
          videourl: item.videourl.de,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 15:
        return {
          id: item.id,
          title: item.title.bn,
          description: item.description.bn,
          meditationsteps: item.meditationsteps.bn,
          videourl: item.videourl.bn,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 16:
        return {
          id: item.id,
          title: item.title.ml,
          description: item.description.ml,
          meditationsteps: item.meditationsteps.ml,
          videourl: item.videourl.ml,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 17:
        return {
          id: item.id,
          title: item.title.uk,
          description: item.description.uk,
          meditationsteps: item.meditationsteps.uk,
          videourl: item.videourl.uk,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 18:
        return {
          id: item.id,
          title: item.title.od,
          description: item.description.od,
          meditationsteps: item.meditationsteps.od,
          videourl: item.videourl.od,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 19:
        return {
          id: item.id,
          title: item.title.enUs,
          description: item.description.enUs,
          meditationsteps: item.meditationsteps.enUs,
          videourl: item.videourl.enUs,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
      case 20:
        return {
          id: item.id,
          title: item.title.enAu,
          description: item.description.enAu,
          meditationsteps: item.meditationsteps.enAu,
          videourl: item.videourl.enAu,
          imgurl: item.imgurl,
          thumbnail: item.thumbnail,
        };
    }
  };

  const renderVideosList = (data: any) => {
    //console.log("data from table", data);
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  value={searchVideo}
                  fullWidth
                  variant="outlined"
                  placeholder="Search video by title"
                  size="small"
                  onChange={(e) => {
                    setSearchVideo(e.target.value);
                    const filteredData = data.filter((item: any) =>
                      setLocalisedContent(item.prerecorded)
                        ?.title.toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(getAllGroups(page, rowsPerPage, isUnpublished));
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="center">Registerd users</TableCell>
                    <TableCell align="left">Recurrence</TableCell>
                    <TableCell align="left">Scheduled Time</TableCell>
                    {!isUnpublished ? (
                      <>
                        <TableCell align="center">Publish/Unpublish</TableCell>
                        <TableCell align="left">Edit</TableCell>
                      </>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {console.log(data)}

                  {(searchVideo !== "" ? searchData : data).map(
                    (item: any, idx: number) => {
                      return (
                        <React.Fragment key={idx}>
                          <GroupItem
                            item={item}
                            setLocalisedContent={setLocalisedContent}
                            languageType={languageType}
                            language={language}
                            setStates={setStates}
                            setCurrentIndex={setCurrentIndex}
                            pageNumber={page}
                            pageSize={rowsPerPage}
                            isUnpublished={isUnpublished}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={scheduledPreRecordedVideoList.content.pageable?.totalcount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No videos to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card
            className="mt-3 mb-5"
            //sx={{boxShadow:'none'}}
          >
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: " 16px",
                  }}
                >
                  <Grid item xs={12} sm={6} md={8} lg={6}>
                    <Grid
                      container
                      spacing={4}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h6">Schedule Videos</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          // onClick={() => {
                          //   //onClickScheduleSession();
                          //   //dispatch(getCoachListAsync());
                          // }}
                          onClick={() => {
                            setCurrentIndex(1);
                          }}
                          startIcon={<AddIcon />}
                        >
                          Schedule Video
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={3}>
                    <ButtonGroup disableElevation>
                      <Button
                        variant={isUnpublished ? "outlined" : "contained"}
                        onClick={() => setIsUnpublished(false)}
                      >
                        Published
                      </Button>
                      <Button
                        variant={!isUnpublished ? "outlined" : "contained"}
                        onClick={() => setIsUnpublished(true)}
                      >
                        Unpublished
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={3}>
                    <FormControl variant="outlined" className="m-3" fullWidth>
                      <InputLabel id="language-select">Language</InputLabel>
                      {languages && (
                        <Select
                          defaultValue=""
                          labelId="language-select"
                          value={language}
                          label="Language"
                          onChange={(e) => {
                            setLanguage(e.target.value as number);
                          }}
                          MenuProps={MenuProps}
                        >
                          {languages.map((lang: any, i: any) => (
                            <MenuItem key={i} value={lang.code}>
                              {lang.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent>
                  <Paper>
                    {scheduledPreRecordedVideoList.loading
                      ? ListSkeleton()
                      : renderVideosList(
                          scheduledPreRecordedVideoList.content.groupsList
                        )}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 2 ? { timeout: 1000 } : {})}
            >
              <SchedulePreRecordedVideoForm
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBack={onClickBack}
                lang={language}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
