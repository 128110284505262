import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  Grow,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState, ReactElement } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import { ListSkeleton } from "components/listSkeleton";
import style from "./index.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { MeditationBackgroundForm } from "./meditationBackgroundForm";
import { getAllBackgrounds } from "redux/actions/meditationBackgroundAction";
import { languageType } from "config/language";
import { MenuProps } from "styles/global.styles";

interface State {
  editing?: boolean;
  editItem?: any;
  backgroundtype: number;
}
export default function MeditaionBackground(): ReactElement {
  const dispatch = useDispatch();
  const backgroundInfo = useSelector(
    (state: RootState) => state.meditationBackground
  );


  const languages = useSelector((state: RootState) => state.app.languages);

  const initialState = {
    editing: false,
    editItem: null,
    backgroundtype: 1,
  };

  const [currentScreen, setcurrentScreen] = useState(1);
  const [state, setState] = useState<State>(initialState);

  const [backgroundType, setBackgroundType] = useState<0 | 1 | 2 | 3 | 4>(1);
  const [language, setLanguage] = useState<any>(1);

  const backgroundTypeList = ["IMAGE", "MUSIC"];
  const languageType = [
    "defaultcontent",
    "en",
    "hi",
    "te",
    "gu",
    "ta",
    "ka",
    "mr",
    "it",
    "ru",
    "es",
    "fr",
    "pt",
    "de",
    "bn",
    "ml",
    "uk",
    "od",
    "en-us",
    "en-au",
  ];
  useEffect(() => {
    dispatch(getAllBackgrounds());
  }, [dispatch]);

  const onClickBack = () => {
    setcurrentScreen(1);
    setState({
      editing: false,
      editItem: null,
      backgroundtype: backgroundType,
    });
  };

  const renderBackgroundList = (data: any) => {
    return (
      <>
        {data.length ? (
          <>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell
                      align="left"
                      sx={{
                        width: "60px",
                      }}
                    >
                      Image
                    </TableCell>
                    <TableCell align="left"> Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((background: any, x: any) => {
                    return (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer" }}
                          hover
                          onClick={() => {
                            setState({
                              editing: true,
                              editItem: background,
                              backgroundtype: backgroundType,
                            });
                            setcurrentScreen(2);
                          }}
                        >
                          <TableCell align="center">
                            <Avatar
                              alt={
                                background.title[languageType[language - 1]]
                                  ? background.title[languageType[language - 1]]
                                  : background.title[languageType[0]]
                              }
                              src={
                                backgroundType === 1
                                  ? background.url
                                  : background.thumbnailimgurl
                              }
                            />
                          </TableCell>

                          <TableCell>
                            {background.title[languageType[language - 1]]
                              ? background.title[languageType[language - 1]]
                              : background.title[languageType[0]]}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Not enough data
              </Typography>
            </Grid>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card className="mt-3 mb-5" style={{ position: "relative" }}>
              <Grow
                in={currentScreen === 1}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 1 ? { timeout: 1000 } : {})}
              >
                <div>
                  <Grid container className="align-items-center p-3">
                    <Typography variant="h6">Meditations</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setcurrentScreen(2);
                      }}
                      startIcon={<AddIcon />}
                      className="ml-3"
                    >
                      {backgroundType === 1
                        ? "Add Background Image"
                        : "Add Background Music"}
                    </Button>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <FormControl variant="outlined" className="m-3" fullWidth>
                        <InputLabel id="background-type-select">
                          Meditate with
                        </InputLabel>
                        {backgroundType && (
                          <Select
                            defaultValue={
                              backgroundTypeList[backgroundType - 1]
                            }
                            labelId="background-type-select"
                            value={backgroundTypeList[backgroundType - 1]}
                            label="Meditate with"
                            onChange={(e) => {
                              const temp =
                                e.target.value === backgroundTypeList[0]
                                  ? 1
                                  : 2;
                              setBackgroundType(temp);
                              setState({
                                ...state,
                                backgroundtype: temp,
                              });
                            }}
                          >
                            {backgroundTypeList.map((medType: any, i: any) => (
                              <MenuItem key={i} value={medType}>
                                {medType}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <FormControl
                        variant="outlined"
                        className="m-3 pl-2"
                        fullWidth
                      >
                        <InputLabel id="language-select">Language</InputLabel>
                        {languages && (
                          <Select
                            defaultValue=""
                            labelId="language-select"
                            value={language}
                            label="Language"
                            onChange={(e) => {
                              setLanguage(e.target.value as number);
                              // setIsLangSelected(true);
                              console.log("language", language);
                            }}
                            MenuProps={MenuProps}
                          >
                            {languages.map((lang, i) => (
                              <MenuItem key={i} value={lang.code}>
                                {lang.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardContent>
                    <Paper>
                      {backgroundInfo.loading
                        ? ListSkeleton()
                        : renderBackgroundList(
                            backgroundType === 1
                              ? backgroundInfo.backgroundList
                                  .meditaionbckgndimgList
                              : backgroundInfo.backgroundList
                                  .meditaionbckgndmusicList
                          )}
                    </Paper>
                  </CardContent>
                </div>
              </Grow>
              <Grow
                in={currentScreen === 2}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentScreen === 2 ? { timeout: 1000 } : {})}
              >
                <MeditationBackgroundForm
                  onClickBack={onClickBack}
                  lang={language}
                  {...state}
                />
              </Grow>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
