import React from "react";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, useRouteMatch } from "react-router";
import { RootState } from "redux/store/store";
import { useAuth } from "services/AuthService";
import { getRouteforRoles } from "utils/helpers";

interface Props extends RouteProps {
  component: any;
  restricted: boolean;
}

export default function PublicRoute({
  component: Component,
  restricted,
  ...rest
}: Props): ReactElement {
  const { currentUser } = useAuth();
  const { path } = useRouteMatch();
  // const state = useSelector((state: RootState) => state.user);
  const users: any = localStorage.getItem("user");
  const user = JSON.parse(users);

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser && restricted ? (
          <Redirect to={`${path}/${getRouteforRoles(user?.role)}`} />
        ) : (
          <Component {...props} />
        )
      }
    ></Route>
  );
}
