import {
  Button,
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";

import { firebaseAuth } from "config/firebase";
import { countryData } from "./countries";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  DonationCreateRequest,
  DonationDetails,
  ID,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { getAllDonations } from "redux/actions/donationActions";
import { RootState } from "redux/store/store";

interface FormProps {
  isEditing?: boolean;
  editItem?: any;
  onClickBack?: any;
  pageSize: number;
  pageNumber: number;
}
interface CountryDataType {
  countryCode: string;
  countryName: string;
  currencyCode: string;
}

export const CreateDonation = React.forwardRef<HTMLDivElement, FormProps>(
  (props, ref): ReactElement => {
    const countries: readonly CountryDataType[] = countryData;
    const dispatch = useDispatch();

    const languages = useSelector((state: RootState) => state.app.languages);

    const [language, setLanguage] = useState<any>(2);

    const [title, setTitle] = useState<any>("");
    const [description, setDescription] = useState("");
    const [country, setCountry] = useState<CountryDataType | null>({
      countryCode: "IN",
      countryName: "India",
      currencyCode: "INR",
    });

    const [amount1, setAmount1] = useState<number>();
    const [amount2, setAmount2] = useState<number>();
    const [amount3, setAmount3] = useState<number>();
    const [amount4, setAmount4] = useState<number>();

    const [isAdding, setIsAdding] = useState(false);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    useEffect(() => {
      if (props.isEditing) {
        const regionNamesInEnglish: any = new Intl.DisplayNames(["en"], {
          type: "region",
        });

        console.log(props.editItem);

        setTitle(props.editItem.title.defaultcontent);
        setDescription(props.editItem.description.defaultcontent);

        const amountArr = props.editItem.amountsList;
        setAmount1(amountArr[0]);
        setAmount2(amountArr[1]);
        setAmount3(amountArr[2]);
        setAmount4(amountArr[3]);

        setCountry(
          countries.filter(
            (el) =>
              el.countryName === regionNamesInEnglish.of(props.editItem.country)
          )[0]
        );
      }
    }, [countries, props.editItem, props.isEditing]);

    useEffect(() => {
      if (props.isEditing)
        switch (language) {
          case 1:
            setTitle(props.editItem.title.defaultcontent);
            setDescription(props.editItem.description.defaultcontent);
            break;
          case 2:
            setTitle(props.editItem.title.en);
            setDescription(props.editItem.description.en);
            break;
          case 3:
            setTitle(props.editItem.title.hi);
            setDescription(props.editItem.description.hi);
            break;
          case 4:
            setTitle(props.editItem.title.te);
            setDescription(props.editItem.description.te);
            break;
          case 5:
            setTitle(props.editItem.title.gu);
            setDescription(props.editItem.description.gu);
            break;
          case 6:
            setTitle(props.editItem.title.ta);
            setDescription(props.editItem.description.ta);
            break;
          case 7:
            setTitle(props.editItem.title.ka);
            setDescription(props.editItem.description.ka);
            break;
          case 8:
            setTitle(props.editItem.title.mr);
            setDescription(props.editItem.description.mr);
            break;
          case 9:
            setTitle(props.editItem.title.it);
            setDescription(props.editItem.description.it);
            break;
          case 10:
            setTitle(props.editItem.title.ru);
            setDescription(props.editItem.description.ru);
            break;
          case 11:
            setTitle(props.editItem.title.es);
            setDescription(props.editItem.description.es);
            break;
          case 12:
            setTitle(props.editItem.title.fr);
            setDescription(props.editItem.description.fr);
            break;
          case 13:
            setTitle(props.editItem.title.pt);
            setDescription(props.editItem.description.pt);
            break;
          case 14:
            setTitle(props.editItem.title.de);
            setDescription(props.editItem.description.de);
            break;
          case 15:
            setTitle(props.editItem.title.bn);
            setDescription(props.editItem.description.bn);
            break;
          case 16:
            setTitle(props.editItem.title.ml);
            setDescription(props.editItem.description.ml);
            break;
          case 17:
            setTitle(props.editItem.title.uk);
            setDescription(props.editItem.description.uk);
            break;
          case 18:
            setTitle(props.editItem.title.od);
            setDescription(props.editItem.description.od);
            break;
          case 19:
            setTitle(props.editItem.title.enUs);
            setDescription(props.editItem.description.enUs);
            break;
          case 20:
            setTitle(props.editItem.title.enAu);
            setDescription(props.editItem.description.enAu);
            break;
        }
    }, [language]);
    const validateDonation = () => {
      if (title.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Donation title can not be empty",
        });
      } else if (description.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Donation description  can not be empty",
        });
      } else {
        return true;
      }
    };

    const localeObject = {
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
    };

    const decideLocale = () => {
      switch (language) {
        case 1:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              en: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              en: description,
            },
          };
        case 2:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              en: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              en: description,
            },
          };
        case 3:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              hi: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              hi: description,
            },
          };
        case 4:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              te: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              te: description,
            },
          };

        case 5:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              gu: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              gu: description,
            },
          };
        case 6:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ta: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ta: description,
            },
          };
        case 7:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ka: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ka: description,
            },
          };
        case 8:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              mr: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              mr: description,
            },
          };
        case 9:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              it: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              it: description,
            },
          };
        case 10:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ru: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ru: description,
            },
          };
        case 11:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              es: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              es: description,
            },
          };
        case 12:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              fr: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              fr: description,
            },
          };
        case 13:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              pt: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              pt: description,
            },
          };
        case 14:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              de: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              de: description,
            },
          };
        case 15:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              bn: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              bn: description,
            },
          };
        case 16:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              ml: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              ml: description,
            },
          };
        case 17:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              uk: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              uk: description,
            },
          };
        case 18:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              od: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              od: description,
            },
          };
        case 19:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              enUs: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              enUs: description,
            },
          };
        case 20:
          return {
            title: {
              ...localeObject,
              defaultcontent: props.editItem.title.defaultcontent,
              enAu: title,
            },
            description: {
              ...localeObject,
              defaultcontent: props.editItem.description.defaultcontent,
              enAu: description,
            },
          };
      }
    };

    const addDonation = () => {
      if (validateDonation()) {
        setIsAdding(true);
        let amountsArr = [amount1, amount2, amount3, amount4];
        const data: any = {
          title: title,
          description: description,
          amounts: amountsArr.filter((e) => e && e !== 0),
          currency: country?.currencyCode,
          country: country?.countryCode,
          language: "EN",
        };
        _addDonation(data);
      }
    };

    const _addDonation = async (data: any) => {
      console.log("meditaion data", data);
      try {
        const createDonationReq: DonationCreateRequest =
          new DonationCreateRequest();

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultcontent);
        titleLocale.setEn(data.title.en);
        createDonationReq.setTitle(titleLocale);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultcontent);
        descriptionLocale.setEn(data.description.en);
        createDonationReq.setDescription(descriptionLocale);

        createDonationReq.setCountry(data.country);
        createDonationReq.setCurrency(data.currency);
        createDonationReq.setCountry(data.country);
        createDonationReq.setAmountsList(data.amounts);

        console.log(createDonationReq.toObject());

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.CreateDonation, {
          request: createDonationReq,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<DonationDetails>) => {
            const { status, statusMessage, message } = res;
            console.log("statusMessage", statusMessage);

            if (status === grpc.Code.OK && message) {
              console.log("all ok ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: " Donation card added successfully",
              });
              dispatch(getAllDonations(props.pageSize, props.pageNumber));
              setTimeout(() => props.onClickBack(), 2000);
              return message;
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in adding Donation card ",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in adding donation",
        });
        console.error("something went wrong in adding donation", error);
      }
    };
    const updateDonation = () => {
      if (validateDonation()) {
        setIsAdding(true);
        let amountsArr = [amount1, amount2, amount3, amount4];
        const data: any = {
          id: props.editItem.id,
          title: decideLocale()?.title,
          description: decideLocale()?.description,
          amounts: amountsArr.filter((e) => e && e !== 0),
          currency: country?.currencyCode,
          country: country?.countryCode,
          language: "EN",
        };
        _updateDonation(data);
      }
    };
    const _updateDonation = async (data: any) => {
      console.log("meditaion data", data);
      try {
        const updateDonationReq: DonationDetails = new DonationDetails();

        const titleLocale: Locale = new Locale();
        titleLocale.setDefaultcontent(data.title.defaultcontent);
        titleLocale.setEn(data.title.en);
        titleLocale.setHi(data.title.hi);
        titleLocale.setTe(data.title.te);
        titleLocale.setGu(data.title.gu);
        titleLocale.setTa(data.title.ta);
        titleLocale.setKa(data.title.ka);
        titleLocale.setMr(data.title.mr);
        titleLocale.setIt(data.title.it);
        titleLocale.setRu(data.title.ru);
        titleLocale.setEs(data.title.es);
        titleLocale.setFr(data.title.fr);
        titleLocale.setPt(data.title.pt);
        titleLocale.setDe(data.title.de);
        titleLocale.setBn(data.title.bn);
        titleLocale.setMl(data.title.ml);
        titleLocale.setUk(data.title.uk);
        titleLocale.setOd(data.title.od);
        titleLocale.setEnUs(data.title.enUs);
        titleLocale.setEnAu(data.title.enAu);
        updateDonationReq.setTitle(titleLocale);

        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultcontent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.de);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);
        updateDonationReq.setDescription(descriptionLocale);

        updateDonationReq.setId(data.id);
        updateDonationReq.setCountry(data.country);
        updateDonationReq.setCurrency(data.currency);
        updateDonationReq.setCountry(data.country);
        updateDonationReq.setAmountsList(data.amounts);

        console.log(updateDonationReq.toObject());

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        grpc.unary(GamificationService.UpdateDonation, {
          request: updateDonationReq,
          host: GAMIFICATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<DonationDetails>) => {
            const { status, statusMessage, message } = res;
            console.log("statusMessage", statusMessage);

            if (status === grpc.Code.OK && message) {
              setSnackBar({
                open: true,
                severity: "success",
                message: " Donation card updating successfully",
              });
              dispatch(getAllDonations(props.pageSize, props.pageNumber));
              setTimeout(() => props.onClickBack(), 2000);
              return message;
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in updating Donation card ",
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in updaing donation",
        });
        console.error("something went wrong in updating donation", error);
      }
    };

    const deleteDonation = async (id: number) => {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      const reqBody: ID = new ID();
      reqBody.setId(id);
      grpc.unary(GamificationService.DeleteDonationByID, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, statusMessage } = res;
          if (status === grpc.Code.OK) {
            console.log("donation deleted");
            setSnackBar({
              open: true,
              severity: "success",
              message: " Donation card deleted successfully",
            });
            dispatch(getAllDonations(props.pageSize, props.pageNumber));
            setTimeout(() => props.onClickBack(), 2000);
          } else {
            console.error("Error in deleting donation card", statusMessage);
            setSnackBar({
              open: true,
              severity: "error",
              message: "Could not delete donaiton card",
            });
          }
        },
      });
    };

    return (
      <div ref={ref}>
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={() => props.onClickBack()} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="h6">
                  {props.isEditing
                    ? `Edit Donation card `
                    : `Add New Donation Card `}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteDonation(props.editItem.id)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={9} sm={9} xs={12}>
              <TextField
                fullWidth
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                label="Donation Title"
                name="donationTitle"
                required
                variant="outlined"
                className="mr-3"
              />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <FormControl
                variant="outlined"
                fullWidth
                disabled={props.editItem ? false : true}
              >
                <InputLabel id="language-select">Language</InputLabel>
                {languages && (
                  <Select
                    defaultValue=""
                    labelId="language-select"
                    value={language}
                    label="Language"
                    inputProps={{
                      readOnly: props.editItem ? false : true,
                    }}
                    onChange={(e) => {
                      setLanguage(e.target.value as number);
                    }}
                  >
                    {languages.map((lang, i) => (
                      <MenuItem key={i} value={lang.code}>
                        {lang.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6} md={3} lg={3}>
              <TextField
                type="number"
                label="Amount 1"
                value={amount1?.toString()}
                fullWidth
                onChange={(e) => setAmount1(Number(e.target.value))}
              ></TextField>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                type="number"
                label="Amount 2"
                value={amount2?.toString()}
                fullWidth
                onChange={(e) => setAmount2(Number(e.target.value))}
              ></TextField>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                type="number"
                label="Amount 3"
                fullWidth
                value={amount3?.toString()}
                onChange={(e) => setAmount3(Number(e.target.value))}
              ></TextField>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                type="number"
                label="Amount 4"
                fullWidth
                value={amount4?.toString()}
                onChange={(e) => setAmount4(Number(e.target.value))}
              ></TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                options={countries}
                value={country}
                onChange={(e, val) => setCountry(val)}
                getOptionLabel={(option) =>
                  `${option.countryName} (${option.currencyCode})`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.countryName} ({option.countryCode})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                required
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                label="Description"
                name="description"
                rows={3}
                variant="outlined"
                multiline
                value={description}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => {
              props.isEditing ? updateDonation() : addDonation();
            }}
            disabled={isAdding}
            color="primary"
            variant="contained"
          >
            {props.isEditing ? "Update Donation" : "Add Donation"}
          </Button>
        </Box>

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
);
