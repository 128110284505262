import { grpc } from "@improbable-eng/grpc-web";
import { Code } from "@improbable-eng/grpc-web/dist/typings/Code";
import { Metadata } from "@improbable-eng/grpc-web/dist/typings/metadata";
import { Empty } from "api/models/gamification-service/gamification_pb";
import {
  ID,
  ListOfCoach,
  ListOfQuestion,
  ListOfUserFeedBacks,
  LocaleType,
  RequestForGetAllBenefits,
  RequestForGetAllBenefitsByCoachId,
  RequestForGetLiveSessionByCoachId,
  RequestGetAllLiveSession,
  RequestPageable,
  RequestPageableWithId,
  RequestQuestionBySessionId,
  requestScheduleSessionsDateRange,
  ResponseForGetAllBenefits,
  ResponseForGetAllBenefitsByCoachId,
  ResponseForGetLiveSessionByCoachId,
  ResponseListOfBenefits,
  ResponseListOfLiveMeditations,
  ResponseLiveMeditation,
  responseScheduleSessionsDateRange,
  TagsList,
} from "api/models/live-meditation-service/live-meditation_pb";
import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";
import {
  //GAMIFICATION_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";
import { firebaseAuth } from "config/firebase";
import {
  benifitsIdArrayLoading,
  benifitsIdArrayUpdate,
  benifitsListByCoachIdError,
  benifitsListByCoachIdLoading,
  benifitsListByCoachIdUpdate,
  benifitsListByIdError,
  benifitsListByIdLoading,
  benifitsListByIdUpdate,
  benifitsListError,
  benifitsListLoading,
  benifitsListUpdate,
  coachListError,
  coachListLoading,
  coachListUpdate,
  feedbackListError,
  feedbackListLoading,
  feedbackListUpdate,
  liveMeditationlistError,
  liveMeditationListUpdate,
  liveMeditationLoading,
  liveSessionDetailsLoading,
  liveSessionDetailsUpdate,
  liveSessionsBetweenError,
  liveSessionsBetweenLoading,
  liveSessionsBetweenUpdate,
  liveSessionsByCoachIdError,
  liveSessionsByCoachIdLoading,
  liveSessionsByCoachIdUpdate,
  questionListError,
  questionListLoading,
  storeQuestions,
  updateHelpDocs,
  // userQuestionListError,
  // userQuestionListLoading,
  // userQuestionListUpdate,
} from "redux/reducers/liveMeditationReducer";
import { AppThunk } from "redux/store/store";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import moment from "moment";
import firebase from "firebase";
import { setTagsList, tagsListIsLoading } from "redux/reducers/tagReducer";

const remoteConfig = firebase.remoteConfig();

// use the line to disable firebase remote config fetch throtlling while development
remoteConfig.settings.minimumFetchIntervalMillis = 60000; // !!!! Don't forget to comment when development is finished !!!!!

export const getCoachListAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(coachListLoading());
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    grpc.invoke(LiveMeditationService.GetAllCoach, {
      request: new Empty(),
      host: LIVE_MEDITATION_SERVICE_HOST,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onMessage: (res: ListOfCoach) => {
        dispatch(coachListUpdate(res.toObject().listofcoachList));
        console.log("reponse coming from getAllCoach", res.toObject());
      },
      onEnd: (code: Code, message: string, trailers: Metadata) => {
        if (code) {
          console.error({ code, message, trailers });
          dispatch(coachListError());
        }
      },
    });
  } catch (err) {
    dispatch(coachListError());
    console.log(err);
  }
};

export const getAllLiveMeditationsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(liveMeditationLoading());
    const jwtToken = await firebaseAuth.currentUser?.getIdToken();
    const requestBody: RequestGetAllLiveSession =
      new RequestGetAllLiveSession();
    const currentGoogleTimeStamp: Timestamp = new Timestamp();
    currentGoogleTimeStamp.fromDate(new Date());
    requestBody.setScheduledtime(currentGoogleTimeStamp);

    grpc.invoke(LiveMeditationService.GetAllLiveSession, {
      host: LIVE_MEDITATION_SERVICE_HOST,
      request: requestBody,
      metadata: {
        Authorization: `Bearer ${jwtToken}`,
      },
      onMessage: (res: ResponseListOfLiveMeditations) => {
        dispatch(
          liveMeditationListUpdate(
            res.toObject().listoflivemeditationsessionList
          )
        );
      },
      onEnd: (code: Code, message: string, trailers: Metadata) => {
        if (code) {
          console.error({ code, message, trailers });
          dispatch(liveMeditationlistError());
        }
      },
    });
  } catch (err) {
    console.log(err);
    dispatch(liveMeditationlistError());
  }
};

export const getAllLiveMeditationsBetweenDates =
  (
    startDate: string,
    endDate: string,
    language: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 = 0,
    pageSize: number = 100,
    pageNumber: number = 0
  ): AppThunk =>
  async (dispatch) => {
    // const end = moment(endDate).endOf("day").format("");
    console.log("start Date", moment(startDate).format(""));

    try {
      const end = moment(endDate).endOf("day").format("");
      console.log("end Date", end);
      dispatch(liveSessionsBetweenLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: requestScheduleSessionsDateRange =
        new requestScheduleSessionsDateRange();
      const currentGoogleTimeStamp: Timestamp = new Timestamp();
      const endGoogleTimeStamp: Timestamp = new Timestamp();

      const selectedLanguage = new LocaleType();
      selectedLanguage.setSelectedlanguage(language ?? 0);
      selectedLanguage.setId(0);

      const pageable = new RequestPageable();
      pageable.setPagesize(100);
      pageable.setPagenumber(0);

      currentGoogleTimeStamp.fromDate(new Date(startDate));
      endGoogleTimeStamp.fromDate(new Date(end));

      reqBody.setStartdate(currentGoogleTimeStamp);
      reqBody.setEnddate(endGoogleTimeStamp);
      reqBody.setSelectedlanguage(selectedLanguage);
      reqBody.setGetdefinesession(false);
      reqBody.setPageable(pageable);

      console.log("reqbody", reqBody.toObject());
      grpc.unary(LiveMeditationService.GetAllScheduleSessionsBetweenDateRange, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<responseScheduleSessionsDateRange>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("data coming", message.toObject());
              dispatch(liveSessionsBetweenUpdate(message.toObject()));
            }
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(liveSessionsBetweenError());
    }
  };

export const getAllLiveMeditationsBetweenDatesV2 =
  (pageSize: number, page: number, bool: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(liveSessionsBetweenLoading());

      const pageable = new RequestPageable();
      pageable.setPagesize(pageSize);
      pageable.setPagenumber(page);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: requestScheduleSessionsDateRange =
        new requestScheduleSessionsDateRange();
      reqBody.setGetdefinesession(bool);
      reqBody.setPageable(pageable);
      console.log(
        "reqbody for getAllLiveMeditationsBetweenDatesV2",
        reqBody.toObject()
      );
      grpc.unary(LiveMeditationService.GetAllScheduleSessionsBetweenDateRange, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<responseScheduleSessionsDateRange>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log("data coming", message.toObject());
              dispatch(liveSessionsBetweenUpdate(message.toObject()));
            }
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(liveSessionsBetweenError());
    }
  };

// export const setGlobalQuestion =
//   (questionsList: any): AppThunk =>
//   async (dispatch) => {
//     dispatch(questionListUpdate(questionsList));
//   };

export const getAllQuestionBySessionID =
  (liveSessionId: number, pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(questionListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestQuestionBySessionId =
        new RequestQuestionBySessionId();
      reqBody.setLivesessionid(liveSessionId);
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);
      console.log("request for questions by session id", reqBody.toObject());
      grpc.unary(LiveMeditationService.GetAllQuestionBySessionID, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ListOfQuestion>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from getAllQuestionBySessionID",
                message.toObject()
              );
              const assembledPayload = {
                sessionId: liveSessionId,
                sessionQuestions: message.toObject(),
              };
              const data = message.toObject().questionList;
              console.log("question data", data);
              console.log("payload", assembledPayload);

              // const newData={
              //   sessionId:liveSessionId,
              //   result: message.toObject()
              // }
              //dispatch(storeQuestions(assembledPayload));
              if (data.length === 0) {
                dispatch(
                  getAllUserQuestionBySessionID(
                    liveSessionId,
                    pageSize,
                    pageNumber
                  )
                );
              } else {
                console.log("payload", assembledPayload);
                dispatch(storeQuestions(assembledPayload));
                //dispatch(questionListUpdate(message.toObject()));
                //dispatch(liveSessionDetailsUpdate(newData));
              }
            }
          } else {
            dispatch(
              getAllUserQuestionBySessionID(liveSessionId, pageSize, pageNumber)
            );
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(questionListError());
    }
  };

export const getAllUserQuestionBySessionID =
  (liveSessionId: number, pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(questionListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestQuestionBySessionId =
        new RequestQuestionBySessionId();
      reqBody.setLivesessionid(liveSessionId);
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);

      grpc.unary(LiveMeditationService.GetAllUserQuestionBySessionID, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ListOfQuestion>) => {
          const { status, message } = res;
          // console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            console.log(
              "data coming from get all user question",
              message.toObject()
            );
            const assembledPayload = {
              sessionId: liveSessionId,
              sessionQuestions: message.toObject(),
            };
            dispatch(storeQuestions(assembledPayload));
            //dispatch(questionListUpdate(message.toObject()));
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(questionListError());
    }
  };

export const getAllSessionBenifits =
  (pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      //console.log('pageSize',pageSize);
      dispatch(benifitsListLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestForGetAllBenefits = new RequestForGetAllBenefits();
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);

      grpc.unary(LiveMeditationService.GetAllBenefits, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ResponseForGetAllBenefits>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log("data coming", message.toObject());
            dispatch(benifitsListUpdate(message.toObject()));
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(benifitsListError());
    }
  };

export const getAllBenifitsBySessionId =
  (sessionId: number): AppThunk =>
  async (dispatch) => {
    try {
      // console.log('pageSize',pageSize);
      dispatch(benifitsListByIdLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(sessionId);

      grpc.unary(LiveMeditationService.GetAllBenefitsBySessionID, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ResponseListOfBenefits>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log(
              "data coming from getallbenefitsbysession id",
              message.toObject()
            );
            dispatch(benifitsListByIdUpdate(message.toObject()));
            if (message.toObject().listofbenefitsList.length) {
              let resultArray: number[] = [];
              message
                .toObject()
                .listofbenefitsList.forEach((item) =>
                  resultArray.push(item.id)
                );
              dispatch(benifitsIdArrayUpdate(resultArray));
            } else {
              dispatch(benifitsIdArrayLoading());
            }
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(benifitsListByIdError());
    }
  };

export const getAllSessionBenifitsByCoachId =
  (pageSize: number, pageNumber: number, coachId: number): AppThunk =>
  async (dispatch) => {
    try {
      //console.log('pageSize',pageSize);
      dispatch(benifitsListByCoachIdLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestForGetAllBenefitsByCoachId =
        new RequestForGetAllBenefitsByCoachId();
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);
      reqBody.setCoachid(coachId);

      grpc.unary(LiveMeditationService.GetAllBenefitsByCoachId, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseForGetAllBenefitsByCoachId>) => {
          const { status, message } = res;
          if (status === grpc.Code.OK && message) {
            console.log(
              "response from getAllSessionBenifitsByCoachId",
              message.toObject()
            );
            dispatch(benifitsListByCoachIdUpdate(message.toObject()));
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(benifitsListByCoachIdError());
    }
  };

export const sessionByCoachID =
  (coachId: number, sessionId: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    let sessionIdArr: number[] = [];
    dispatch(
      getAllSessionByCoachID(coachId, sessionId, pageNumber, sessionIdArr)
    );
  };
export const getAllSessionByCoachID =
  (
    coachId: number,
    sessionId: number,
    pageNumber: number,
    sessionIdArr: number[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      console.log("data", coachId, sessionId, pageNumber);
      dispatch(liveSessionsByCoachIdLoading());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestForGetLiveSessionByCoachId =
        new RequestForGetLiveSessionByCoachId();
      //reqBody.setcoachId(liveSessionId);
      reqBody.setCoachid(coachId);
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(5);

      grpc.unary(LiveMeditationService.GetLiveSessionsByCoachId, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseForGetLiveSessionByCoachId>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            console.log(
              "data coming from get all session by coach id",
              message.toObject()
            );

            const arr = message.toObject().livesessionidsList;
            console.log("arr", arr);
            console.log("sessionIdArr", sessionIdArr);
            if (!sessionIdArr.length) {
              const index = arr.findIndex((item) => item === sessionId);
              console.log("index of selected session", index);
              if (index === -1) {
                const nextPage = pageNumber + 1;
                dispatch(
                  getAllSessionByCoachID(
                    coachId,
                    sessionId,
                    nextPage,
                    sessionIdArr
                  )
                );
              } else {
                console.log("else condition");
                arr.splice(0, index);
                sessionIdArr = [...arr];
                console.log("sliced array", sessionIdArr);
                const nextPage = pageNumber + 1;
                dispatch(
                  getAllSessionByCoachID(
                    coachId,
                    sessionId,
                    nextPage,
                    sessionIdArr
                  )
                );
              }
            } else {
              console.log("main else condition");
              if (arr.length) {
                if (sessionIdArr.length < 5) {
                  if (arr.length > 5 - sessionIdArr.length) {
                    arr.splice(5 - sessionIdArr.length);
                    sessionIdArr = [...sessionIdArr, ...arr];

                    dispatch(liveSessionsByCoachIdUpdate(sessionIdArr));
                  } else {
                    sessionIdArr = [...sessionIdArr, ...arr];
                    dispatch(liveSessionsByCoachIdUpdate(sessionIdArr));
                  }
                } else {
                  dispatch(liveSessionsByCoachIdUpdate(sessionIdArr));
                }
              } else {
                dispatch(liveSessionsByCoachIdUpdate(sessionIdArr));
              }
            }
            // dispatch(liveSessionsByCoachIdUpdate(message.toObject()));
            return message.toObject();
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(liveSessionsByCoachIdError());
    }
  };

export const getCompletedLiveSessionByID =
  (sessionId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(liveSessionDetailsLoading(sessionId));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      //reqBody.setcoachId(liveSessionId);
      reqBody.setId(sessionId);
      grpc.unary(LiveMeditationService.GetCompletedLiveSessionsById, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseLiveMeditation>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            console.log(
              "response coming from get session by id",
              message.toObject()
            );
            const data = {
              sessionId: sessionId,
              result: message.toObject().sessioninfo,
            };
            //dispatch(globalLoadingUpdate());
            dispatch(liveSessionDetailsUpdate(data));
          }
        },
      });
    } catch (err) {
      console.log(err);
      //dispatch(globalLoadingError());
    }
  };

export const getAllUserFeedbackBySessionID =
  (liveSessionId: number, pageSize: number, pageNumber: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(feedbackListLoading(liveSessionId));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: RequestPageableWithId = new RequestPageableWithId();
      const reqPage: RequestPageable = new RequestPageable();
      reqPage.setPagenumber(pageNumber);
      reqPage.setPagesize(pageSize);
      reqBody.setPageable(reqPage);
      reqBody.setId(liveSessionId);

      grpc.unary(LiveMeditationService.GetAllUserFeedBacksByLiveSessionId, {
        host: LIVE_MEDITATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },

        onEnd: (res: UnaryOutput<ListOfUserFeedBacks>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            console.log(
              "data coming from get all user feedback",
              message.toObject()
            );
            const assembledPayload = {
              sessionId: liveSessionId,
              sessionFeedback: message.toObject(),
            };
            dispatch(feedbackListUpdate(assembledPayload));
          }
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(feedbackListError(liveSessionId));
    }
  };

export const fetchRemoteConfigHelpDocUrls = () => async (dispatch) => {
  remoteConfig
    .fetchAndActivate()
    .then(() => {
      const val = remoteConfig
        .getValue("live_session_help_admin_panel")
        .asString();

      const rcJSON = JSON.parse(val);
      console.log("REMOTE CONFIG", rcJSON);
      dispatch(updateHelpDocs(rcJSON));
    })
    .catch((err) => console.log("error in remote config\n", err));
};

export const getAllLiveSessionTags =
  (pageSize: number, pageNumber: number, isUnPublished: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(tagsListIsLoading(true));
      const reqBody: RequestPageable = new RequestPageable();
      reqBody.setPagenumber(pageNumber);
      reqBody.setPagesize(pageSize);
      reqBody.setIsunpublished(isUnPublished);

      const jwtToken = await firebaseAuth.currentUser?.getIdToken();

      grpc.unary(LiveMeditationService.GetAllContentTags, {
        request: reqBody,
        host: LIVE_MEDITATION_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<TagsList>) => {
          const { status, message, statusMessage } = res;
          if (status === grpc.Code.OK && message) {
            console.log("LiveMeditation Tags: ", message.toObject());
            dispatch(setTagsList(message.toObject()));
            dispatch(tagsListIsLoading(false));
          } else {
            console.log("Error in fetching tags ", statusMessage);
            dispatch(tagsListIsLoading(false));
          }
        },
      });
    } catch (err) {
      console.log("Error in fetching all content tags: ", err);
    }
  };
