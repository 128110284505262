import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogBox from "components/dialogBox/dialog";
import { useDispatch } from "react-redux";
import {
  addFilter,
  pageableType,
  deleteFilter,
  updateFilter,
} from "redux/actions/contentActions";

interface FilerProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: number;
  setPageNumber: any;
  pageable: pageableType;
}

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
};

const PREFIX = "BlogsForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  audioPlayer: `${PREFIX}-audioPlayer`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "elipse",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.actionIcon}`]: {
    alignSelf: "center",
    left: "45%",
  },

  [`& .${classes.audioPlayer}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export const FilterForm = React.forwardRef<HTMLDivElement, FilerProps>(
  (props, ref): ReactElement => {
    const languageType = [
      "defaultcontent",
      "en",
      "hi",
      "te",
      "gu",
      "ta",
      "ka",
      "mr",
      "it",
      "ru",
      "es",
      "fr",
      "pt",
      "de",
      "bn",
      "ml",
      "uk",
      "od",
      "enUs",
      "enAu",
    ];

    const dispatch = useDispatch();

    const [states, setStates] = useState<State>(initialState);
    const [id, setId] = useState<number>();
    const [contentFilterName, setContentFilterName] = useState({
      defaultcontent: "",
      en: "",
      hi: "",
      te: "",
      gu: "",
      ta: "",
      ka: "",
      mr: "",
      it: "",
      ru: "",
      es: "",
      fr: "",
      pt: "",
      de: "",
      bn: "",
      ml: "",
      uk: "",
      od: "",
      enUs: "",
      enAu: "",
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<number>(0);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });
    const [isAdding, setIsAdding] = useState(false);

    const onClickEdit = useCallback((item: any) => {
      setStates({ editing: true });
      setId(item.id);
      setContentFilterName(item.contentfiltername);
    }, []);

    useEffect(() => {
      if (props.isEditing) {
        onClickEdit(props.editItem);
        setLanguage(props.lang - 1);
      }
    }, [onClickEdit, props.editItem, props.isEditing, props.lang]);

    const validateForm = () => {
      if (contentFilterName[languageType[language]].trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Filter name can not be empty.",
        });
      } else {
        return true;
      }
    };

    const filterHandler = () => {
      console.log("content filters: ", contentFilterName);
      if (validateForm()) {
        setIsLoading(true);
        if (props.isEditing) {
          setIsAdding(true);
          dispatch(
            updateFilter({
              id: Number(id),
              contentFilterName,
              getAllFilterData: props.pageable,
              setSnackBar,
            })
          );
        } else {
          setIsAdding(true);
          dispatch(
            addFilter({
              contentFilterName: {
                ...contentFilterName,
                en: contentFilterName.defaultcontent,
              },
              setSnackBar,
              getAllFilterData: props.pageable,
            })
          );
        }
        setTimeout(() => {
          setIsLoading(false);
          props.onClickBack();
        }, 2000);
      }
    };

    const deleteFilterHandler = () => {
      setIsLoading(true);
      setStates({ showDeleteModal: false });
      props.setPageNumber(0);
      dispatch(
        deleteFilter({
          id: Number(id),
          setSnackBar,
          getAllFilterData: {
            pageNumber: 0,
            pageSize: props.pageable.pageSize,
          },
        })
      );
      setTimeout(() => {
        setIsLoading(false);
        props.onClickBack();
      }, 2000);
    };

    return (
      <Root ref={ref} className={classes.root}>
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          className="p-2 align-items-center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid>
                <IconButton onClick={props.onClickBack} size="large">
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {props.isEditing ? "Edit Filter" : "Add New Filter"}
                </Typography>
                {props.isEditing ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                      color="inherit"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClickBack}
                    >
                      Filters
                    </Typography>
                    <Typography color="inherit" style={{ cursor: "pointer" }}>
                      {props.editItem.contentfiltername[
                        languageType[language]
                      ]?.slice(0, 20)}
                      {props.editItem.contentfiltername[languageType[language]]
                        ?.length > 20 && "..."}
                    </Typography>
                    <Typography color="textPrimary">Edit</Typography>
                  </Breadcrumbs>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setStates({ showDeleteModal: true });
                  }}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                name="filterName"
                label="Filter Name"
                variant="outlined"
                value={contentFilterName[languageType[language]]}
                onChange={(e) => {
                  setContentFilterName((prevState) => ({
                    ...prevState,
                    [languageType[language]]: e.target.value,
                  }));
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            className={classes.formBtn}
            onClick={props.onClickBack}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={classes.formBtn}
            onClick={() => filterHandler()}
            disabled={isAdding}
            color="primary"
            variant="contained"
          >
            {props.isEditing ? "Update Filter" : "Add Filter"}
          </Button>
        </Box>
        <DialogBox
          open={states.showDeleteModal || false}
          onClose={() => {
            setStates({ showDeleteModal: false });
          }}
          onCancel={() => {
            setStates({ showDeleteModal: false });
          }}
          onConfirm={deleteFilterHandler}
          type={"delete"}
          title={`Sure !! Want to delete this Filter ?`}
        />
        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Root>
    );
  }
);
