import {
  createSlice,
  //PayloadAction
} from "@reduxjs/toolkit";

interface AppState {
  languages: Language[];
  uploadFilesList?: File[];
}

const initialState: AppState = {
  languages: [
    {
      displayName: "Default",
      code: 1,
      name: "de",
    },
    {
      displayName: "English",
      code: 2,
      name: "en",
    },
    {
      displayName: "Hindi",
      code: 3,
      name: "hi",
    },
    {
      displayName: "Telugu",
      code: 4,
      name: "te",
    },
    {
      displayName: "Gujarati",
      code: 5,
      name: "gu",
    },
    {
      displayName: "Tamil",
      code: 6,
      name: "ta",
    },
    {
      displayName: "Kannada",
      code: 7,
      name: "ka",
    },
    {
      displayName: "Marathi",
      code: 8,
      name: "mr",
    },
    {
      displayName: "Italian",
      code: 9,
      name: "it",
    },
    {
      displayName: "Russian",
      code: 10,
      name: "ru",
    },
    {
      displayName: "Spanish",
      code: 11,
      name: "es",
    },
    {
      displayName: "French",
      code: 12,
      name: "fr",
    },
    {
      displayName: "Portuguese",
      code: 13,
      name: "pt",
    },
    {
      displayName: "German",
      code: 14,
      name: "de",
    },
    {
      displayName: "Bengali",
      code: 15,
      name: "bn",
    },
    {
      displayName: "Malayalam",
      code: 16,
      name: "ml",
    },
    {
      displayName: "Ukrainian",
      code: 17,
      name: "uk",
    },
    {
      displayName: "Odia",
      code: 18,
      name: "od",
    },
    {
      displayName: "English US",
      code: 19,
      name: "enUs",
    },
    {
      displayName: "English AUS",
      code: 20,
      name: "enAu",
    },
  ],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
});

export default appSlice.reducer;
