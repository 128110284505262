import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Grow,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Chip,
} from "@mui/material";

import { ListSkeleton } from "components/listSkeleton";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGoalsDetails } from "redux/actions/goalsAction";
import { RootState } from "redux/store/store";
import { GoalsForm } from "./goalForm";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import style from "./index.module.scss";
import { getBadgesAsync } from "redux/actions/dashboardActions";
import { MenuProps } from "styles/global.styles";

interface State {
  editing?: boolean;
  editItem?: any;
  lang: number;
}
export default function GoalsScreen(): ReactElement {
  const dispatch = useDispatch();
  //const goalRef: React.MutableRefObject<any> = useRef();
  const state = useSelector((state: RootState) => state.goals.goalsList);
  const initialState = {
    editing: false,
    editItem: null,
    lang: 1,
  };
  const languages = useSelector((state: RootState) => state.app.languages);
  const [language, setLanguage] = useState<any>(1);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [searchGoal, setsearchGoal] = useState("");
  const [searchData, setsearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [states, setStates] = useState<State>(initialState);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllGoalsDetails(language));
  }, [dispatch, language]);

  const onClickBack = () => {
    setcurrentIndex(0);
    setStates({
      editing: false,
      editItem: null,
      lang: language,
    });
  };
  const renderGoalList = (data: any) => {
    return (
      <div>
        {data.length ? (
          <>
            <Grid
              style={{ backgroundColor: "transparent" }}
              className="d-flex py-2 pl-3 align-items-center"
            >
              <Grid className="d-flex flex-grow-1">
                <TextField
                  type="search"
                  fullWidth
                  variant="outlined"
                  placeholder="Search goal by title"
                  size="small"
                  onChange={(e) => {
                    setsearchGoal(e.target.value);
                    const filteredData = data.filter((item: any) =>
                      item.goal.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    setsearchData(filteredData);
                  }}
                  className="mr-2"
                />
              </Grid>
              <Grid className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    dispatch(getAllGoalsDetails(language));
                  }}
                  size="large"
                >
                  <Tooltip title="Refresh">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer
              style={{ maxHeight: "400px" }}
              className={style.scrollbar}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Badge Name</TableCell>
                    <TableCell align="left">Goal Title</TableCell>
                    <TableCell align="left"> Level</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchGoal !== "" ? searchData : data)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: any, x: any) => (
                      <React.Fragment key={x}>
                        <TableRow
                          sx={{ cursor: "pointer" }}
                          hover
                          onClick={() => {
                            setStates({
                              editing: true,
                              editItem: item,
                              lang: language,
                            });
                            setcurrentIndex(1);
                            console.log("Lang", language);
                            dispatch(getBadgesAsync(language));
                          }}
                        >
                          <TableCell align="center">
                            <Avatar src={item.badge.icon} />
                          </TableCell>
                          <TableCell align="left">
                            {item.badge.name ? (
                              item.badge.name
                            ) : (
                              <Chip
                                variant="filled"
                                label="Content not set for this language"
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {item.goal.title ? (
                              item.goal.title
                            ) : (
                              <Chip
                                variant="filled"
                                label="Content not set for this language"
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {item.goal.subtitle ? (
                              item.goal.subtitle
                            ) : (
                              <Chip
                                variant="filled"
                                label="Content not set for this language"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={data.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
              className={style.scrollbar}
            />
          </>
        ) : (
          <Box height={350}>
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No live session to show
              </Typography>
            </Grid>
          </Box>
        )}
      </div>
    );
  };
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mt-3 mb-5">
            <Grow
              in={currentIndex === 0}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 0 ? { timeout: 1000 } : {})}
            >
              <div>
                <Grid container className="align-items-center p-3" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">All Goals</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setcurrentIndex(1);
                        dispatch(getBadgesAsync(language));
                      }}
                      startIcon={<AddIcon />}
                    >
                      Create Goal
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <FormControl variant="outlined" className="m-3" fullWidth>
                    <InputLabel id="language-select">Language</InputLabel>
                    {languages && (
                      <Select
                        defaultValue=""
                        labelId="language-select"
                        value={language}
                        label="Language"
                        onChange={(e) => {
                          setLanguage(e.target.value as number);
                        }}
                        MenuProps={MenuProps}
                      >
                        {languages.map((lang: any, i: any) => (
                          <MenuItem key={i} value={lang.code}>
                            {lang.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Divider />

                <CardContent>
                  <Paper>
                    {state.goalsList.loading
                      ? ListSkeleton()
                      : renderGoalList(state.goalsList.goals)}
                  </Paper>
                </CardContent>
              </div>
            </Grow>
            <Grow
              in={currentIndex === 1}
              mountOnEnter
              unmountOnExit
              style={{ transformOrigin: "0 0 0" }}
              {...(currentIndex === 1 ? { timeout: 1000 } : {})}
            >
              <GoalsForm
                isEditing={states.editing}
                editItem={states.editItem}
                onClickBack={onClickBack}
                lang={language}
              />
            </Grow>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
