import {
  Avatar,
  Card,
  CardContent,
  Divider,
  FormControl,
  Typography,
  TextField,
  Grid,
  Button,
  IconButton,
  CardActionArea,
  InputLabel,
  Snackbar,
  CircularProgress,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import momentz from "moment-timezone";
import { Alert, Autocomplete } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import {
  CoachProfile,
  RequestUpdateBenefits,
  SessionInfo,
  Tag,
  UpdateBenefits,
} from "api/models/live-meditation-service/live-meditation_pb";
//import {DeleteFileRequest} from 'api/models/gamification-service/gamification_pb'

import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBenifitsBySessionId,
  getAllLiveMeditationsBetweenDates,
  getAllLiveSessionTags,
  getAllSessionBenifitsByCoachId,
} from "redux/actions/liveMeditationAction";

import { RootState } from "redux/store/store";

import { firebaseAuth } from "config/firebase";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import {
  GAMIFICATION_SERVICE_HOST,
  LIVE_MEDITATION_SERVICE_HOST,
} from "api/serviceEndpoints";

import { LiveMeditationService } from "api/models/live-meditation-service/live-meditation_pb_service";

import { grpc } from "@improbable-eng/grpc-web";
import style from "./index.module.scss";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useFileUploadService } from "services/FileUplaodService";
import { dataURLtoFile, getCroppedImg, toUploadFile } from "utils/helpers";
import { UploadEventResponse } from "components/UploadManager/UploadListItem";
import PreviewProfileImage from "components/image-preview/profileImagePreview";
import { ImageOutlined } from "@mui/icons-material";
import ImageCrop from "components/image-crop/image-crop";

import { Locale } from "api/models/gamification-service/common/common_pb";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import {
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    maxWidth: 350,
  },
  rootVideo: {
    height: 200,
    width: 300,
  },
  imgCardHeader: {
    height: "20%",
    textOverflow: "elipse",
  },
  progress: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },
  formBtn: {
    marginRight: "20px",
  },
  thumbNail: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "cenrter",
    alignItems: "center",
  },
  media: {
    height: "60%",
  },
  actionIcon: {
    alignSelf: "center",
    left: "45%",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },
}));

interface FormProps {
  coachId?: number;
  onClickBack?: any;
  isEditing?: boolean;
  editItem?: any;
  onClickBackToDetails?: any;
  setcurrentIndex?: any;
  setstartDate?: any;
  setendDate?: any;
  startDate?: any;
  endDate?: any;
  dataLanguage?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20;
}

interface Crop {
  unit: "%" | "px" | undefined;
  width: number;
  aspect: number;
}

const UpdateSession = React.forwardRef<HTMLDivElement, FormProps>(
  (
    {
      onClickBack,
      isEditing,
      editItem,
      setcurrentIndex,
      setstartDate,
      dataLanguage,
      setendDate,
      startDate,
      endDate,
    },
    ref
  ): ReactElement => {
    const hiddenFileInput: any = React.useRef(null);
    const hiddenIconInput: any = React.useRef([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const languages = useSelector((state: RootState) => state.app.languages);
    const tagsList = useSelector((state: RootState) => state.tags.tagList);

    const rootState = useSelector((state: RootState) => state.liveMeditation);
    const { appendFilesToUploadManager } = useFileUploadService();
    const cropDef: Crop = {
      unit: "%",
      width: 30,
      aspect: 16 / 16,
    };

    // const start = moment(moment(new Date()).subtract(3, "day"))
    //   .startOf("day")
    //   .format("YYYY-MM-DD , HH:mm:ss");
    // const end = moment(new Date()).endOf("day").format("YYYY-MM-DD,HH:mm:ss");
    const [language, setLanguage] = useState<any>(
      isEditing && languages[editItem.sessioninfo?.selectedlanguage - 1].code
    );
    const [sessionTitle, setsessionTitle] = useState("");
    const [sessionSubTitle, setsessionSubTitle] = useState("");
    const [sessionInfo, setsessionInfo] = useState("");
    const [aboutSession, setaboutSession] = useState("");
    const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [duration, setduration] = useState<number>(30);
    const [scheduleDate, setscheduleDate] = useState<any>(new Date());
    const [registerCount, setregisterCount] = useState("");
    const [liveParticipantsCount, setliveParticipantsCount] = useState("");
    const [CoachId, setCoachId] = useState<any>(null);
    const [sessionId, setsessionId] = useState<any>(null);
    const [scheduleDateTime, setscheduleDateTime] = useState<any>(new Date());
    const [scheduleTimeZone, setscheduleTimeZone] = useState<any>(new Date());
    const [timeZone, settimeZone] = useState<any>(moment.tz.guess());
    const [inputTimeZoneValue, setinputTimeZoneValue] = useState("");
    const [link, setLink] = useState("");
    const [stepsForMeditation, setstepsForMeditation] = useState<any>([]);
    const [coachName, setcoachName] = useState("");
    const [coachDescription, setcoachDescription] = useState("");

    const [iconRef, seticonRef] = useState<any>();
    const [iconSrc, seticonSrc] = useState<any>(null);
    const [iconCrop, seticonCrop] = useState<Crop>(cropDef);
    const [iconFile, seticonFile] = useState<any>(null);
    const [croppedIcon, setCroppedIcon] = useState<any>(null);

    const [currentIconIndex, setcurrentIconIndex] = useState(0);
    const [benefitsArr, setbenefitsArr] = useState<any>([]);
    const [benefitsArrNew, setbenefitsArrNew] = useState<any>([]);
    const [updatedLiveSessionData, setupdatedLiveSessionData] = useState<any>(
      {}
    );
    const [updatedCoachProfile, setupdatedCoachProfile] = useState<any>({});

    const [imageRef, setImageRef] = useState<any>();
    const [src, setSrc] = useState<any>(null);
    const [crop, setCrop] = useState<Crop>(cropDef);
    const [imageFile, setImageFile] = useState<any>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);
    const [open, setopen] = useState(false);

    const [editScheduleSession, seteditScheduleSession] = useState(false);
    const [successScheduleSession, setsuccessScheduleSession] = useState(false);
    const [successCoachProfile, setsuccessCoachProfile] = useState(false);

    const [editSessionBenefits, seteditSessionBenefits] = useState<any>({
      editBenefit: false,
      addNewBenefit: false,
      addExistingBenefit: false,
    });
    const [editCoachProfile, seteditCoachProfile] = useState(false);

    // const [deleteSession, setdeleteSession] =
    //   useState<State>(initialDeleteState);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackBar, setSnackBar] = useState<any>({
      open: false,
      severity: "success",
      message: "",
    });

    useEffect(() => {
      if (isEditing) {
        // dispatch(getAllBenifitsBySessionId(Number(editItem.coachprofile.sessioninfo?.id)));
        onEditSession(editItem);
        onEditCoachProfile(editItem);
        console.log("EditItem: ", editItem);
      }
      onEditBenifits();
    }, [
      isEditing,
      editItem,
      rootState.benifitsListById.benifits.listofbenefitsList,
    ]);

    useEffect(() => {
      dispatch(getAllLiveSessionTags(10, 0, false));
    }, [dispatch]);

    const onEditSession = (item: any) => {
      setsessionTitle(item.sessioninfo?.sessiontitle);
      setsessionSubTitle(item.sessioninfo?.sessionsubtitle);
      setsessionInfo(item.sessioninfo?.sessioninfo);
      setaboutSession(item.sessioninfo?.aboutsession);
      // setTimestamp(item.sessioninfo?.scheduledtime?.seconds);
      setduration(item.sessioninfo?.duration);
      setscheduleDate(
        new Date(Number(item.sessioninfo?.scheduledtime?.seconds) * 1000)
      );
      setscheduleDateTime(
        new Date(Number(item.sessioninfo?.scheduledtime?.seconds) * 1000)
      );
      setregisterCount(item.sessioninfo?.registeredusercount);
      setliveParticipantsCount(item.sessioninfo?.liveParticipantsCount);
      setCoachId(item.coachprofile?.id);
      setsessionId(item.sessioninfo?.id);
      setLink(item.sessioninfo?.link);
      setSelectedTagsId(item.sessioninfo?.tagidsList);
      setSelectedTags(item.sessioninfo?.tagsList.map((item) => item.name));

      const arr = item.sessioninfo?.meditationsteps
        .split("\n")
        .map((step: any) => ({ stepValue: step }));
      setstepsForMeditation(arr);
    };

    const handleMultipleTagChange = (event: any) => {
      const {
        target: { value },
      } = event;
      // console.log(value);

      const arrOfNum: number[] = (
        typeof value === "string" ? value.split(",") : value
      ).map((str) => {
        return Number(str);
      });
      const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
      const tagIndex = selectedTagsId.indexOf(val[0]);

      setSelectedTagsId(arrOfNum);

      if (tagIndex > -1) {
        setSelectedTags(() => {
          const updatedTags = [...selectedTags];
          updatedTags.splice(tagIndex, 1);
          // console.log("updated Tags: ", updatedTags);
          return updatedTags;
        });
      } else if (tagIndex === -1) {
        setSelectedTags(() => {
          const temp = tagsList.tags.tagsList.filter((item) =>
            value.includes(item.id)
          );

          const arrOfString = temp.map((item: any) => item.name);
          return [...new Set([...selectedTags, ...arrOfString])];
        });
      }
    };

    const tagsArray = () => {
      let arr: Tag[] = [];
      for (const tagIndex in selectedTags) {
        const temp = new Tag();
        temp.setId(selectedTagsId[tagIndex]);
        temp.setName(selectedTags[tagIndex]);
        arr.push(temp);
      }
      console.log("tagsArray: ", arr);
      return arr;
    };

    const onEditCoachProfile = (item: any) => {
      setcoachName(item.coachprofile?.name);
      switch (item.sessioninfo.selectedlanguage) {
        case 2:
          setcoachDescription(item.coachprofile?.profiledescription.en);
          break;
        case 3:
          setcoachDescription(item.coachprofile?.profiledescription.hi);
          break;
        case 4:
          setcoachDescription(item.coachprofile.profiledescription.te);
          break;
        case 5:
          setcoachDescription(item.coachprofile.profiledescription.gu);
          break;
        case 6:
          setcoachDescription(item.coachprofile.profiledescription.ta);
          break;
        case 7:
          setcoachDescription(item.coachprofile.profiledescription.ka);
          break;
        case 8:
          setcoachDescription(item.coachprofile.profiledescription.mr);
          break;
        case 9:
          setcoachDescription(item.coachprofile.profiledescription.it);
          break;
        case 10:
          setcoachDescription(item.coachprofile.profiledescription.ru);
          break;
        case 11:
          setcoachDescription(item.coachprofile.profiledescription.es);
          break;
        case 12:
          setcoachDescription(item.coachprofile.profiledescription.fr);
          break;
        case 13:
          setcoachDescription(item.coachprofile.profiledescription.pt);
          break;
        case 14:
          setcoachDescription(item.coachprofile.profiledescription.de);
          break;
        case 15:
          setcoachDescription(item.coachprofile.profiledescription.bn);
          break;
        case 16:
          setcoachDescription(item.coachprofile.profiledescription.ml);
          break;
        case 17:
          setcoachDescription(item.coachprofile.profiledescription.uk);
          break;
        case 18:
          setcoachDescription(item.coachprofile.profiledescription.od);
          break;
        case 19:
          setcoachDescription(item.coachprofile.profiledescription.enUs);
          break;
        case 20:
          setcoachDescription(item.coachprofile.profiledescription.enAu);
          break;
        default:
          setcoachDescription(
            item.coachprofile?.profiledescription.defaultcontent
          );
      }
      setCroppedImageUrl(item.coachprofile?.imageurl);
    };
    const onEditBenifits = () => {
      const arr = rootState.benifitsListById.benifits.listofbenefitsList.map(
        (item) => ({
          idofbenefit: item.id,
          description: item.description,
          imageurl: item.imageurl,
          removebenefit: false,
          editBenefits: false,
        })
      );
      // console.log(
      //   "benefit array",
      //   rootState.benifitsListById.benifits.listofbenefitsList
      // );
      setbenefitsArr(arr);
      setbenefitsArrNew(arr);
    };

    const onClickAdd = () => {
      setstepsForMeditation([...stepsForMeditation, { stepValue: "" }]);
    };
    const onClickDelete = (i: any) => {
      var newstepsForMeditation = [...stepsForMeditation];

      if (i === 0) {
        newstepsForMeditation[i].stepValue = "";
      } else {
        newstepsForMeditation.splice(i, 1);
      }
      setstepsForMeditation(newstepsForMeditation);
    };
    const onDeleteBenefits = (i: any) => {
      let newbenefitsArr = [...benefitsArr];
      newbenefitsArr.splice(i, 1);
      setbenefitsArr(newbenefitsArr);
    };
    const _onOpenAddCoach = () => {
      setopen(true);
    };
    const _onCloseAddCoach = () => {
      setopen(false);
    };
    //--------------------------------for profile image upload------------------------------>
    const onSelection = (event: any) => {
      if (event.target.files[0]) {
        setImageFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          setSrc(reader.result);
          setopen(true);
        };
      }
    };

    const onImageClose = () => {
      // const data={
      //   imgPath:croppedImageUrl
      // }
      setSrc(null);
      setImageFile(null);
      setCroppedImage(null);
      setImageFile(null);
      setCrop(cropDef);
      //setCroppedImageUrl("");
      _deleteFileFromGcp(croppedImageUrl);
    };
    const onCropDone = async () => {
      const cr: any = crop;
      if (imageRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(imageRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${imageFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        setCroppedImageUrl(croppedUrl);
        const uploadFile = toUploadFile(croppedFile, "ProfilePicture");
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              console.log(
                "img url response from update session",
                res.detail.response
              );
              setCroppedImageUrl(res.detail.response);
            }
          }
        );
        setCroppedImage(croppedFile);
      }
      setSrc(null);
    };

    const onImageLoaded = (image: any) => {
      setImageRef(image);
    };

    const onCropChange = (crop: any) => {
      setCrop(crop);
    };
    const onCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };

    const handleUploadClick = () => {
      hiddenFileInput.current.click();
    };
    const _deleteFileFromGcp = async (data: any) => {
      try {
        console.log("image url to delete from gcp", data);
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: DeleteFileRequest = new DeleteFileRequest();
        reqBody.setImgpath(data);

        //CroppedImageUrl
        //setIsLoading(true);
        grpc.unary(GamificationService.DeleteFileFromGCP, {
          host: GAMIFICATION_SERVICE_HOST,
          request: reqBody,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ResponseStatus>) => {
            const { status, message, statusMessage } = res;
            console.log("statusMessage", statusMessage);
            if (status === grpc.Code.OK && message) {
              if (message) {
                //setIsLoading(false);
                console.log(
                  "data coming from delete file from gcp",
                  message.toObject()
                );
                setCroppedImageUrl("");
              }
            } else {
              setIsLoading(false);
              // setSnackBar({
              //   open: true,
              //   severity: "error",
              //   message: "error in removing coach image",
              // });
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    //-------------------------------image upload end------------------------------->
    //---------------------------for icon upload------------------------------------->

    const handleIconClick = (idx: any) => {
      hiddenIconInput.current[idx].click();
    };
    const onIconSelection = (event: any, index: any) => {
      setcurrentIconIndex(index);
      if (event.target.files[0]) {
        seticonFile(event.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
          seticonSrc(reader.result);
          setopen(true);
        };
      }
    };
    const onIconCropDone = async () => {
      const cr: any = iconCrop;
      if (iconRef && cr.width && cr.height) {
        const croppedUrl = await getCroppedImg(iconRef, cr);
        const croppedFile: File = dataURLtoFile(
          croppedUrl,
          `${new Date().getTime().toString()}_${iconFile.name.replaceAll(
            " ",
            ""
          )}`
        );
        const uploadFile = toUploadFile(
          croppedFile,
          "ProfilePicture".replaceAll(" ", "")
        );
        appendFilesToUploadManager([uploadFile]);
        window.addEventListener(
          uploadFile.token,
          (res: CustomEventInit<UploadEventResponse>) => {
            if (res.detail?.code === 200) {
              let newbenefitsArrNew = [...benefitsArrNew];
              newbenefitsArrNew[currentIconIndex].imageurl =
                res.detail.response;
              setbenefitsArr(newbenefitsArrNew);
              let newbenefitsArr = [...benefitsArr];
              newbenefitsArr[currentIconIndex].imageurl = res.detail.response;
              setbenefitsArr(newbenefitsArr);
            }
          }
        );
        setCroppedIcon(croppedFile);
      }
      seticonSrc(null);
    };
    const onIconLoaded = (image: any) => {
      seticonRef(image);
    };

    const onIconCropChange = (crop: any) => {
      seticonCrop(crop);
    };
    const onIconCropComplete = async () => {
      //console.log("state crop", crop, imageRef);
    };
    const onIconClose = (index: number) => {
      seticonSrc(null);
      seticonFile(null);
      setCroppedIcon(null);
      seticonFile(null);
      seticonCrop(cropDef);
      let newbenefitsArrNew = [...benefitsArrNew];
      _deleteFileFromGcp(newbenefitsArrNew[index].imageurl);
      newbenefitsArrNew[index].imageurl = "";
      setbenefitsArr(newbenefitsArrNew);
      let newbenefitsArr = [...benefitsArr];
      newbenefitsArr[index].imageurl = "";

      setbenefitsArr(newbenefitsArr);
    };

    //------------------------icon upload end----------------------------------------->

    const validateForm = () => {
      if (sessionTitle.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "session title can not be empty",
        });
      } else if (aboutSession.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "about session can not be empty",
        });
        // } else if (selectedTagsId.length === 0) {
        //   setSnackBar({
        //     open: true,
        //     severity: "error",
        //     message: "Minimum 1 tag needs to be selected",
        //   });
        // } else if (selectedTagsId.length > 3) {
        //   setSnackBar({
        //     open: true,
        //     severity: "error",
        //     message: "Maximum 3 tags can be selected",
        //   });
      } else {
        return true;
      }
    };
    const validateCoachForm = () => {
      if (coachName.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: " Coach name can not be empty",
        });
      } else if (coachDescription.trim() === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "coach profile description  can not be empty",
        });
      } else if (croppedImageUrl === "") {
        setSnackBar({
          open: true,
          severity: "error",
          message: "coach profile image  can not be empty",
        });
      } else {
        return true;
      }
    };
    const updatedSessionData = (item: any) => {
      setsessionTitle(item.sessiontitle);
      setaboutSession(item.aboutsession);
      setscheduleDate(new Date(Number(item.scheduledtime?.seconds) * 1000));
      setscheduleDateTime(
        new Date(Number(item.sessioninfo?.scheduledtime?.seconds) * 1000)
      );
      const arr = item.meditationsteps
        .split("\n")
        .map((step: any) => ({ stepValue: step }));
      setstepsForMeditation(arr);
    };

    const updateSession = async (data: any) => {
      try {
        console.log("updated session data", data);
        var updatemeditaionReq = new SessionInfo();

        const currentGoogleTimeStamp: Timestamp = new Timestamp();
        currentGoogleTimeStamp.fromDate(new Date(data.scheduledTime));
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        updatemeditaionReq.setId(data.id);
        updatemeditaionReq.setScheduledtime(currentGoogleTimeStamp);
        updatemeditaionReq.setSessiontitle(data.sessionTitle);
        updatemeditaionReq.setSessionsubtitle(data.sessionSubTitle);
        updatemeditaionReq.setSessioninfo(data.sessionInfo);
        updatemeditaionReq.setDuration(Number(data.duration));
        updatemeditaionReq.setAboutsession(data.aboutSession);
        updatemeditaionReq.setAssociatedcoachid(data.associatedCoachId);
        updatemeditaionReq.setLiveparticipantscount(data.liveParticipantsCount);
        updatemeditaionReq.setRegisteredusercount(data.registeredUserCount);
        updatemeditaionReq.setMeditationsteps(data.meditationSteps);
        updatemeditaionReq.setImageurl(data.imageURL);
        updatemeditaionReq.setVideourl(data.videoURL);
        updatemeditaionReq.setUpdateallocatedcoach(true);
        updatemeditaionReq.setLink(data.link);
        updatemeditaionReq.setTagsList(tagsArray());

        grpc.unary(LiveMeditationService.UpdateScheduleSession, {
          request: updatemeditaionReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<SessionInfo>) => {
            const { status, message, statusMessage } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());

              const updatedData = message.toObject();
              setSnackBar({
                open: true,
                severity: "success",
                message: "session updated successfully",
              });
              const end = moment(moment(new Date()).add(7, "day"))
                .endOf("day")
                .format("YYYY-MM-DD");
              const start = moment(new Date())
                .startOf("day")
                .format("YYYY-MM-DD");

              dispatch(
                getAllLiveMeditationsBetweenDates(start, end, dataLanguage)
              );
              setupdatedLiveSessionData(updatedData);
              updatedSessionData(updatedData);
              setsuccessScheduleSession(true);
              seteditScheduleSession(false);
              setstartDate(start);
              setendDate(end);
              setTimeout(() => {
                onClickBack();
              }, 1000);
              // setcurrentIndex(3);
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "Error in updating session",
              });
              onEditSession(editItem);
              seteditScheduleSession(false);
              console.log("UpdateError: ", statusMessage);
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "error in  update session ",
        });
        console.error("something went wrong in update session", error);
      }
    };

    const _updateSession = () => {
      const medSteps = stepsForMeditation
        .map((item: any) => item.stepValue)
        .join("\n");

      if (validateForm()) {
        const data = {
          id: sessionId,
          // scheduledTime: scheduleDate,
          scheduledTime: moment(scheduleDateTime)
            .tz(moment.tz.guess())
            .format(),
          sessionTitle: sessionTitle,
          sessionSubTitle: sessionSubTitle,
          sessionInfo: sessionInfo,
          duration: duration,
          registeredUserCount: registerCount,
          liveParticipantsCount: liveParticipantsCount,
          aboutSession: aboutSession,
          meditationSteps: medSteps,
          associatedCoachId: CoachId,
          imageURL: "",
          videoURL: "",
          link: link,
        };
        updateSession(data);
      }
    };

    const updateBenefits = () => {
      const data = {
        updateBenefits: benefitsArrNew,
        liveSessionID: sessionId,
      };
      UpdateSessionBenefits(data);
    };
    const UpdateSessionBenefits = async (data: any) => {
      console.log("update benefit data", data);
      try {
        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        const reqBody: RequestUpdateBenefits = new RequestUpdateBenefits();
        reqBody.setUpdatebenefitsList(
          data.updateBenefits.map((benefits: UpdateBenefits.AsObject) => {
            let _updateBenifits = new UpdateBenefits();
            _updateBenifits.setDescription(benefits.description);
            _updateBenifits.setIdofbenefit(benefits.idofbenefit);
            _updateBenifits.setImageurl(benefits.imageurl);
            _updateBenifits.setRemovebenefit(benefits.removebenefit);
            return _updateBenifits;
          })
        );
        reqBody.setLivesessionid(data.liveSessionID);
        grpc.unary(LiveMeditationService.UpdateSessionBenefits, {
          request: reqBody,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res: UnaryOutput<ProtobufMessage>) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok: ", message.toObject());
              setSnackBar({
                open: true,
                severity: "success",
                message: "session benefits updated successfully",
              });
              //dispatch(getAllLiveMeditationsBetweenDates(start, end));
              dispatch(getAllBenifitsBySessionId(Number(sessionId)));
              setcurrentIndex(3);
              seteditSessionBenefits({
                ...editSessionBenefits,
                editBenefit: false,
              });
              return message;
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in  update session benefit",
              });
              dispatch(getAllBenifitsBySessionId(Number(sessionId)));
              seteditSessionBenefits({
                ...editSessionBenefits,
                editBenefit: false,
              });
            }
          },
        });
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error in  updating benefit",
        });
        console.error("something went wrong in update session benefits", error);
      }
    };

    const decideLocale = () => {
      console.log(language);

      switch (language) {
        case 1:
          return {
            description: {
              defaultContent: coachDescription,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 2:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: coachDescription,
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 3:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: coachDescription,
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 4:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: coachDescription,
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 5:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: coachDescription,
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 6:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: coachDescription,
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 7:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: coachDescription,
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 8:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: coachDescription,
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 9:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: coachDescription,
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 10:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: coachDescription,
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 11:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: coachDescription,
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 12:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: coachDescription,
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 13:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: coachDescription,
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 14:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: coachDescription,
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 15:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: coachDescription,
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 16:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: coachDescription,
              uk: "",
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 17:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: coachDescription,
              od: "",
              enUs: "",
              enAu: "",
            },
          };
        case 18:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: coachDescription,
              enUs: "",
              enAu: "",
            },
          };
        case 19:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: coachDescription,
              enAu: "",
            },
          };
        case 20:
          return {
            description: {
              defaultContent:
                editItem.coachprofile.profiledescription.defaultcontent,
              en: "",
              hi: "",
              te: "",
              gu: "",
              ta: "",
              ka: "",
              mr: "",
              it: "",
              ru: "",
              es: "",
              fr: "",
              pt: "",
              de: "",
              bn: "",
              ml: "",
              uk: "",
              od: "",
              enUs: "",
              enAu: coachDescription,
            },
          };
      }
    };

    const _updateCoach = (item: any) => {
      if (validateCoachForm()) {
        const coachData: any = {
          id: item.coachprofile?.id,
          name: coachName,
          email: item.coachprofile?.email,
          description: decideLocale()?.description,
          imageURL: croppedImageUrl,
          videoURL: "awsCommonChannel",
          playBackUrl: item.coachprofile?.playBackUrl,
        };
        updateCoach(coachData);
      }
    };

    const _updatedCoachData = (updatedCoachData: any) => {
      console.log(updatedCoachData);
      setcoachName(updatedCoachData.name);
      switch (editItem.sessioninfo.selectedlanguage) {
        case 2:
          setcoachDescription(updatedCoachData.profiledescription.en);
          break;
        case 3:
          setcoachDescription(updatedCoachData.profiledescription.hi);
          break;
        case 4:
          setcoachDescription(updatedCoachData.profiledescription.te);
          break;
        case 5:
          setcoachDescription(updatedCoachData.profiledescription.gu);
          break;
        case 6:
          setcoachDescription(updatedCoachData.profiledescription.ta);
          break;
        case 7:
          setcoachDescription(updatedCoachData.profiledescription.ka);
          break;
        case 8:
          setcoachDescription(updatedCoachData.profiledescription.mr);
          break;
        case 9:
          setcoachDescription(updatedCoachData.profiledescription.it);
          break;
        case 10:
          setcoachDescription(updatedCoachData.profiledescription.ru);
          break;
        case 11:
          setcoachDescription(updatedCoachData.profiledescription.es);
          break;
        case 12:
          setcoachDescription(updatedCoachData.profiledescription.fr);
          break;
        case 13:
          setcoachDescription(updatedCoachData.profiledescription.pt);
          break;
        case 14:
          setcoachDescription(updatedCoachData.profiledescription.de);
          break;
        case 15:
          setcoachDescription(updatedCoachData.profiledescription.bn);
          break;
        case 16:
          setcoachDescription(updatedCoachData.profiledescription.ml);
          break;
        case 17:
          setcoachDescription(updatedCoachData.profiledescription.uk);
          break;
        case 18:
          setcoachDescription(updatedCoachData.profiledescription.od);
          break;
        case 19:
          setcoachDescription(updatedCoachData.profiledescription.enUs);
          break;
        case 20:
          setcoachDescription(updatedCoachData.profiledescription.enAu);
          break;
        default:
          setcoachDescription(
            updatedCoachData.profiledescription.defaultcontent
          );
      }
      setCroppedImageUrl(updatedCoachData.imageurl);
    };

    const updateCoach = async (data: any) => {
      try {
        const descriptionLocale: Locale = new Locale();
        descriptionLocale.setDefaultcontent(data.description.defaultContent);
        descriptionLocale.setEn(data.description.en);
        descriptionLocale.setHi(data.description.hi);
        descriptionLocale.setTe(data.description.te);
        descriptionLocale.setGu(data.description.gu);
        descriptionLocale.setTa(data.description.ta);
        descriptionLocale.setKa(data.description.ka);
        descriptionLocale.setMr(data.description.mr);
        descriptionLocale.setIt(data.description.it);
        descriptionLocale.setRu(data.description.ru);
        descriptionLocale.setEs(data.description.es);
        descriptionLocale.setFr(data.description.fr);
        descriptionLocale.setPt(data.description.pt);
        descriptionLocale.setDe(data.description.decideLocale);
        descriptionLocale.setBn(data.description.bn);
        descriptionLocale.setMl(data.description.ml);
        descriptionLocale.setUk(data.description.uk);
        descriptionLocale.setOd(data.description.od);
        descriptionLocale.setEnUs(data.description.enUs);
        descriptionLocale.setEnAu(data.description.enAu);
        const updateCoachReq = new CoachProfile();

        updateCoachReq.setName(data.name);
        updateCoachReq.setEmail(data.email);
        updateCoachReq.setProfiledescription(descriptionLocale);
        updateCoachReq.setImageurl(data.imageURL);
        updateCoachReq.setVideourl(data.videoURL);
        updateCoachReq.setId(data.id);
        updateCoachReq.setPlaybackurl(data.playBackUrl);

        const jwtToken = await firebaseAuth.currentUser?.getIdToken();
        console.log("update coach payload", updateCoachReq.toObject());
        grpc.unary(LiveMeditationService.UpdateCoach, {
          request: updateCoachReq,
          host: LIVE_MEDITATION_SERVICE_HOST,
          metadata: {
            Authorization: `Bearer ${jwtToken}`,
          },
          onEnd: (res) => {
            const { status, message } = res;

            if (status === grpc.Code.OK && message) {
              console.log("all ok. got book: ", message.toObject());
              const updatedCoachData = message.toObject();
              console.log("updated Coach Data", updatedCoachData);
              setSnackBar({
                open: true,
                severity: "success",
                message: "coach updated successfully",
              });

              setcurrentIndex(3);
              _updatedCoachData(updatedCoachData);
              seteditCoachProfile(false);
              setsuccessCoachProfile(true);
              setupdatedCoachProfile(updatedCoachData);
            } else {
              setSnackBar({
                open: true,
                severity: "error",
                message: "error in  updating coach",
              });
              seteditCoachProfile(false);
              onEditCoachProfile(editItem);
            }
          },
        });
      } catch (error) {
        console.error("something went wrong in update Coach", error);
        setSnackBar({
          open: true,
          severity: "error",
          message: "something went wrong in update Coach",
        });
      }
    };

    return (
      <div ref={ref}>
        {/* <Card variant="outlined" className="mt-3 mb-5"> */}
        {isLoading && (
          <CircularProgress
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{
            padding: " 8px",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  onClick={() => {
                    onClickBack();
                  }}
                  size="large"
                >
                  <KeyboardBackspaceIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">Reschedule Live Session</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {/* -------------------------session details start-------------------------------- */}
            <Grid item xs={12}>
              <Card variant="outlined">
                <Grid
                  container
                  spacing={2}
                  className="p-3 align-items-center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography style={{ fontWeight: "bold" }}>
                      Session Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={() => seteditScheduleSession(true)}
                          disabled={editScheduleSession}
                        >
                          Edit
                        </Button> */}
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            _updateSession();
                          }}
                          // disabled={!editScheduleSession}
                          disabled={duration < 30 || duration > 60}
                        >
                          Save
                        </Button>
                      </Grid>
                      {/* <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            seteditScheduleSession(false);

                            successScheduleSession
                              ? updatedSessionData(updatedLiveSessionData)
                              : onEditSession(editItem);
                          }}
                          // disabled={!editScheduleSession}
                        >
                          Cancel
                        </Button>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent
                  style={{ maxHeight: "590px", overflowY: "auto" }}
                  className={style.scrollbar}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={4} lg={3}>
                      <FormControl variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            inputFormat="dd/MM/yyyy hh:mm a"
                            label="Schedule Date And Time"
                            // size="small"
                            // error={false}
                            // helperText={null}
                            value={scheduleDate}
                            onChange={(value: any) => {
                              //console.log('time zone',timeZone);
                              const time = moment(value)
                                .tz(`${timeZone}`)
                                .format("DD/MM/yyyy hh:mm a ");
                              console.log(
                                `on date change time for ${timeZone}`,
                                value
                              );
                              //const time=moment.tz(value, 'YYYY-MM-DD HH:mm:ss', `${timeZone}`).format('DD/MM/yyyy hh:mm a ');
                              console.log(
                                `on date change time for ${timeZone}`,
                                time
                              );
                              const newTime = moment(value)
                                .tz(`${timeZone}`)
                                .format();
                              // const newTime=moment.tz(value, 'YYYY-MM-DD HH:mm:ss', `${timeZone}`).format();
                              console.log(
                                `on date change new time for ${timeZone}`,
                                newTime
                              );
                              setscheduleDateTime(newTime);
                              setscheduleDate(value);
                              setscheduleTimeZone(value);
                            }}
                            renderInput={(params) => (
                              <TextField variant={"outlined"} {...params} />
                            )}
                            // inputVariant={
                            //   editScheduleSession ? "outlined" : "standard"
                            // }
                            // readOnly={!editScheduleSession}
                            // TextFieldComponent={(props) => (
                            //   <TextField
                            //     InputProps={{
                            //       readOnly: true,
                            //     }}
                            //     {...props}
                            //   />
                            // )}
                            //InputLabelProps={{ style: { fontSize: 20 } }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        disableClearable
                        value={timeZone}
                        onChange={(event, newValue) => {
                          // console.log('current time zone', moment.tz('Asia/Bahrain').format('DD/MM/yyyy hh:mm a' ))
                          settimeZone(newValue);
                          // const time = moment.tz(scheduleTimeZone, 'YYYY-MM-DD HH:mm:ss', `${newValue}`).format('DD/MM/yyyy hh:mm a')
                          const time = moment(scheduleTimeZone)
                            .tz(`${newValue}`)
                            .format("DD/MM/yyyy hh:mm a");
                          setscheduleDate(time);
                          console.log(
                            `on select change time for ${newValue}`,
                            time
                          );
                          // const newTime=moment.tz(scheduleTimeZone, 'YYYY-MM-DD HH:mm:ss', `${newValue}`).format()
                          const newTime = moment(scheduleTimeZone)
                            .tz(`${newValue}`)
                            .format();
                          console.log(
                            `on select change new time for ${newValue}`,
                            newTime
                          );
                          setscheduleDateTime(newTime);
                        }}
                        inputValue={inputTimeZoneValue}
                        onInputChange={(event, newInputValue) => {
                          setinputTimeZoneValue(newInputValue);
                        }}
                        // disabled={!editScheduleSession}
                        id="combo-box-demo"
                        options={momentz.tz.names()}
                        style={{ maxWidth: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Timezone"
                            // variant={
                            //   editScheduleSession ? "outlined" : "standard"
                            // }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={3} xs={4}>
                      <TextField
                        fullWidth
                        required
                        defaultValue={30}
                        type="number"
                        label="Duration"
                        value={duration}
                        error={duration < 30 || duration > 60}
                        helperText={
                          duration < 30 || duration > 60
                            ? "Enter a value between 30 and 60 minutes"
                            : ""
                        }
                        onChange={(e) => {
                          setduration(e.target.value as unknown as number);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                      <FormControl variant="outlined" fullWidth disabled>
                        <InputLabel id="language-select">Language</InputLabel>
                        {languages && (
                          <Select
                            defaultValue=""
                            labelId="language-select"
                            value={language}
                            label="Language"
                            onChange={(e) => {
                              setLanguage(e.target.value as number);
                              // setIsLangSelected(true);
                              console.log("language", language);
                            }}
                          >
                            {languages.map((lang, i) => (
                              <MenuItem key={i} value={lang.code}>
                                {lang.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        label="Session Title"
                        name="session title"
                        // size="small"
                        required
                        // variant={editScheduleSession ? "outlined" : "standard"}
                        value={sessionTitle}
                        onChange={(e) => setsessionTitle(e.target.value)}
                        //InputLabelProps={{ style: { fontSize: 20 } }}
                        // InputProps={{
                        //   readOnly: editScheduleSession ? false : true,
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        label="About session"
                        name="About session"
                        required
                        // variant={editScheduleSession ? "outlined" : "standard"}
                        // size="small"
                        multiline
                        rows={1}
                        maxRows={20}
                        inputProps={{
                          // maxLength: CHARACTER_LIMIT,
                          style: {
                            fontSize: 14,
                          },
                          // readOnly: editScheduleSession ? false : true,
                        }}
                        value={aboutSession}
                        onChange={(e) => setaboutSession(e.target.value)}
                        //InputLabelProps={{ style: { fontSize: 20 } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Link"
                        name="Link"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="multiple-input">Tags</InputLabel>
                        <Select
                          labelId="tags-multiple-checkbox-label"
                          id="tags-multiple-checkbox"
                          multiple
                          disabled
                          value={selectedTagsId}
                          onChange={handleMultipleTagChange}
                          input={<OutlinedInput label="Tags" />}
                          renderValue={() => selectedTags.join(",")}
                        >
                          {tagsList.tags.tagsList.map((x: any) => (
                            <MenuItem key={x.id} value={x.id}>
                              <Checkbox
                                checked={selectedTagsId.indexOf(x.id) > -1}
                              />
                              <ListItemText primary={String(x.name)} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Card variant="outlined">
                        <Typography color="textSecondary" className="pl-3 pt-3">
                          Meditation Steps
                        </Typography>
                        <CardContent>
                          <Grid container>
                            {stepsForMeditation.map((item: any, index: any) => (
                              <Grid
                                item
                                xs={12}
                                key={index}
                                className="d-flex align-items-start mt-4"
                              >
                                <TextField
                                  //fullWidth
                                  //size="small"
                                  label={`Step ${index + 1}`}
                                  name="Meditation steps"
                                  // variant={
                                  //   editScheduleSession
                                  //     ? "outlined"
                                  //     : "standard"
                                  // }
                                  multiline
                                  rows={1}
                                  maxRows={20}
                                  inputProps={{
                                    //maxLength: 150,
                                    style: {
                                      fontSize: 14,
                                    },
                                    // readOnly: editScheduleSession
                                    //   ? false
                                    //   : true,
                                  }}
                                  value={item.stepValue}
                                  onChange={(e) => {
                                    let newstepsForMeditation = [
                                      ...stepsForMeditation,
                                    ];
                                    newstepsForMeditation[index].stepValue =
                                      e.target.value;
                                    setstepsForMeditation(
                                      newstepsForMeditation
                                    );
                                  }}
                                  style={{ flexGrow: 1, marginRight: "10px" }}
                                />
                                <Grid className="d-flex align-items-center">
                                  <Grid>
                                    {index ? (
                                      <IconButton
                                        onClick={() => onClickDelete(index)}
                                        // disabled={!editScheduleSession}
                                        size="large"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        style={{ visibility: "hidden" }}
                                        size="large"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                            <Grid className="mt-2">
                              <Button
                                color="primary"
                                variant="contained"
                                style={{ textTransform: "none" }}
                                onClick={() => onClickAdd()}
                                // disabled={!editScheduleSession}
                              >
                                Add row
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* -------------------------session details end-------------------------------- */}

            {/* -------------------------session benifits start-------------------------------- */}
            <Grid item xs={12}>
              <Card variant="outlined">
                <Grid
                  container
                  spacing={2}
                  className="p-3"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography style={{ fontWeight: "bold" }}>
                      Session Benefits
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            seteditSessionBenefits({
                              ...editSessionBenefits,
                              addNewBenefit: true,
                            });
                            setcurrentIndex(4);
                            dispatch(
                              getAllSessionBenifitsByCoachId(3, 0, CoachId)
                            );
                          }}
                          disabled={
                            editSessionBenefits.addNewBenefit ||
                            editSessionBenefits.editBenefit
                          }
                        >
                          Add benefits
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            seteditSessionBenefits({
                              ...editSessionBenefits,
                              editBenefit: true,
                            });
                          }}
                          disabled={
                            editSessionBenefits.editBenefit ||
                            !rootState.benifitsListById.benifits
                              .listofbenefitsList.length
                          }
                        >
                          Edit
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => updateBenefits()}
                          disabled={!editSessionBenefits.editBenefit}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            seteditSessionBenefits({
                              ...editSessionBenefits,
                              editBenefit: false,
                            });
                            onEditBenifits();
                          }}
                          disabled={!editSessionBenefits.editBenefit}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                {rootState.benifitsListById.benifits.listofbenefitsList
                  .length !== 0 ? (
                  <CardContent
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                    className={style.scrollbar}
                  >
                    {benefitsArr.map((item: any, index: any) => {
                      return (
                        <div key={index}>
                          {editSessionBenefits.editBenefit ? (
                            <div>
                              <Grid
                                container
                                spacing={2}
                                className="align-items-center mt-2 "
                              >
                                <Grid item xs={10} sm={10} md={4}>
                                  <FormControl variant="outlined" fullWidth>
                                    <Card
                                      variant="outlined"
                                      className={classes.rootVideo}
                                    >
                                      {item.imageurl ? (
                                        <PreviewProfileImage
                                          onImageClose={onIconClose}
                                          src={item.imageurl}
                                          file={croppedIcon}
                                          size="small"
                                          index={index}
                                        />
                                      ) : (
                                        <CardActionArea
                                          className={classes.thumbNail}
                                          onClick={() => {
                                            handleIconClick(index);
                                          }}
                                        >
                                          <InputLabel htmlFor="image">
                                            Upload Icon
                                          </InputLabel>
                                          <Avatar
                                            className={classes.actionIcon}
                                            variant="square"
                                          >
                                            <ImageOutlined />
                                          </Avatar>
                                          <input
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            hidden
                                            onChange={(e) =>
                                              onIconSelection(e, index)
                                            }
                                            //ref={hiddenIconInput}
                                            ref={(el) =>
                                              (hiddenIconInput.current[index] =
                                                el)
                                            }
                                          />
                                        </CardActionArea>
                                      )}
                                    </Card>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={10} sm={10} md={7}>
                                  <TextField
                                    fullWidth
                                    label="Benefits"
                                    name="Benefits"
                                    variant="outlined"
                                    multiline
                                    rows={7}
                                    maxRows={20}
                                    inputProps={{
                                      //maxLength: CHARACTER_LIMIT,
                                      style: {
                                        fontSize: 14,
                                      },
                                    }}
                                    value={item.description}
                                    onChange={(e) => {
                                      let newbenefitsArr = [...benefitsArrNew];
                                      newbenefitsArr[index].description =
                                        e.target.value;
                                      setbenefitsArrNew(newbenefitsArr);
                                      let benefitsAr = [...benefitsArr];
                                      benefitsAr[index].description =
                                        e.target.value;
                                      setbenefitsArr(benefitsAr);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={1} md={1}>
                                  <IconButton
                                    //disabled={editSessionBenefits.editBenefit}
                                    onClick={() => {
                                      let newbenefitsArr = [...benefitsArrNew];
                                      newbenefitsArr[index].removebenefit =
                                        true;
                                      setbenefitsArrNew(newbenefitsArr);
                                      onDeleteBenefits(index);
                                    }}
                                    size="large"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            <div>
                              <Grid
                                container
                                className="p-2"
                                key={index}
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  xs={2}
                                  sm={1}
                                  md={1}
                                  style={{ maxWidth: "60px" }}
                                >
                                  <Avatar
                                    //style={{ backgroundColor: "#3f51b5" }}
                                    src={item.imageurl}
                                  />
                                </Grid>
                                <Grid item xs={10} sm={11} md={11}>
                                  <Typography variant="body1">
                                    {item.description}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider style={{ margin: "0 10px " }} />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </CardContent>
                ) : (
                  <CardContent className="p-3">
                    <Typography align="center">
                      No benefits available.
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </Grid>
            {/* -------------------------session benifits end-------------------------------- */}

            {/* -------------------------coach details start-------------------------------- */}
            <Grid item xs={12}>
              <Card variant="outlined">
                <Grid
                  container
                  spacing={2}
                  className="p-3"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography style={{ fontWeight: "bold" }}>
                      Coach Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            seteditCoachProfile(true);
                          }}
                          disabled={editCoachProfile}
                        >
                          Edit
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => _updateCoach(editItem)}
                          className="mx-2"
                          disabled={!editCoachProfile}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            seteditCoachProfile(false);
                            successCoachProfile
                              ? _updatedCoachData(updatedCoachProfile)
                              : onEditCoachProfile(editItem);
                          }}
                          disabled={!editCoachProfile}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent
                  style={{ maxHeight: "450px", overflowY: "auto" }}
                  className={style.scrollbar}
                >
                  <Grid container spacing={3} alignItems="center">
                    {/* <Grid item xs={4}> */}
                    {editCoachProfile ? (
                      <Grid item xs={12} sm={12} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Card variant="outlined" className={classes.root}>
                            {croppedImageUrl ? (
                              <PreviewProfileImage
                                onImageClose={onImageClose}
                                src={croppedImageUrl}
                                file={croppedImage}
                              />
                            ) : (
                              <CardActionArea
                                className={classes.thumbNail}
                                onClick={() => {
                                  handleUploadClick();
                                }}
                              >
                                <InputLabel htmlFor="image">
                                  Upload Profile Image
                                </InputLabel>
                                <Avatar
                                  className={classes.actionIcon}
                                  variant="square"
                                >
                                  <ImageOutlined />
                                </Avatar>
                                <input
                                  accept="image/*"
                                  id="contained-button-file"
                                  type="file"
                                  hidden
                                  onChange={onSelection}
                                  ref={hiddenFileInput}
                                />
                              </CardActionArea>
                            )}
                          </Card>
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={4}>
                        <Grid container justifyContent="center">
                          {croppedImageUrl === "" ||
                          croppedImageUrl === null ? (
                            <Avatar
                              src=""
                              style={{ height: "200px", width: "200px" }}
                            />
                          ) : (
                            <img
                              alt="coach Profile"
                              src={croppedImageUrl}
                              style={{
                                height: "200px",
                                width: "200px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {/* </Grid> */}
                    <Grid item md={8} sm={12} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Coach Name"
                            name="Coach Name"
                            required
                            variant={editCoachProfile ? "outlined" : "standard"}
                            value={coachName}
                            onChange={(e) => setcoachName(e.target.value)}
                            //InputLabelProps={{ style: { fontSize: 20 } }}
                            InputProps={{
                              readOnly: editCoachProfile ? false : true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Coach Profile Description"
                            name="Coach Profile Description"
                            required
                            variant={editCoachProfile ? "outlined" : "standard"}
                            multiline
                            rows={1}
                            maxRows={20}
                            inputProps={{
                              // maxLength: CHARACTER_LIMIT,
                              style: {
                                fontSize: 14,
                              },
                              readOnly: editCoachProfile ? false : true,
                            }}
                            value={coachDescription}
                            onChange={(e) =>
                              setcoachDescription(e.target.value)
                            }
                            //InputLabelProps={{ style: { fontSize: 20 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* -------------------------coach details end-------------------------------- */}
          </Grid>
        </CardContent>
        {src && (
          <ImageCrop
            src={src.toString()}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onCropComplete={onCropComplete}
            onCropChange={onCropChange}
            onPressDone={onCropDone}
            onImageClose={onImageClose}
            open={open}
            onOpen={_onOpenAddCoach}
            onClose={_onCloseAddCoach}
          />
        )}
        {iconSrc && (
          <ImageCrop
            src={iconSrc.toString()}
            crop={iconCrop}
            onImageLoaded={onIconLoaded}
            onCropComplete={onIconCropComplete}
            onCropChange={onIconCropChange}
            onPressDone={onIconCropDone}
            onImageClose={onIconClose}
            open={open}
            onOpen={_onOpenAddCoach}
            onClose={_onCloseAddCoach}
          />
        )}

        <Snackbar
          open={snackBar.open}
          autoHideDuration={10000}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            severity={snackBar.severity}
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
);

export default UpdateSession;
