import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ContentCategoryRequest,
  DeleteFileRequest,
  ResponseStatus,
} from "api/models/gamification-service/gamification_pb";
import { firebaseAuth } from "config/firebase";
import DialogBox from "components/dialogBox/dialog";
import { Locale } from "api/models/gamification-service/common/common_pb";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { RootState } from "redux/store/store";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import {
  GAMIFICATION_SERVICE_HOST,
  HFN_EVENTS_SERVICE_HOST,
} from "api/serviceEndpoints";
import { grpc } from "@improbable-eng/grpc-web";
import UploadFileContent from "components/upload-file-content/UploadFileContent";
import {
  getAllBhandaraTags,
  getAllYatraGarden,
} from "redux/actions/bhandaraActions";
import { HFNEventsService } from "api/models/hfn-events-service/hfn-events_pb_service";
import {
  Content,
  ID,
  Status,
  Tag,
} from "api/models/hfn-events-service/hfn-events_pb";
import { ContentCategory } from "api/models/hfn-events-service/common/common_pb";
import { Root, classes } from "./YatraGarden.styles";
import { localeObject } from "constants/lang";

interface State {
  editing?: boolean;
  editItem?: any;
  showDeleteModal?: boolean;
  deleteItemId?: String;
}

const initialState = {
  editing: false,
  editItem: null,
  showDeleteModal: false,
  deleteItemId: "0",
};

const languageType = Object.keys(localeObject);

export const YatraGardenForm = React.forwardRef<
  HTMLDivElement,
  YatraGardenFormProps
>((props, ref): ReactElement => {
  const dispatch = useDispatch();
  const tagsList = useSelector((state: RootState) => state.bhandara.tagList);
  const [language, setLanguage] = useState<any>(1);
  const [states, setStates] = useState<State>(initialState);
  const [id, setId] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [readingTime, setReadingTime] = useState<string>("10");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [isAdding, setIsAdding] = useState(false);
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<any>(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [selectedTagsId, setSelectedTagsId] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [snackBar, setSnackBar] = useState<any>({
    open: false,
    severity: "success",
    message: "",
  });

  const onClickEdit = useCallback(
    (item: any) => {
      setStates({ editing: true });
      setLanguage(props.lang);
      setId(item.id);
      setTitle(item.title);
      setSubtitle(item.subtitle);
      setReadingTime(item.duration);
      setAudioUrl(item.contenturl);
      setThumbnailImageUrl(item.thumbnailimageurl);
      setBackgroundImageUrl(item.backgroundimageurl);
      setSelectedTagsId(item.tagsList.map((item) => item.id));
      setSelectedTags(item.tagsList.map((item) => item.name));
    },
    [props.lang]
  );

  useEffect(() => {
    console.log("Props: ", props.editItem);
    if (props.isEditing) {
      onClickEdit(props.editItem);
    }
  }, [props.isEditing, props.editItem, onClickEdit]);

  useEffect(() => {
    dispatch(getAllBhandaraTags(10, page));
  }, [page, dispatch]);

  const handleMultipleTagChange = (event: any) => {
    const {
      target: { value },
    } = event;
    // console.log(value);

    const arrOfNum: number[] = (
      typeof value === "string" ? value.split(",") : value
    ).map((str) => {
      return Number(str);
    });
    const val = selectedTagsId.filter((item) => value.indexOf(item) === -1);
    const tagIndex = selectedTagsId.indexOf(val[0]);
    setSelectedTagsId(arrOfNum);
    if (tagIndex > -1) {
      setSelectedTags(() => {
        const updatedTags = [...selectedTags];
        updatedTags.splice(tagIndex, 1);
        // console.log("updated Tags: ", updatedTags);
        return updatedTags;
      });
    } else if (tagIndex === -1) {
      setSelectedTags(() => {
        const temp = tagsList.tags.tagsList.filter((item) =>
          value.includes(item.id)
        );

        const arrOfString = temp.map((item: any) => item.name);
        return [...new Set([...selectedTags, ...arrOfString])];
      });
    }
  };
  const tagsArray = () => {
    let arr: Tag[] = [];
    for (const tagIndex in selectedTags) {
      const temp = new Tag();
      temp.setId(selectedTagsId[tagIndex]);
      temp.setName(selectedTags[tagIndex]);
      arr.push(temp);
    }

    console.log("tagsArray: ", arr);
    return arr;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const _deleteFileFromGcp = async (data: any) => {
    try {
      console.log("pdf url to delete from gcp", data);
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: DeleteFileRequest = new DeleteFileRequest();
      reqBody.setImgpath(data);
      grpc.unary(GamificationService.DeleteFileFromGCP, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<ResponseStatus>) => {
          const { status, message, statusMessage } = res;
          console.log("statusMessage", statusMessage);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(
                "data coming from delete file from gcp",
                message.toObject()
              );
            }
          } else {
            setIsLoading(false);
          }
        },
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };
  const onThumbnailImageClose = () => {
    _deleteFileFromGcp(thumbnailImageUrl);
    setThumbnailImageUrl("");
  };
  const onBackgroundImageClose = () => {
    _deleteFileFromGcp(backgroundImageUrl);
    setBackgroundImageUrl("");
  };

  const onAudioClose = () => {
    _deleteFileFromGcp(audioUrl);
    setAudioUrl("");
  };

  const onClickDelete = (pdfId: any) => {
    setStates({ showDeleteModal: true, deleteItemId: pdfId });
  };

  const handleDeleteClose = () => {
    setStates({ showDeleteModal: false });
  };

  const deleteYatraGarden = async () => {
    deleteYatraGardenById(states.deleteItemId);
  };
  const deleteYatraGardenById = async (pdfId: String | undefined) => {
    setIsLoading(true);
    console.log(`Delete call for YatraGarden with id ${pdfId}`);
    try {
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: ID = new ID();
      reqBody.setId(Number(pdfId));
      grpc.unary(HFNEventsService.DeleteContentById, {
        host: HFN_EVENTS_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Status>) => {
          const { status, message } = res;
          console.log("status", status);
          if (status === grpc.Code.OK && message) {
            if (message) {
              console.log(`Delete Yatra Garden response ${message.toObject()}`);
              setIsLoading(false);
              _deleteFileFromGcp(audioUrl);
              setStates({ showDeleteModal: false });
              setTimeout(() => props.onClickBack(), 2000);
              dispatch(
                getAllYatraGarden(props.rowsPerPage, props.page, props.lang)
              );
              setSnackBar({
                open: true,
                severity: "success",
                message: " Deleted Yatra garden successfully",
              });
            } else {
              setIsLoading(false);
              setStates({ showDeleteModal: false });
              setSnackBar({
                open: true,
                severity: "Error",
                message: "Error in  deleting yatra garden",
              });
            }
          }
        },
      });
    } catch (err) {
      setIsLoading(false);
      console.log(`Error: ${err}`);
      setSnackBar({
        open: true,
        severity: "Error",
        message: "Error in  deleting yatra garden",
      });
    }
  };

  const validateForm = () => {
    if (title.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Yatra garden title can not be empty.",
      });
    } else if (subtitle.trim() === "") {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Yatra garden description can not be empty.",
      });
    } else if (!thumbnailImageUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Thumbnail image can not be empty.",
      });
    } else if (!backgroundImageUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Background image can not be empty.",
      });
    } else if (!audioUrl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "audio can not be empty.",
      });
    } else {
      return true;
    }
  };

  const _addYatraGarden = () => {
    if (validateForm()) {
      const messageData = {
        title: {
          ...localeObject,
          ...(language === 1 || language === 2
            ? { defaultcontent: title, en: title }
            : { [languageType[language - 1]]: title }),
        },
        subtitle: {
          ...localeObject,
          ...(language === 1 || language === 2
            ? { defaultcontent: subtitle, en: subtitle }
            : { [languageType[language - 1]]: subtitle }),
        },

        contentUrl: {
          ...localeObject,
          ...(language === 1 || language === 2
            ? { defaultcontent: audioUrl, en: audioUrl }
            : { [languageType[language - 1]]: audioUrl }),
        },
        readingTime: readingTime,
        thumbnailimageurl: thumbnailImageUrl,
        backgroundimageurl: backgroundImageUrl,
      };
      setIsAdding(true);
      addYatraGarden(messageData);
    }
  };

  const addYatraGarden = async (data: any) => {
    setIsLoading(true);
    try {
      console.log(`Request to adding new message ${data}`);

      const titleLocale: Locale = new Locale();
      titleLocale.setDefaultcontent(data.title?.defaultcontent);
      titleLocale.setEn(data.title.en);
      titleLocale.setHi(data.title.hi);
      titleLocale.setTe(data.title.te);
      titleLocale.setGu(data.title.gu);
      titleLocale.setTa(data.title.ta);
      titleLocale.setKa(data.title.ka);
      titleLocale.setMr(data.title.mr);
      titleLocale.setIt(data.title.it);
      titleLocale.setRu(data.title.ru);
      titleLocale.setEs(data.title.es);
      titleLocale.setFr(data.title.fr);
      titleLocale.setPt(data.title.pt);
      titleLocale.setDe(data.title.de);
      titleLocale.setBn(data.title.bn);
      titleLocale.setMl(data.title.ml);
      titleLocale.setUk(data.title.uk);
      titleLocale.setOd(data.title.od);
      titleLocale.setEnUs(data.title.enUs);
      titleLocale.setEnAu(data.title.enAu);

      const subtitletLocale: Locale = new Locale();
      subtitletLocale.setDefaultcontent(data.subtitle?.defaultcontent);
      subtitletLocale.setEn(data.subtitle.en);
      subtitletLocale.setHi(data.subtitle.hi);
      subtitletLocale.setTe(data.subtitle.te);
      subtitletLocale.setGu(data.subtitle.gu);
      subtitletLocale.setTa(data.subtitle.ta);
      subtitletLocale.setKa(data.subtitle.ka);
      subtitletLocale.setMr(data.subtitle.mr);
      subtitletLocale.setIt(data.subtitle.it);
      subtitletLocale.setRu(data.subtitle.ru);
      subtitletLocale.setEs(data.subtitle.es);
      subtitletLocale.setFr(data.subtitle.fr);
      subtitletLocale.setPt(data.subtitle.pt);
      subtitletLocale.setDe(data.subtitle.de);
      subtitletLocale.setBn(data.subtitle.bn);
      subtitletLocale.setMl(data.subtitle.ml);
      subtitletLocale.setUk(data.subtitle.uk);
      subtitletLocale.setOd(data.subtitle.od);
      subtitletLocale.setEnUs(data.subtitle.enUs);
      subtitletLocale.setEnAu(data.subtitle.enAu);

      const contentLocale: Locale = new Locale();
      contentLocale.setDefaultcontent(data.contentUrl?.defaultcontent);
      contentLocale.setEn(data.contentUrl.en);
      contentLocale.setHi(data.contentUrl.hi);
      contentLocale.setTe(data.contentUrl.te);
      contentLocale.setGu(data.contentUrl.gu);
      contentLocale.setTa(data.contentUrl.ta);
      contentLocale.setKa(data.contentUrl.ka);
      contentLocale.setMr(data.contentUrl.mr);
      contentLocale.setIt(data.contentUrl.it);
      contentLocale.setRu(data.contentUrl.ru);
      contentLocale.setEs(data.contentUrl.es);
      contentLocale.setFr(data.contentUrl.fr);
      contentLocale.setPt(data.contentUrl.pt);
      contentLocale.setDe(data.contentUrl.de);
      contentLocale.setBn(data.contentUrl.bn);
      contentLocale.setMl(data.contentUrl.ml);
      contentLocale.setUk(data.contentUrl.uk);
      contentLocale.setOd(data.contentUrl.od);
      contentLocale.setEnUs(data.contentUrl.enUs);
      contentLocale.setEnAu(data.contentUrl.enAu);

      const reqBody = new Content();
      props.isEditing && reqBody.setId(props.editItem.id);
      reqBody.setTitle(titleLocale);
      reqBody.setSubtitle(subtitletLocale);
      reqBody.setContenturl(contentLocale);
      reqBody.setThumbnailimageurl(thumbnailImageUrl);
      reqBody.setBackgroundimageurl(backgroundImageUrl);
      reqBody.setDuration(data.readingTime);
      reqBody.setTagsList(tagsArray());
      reqBody.setContentcategory(ContentCategory.YATRA_GARDEN);

      console.log("Request for updating yatra", reqBody.toObject());
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      grpc.unary(HFNEventsService.AddOrUpdateContent, {
        request: reqBody,
        host: HFN_EVENTS_SERVICE_HOST,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<Content>) => {
          const { status, message, statusMessage } = res;
          setIsLoading(false);
          if (status === grpc.Code.OK && message) {
            console.log("update message response", message.toObject());
            setIsLoading(false);
            dispatch(
              getAllYatraGarden(props.rowsPerPage, props.page, props.lang)
            );
            setSnackBar({
              open: true,
              severity: "success",
              message: `Yatra garden ${
                props.isEditing ? "updated" : "added"
              } successfully`,
            });
            setTimeout(() => props.onClickBack(), 2000);
          } else {
            setIsLoading(false);
            setSnackBar({
              open: true,
              severity: "error",
              message: `Error in ${
                props.isEditing ? "updat" : "add"
              } message : ${statusMessage}`,
            });
          }
        },
      });
    } catch (error) {
      setIsLoading(false);
      setIsLoading(false);
      console.log(`Error in update message ${error}`);
      setSnackBar({
        open: true,
        severity: "error",
        message: error,
      });
    }
  };

  return (
    <Root ref={ref} className={classes.root}>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", right: "50%" }}
        />
      )}
      <Grid
        container
        className="p-2 align-items-center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <IconButton onClick={props.onClickBack} size="large">
                <KeyboardBackspaceIcon color="action" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {props.isEditing ? "Edit Yatra Garden" : "Add new Yatra Garden"}
              </Typography>
              {props.isEditing ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={props.onClickBack}
                  >
                    Daaji's Message
                  </Typography>
                  <Tooltip
                    title={props.editItem.title}
                    disableHoverListener={props.editItem.title?.length < 20}
                    placement="bottom"
                  >
                    <Box>
                      <Typography color="inherit" style={{ cursor: "pointer" }}>
                        {props.editItem.title?.slice(0, 20)}
                        {props.editItem.title?.length > 20 && "..."}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Typography color="textPrimary">Edit</Typography>
                </Breadcrumbs>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.isEditing ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => onClickDelete(id)}
              >
                Delete
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="title"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              name="Subtitle"
              label="Subtitle"
              variant="outlined"
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value);
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              type="number"
              name="Duratione"
              label="Duration"
              variant="outlined"
              value={readingTime}
              onChange={(e) => {
                setReadingTime(e.target.value > "0" ? e.target.value : "");
              }}
              className="mr-3"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
              <Select
                labelId="tags-multiple-checkbox-label"
                id="tags-multiple-checkbox"
                multiple
                value={selectedTagsId}
                onChange={handleMultipleTagChange}
                input={<OutlinedInput label="Tags" />}
                renderValue={() => selectedTags.join(",")}
                MenuProps={{
                  sx: {
                    maxHeight: "500px",
                  },
                }}
              >
                {tagsList.loading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  tagsList.tags.tagsList.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      <Checkbox checked={selectedTagsId.indexOf(x.id) > -1} />
                      <ListItemText primary={String(x.name)} />
                    </MenuItem>
                  ))
                )}
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={tagsList.tags.pageable?.totalcount || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={10}
                  style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
                />
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <UploadFileContent
              title="Thumbnail Image"
              fileUrl={thumbnailImageUrl}
              setFileUrl={setThumbnailImageUrl}
              type="image"
              onFileClose={onThumbnailImageClose}
              language={language}
              uploadPath="Bhandara/yatra-garden/images"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <UploadFileContent
              title="Background Image"
              fileUrl={backgroundImageUrl}
              setFileUrl={setBackgroundImageUrl}
              type="image"
              onFileClose={onBackgroundImageClose}
              language={language}
              uploadPath="Bhandara/yatra-garden/images"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <UploadFileContent
              title="Audio"
              fileUrl={audioUrl}
              setFileUrl={setAudioUrl}
              type="audio"
              onFileClose={onAudioClose}
              language={language}
              uploadPath="Bhandara/yatra-garden/audios"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.formBtn}
          onClick={props.onClickBack}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          className={classes.formBtn}
          onClick={_addYatraGarden}
          color="primary"
          variant="contained"
          disabled={isAdding}
        >
          {props.isEditing ? "Update Yatra Garden" : "Add Yatra Garden"}
        </Button>
      </Box>

      <DialogBox
        open={states.showDeleteModal || false}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={deleteYatraGarden}
        type={"delete"}
        title={`Sure !! Want to delete this Yatra garden?`}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          severity={snackBar.severity}
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </Root>
  );
});
